<template>
  <!-- Top Menu Items -->
  <nav class="navbar navbar-inverse navbar-fixed-top">
    <div class="mobile-only-brand pull-left">
      <div class="nav-header pull-left">
        <div class="logo-wrap">
          <router-link to="/">
            <img
              v-if="site.logo_path"
              style="width: 40px; height: 40px"
              class="brand-img"
              :src="mediaUrl + site.logo_path"
            />
            <span class="brand-text">{{ site.name }}</span>
          </router-link>
        </div>
      </div>
      <a
        id="toggle_nav_btn"
        class="toggle-left-nav-btn inline-block ml-20 pull-left"
        href="javascript:void(0);"
        ><i class="zmdi zmdi-menu"></i
      ></a>
      <a
        id="toggle_mobile_search"
        data-toggle="collapse"
        data-target="#search_form"
        class="mobile-only-view"
        href="javascript:void(0);"
        ><i class="zmdi zmdi-search"></i
      ></a>
      <a
        id="toggle_mobile_nav"
        class="mobile-only-view"
        href="javascript:void(0);"
        ><i class="zmdi zmdi-more"></i
      ></a>
      <form
        id="search_form"
        role="search"
        class="top-nav-search collapse pull-left"
        style="padding:0px"
      >
        <select-site></select-site>
      </form>
    </div>
    <div id="mobile_only_nav" class="mobile-only-nav pull-right">
      <ul class="nav navbar-right top-nav pull-right">
        <!-- <li>
          <a id="open_right_sidebar" href="#"
            ><i class="zmdi zmdi-settings top-nav-icon"></i
          ></a>
        </li> -->
        <li>
          <a href="javascript:void" @click="logout"
                ><i class="zmdi zmdi-power"></i><span>&nbsp; Log Out</span></a
              >
        </li>
        <!-- <li class="dropdown auth-drp">
          <a href="#" class="dropdown-toggle pr-0" data-toggle="dropdown"
            ><img
              :src="mediaUrl + '/user.png'"
              alt="user_auth"
              class="user-auth-img img-circle" /><span
              class="user-online-status"
            ></span
          ></a>
          <ul
            class="dropdown-menu user-auth-dropdown"
            data-dropdown-in="flipInX"
            data-dropdown-out="flipOutX"
          >
            <li>
              <router-link to="/profile">
                <i class="zmdi zmdi-account"></i><span>Profile</span>
              </router-link>
            </li>
            <li class="divider"></li>
            <li>
              <a href="javascript:void" @click="logout"
                ><i class="zmdi zmdi-power"></i><span>Log Out</span></a
              >
            </li>
          </ul>
        </li> -->
      </ul>
    </div>
  </nav>
  <!-- /Top Menu Items -->
</template>

<script type="text/javascript">
import { mapActions } from "vuex";
import store from "@/store";
import SelectSite from "../widgets/select-site.vue";

export default {
  computed: {
    site() {
      let site = store.getters["auth/site"];
      return site ? site : {};
    },
  },
  components: {
    SelectSite,
  },
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
      location.replace("/");
    },
  },
};
</script>
