<template>
  <section id="mydiv">
    <div class="container-fluid">
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">LOGO</h5>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12"></div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <h3>V ALLIANCE</h3>
            <p>{{ site.address }}</p>
          </h5>
        </div>
      </div>
    </div>
    <br />
    <div class="container-fluid row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <br />
        <p>EMP Code : {{ monthlySalaryOfUser.employee_id }}</p>
        <p>ESIC No : {{ monthlySalaryOfUser.employee ? monthlySalaryOfUser.employee.esi_number : '' }}</p>
        <p>Joining Date : {{ monthlySalaryOfUser.employee.joined_on }}</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <!-- <p>Payslip for the month :</p> -->
        <p>Payslip for the month : {{ $route.query.monthId | mm }} {{ $route.query.year }}</p>
        <p>Name : {{ monthlySalaryOfUser.employee ? monthlySalaryOfUser.employee.full_name : '' }}</p>
        <p>Department : {{ department_category ? department_category : "" }}</p>
        <p>UAN No : {{ monthlySalaryOfUser.employee.uan_no }}</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p>Branch : {{ branch_category ? branch_category : "" }}</p>
        <p>
          Designation : {{ designation_category ? designation_category : "" }}
        </p>
        <p>
          Division :
          {{
            monthlySalaryOfUser.employee.group_division
              ? monthlySalaryOfUser.employee.group_division.division_name
              : ""
          }}
        </p>
        <p>PF No : {{ monthlySalaryOfUser.employee.pf_no }}</p>
      </div>
    </div>
    <hr />
    <div class="container-fluid row">
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>Days Paid : {{ monthlySalaryOfUser.payable_days }}</p>
        <p>OT hours : {{ monthlySalaryOfUser.ot_hrs }}</p>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>Days Peresent : {{ monthlySalaryOfUser.days_present }}</p>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>W.Off/Pd.Off : 4.00/0.00</p>
        <p>PL : 0.00</p>
        <p>Bal PL No : 0.00</p>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>LWP/Absent : 0.00/0.00</p>
      </div>
    </div>
    <hr />
    <div class="container-fluid">
      <table class="table table-responsive col-md-10">
        <thead>
          <tr>
            <th>Earning & Reimbursements</th>
            <th>Gross Amt</th>
            <th>Actual Amt</th>
            <th>Deduction & Recoveries</th>
            <th>Gross Amt</th>
            <th>Actual Amt</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Basic Salary</td>
            <td>{{ monthlySalaryOfUser.employee.user_salaries[0].fixed_basic }}</td>
            <td>{{ monthlySalaryOfUser.employee.user_salaries[0].fixed_basic }}</td>
            <td>PROV. FUND</td>
            <td>{{ monthlySalaryOfUser.employee_pf }}</td>
            <td>{{ monthlySalaryOfUser.employee_pf }}</td>
          </tr>
          <tr>
            <td>Conveyance Allowance</td>
            <td>{{ monthlySalaryOfUser.employee.user_salaries[0].fixed_conveyance }}</td>
            <td>{{ monthlySalaryOfUser.employee.user_salaries[0].fixed_conveyance }}</td>
            <td>E.S.I.C</td>
            <td>{{ monthlySalaryOfUser.employee_esic }}</td>
            <td>{{ monthlySalaryOfUser.employee_esic }}</td>
          </tr>
          <tr>
            <td>Other Allowance</td>
            <td>{{ monthlySalaryOfUser.employee.user_salaries[0].fixed_other_allowance }}</td>
            <td>{{ monthlySalaryOfUser.employee.user_salaries[0].fixed_other_allowance }}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Leave Encashment</td>
            <td>{{ monthlySalaryOfUser.employee.user_salaries[0].leave_encashment }}</td>
            <td>{{ monthlySalaryOfUser.employee.user_salaries[0].leave_encashment }}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>OVER TIME</td>
            <td>{{ monthlySalaryOfUser.employee.user_salaries[0].ot_rate }}</td>
            <td>{{ monthlySalaryOfUser.employee.user_salaries[0].ot_rate }}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th>Total Earnings</th>
            <th>
              {{
                monthlySalaryOfUser.employee.user_salaries[0].fixed_basic +
                  monthlySalaryOfUser.employee.user_salaries[0].fixed_conveyance +
                  monthlySalaryOfUser.employee.user_salaries[0].fixed_other_allowance +
                  monthlySalaryOfUser.employee.user_salaries[0].leave_encashment +
                  monthlySalaryOfUser.employee.user_salaries[0].ot_rate
              }}
            </th>
            <th>
              {{
                monthlySalaryOfUser.employee.user_salaries[0].fixed_basic +
                  monthlySalaryOfUser.employee.user_salaries[0].fixed_conveyance +
                  monthlySalaryOfUser.employee.user_salaries[0].fixed_other_allowance +
                  monthlySalaryOfUser.employee.user_salaries[0].leave_encashment +
                  monthlySalaryOfUser.employee.user_salaries[0].ot_rate
              }}
            </th>
            <th>
              <p>Total Deductions</p>
              <p>Net Pay</p>
            </th>
            <th>
              {{ monthlySalaryOfUser.employee.user_salaries[0].employee_esic + monthlySalaryOfUser.employee.user_salaries[0].employee_pf }}
            </th>
            <th>
              <p>
                {{
                  monthlySalaryOfUser.employee.user_salaries[0].employee_pf +
                    monthlySalaryOfUser.employee.user_salaries[0].employee_esic +
                    monthlySalaryOfUser.employee.user_salaries[0].employee_mlwf +
                    monthlySalaryOfUser.employee.user_salaries[0].employee_pt
                }}
              </p>
              <p>{{ monthlySalaryOfUser.employee.user_salaries[0].net_in_hand }}</p>
            </th>
          </tr>
        </tbody>
      </table>
      <p>Net Pay: {{ monthlySalaryOfUser.employee.user_salaries[0].NetinHandinWords ? monthlySalaryOfUser.employee.user_salaries[0].NetinHandinWords : 'Zero'}} Only</p>
      <p>
        *This is computer generated salary slip and requires no physical
        signature or stamp*
      </p>
      <p>
        Bank Name: {{ monthlySalaryOfUser.employee.bank_name.name }} - Bank A/c No. :
        {{ monthlySalaryOfUser.employee.bank_acc_no }}
      </p>
    </div>
  </section>
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  data() {
    return {
      monthlySalaryOfUser: {},
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
   async getUser() {
      let monthlySalaryOfUser = await axios.get(`/pt_reports/generate_payslip3_by_id/${this.$route.params.payslip3Id}/${this.$route.query.monthId}`);
      this.monthlySalaryOfUser = monthlySalaryOfUser.data.data.monthlySalaryOfUser
    },
  },
};
</script>
