<template>
  <section id="mydiv">
    <div class="container-fluid">
      <div class="col-md-6">
        <center><p>E.S.I.C</p></center>
        <p style="float: right;">Challan No. 1</p>
        <br>
        <p style="font-weight: bold">
          <center>EMPLOYEE'S STATE INSURANCE FUND ACCOUNT NO - 1</center>
        </p>
        <p style="font-weight: bold">
          <center>PAY-IN-SLIP FOR CONTIBUTION</center>
        </p>
        <p style="font-weight: bold"><center>STATE BANK OF INDIA</center></p>
        <div class="col-md-3">
          <p style="font-weight: bold">Station</p>
        </div>
        <div class="col-md-3">AHEMDABAD</div>
        <div class="col-md-3">Date:</div>
        <div class="col-md-3">14/05/2021</div>
        <div class="col-md-12"></div>
        <div class="col-md-6">
          <table id="table-example-1" class="table table-responsive col-md-10">
            <thead>
              <tr>
                <th rowspan="2">Particulars of Cash / Cheque</th>
                <th>Amount</th>
              </tr>
              <tr>
                <th>Rs. P.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>77305.00</td>
              </tr>
              <tr>
                <th>TOTAL</th>
                <th>77305.00</th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <p>Paid into the credit of the Employee's State</p>
          <p>Insurance Fund</p>
          <p>
            Accound No. 1 Rs. <span style="font-weight: bold">77305.00</span>
          </p>
          <p>(Rupee Seventy Seven tousand Three Hundred Five Ony)</p>
          <br />
          <br />
          <p>In Cash/by Cheque (on reallisation) for payment</p>
          <p>of contribution as per details given below</p>
          <p>under the Employee State Insurance Act 1948.</p>
          <p>under the month of April 2021</p>
        </div>
        <div class="col-md-12">
          <p>Emplyer's Code No. :</p>
          <p>Deposited bt:</p>
        </div>
        <div class="col-md-12">
          <hr />
          <center>
            <p>Name And Address of the Factory / Establishment</p>
          </center>
          <p style="font-size: 20px; font-weight: bold">V ALLIANCE</p>
          <p>Office No-5-A, Raheja Arcade, Sector-11,</p>
          <p>CBD Belapur, Navi Mumbai-4000614.</p>
          <p>Plot no-61</p>
          <p>Navi Mumbai-4000614.</p>
        <hr />
        </div>
        <div class="col-md-12"></div>
        <div class="col-md-6">
          <p>No. Of Employees : <span style="font-weight: bold">164</span></p>
          <p>
            Employee's Contribution Rs:
            <span style="font-weight: bold">14571.00</span>
          </p>
          <p>
            Employer's Contribution Rs:
            <span style="font-weight: bold">62734.00</span>
          </p>
          <p>Total Rs: <span style="font-weight: bold">62734.00</span></p>
        </div>
        <div class="col-md-6">
          <p>Total Wages: <span style="font-weight: bold">1930171.00</span></p>
        </div>
        <div class="col-md-12">
          <hr>
          <p>(For use in bank)</p>
          <center><p style="font-weight: bold; font-size: 15px;">ACKNOWLEDGEMENT</p></center>
          <center><p>(To be filled by depositor)</p></center>
          <p>Received payment with Cash / Cheque / Draft No. </p>
          <p>Date 14/05/2021 for Rs . <span style="font-weight: bold;">77305.00</span></p>
          <p>(Rupee Seventy Seven tousand Three Hundred Five Ony)</p>
          <p>draft on ICICI BANK (Bank) in favour of</p>
          <p>Employee's Sate Insurance Fund Account No 1.</p>
          <p>SI No. In Bank Scroll</p>
        </div>
        
        <div class="col-md-6">
          <p>Date : 14/05/2021</p>
        </div>
        <div class="col-md-6">
          <p>Authrised Signatory</p>
        </div>
      </div>

      <!-- Second half -->
      <div class="col-md-6">
        <center><p>E.S.I.C</p></center>
        <p style="float: right;">Challan No. 1</p>
        <br>
        <p style="font-weight: bold">
          <center>EMPLOYEE'S STATE INSURANCE FUND ACCOUNT NO - 1</center>
        </p>
        <p style="font-weight: bold">
          <center>PAY-IN-SLIP FOR CONTIBUTION</center>
        </p>
        <p style="font-weight: bold"><center>STATE BANK OF INDIA</center></p>
        <div class="col-md-3">
          <p style="font-weight: bold">Station</p>
        </div>
        <div class="col-md-3">AHEMDABAD</div>
        <div class="col-md-3">Date:</div>
        <div class="col-md-3">14/05/2021</div>
        <div class="col-md-12"></div>
        <div class="col-md-6">
          <table id="table-example-1" class="table table-responsive col-md-10">
            <thead>
              <tr>
                <th rowspan="2">Particulars of Cash / Cheque</th>
                <th>Amount</th>
              </tr>
              <tr>
                <th>Rs. P.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>77305.00</td>
              </tr>
              <tr>
                <th>TOTAL</th>
                <th>77305.00</th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <p>Paid into the credit of the Employee's State</p>
          <p>Insurance Fund</p>
          <p>
            Accound No. 1 Rs. <span style="font-weight: bold">77305.00</span>
          </p>
          <p>(Rupee Seventy Seven tousand Three Hundred Five Ony)</p>
          <br />
          <br />
          <p>In Cash/by Cheque (on reallisation) for payment</p>
          <p>of contribution as per details given below</p>
          <p>under the Employee State Insurance Act 1948.</p>
          <p>under the month of April 2021</p>
        </div>
        <div class="col-md-12">
          <p>Emplyer's Code No. :</p>
          <p>Deposited bt:</p>
        </div>
        <div class="col-md-12">
          <hr />
          <center>
            <p>Name And Address of the Factory / Establishment</p>
          </center>
          <p style="font-size: 20px; font-weight: bold">V ALLIANCE</p>
          <p>Office No-5-A, Raheja Arcade, Sector-11,</p>
          <p>CBD Belapur, Navi Mumbai-4000614.</p>
          <p>Plot no-61</p>
          <p>Navi Mumbai-4000614.</p>
        <hr />
        </div>
        <div class="col-md-12"></div>
        <div class="col-md-6">
          <p>No. Of Employees : <span style="font-weight: bold">164</span></p>
          <p>
            Employee's Contribution Rs:
            <span style="font-weight: bold">14571.00</span>
          </p>
          <p>
            Employer's Contribution Rs:
            <span style="font-weight: bold">62734.00</span>
          </p>
          <p>Total Rs: <span style="font-weight: bold">62734.00</span></p>
        </div>
        <div class="col-md-6">
          <p>Total Wages: <span style="font-weight: bold">1930171.00</span></p>
        </div>
        <div class="col-md-12">
          <hr>
          <p>(For use in bank)</p>
          <center><p style="font-weight: bold; font-size: 15px;">ACKNOWLEDGEMENT</p></center>
          <center><p>(To be filled by depositor)</p></center>
          <p>Received payment with Cash / Cheque / Draft No. </p>
          <p>Date 14/05/2021 for Rs . <span style="font-weight: bold;">77305.00</span></p>
          <p>(Rupee Seventy Seven tousand Three Hundred Five Ony)</p>
          <p>draft on ICICI BANK (Bank) in favour of</p>
          <p>Employee's Sate Insurance Fund Account No 1.</p>
          <p>SI No. In Bank Scroll</p>
        </div>
        
        <div class="col-md-6">
          <p>Date : 14/05/2021</p>
        </div>
        <div class="col-md-6">
          <p>Authrised Signatory</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "ESICChallan",
   mounted: function () {
      this.createPDF()
  },
  methods: {
    createPDF() {
      var element = document.getElementById("mydiv");

      var opt = {
        margin: 0,
        filename: "ESICChallan.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };

      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
