<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;">Background Check</span>
            <span style="float: right;">
              <i class="fa fa-pencil-square-o" aria-hidden="true" v-if="!editData" @click="toggleEditData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="editData" @click="toggleEditData"></i>
            </span>
          </div>

          <br>

          <div class="row">

            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Verification Status</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.verification_status ? form.verification_status.code : '' }}</p>
                <my-select :selection.sync="form.verification_status_id" :suggestions="verificationStatuses" placeholder="Select verification status" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Verification Completed On</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.vetification_on | ddmmyyyy }}</p>
                <my-datepicker :date.sync="form.vetification_on" placeholder="Verification Completed On" v-if="editData"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Agency Name</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.agency_name }}</p>
                <input v-uppercase type="text" class="form-control" placeholder="Agency Name" v-if="editData" v-model="form.agency_name">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Remarks</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.remarks }}</p>
                <input v-uppercase type="text" class="form-control" placeholder="Remarks" v-if="editData" v-model="form.remarks">
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-10"
                v-if="editData"
              >
                <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
              </div>
            </div>
            
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      editData: false,
    }
  },
  props: ['form', 'verificationStatuses', 'isLoading'],
  methods: {
    toggleEditData() {
      this.editData = !this.editData
    },
    cancelUpdate() {
      this.editData = false
    },
    async update() {
      this.$emit('update')
      this.editData = false
    }
  }
}
</script>
