<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button
              link="/main/workflows/monitor/attendance"
            ></back-button>
            Create an attendance
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Payroll</li>
            <li>Attendance</li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Date*</label>
                        <div class="col-sm-9">
                          <my-datepicker
                            :date.sync="rawdate"
                            placeholder="Select Date"
                          ></my-datepicker>
                          <span class="error-block">{{ errors.date }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Users*</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.user_id"
                            :suggestions="users"
                            placeholder="Select user"
                          ></my-select>
                          <span class="error-block">{{ errors.user_id }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Time*</label>
                        <div class="col-sm-9">
                          <my-timepicker
                            :time.sync="rawtime"
                            placeholder="Time"
                          ></my-timepicker>
                          <span class="error-block">{{
                            errors.from_time
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">State*</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.state"
                            :suggestions="states"
                            placeholder="Select State"
                          ></my-select>
                          <span class="error-block">{{ errors.state }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Session Type*</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.session_type_id"
                            :suggestions="sessionTypes"
                            placeholder="Select Session type"
                          ></my-select>
                          <span class="error-block">{{
                            errors.session_type_id
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Remark</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Remark"
                            v-model="form.remarks"
                          />
                          <span class="error-block">{{ errors.remarks }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Address</label>
                        <div class="col-sm-9">
                          <textarea
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Address"
                            v-model="form.address"
                          ></textarea>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="save"
                            text="Save"
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "groupCreate",
  data() {
    return {
      form: {
        user_id: "",
        state: "",
        date: "",
        time: "",
        session_type_id: "",
      },
      users: [],
      sessionTypes: [],
      states: [
        { id: "IN", name: "IN" },
        { id: "OUT", name: "OUT" },
      ],
      isLoading: false,
    };
  },
  mounted() {
    // this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      masters.users.forEach((user) => {
        this.users.push({
          id: user.id,
          name: user.full_name,
        });
      });
      masters.session_types.forEach((sessionType) => {
        this.sessionTypes.push({
          id: sessionType.id,
          name: sessionType.description,
        });
      });
    },
    async save() {
      this.isLoading = true;
      this.form.source = "PORTAL";
      if (this.rawdate) {
        let date = moment(this.rawdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        this.form.date = date;
      }
      if (this.rawtime) {
        let time = moment(this.rawtime, "HH:mm:ss").format("hh:mm A");
        this.form.time = time;
      }

      try {
        await axios.post(`/users/${this.form.user_id}/user_punches`, this.form);

        this.isLoading = false;
        this.$router.push(`/main/workflows/monitor/attendance`);
      } catch (e) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
