<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/settings/esic-configurations"></back-button> 
            Update ESIC Configurations
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/settings/esic-configurations">ESIC Configurations</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Effective Date</label>
                        <div class="col-sm-9">
                          <my-datepicker :date.sync="form.effective_date" placeholder="Effective Date"></my-datepicker>
                          <span class="error-block">{{ errors.effective_date }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Employer ESIC</label>
                        <div class="col-sm-5">
                          <input v-uppercase type="text" class="form-control" placeholder="Employer ESIC" v-model="form.employer_value">
                          <span class="error-block">{{ errors.employer_value }}</span>
                        </div>
                        <div class="col-sm-4">
                          <my-select :selection.sync="form.employer_type" :suggestions="configuration_types" placeholder="Employer ESIC Type"></my-select>
                          <span class="error-block">{{ errors.employer_type }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Employee ESIC</label>
                        <div class="col-sm-5">
                          <input v-uppercase type="text" class="form-control" placeholder="Employee ESIC" v-model="form.employee_value">
                          <span class="error-block">{{ errors.employee_value }}</span>
                        </div>
                        <div class="col-sm-4">
                          <my-select :selection.sync="form.employee_type" :suggestions="configuration_types" placeholder="Employee ESIC Type"></my-select>
                          <span class="error-block">{{ errors.employee_type }}</span>
                        </div>
                      </div>
                      <!-- <div class="form-group">
                        <label class="col-sm-3 control-label">Min Wage</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="number" class="form-control" placeholder="Min Wage" v-model="form.min_wage">
                          <span class="error-block">{{ errors.min_wage }}</span>
                        </div>
                      </div> -->
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Max Wage</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="number" class="form-control" placeholder="Max Wage" v-model="form.max_wage">
                          <span class="error-block">{{ errors.max_wage }}</span>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button :isLoading="isLoading" @submit="save" text="Update"></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'esicConfigurationEdit',
  data () {
    return {
      form: {
        effective_date: new Date(),
        employer_value: 0,
        employer_type: 2,
        employee_value: 0,
        employee_type: 2,
        min_wage: 0,
        max_wage: 0,
      },
      configuration_types: [
        {id: 1, name: 'Flat',},
        {id: 2, name: '%',},
      ],
      isLoading: false,
    }
  },
  mounted() {
    this.form.site_id = this.site.id
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      let form = await axios.get(`/esic_configurations/${this.$route.params.esicConfigurationId}`)
      this.form = form.data.data
      this.isLoading = false
    },
    async save() {
      try {
        this.isLoading = true
        await axios.patch(`/esic_configurations/${this.$route.params.esicConfigurationId}`, this.form)
        this.isLoading = false
        this.$router.push('/settings/esic-configurations')
      } catch(e) {
        this.isLoading = false
      }
    }
  }
}
</script>