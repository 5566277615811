<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            Arrear Detailed Pay Slip
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
          <my-search
            :search="searchText"
            placeholder="Search here..."
            @searchData="search"
          ></my-search>
          <br /><br />
          <div class="row">
            <div class="col-md-4">
              <my-select
                style="margin: 9px !important"
                @change="updateMonth"
                disableFormControl="true"
                :selection.sync="month_id"
                :suggestions="months"
                placeholder="Select month"
              ></my-select>
              <my-select
                style="margin: 9px !important"
                @change="updateMonth"
                disableFormControl="true"
                :selection.sync="year"
                :suggestions="years"
                placeholder="Select year"
              ></my-select>
              <br /><br />
              <!-- <button @click="searchData('search')">Search</button>&nbsp;&nbsp; -->
              <button @click="searchData('generate')">Generate</button
              >&nbsp;&nbsp;
              <button @click="generatePDF">Save & Print</button>
              <br /><br />
            </div>
            <div class="col-md-offset-2 col-md-6">
              <my-select
                style="margin: 9px !important"
                @change="selectDivision"
                disableFormControl="true"
                :selection.sync="group"
                :suggestions="groups"
                :placeholder="`Select GROUP`"
              >
              </my-select>
              <my-select
                style="margin: 9px !important"
                disableFormControl="true"
                :selection.sync="groupDivision"
                :suggestions="groupDivisions"
                :placeholder="`Select DIVISION`"
              >
              </my-select>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />

                <div
                  class="table-wrap"
                  v-if="showArrearDetailedPaySlipTable"
                ></div>
                <section id="mydiv" v-if="showArrearDetailedPaySlip">
                  <div class="container-fluid">
                    <p style="font-size: 18px; font-weight: bold">
                      <center>V ALLIANCE</center>
                    </p>
                    <p>
                      <center>
                        Office No-5-A, Raheja Arcade, Sector-11, CBD Belapur,
                        Navi Mumbai- 400 614
                      </center>
                    </p>
                    <p style="font-weight: bold">Arrear Payslip</p>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>Payslip :</p>
                        </div>
                        <div class="col-md-6"><p>1</p></div>
                      </div>
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>Paid Month :</p>
                        </div>
                        <div class="col-md-6"><p>June 2019</p></div>
                      </div>
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>Branch :</p>
                        </div>
                        <div class="col-md-6"><p>MUMBAI</p></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>Emp Code :</p>
                        </div>
                        <div class="col-md-6"><p>VA00173</p></div>
                      </div>
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>Name :</p>
                        </div>
                        <div class="col-md-6"><p>Mr. KALPESH B BAGWE</p></div>
                      </div>
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>Department :</p>
                        </div>
                        <div class="col-md-6"><p>OPERATION</p></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>Designation :</p>
                        </div>
                        <div class="col-md-6"><p>OFFICE BOY</p></div>
                      </div>
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>Division :</p>
                        </div>
                        <div class="col-md-6">
                          <p>KAYA LIMITED ANDHERI OFFICE</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>Joining Dt. :</p>
                        </div>
                        <div class="col-md-6"><p>01/05/2017</p></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>ESIC No. :</p>
                        </div>
                        <div class="col-md-6"><p>311151515</p></div>
                      </div>
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>PF No. :</p>
                        </div>
                        <div class="col-md-6"><p></p></div>
                      </div>
                      <div class="col-md-4">
                        <div class="col-md-6" style="font-weight: bold">
                          <p>PAN No. :</p>
                        </div>
                        <div class="col-md-6"><p></p></div>
                      </div>
                    </div>
                    <!-- <div class="row">
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold">
            <p>Payslip :</p>
            <p>Emp Code :</p>
            <p>Designation :</p>
            <p>ESIC No. :</p>
          </div>
          <div class="col-md-6">
            <p>1</p>
            <p>VA00173</p>
            <p>OFFICE BOY</p>
            <p>3111029675</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold">
            <p>Paid Month :</p>
            <p>Name :</p>
            <p>Division :</p>
            <p>PF No. :</p>
          </div>
          <div class="col-md-6">
            <p>June 2019</p>
            <p>Mr. KALPESH B BAGWE</p>
            <p>KAYA LIMITED ANDHERI OFFICE</p>
            <p></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold">
            <p>Branch :</p>
            <p>Department :</p>
            <p>Joining Dt. :</p>
            <p>PAN No. :</p>
          </div>
          <div class="col-md-6">
            <p>MUMBAI</p>
            <p>OPERATION</p>
            <p>01/05/2017</p>
            <p></p>
          </div>
        </div>
      </div> -->
                    <table
                      id="table-example-1"
                      class="table table-responsive col-md-9"
                    >
                      <thead>
                        <tr>
                          <th>Month</th>
                          <th>Year</th>
                          <th>Particulars</th>
                          <th>Paid</th>
                          <th>Payable</th>
                          <th>Difference</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>January</td>
                          <td>19</td>
                          <td>D.A.</td>
                          <td>3650.00</td>
                          <td>5000.00</td>
                          <td>459.00</td>
                        </tr>
                        <tr>
                          <td>February</td>
                          <td>19</td>
                          <td>D.A.</td>
                          <td>3650.00</td>
                          <td>4104.00</td>
                          <td>459.00</td>
                        </tr>
                        <tr>
                          <td>March</td>
                          <td>19</td>
                          <td>D.A.</td>
                          <td>3650.00</td>
                          <td>4104.00</td>
                          <td>459.00</td>
                        </tr>
                        <tr>
                          <td>January</td>
                          <td>19</td>
                          <td>H.R.A.</td>
                          <td>453.00</td>
                          <td>476.00</td>
                          <td>23</td>
                        </tr>
                        <tr>
                          <td>February</td>
                          <td>19</td>
                          <td>H.R.A.</td>
                          <td>453.00</td>
                          <td>476.00</td>
                          <td>23</td>
                        </tr>
                        <tr>
                          <td>March</td>
                          <td>19</td>
                          <td>H.R.A.</td>
                          <td>453.00</td>
                          <td>476.00</td>
                          <td>23</td>
                        </tr>
                        <tr>
                          <td>January</td>
                          <td>19</td>
                          <td>OVER TIME</td>
                          <td>107.00</td>
                          <td>5000.00</td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td>February</td>
                          <td>19</td>
                          <td>OVER TIME</td>
                          <td>107.00</td>
                          <td>5000.00</td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td>March</td>
                          <td>19</td>
                          <td>OVER TIME</td>
                          <td>107.00</td>
                          <td>5000.00</td>
                          <td>4</td>
                        </tr>
                        <tr style="font-weight: bold">
                          <td colspan="3"><center>Total</center></td>
                          <td>13685.00</td>
                          <td>15183.00</td>
                          <td>1498.00</td>
                        </tr>
                        <tr style="font-weight: bold">
                          <th colspan="3"></th>
                          <th>Recovered</th>
                          <th>Recoverable</th>
                          <th>Difference</th>
                        </tr>
                        <tr>
                          <td>January</td>
                          <td>19</td>
                          <td>PROV. FUND</td>
                          <td>1085.00</td>
                          <td>1140.00</td>
                          <td>55.00</td>
                        </tr>
                        <tr>
                          <td>February</td>
                          <td>19</td>
                          <td>PROV. FUND</td>
                          <td>1085.00</td>
                          <td>1140.00</td>
                          <td>55.00</td>
                        </tr>
                        <tr>
                          <td>March</td>
                          <td>19</td>
                          <td>PROV. FUND</td>
                          <td>1085.00</td>
                          <td>1140.00</td>
                          <td>55.00</td>
                        </tr>
                        <tr>
                          <td>January</td>
                          <td>19</td>
                          <td>E.S.I.C</td>
                          <td>258.00</td>
                          <td>274.00</td>
                          <td>8.00</td>
                        </tr>
                        <tr>
                          <td>February</td>
                          <td>19</td>
                          <td>E.S.I.C</td>
                          <td>258.00</td>
                          <td>274.00</td>
                          <td>8.00</td>
                        </tr>
                        <tr>
                          <td>March</td>
                          <td>19</td>
                          <td>E.S.I.C</td>
                          <td>258.00</td>
                          <td>274.00</td>
                          <td>8.00</td>
                        </tr>
                        <tr style="font-weight: bold">
                          <td colspan="3"><center>Total</center></td>
                          <td>13685.00</td>
                          <td>15183.00</td>
                          <td>1498.00</td>
                        </tr>
                        <tr style="font-weight: bold">
                          <td colspan="3"><center>NET SALARY</center></td>
                          <td>13685.00</td>
                          <td>15183.00</td>
                          <td>1498.00</td>
                        </tr>
                      </tbody>
                    </table>
                    <p>Rupees One Thousand Three Hundred Seven Only</p>
                    <p>
                      Bank : ICICI BANK
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Account No. :
                      107101508117
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFArrearDetailedPaySlip",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showArrearDetailedPaySlipTable: false,
      showArrearDetailedPaySlip: false,
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showArrearDetailedPaySlip = false;
      this.showArrearDetailedPaySlipTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        let users = await axios.get(
          `/user_punches/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = [];
        users.data.data.forEach((user) => {
          if (
            user.employee_status == null ||
            (user.employee_status.code != "LEFT" &&
              user.employee_status.code != "TERMINATE")
          )
            this.users.push(user);
        });
        for (const user of this.users) {
          if (user.monthly_salary == null)
            this.initializeEmptyMonthlySalary(user);
          else this.getEarnedSalaryComponents(user);
        }
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showArrearDetailedPaySlipTable = true;
        this.showArrearDetailedPaySlip = false;
      }
      if (source == "generate") {
        this.showArrearDetailedPaySlipTable = false;
        this.showArrearDetailedPaySlip = true;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "ArrearDetailedPaySlip.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
