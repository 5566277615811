<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Employee Salary</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-6 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Employee</li>
            <li>Information</li>
            <li class="active">Employee Salary</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-9">
                      <autocomplete
                        :search="search"
                        placeholder="Search employee"
                        aria-label="Search employee"
                        :get-result-value="getResultValue"
                        @submit="handleSubmit"
                      ></autocomplete>
                    </div>
                  </div>
                  <br />
                  <u>{{ form.full_name }}</u>
                  <br />
                  <br />
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        v-model="sendEmailTo"
                      />
                    </div>
                    <div class="col-md-4">
                      <button class="btn-link" @click="sendEmail(response)">
                        Send Email</button
                      >&nbsp;
                      <span v-if="isSendingEmail">Sending email...</span>&nbsp;
                      <button
                        class="btn btn-success btn-sm"
                        @click="generatePDF"
                      >
                        Save & Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-12">
                          <Salary
                            :form="form"
                            :isLoading="isLoading"
                            :configurations="configurations"
                            @update="update"
                          ></Salary>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import store from "@/store";
import { updateFormData } from "@/static/helpers.js";
import Salary from "./salary.vue";
import html2pdf from "html2pdf.js";

export default {
  name: "EmployeeSalary",
  data() {
    return {
      form: {},
      isLoading: false,
      isSendingEmail: false,
      addressTypes: [],
      configurations: {
        pf_configuration: {},
        esic_configuration: {},
        bonus_configuration: {},
      },
    };
  },
  components: {
    Salary,
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;

      masters.address_types.forEach((addressType) => {
        this.addressTypes.push({
          id: addressType.id,
          name: addressType.code,
          code: addressType.code,
        });
      });

      this.configurations = masters.configurations;

      // Get Employee
      if (this.employee != null) {
        this.updateForm(this.employee);
      }
    },
    async search(input) {
      // if (input.length < 3) {
      //   return []
      // } else {
      let response = await axios.get(`/users?search=${input}`);
      return response.data.data;
      // }
    },
    getResultValue(result) {
      return result.full_name;
    },
    async handleSubmit(result) {
      let response = await axios.get(`users/${result.id}`);
      this.response = response.data.data;
      let sendEmailTo = "";
      this.sendEmailTo = this.response.email;
      // console.log(this.sendEmailTo);
      if (sendEmailTo) this.isSendingEmail = false;
      store.dispatch("employee/setEmployee", response.data.data);
      this.updateForm(response.data.data);
    },
    updateForm(formData) {
      this.form = updateFormData(formData, this.addressTypes, this.site);
    },
    async update() {
      try {
        this.isLoading = true;
        let response = await axios.post(`/users`, this.form);
        store.dispatch("employee/setEmployee", response.data.data);
        this.updateForm(response.data.data);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async sendEmail(response) {
      this.isSendingEmail = true;
      // console.log(this.sendEmailTo);
      let emailsend = await axios.get(
        `/ctc/${response.id}/sendEmail?to=${this.sendEmailTo}&monthId=${this.month_id}&year=${this.year}`
      );
      console.log(emailsend);
      // console.log(response);
      setInterval(() => {
        this.isSendingEmail = false;
      }, 3000);
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        // margin: [bottom:10],
        filename: "CTC.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          // format: [210,450],
          format: [310, 450],
          size: 1,
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
