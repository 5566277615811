<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Training Videos
            <add-button link="/training-videos/create"></add-button>
            &nbsp;
            <upload-button link="/training-videos/upload"></upload-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Main</li>
            <li class="active">Training Videos</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="inner" style="height: 700px">
                    <table
                      class="table table-hover table-striped table-responsive"
                    >
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Course Name</th>
                          <th>Description</th>
                          <th>Instructor</th>
                          <th>No of Hrs</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <loading-table
                          :isLoading="isLoading"
                          cols="8"
                        ></loading-table>
                        <router-link
                          v-for="(course, at) in courses"
                          :key="`course${at}`"
                          :to="`/training-videos/${course.id}`"
                          tag="tr"
                        >
                          <td>{{ serialNoStarting + at + 1 }}</td>
                          <td>{{ course.course_name || "" }}</td>
                          <td>{{ course.description }}</td>
                          <td>{{ course.instructor }}</td>
                          <td>{{ course.no_of_hrs }}</td>
                          <td>
                            <router-link
                              class="btn btn-primary"
                              :to="`/training-videos/${course.id}/details`"
                            >
                              Details
                            </router-link>
                          </td>
                        </router-link>
                      </tbody>
                    </table>
                  </div>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "trainingVideos",
  data() {
    return {
      courses: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let courses = await axios.get(
        `/courses?page=${page}&rowsPerPage=${this.rowsPerPage}&search=all`
      );
      this.courses = courses.data.data;
      this.count = courses.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
