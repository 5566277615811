<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;"><b>Family Details</b></span>
            <span style="float: right;">
              <i class="fa fa-plus" aria-hidden="true" v-if="!addData" @click="toggleAddData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="addData" @click="toggleAddData"></i>
            </span>
          </div>

          <br>

          <div class="row"
            v-if="addData"
          >
            <p><u>Add New Family Details</u></p>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Name</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Name" v-model="user_family_detail.name">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Profession</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Profession" v-model="user_family_detail.profession">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">DOB</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_family_detail.dob" placeholder="DOB"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Nationality</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_family_detail.nationality_id" :suggestions="nationalities" placeholder="Select nationality"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Gender</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_family_detail.gender_id" :suggestions="genders" placeholder="Select gender"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Blood Group</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_family_detail.blood_group_id" :suggestions="blood_groups" placeholder="Select blood group"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Relation</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_family_detail.relation_id" :suggestions="relations" placeholder="Select relation"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Is address same as employee</label>
              <div class="col-sm-8">
                <my-checkbox :selection.sync="user_family_detail.is_address_same"></my-checkbox>
              </div>
            </div>
            <div
              v-if="(user_family_detail.is_address_same == 0)"
            >
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Address 1</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Address 1" v-model="user_family_detail.address_1">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Address 2</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Address 2" v-model="user_family_detail.address_2">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Address 3</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Address 3" v-model="user_family_detail.address_3">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">City</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="City" v-model="user_family_detail.city">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">State</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="user_family_detail.state_id" :suggestions="states" placeholder="Select state"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Country</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="user_family_detail.country_id" :suggestions="countries" placeholder="Select country"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Pincode</label>
                <div class="col-sm-8">
                  <input v-uppercase type="number" class="form-control" placeholder="Pincode" v-model="user_family_detail.pincode">
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Mobile</label>
              <div class="col-sm-8">
                <input v-uppercase type="number" class="form-control" placeholder="Mobile" v-model="user_family_detail.mobile">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Phone</label>
              <div class="col-sm-8">
                <input v-uppercase type="number" class="form-control" placeholder="Phone" v-model="user_family_detail.phone">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Email</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Email" v-model="user_family_detail.email">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10">
                <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
              </div>
            </div>
          </div>

          <!-- List of employee passports -->
          <div class="row"
            v-for="(userFamilyDetail, ufd) in form.user_family_details"
            :key="`userFamilyDetail${ufd}`"
          >
            <div class="form-group row"
              v-if="!(editData == ufd)"
            >
              <label class="col-sm-4 col-form-label">{{ ufd + 1 }}. {{ userFamilyDetail.name }}</label>
              <div class="col-sm-6">
                {{ userFamilyDetail.dob | ddmmyyyy }}
              </div>
              <div class="col-md-2">
                <i class="fa fa-pencil-square-o" style="float: right;" aria-hidden="true" @click="toggleEditData(userFamilyDetail, ufd)"></i>
              </div>
            </div>
            <div
              v-else
            >
              <p><u>{{ ufd + 1 }}. Update Employee Family Details</u></p>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Name</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Name" v-model="user_family_detail.name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Profession</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Profession" v-model="user_family_detail.profession">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">DOB</label>
                <div class="col-sm-8">
                  <my-datepicker :date.sync="user_family_detail.dob" placeholder="DOB"></my-datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Nationality</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="user_family_detail.nationality_id" :suggestions="nationalities" placeholder="Select nationality"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Gender</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="user_family_detail.gender_id" :suggestions="genders" placeholder="Select gender"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Blood Group</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="user_family_detail.blood_group_id" :suggestions="blood_groups" placeholder="Select blood group"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Relation</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="user_family_detail.relation_id" :suggestions="relations" placeholder="Select relation"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Is address same as employee</label>
                <div class="col-sm-8">
                  <my-checkbox :selection.sync="user_family_detail.is_address_same"></my-checkbox>
                </div>
              </div>
              <div
                v-if="(user_family_detail.is_address_same == 0)"
              >
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Address 1</label>
                  <div class="col-sm-8">
                    <input v-uppercase type="text" class="form-control" placeholder="Address 1" v-model="user_family_detail.address_1">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Address 2</label>
                  <div class="col-sm-8">
                    <input v-uppercase type="text" class="form-control" placeholder="Address 2" v-model="user_family_detail.address_2">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Address 3</label>
                  <div class="col-sm-8">
                    <input v-uppercase type="text" class="form-control" placeholder="Address 3" v-model="user_family_detail.address_3">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">City</label>
                  <div class="col-sm-8">
                    <input v-uppercase type="text" class="form-control" placeholder="City" v-model="user_family_detail.city">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">State</label>
                  <div class="col-sm-8">
                    <my-select :selection.sync="user_family_detail.state_id" :suggestions="states" placeholder="Select state"></my-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Country</label>
                  <div class="col-sm-8">
                    <my-select :selection.sync="user_family_detail.country_id" :suggestions="countries" placeholder="Select country"></my-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Pincode</label>
                  <div class="col-sm-8">
                    <input v-uppercase type="number" class="form-control" placeholder="Pincode" v-model="user_family_detail.pincode">
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Mobile</label>
                <div class="col-sm-8">
                  <input v-uppercase type="number" class="form-control" placeholder="Mobile" v-model="user_family_detail.mobile">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Phone</label>
                <div class="col-sm-8">
                  <input v-uppercase type="number" class="form-control" placeholder="Phone" v-model="user_family_detail.phone">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Email</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Email" v-model="user_family_detail.email">
                </div>
              </div>
              <div class="col-sm-2">
                <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      addData: false,
      editData: null,
      user_family_detail: {
        name: '',
        profession: '',
        dob: '',
        nationality_id: '',
        gender_id: '',
        blood_group_id: '',
        relation_id: '',
        is_address_same: '',
        copy_address_id: '',
        address_1: '',
        address_2: '',
        address_3: '',
        city: '',
        state_id: '',
        country_id: '',
        pincode: '',
        mobile: '',
        phone: '',
        email: '',
      },
    }
  },
  props: ['form', 'isLoading', 'nationalities', 'genders', 'blood_groups', 'relations', 'states', 'countries'],
  methods: {
    toggleAddData() {
      this.user_family_detail = {
        name: '',
        profession: '',
        dob: '',
        nationality_id: '',
        gender_id: '',
        blood_group_id: '',
        relation_id: '',
        is_address_same: '',
        copy_address_id: '',
        address_1: '',
        address_2: '',
        address_3: '',
        city: '',
        state_id: '',
        country_id: '',
        pincode: '',
        mobile: '',
        phone: '',
        email: '',
      }
      this.addData = !this.addData
    },
    toggleEditData(userFamilyDetail, ufd) {
      this.editData = ufd
      this.user_family_detail = userFamilyDetail
    },
    cancelUpdate() {
      this.editData = null
    },
    save() {
      if(this.user_family_detail.name == '' || this.user_family_detail.dob == '') 
        alert('Please enter name and dob')
      else {
        this.form.user_family_details.push(this.user_family_detail)
        this.update()
        this.addData = false
      }
    },
    async update() {
      this.$emit('update')
      this.editData = null
    }
  }
}
</script>