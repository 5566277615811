<template>
  <section id="mydiv">
    <div class="container-fluid">
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-4"><p>PART-I</p></div>
          <div class="col-md-4">
            <p><center>Challan</center></p>
          </div>
          <div class="col-md-4"><p style="float: right;">FOR TREASURY</p></div>
        </div>
        <br />
        <p style="font-weight: bold">
          <center>MTR-6</center>
        </p>
        <br />
        <p>
          <center>
            (see Rule 11, 11C, 11E and 20 of the professions Tax Rules, 1975)
          </center>
          <br />
          <center>ACCOUNT HEAD:- 00280012</center>
        </p>
        <br />

        <div class="row">
          <table id="table-example-1" class="table table-responsive col-md-10">
            <thead>
              <tr>
                <th colspan="2">GRN</th>
                <th>Form-ID</th>
                <th>
                  <ol>
                    <li>I</li>
                    <li>IIIB</li>
                    <li>VIII</li>
                  </ol>
                </th>
              </tr>
              <tr>
                <th>Department</th>
                <th>Department of Sales Tax</th>
                <th>Date:-</th>
                <th>01/06/2021</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2">Type of Payment</td>
                <td style="font-weight: bold">Professions Tax Act. 1975</td>
                <td colspan="2">Payee Details</td>
              </tr>
              <tr>
                <td>
                  <ol>
                    <li>RC</li>
                    <li>EC</li>
                  </ol>
                  ( Select the applicable one )
                </td>
                <td colspan="2">
                  <p>PT( RC/EC )</p>
                  <p>Number</p>
                  <p>(Tin)</p>
                  <p>27801522796P</p>
                </td>
              </tr>
              <tr>
                <td colspan="2">Location MUMBAI</td>
                <td rowspan="3" colspan="2">
                  <p>Full Name of the Tax Payer</p>
                  <p style="font-weight: bold">V ALLIANCE</p>
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold" colspan="2">
                  <center>Period</center>
                </td>
              </tr>
              <tr>
                <td>
                  <p style="font-weight: bold">FROM</p>
                  <p>01/04/2021</p>
                </td>
                <td>
                  <p style="font-weight: bold">TO</p>
                  <p>30/04/2021</p>
                </td>
                <td></td>
              </tr>
              <tr>
                <th>Account Head Details</th>
                <th>Code</th>
                <th>Amount in Rs,</th>
                <th>Remarks if Any,</th>
              </tr>
              <tr>
                <td>Amount of Tax</td>
                <td>1</td>
                <td>26500.00</td>
                <td rowspan="8"></td>
              </tr>
              <tr>
                <td>Interest Amount</td>
                <td>2</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Penality Amount</td>
                <td>3</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Composition Money</td>
                <td>4</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Fine</td>
                <td>5</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Fees</td>
                <td>6</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Advances Payment</td>
                <td>7</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td style="font-weight: bold">Total</td>
                <td></td>
                <td>26500.00</td>
              </tr>
              <tr>
                <td colspan="5" rowspan="5">
                  <p style="float: left;">
                    Amount in Words: Twenty Six Thousand Five Hundred
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <p style="float: right;">
                    Signature of Person has made payment
                  </p>
                </td>
              </tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
              <tr>
                <td colspan="4"><center>FOR BANKS/ TREASURY</center></td>
              </tr>
              <tr>
                <td colspan="2">Payment Details</td>
                <td colspan="2">For use in receiving Bank</td>
              </tr>
              <tr>
                <td>Name of bank</td>
                <td></td>
                <td>Bank CIN</td>
                <td></td>
              </tr>
              <tr>
                <td>Name of Branch</td>
                <td></td>
                <td>Date</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Time</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Scroll No.</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Second half -->
     <div class="col-md-4">
        <div class="row">
          <div class="col-md-4"><p>PART-II</p></div>
          <div class="col-md-4">
            <p><center>Challan</center></p>
          </div>
          <div class="col-md-4"><p style="float: right;">FOR PROFESSION TAX OFFICER</p></div>
        </div>
        <br />
        <p style="font-weight: bold">
          <center>MTR-6</center>
        </p>
        <br />
        <p>
          <center>
            (see Rule 11, 11C, 11E and 20 of the professions Tax Rules, 1975)
          </center>
          <br />
          <center>ACCOUNT HEAD:- 00280012</center>
        </p>
        <br />

        <div class="row">
          <table id="table-example-1" class="table table-responsive col-md-10">
            <thead>
              <tr>
                <th colspan="2">GRN</th>
                <th>Form-ID</th>
                <th>
                  <ol>
                    <li>I</li>
                    <li>IIIB</li>
                    <li>VIII</li>
                  </ol>
                </th>
              </tr>
              <tr>
                <th>Department</th>
                <th>Department of Sales Tax</th>
                <th>Date:-</th>
                <th>01/06/2021</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2">Type of Payment</td>
                <td style="font-weight: bold">Professions Tax Act. 1975</td>
                <td colspan="2">Payee Details</td>
              </tr>
              <tr>
                <td>
                  <ol>
                    <li>RC</li>
                    <li>EC</li>
                  </ol>
                  ( Select the applicable one )
                </td>
                <td colspan="2">
                  <p>PT( RC/EC )</p>
                  <p>Number</p>
                  <p>(Tin)</p>
                  <p>27801522796P</p>
                </td>
              </tr>
              <tr>
                <td colspan="2">Location MUMBAI</td>
                <td rowspan="3" colspan="2">
                  <p>Full Name of the Tax Payer</p>
                  <p style="font-weight: bold">V ALLIANCE</p>
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold" colspan="2">
                  <center>Period</center>
                </td>
              </tr>
              <tr>
                <td>
                  <p style="font-weight: bold">FROM</p>
                  <p>01/04/2021</p>
                </td>
                <td>
                  <p style="font-weight: bold">TO</p>
                  <p>30/04/2021</p>
                </td>
                <td></td>
              </tr>
              <tr>
                <th>Account Head Details</th>
                <th>Code</th>
                <th>Amount in Rs,</th>
                <th>Remarks if Any,</th>
              </tr>
              <tr>
                <td>Amount of Tax</td>
                <td>1</td>
                <td>26500.00</td>
                <td rowspan="8"></td>
              </tr>
              <tr>
                <td>Interest Amount</td>
                <td>2</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Penality Amount</td>
                <td>3</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Composition Money</td>
                <td>4</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Fine</td>
                <td>5</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Fees</td>
                <td>6</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Advances Payment</td>
                <td>7</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td style="font-weight: bold">Total</td>
                <td></td>
                <td>26500.00</td>
              </tr>
              <tr>
                <td colspan="5" rowspan="5">
                  <p style="float: left;">
                    Amount in Words: Twenty Six Thousand Five Hundred
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <p style="float: right;">
                    Signature of Person has made payment
                  </p>
                </td>
              </tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
              <tr>
                <td colspan="4"><center>FOR BANKS/ TREASURY</center></td>
              </tr>
              <tr>
                <td colspan="2">Payment Details</td>
                <td colspan="2">For use in receiving Bank</td>
              </tr>
              <tr>
                <td>Name of bank</td>
                <td></td>
                <td>Bank CIN</td>
                <td></td>
              </tr>
              <tr>
                <td>Name of Branch</td>
                <td></td>
                <td>Date</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Time</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Scroll No.</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Third half -->
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-4"><p>PART-III</p></div>
          <div class="col-md-4">
            <p><center>Challan</center></p>
          </div>
          <div class="col-md-4"><p style="float: right;">FOR TAX PAYER</p></div>
        </div>
        <br />
        <p style="font-weight: bold">
          <center>MTR-6</center>
        </p>
        <br />
        <p>
          <center>
            (see Rule 11, 11C, 11E and 20 of the professions Tax Rules, 1975)
          </center>
          <br />
          <center>ACCOUNT HEAD:- 00280012</center>
        </p>
        <br />

        <div class="row">
          <table id="table-example-1" class="table table-responsive col-md-10">
            <thead>
              <tr>
                <th colspan="2">GRN</th>
                <th>Form-ID</th>
                <th>
                  <ol>
                    <li>I</li>
                    <li>IIIB</li>
                    <li>VIII</li>
                  </ol>
                </th>
              </tr>
              <tr>
                <th>Department</th>
                <th>Department of Sales Tax</th>
                <th>Date:-</th>
                <th>01/06/2021</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2">Type of Payment</td>
                <td style="font-weight: bold">Professions Tax Act. 1975</td>
                <td colspan="2">Payee Details</td>
              </tr>
              <tr>
                <td>
                  <ol>
                    <li>RC</li>
                    <li>EC</li>
                  </ol>
                  ( Select the applicable one )
                </td>
                <td colspan="2">
                  <p>PT( RC/EC )</p>
                  <p>Number</p>
                  <p>(Tin)</p>
                  <p>27801522796P</p>
                </td>
              </tr>
              <tr>
                <td colspan="2">Location MUMBAI</td>
                <td rowspan="3" colspan="2">
                  <p>Full Name of the Tax Payer</p>
                  <p style="font-weight: bold">V ALLIANCE</p>
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold" colspan="2">
                  <center>Period</center>
                </td>
              </tr>
              <tr>
                <td>
                  <p style="font-weight: bold">FROM</p>
                  <p>01/04/2021</p>
                </td>
                <td>
                  <p style="font-weight: bold">TO</p>
                  <p>30/04/2021</p>
                </td>
                <td></td>
              </tr>
              <tr>
                <th>Account Head Details</th>
                <th>Code</th>
                <th>Amount in Rs,</th>
                <th>Remarks if Any,</th>
              </tr>
              <tr>
                <td>Amount of Tax</td>
                <td>1</td>
                <td>26500.00</td>
                <td rowspan="8"></td>
              </tr>
              <tr>
                <td>Interest Amount</td>
                <td>2</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Penality Amount</td>
                <td>3</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Composition Money</td>
                <td>4</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Fine</td>
                <td>5</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Fees</td>
                <td>6</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Advances Payment</td>
                <td>7</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td style="font-weight: bold">Total</td>
                <td></td>
                <td>26500.00</td>
              </tr>
              <tr>
                <td colspan="5" rowspan="5">
                  <p style="float: left;">
                    Amount in Words: Twenty Six Thousand Five Hundred
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <p style="float: right;">
                    Signature of Person has made payment
                  </p>
                </td>
              </tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
              <tr>
                <td colspan="4"><center>FOR BANKS/ TREASURY</center></td>
              </tr>
              <tr>
                <td colspan="2">Payment Details</td>
                <td colspan="2">For use in receiving Bank</td>
              </tr>
              <tr>
                <td>Name of bank</td>
                <td></td>
                <td>Bank CIN</td>
                <td></td>
              </tr>
              <tr>
                <td>Name of Branch</td>
                <td></td>
                <td>Date</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Time</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Scroll No.</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "PTChallan",
  mounted: function() {
    this.createPDF();
  },
  methods: {
    createPDF() {
      var element = document.getElementById("mydiv");

      var opt = {
        margin: 0,
        filename: "PTChallan.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>
