<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/main/vendors"></back-button> 
            Update vendor: {{ form.id }}<loading :isLoading="isLoading"></loading>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/main/vendors">Vendors</router-link></li>
            <li class="active">Update</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Name*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Name" v-model="form.name">
                          <span class="error-block">{{ errors.name }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Email*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Email" v-model="form.email">
                          <span class="error-block">{{ errors.email }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Phone*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Phone" v-model="form.phone">
                          <span class="error-block">{{ errors.phone }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Organization Name</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Organization Name" v-model="form.organization">
                          <span class="error-block">{{ errors.organization }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Address 1</label>
                        <div class="col-sm-9">
                          <textarea v-uppercase class="form-control" placeholder="Address 1" v-model="form.address_1"></textarea>
                          <span class="error-block">{{ errors.address_1 }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Address 2</label>
                        <div class="col-sm-9">
                          <textarea v-uppercase class="form-control" placeholder="Address 2" v-model="form.address_2"></textarea>
                          <span class="error-block">{{ errors.address_2 }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Country</label>
                        <div class="col-sm-9">
                          <my-select :selection.sync="form.country" :suggestions="countries" placeholder="Select Country"></my-select>
                          <span class="error-block">{{ errors.country }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">State</label>
                        <div class="col-sm-9">
                          <my-select :selection.sync="form.state" :suggestions="states" placeholder="Select State"></my-select>
                          <span class="error-block">{{ errors.state }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">State Code</label>
                        <div class="col-sm-9">
                          <my-select :selection.sync="form.state_code" :suggestions="state_codes" placeholder="Select State Code"></my-select>
                          <span class="error-block">{{ errors.state_code }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"></label>
                        <div class="col-sm-9">
                          <my-checkbox :selection.sync="form.is_gst_register" placeholder="Is Gst Register"></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Gst IN</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Gst In" v-model="form.gstin">
                          <span class="error-block">{{ errors.gstin }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Pan No</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Pan No" v-model="form.pan_no">
                          <span class="error-block">{{ errors.pan_no }}</span>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'VendorUpdate',
  data () {
    return {
      form: {
        state_code: '',
        country: '',
        state: '',
        is_gst_register: 1
      },
      states: [],
      state_codes: [],
      countries: [],
      isLoading: false,
    }
  },
  mounted() {
    this.getData()
    this.getMasters()
  },
  methods: {
    async getMasters() {
      let masters = await axios.get('vendors/masters')
      masters = masters.data
      masters.states.forEach(state => {
        this.states.push({
          id: state.id,
          name: state.description
        })
      })
      masters.countries.forEach(country => {
        this.countries.push({
          id: country.id,
          name: country.description
        })
      })
      masters.states.forEach(code => {
        this.state_codes.push({
          id: code.code,
          name: code.code
        })
      })
    },
    async getData() {
      this.isLoading = true
      let form = await axios.get(`/vendors/${this.$route.params.vendorId}`)
      this.form = form.data.data
      this.isLoading = false
    },
    async update() {
      try {
        this.isLoading = true
        await axios.patch(`/vendors/${this.$route.params.vendorId}`, this.form)
        this.isLoading = false
        this.$router.push('/main/vendors')
      } catch(e) {
        this.isLoading = false
      }
    }
  }
}
</script>