<template>
  <!-- Left Sidebar Menu -->
  <div class="fixed-sidebar-left">
    <ul class="nav navbar-nav side-nav nicescroll-bar" style="background: #2C3744;">
      <!-- Main -->
      <li class="navigation-header">
        <span>Main</span> 
        <i class="zmdi zmdi-more"></i>
      </li>
      <li
        v-if="this.userPermissions.indexOf(156) != -1"
      >
        <router-link to="/"><div class="pull-left"><span class="right-nav-text">Home</span></div><div class="pull-right"></div><div class="clearfix"></div></router-link>
      </li>
      <li
        v-if="this.user.roles[0].id == 1"
      >
        <router-link to="/sites"><div class="pull-left"><span class="right-nav-text">Companies</span></div><div class="pull-right"></div><div class="clearfix"></div></router-link>
      </li>
      <li
        v-if="this.user.roles[0].id == 1"
      >
        <router-link to="/permissions"><div class="pull-left"><span class="right-nav-text">Permissions</span></div><div class="pull-right"></div><div class="clearfix"></div></router-link>
      </li>
      <li
        v-if="this.userPermissions.indexOf(6) != -1"
      >
        <router-link to="/day-summaries"><div class="pull-left"><span class="right-nav-text">Day Summary</span></div><div class="pull-right"></div><div class="clearfix"></div></router-link>
      </li>
      <li
        v-if="this.userPermissions.indexOf(11) != -1"
      >
        <router-link to="/training-videos"><div class="pull-left"><span class="right-nav-text">Training Videos</span></div><div class="pull-right"></div><div class="clearfix"></div></router-link>
      </li>
      <li
        v-if="this.userPermissions.indexOf(16) != -1"
      >
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#employee_dr"><div class="pull-left"><span class="right-nav-text">Employee</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
        <ul id="employee_dr" class="collapse collapse-level-1">
          <li>
            <router-link to="/main/employees">User Directory</router-link>
          </li>
          <li>
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#employee_information_dr"><div class="pull-left"><span class="right-nav-text">Information</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
            <ul id="employee_information_dr" class="collapse collapse-level-1">
              <li>
                <router-link to="/main/employees/profile">Employee Profile</router-link>
              </li>
              <li>
                <router-link to="/main/employees/family-details">Family Details</router-link>
              </li>
              <li>
                <router-link to="/main/employees/position-history">Position History</router-link>
              </li>
              <li>
                <router-link to="/main/employees/bank-pf-esi">Bank/PF/ESI</router-link>
              </li>
              <li>
                <router-link to="/main/employees/passport-visa">Passport & Visa</router-link>
              </li>
              <li>
                <router-link to="/main/employees/assets">Assets</router-link>
              </li>
              <li>
                <router-link to="/main/employees/salary">Employee Salary</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li
        v-if="this.userPermissions.indexOf(21) != -1"
      >
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#workflow_dr"><div class="pull-left"><span class="right-nav-text">Payroll</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
        <ul id="workflow_dr" class="collapse collapse-level-1">
          <li
            v-if="this.userPermissions.indexOf(22) != -1"
          >
            <router-link to="/main/workflows/monitor/attendance">Attendance</router-link>
          </li>
          <li
            v-if="this.userPermissions.indexOf(23) != -1"
          >
            <router-link to="/main/workflows/monitor/payroll">Payroll Process</router-link>
          </li>
          <li
            v-if="this.userPermissions.indexOf(157) != -1"
          >
            <router-link to="/main/workflows/monitor/punch-details">Punch Details</router-link>
          </li>
          <li
            v-if="this.userPermissions.indexOf(158) != -1"
          >
            <router-link to="/main/workflows/monitor/daily-punch-log">Daily Punch Log</router-link>
          </li>
        </ul>
      </li>
      <li
        v-if="this.userPermissions.indexOf(159) != -1"
      >
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#reports_dr"><div class="pull-left"><span class="right-nav-text">Reports</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
        <ul id="reports_dr" class="collapse collapse-level-1">
          <li
            v-if="this.userPermissions.indexOf(129) != -1"
          >
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#pf_reports_dr"><div class="pull-left"><span class="right-nav-text">PF Reports</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
            <ul id="pf_reports_dr" class="collapse collapse-level-1">
              <li
                v-if="this.userPermissions.indexOf(130) != -1"
              >
                <router-link to="/main/reports/pf-reports/challan">PF Challan</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(131) != -1"
              >
                <router-link to="/main/reports/pf-reports/register">PF Register</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(132) != -1"
              >
                <router-link to="/main/reports/pf-reports/form-3A">Form 3A</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(133) != -1"
              >
                <router-link to="/main/reports/pf-reports/form-5A">Form 5A</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(135) != -1"
              >
                <router-link to="/main/reports/pf-reports/form-9A">Form 9A</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(136) != -1"
              >
                <router-link to="/main/reports/pf-ecr">PF ECR</router-link>
              </li>
            </ul>
          </li>
          <li
            v-if="this.userPermissions.indexOf(137) != -1"
          >
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#esic_reports_dr"><div class="pull-left"><span class="right-nav-text">ESIC Reports</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
            <ul id="esic_reports_dr" class="collapse collapse-level-1">
              <li
                v-if="this.userPermissions.indexOf(138) != -1"
              >
                <router-link to="/main/reports/esic-reports/challan">ESIC Challan</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(139) != -1"
              >
                <router-link to="/main/reports/esic-reports/register">ESIC Register</router-link>
              </li>     
            </ul>
          </li>
          <li
            v-if="this.userPermissions.indexOf(140) != -1"
          >
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#pt_dr"><div class="pull-left"><span class="right-nav-text">PT Reports</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
            <ul id="pt_dr" class="collapse collapse-level-1">
              <li
                v-if="this.userPermissions.indexOf(141) != -1"
              >
                <router-link to="/main/reports/pt-reports/pt-challan">PT Challan</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(142) != -1"
              >
                <router-link to="/main/reports/pt-reports/pt-register">PT Register</router-link>
              </li>
            </ul>
          </li>
          <li
            v-if="this.userPermissions.indexOf(143) != -1"
          >
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#lwf_dr"><div class="pull-left"><span class="right-nav-text">LWF Reports</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
            <ul id="lwf_dr" class="collapse collapse-level-1">
              <li
                v-if="this.userPermissions.indexOf(144) != -1"
              >
                <router-link to="/main/reports/lwf-challan">LWF Challan</router-link>
              </li>
            </ul>
          </li>
          <li
            v-if="this.userPermissions.indexOf(145) != -1"
          >
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#pay_slips_dr"><div class="pull-left"><span class="right-nav-text">Pay Slips</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
            <ul id="pay_slips_dr" class="collapse collapse-level-1">
              <li
                v-if="this.userPermissions.indexOf(146) != -1"
              >
                <router-link to="/main/reports/pay-slips/">Pay Slip</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(147) != -1"
              >
                <router-link to="/main/reports/pay-slip-2">Pay Slip 2</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(148) != -1"
              >
                <router-link to="/main/reports/pay-slip-3">Pay Slip 3</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(149) != -1"
              >
                <router-link to="/main/reports/arrear-detailed-payslip">Arrear Detailed Payslip Reports</router-link>
              </li>
            </ul>
          </li>
          <li
            v-if="this.userPermissions.indexOf(149) != -1"
          >
            <router-link to="/main/reports/bonus-register">Bonus Register</router-link>
          </li>
          <li
            v-if="this.userPermissions.indexOf(150) != -1"
          >
            <router-link to="/main/reports/leave-register">Leave Register</router-link>
          </li>
          <li
            v-if="this.userPermissions.indexOf(151) != -1"
          >
            <router-link to="/main/reports/salary-register">Salary Register</router-link>
          </li>
          <li
            v-if="this.userPermissions.indexOf(152) != -1"
          >
            <router-link to="/main/reports/ctc">CTC</router-link>
          </li>
          <li
            v-if="this.userPermissions.indexOf(153) != -1"
          >
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#invoice_dr"><div class="pull-left"><span class="right-nav-text">Invoice Reports</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
            <ul id="invoice_dr" class="collapse collapse-level-1">
              <!-- <li
                v-if="this.userPermissions.indexOf(154) != -1"
              >
                <router-link to="/main/reports/invoice">Invoice</router-link>
              </li> -->
              <li
                v-if="this.userPermissions.indexOf(154) != -1"
              >
                <router-link to="/main/reports/invoice-annexure-1">Invoice</router-link>
              </li>
              <!-- <li
                v-if="this.userPermissions.indexOf(155) != -1"
              >
                <router-link to="/main/reports/invoice-annexure-2">Invoice Annexure 2</router-link>
              </li> -->
            </ul>
          </li>
        </ul>
      </li>
      <li
        v-if="this.userPermissions.indexOf(24) != -1"
      >
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#vendor_dr"><div class="pull-left"><span class="right-nav-text">Vendors</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
        <ul id="vendor_dr" class="collapse collapse-level-1">
          <li
            v-if="this.userPermissions.indexOf(25) != -1"
          >
            <router-link to="/main/vendors">Vendors</router-link>
          </li>
          <li
            v-if="this.userPermissions.indexOf(29) != -1"
          >
            <router-link to="/main/products">Products</router-link>
          </li>
        </ul>
      </li>
      
      <li
        v-if="this.userPermissions.indexOf(34) != -1"
      >
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#group_dr"><div class="pull-left"><span class="right-nav-text">Groups</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
        <ul id="group_dr" class="collapse collapse-level-1">
          <li
            v-if="this.userPermissions.indexOf(35) != -1"
          >
            <router-link to="/main/groups">Groups</router-link>
          </li>
          <li
            v-if="this.userPermissions.indexOf(40) != -1"
          >
            <router-link to="/main/group-divisions">Group Divisions</router-link>
          </li>
        </ul>
      </li>
      <li
        v-if="this.userPermissions.indexOf(44) != -1"
      >
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#ticket_dr"><div class="pull-left"><span class="right-nav-text">Tickets</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
        <ul id="ticket_dr" class="collapse collapse-level-1">
          <li
            v-if="this.userPermissions.indexOf(45) != -1"
          >
            <router-link to="/main/tickets">Tickets</router-link>
          </li>
        </ul>
      </li>
      <li
        v-if="this.userPermissions.indexOf(49) != -1"
      >
        <router-link to="/main/feedbacks"><div class="pull-left"><span class="right-nav-text">Feedbacks</span></div><div class="pull-right"></div><div class="clearfix"></div></router-link>
      </li>
      <li><hr class="light-grey-hr mb-10"/></li>
      <!-- Main End -->

      <!-- Settings -->
      <li class="navigation-header"
        v-if="this.userPermissions.indexOf(160) != -1"
      >
        <span>Settings</span> 
        <i class="zmdi zmdi-more"></i>
      </li>
      <li
          v-if="this.userPermissions.indexOf(161) != -1"
      >
        <a href="javascript:void(0);" data-toggle="collapse" data-target="#s_system_settings_dr"><div class="pull-left"><span class="right-nav-text">System Settings</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
        <ul id="s_system_settings_dr" class="collapse collapse-level-1">
          <li
            v-if="this.userPermissions.indexOf(162) != -1"
          >
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#s_configuration_dr"><div class="pull-left"><span class="right-nav-text">Configurations</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
            <ul id="s_configuration_dr" class="collapse collapse-level-1">
              <li
                v-if="this.userPermissions.indexOf(54) != -1"
              >
                <router-link to="/settings/pf-configurations">PF Configuration</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(59) != -1"
              >
                <router-link to="/settings/esic-configurations">ESIC Configuration</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(64) != -1"
              >
                <router-link to="/settings/pt-configurations">PT Configuration</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(69) != -1"
              >
                <router-link to="/settings/lwf-configurations">LWF Configuration</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(74) != -1"
              >
                <router-link to="/settings/bonus-configurations">Bonus Configuration</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(79) != -1"
              >
                <router-link to="/settings/leave-configurations">Leave Configuration</router-link>
              </li>
            </ul>
          </li>
          <li
            v-if="this.userPermissions.indexOf(163) != -1"
          >
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#s_general_dr"><div class="pull-left"><span class="right-nav-text">General</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
            <ul id="s_general_dr" class="collapse collapse-level-1">
              <li
                v-if="this.userPermissions.indexOf(84) != -1"
              >
                <router-link to="/settings/values">Values</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(89) != -1"
              >
                <router-link to="/settings/value-lists">List of Values</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(94) != -1"
              >
                <router-link to="/settings/banks">Banks</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(99) != -1"
              >
                <router-link to="/settings/bank-branches">Bank Branches</router-link>
              </li>
            </ul>
          </li>
          <li
            v-if="this.userPermissions.indexOf(164) != -1"
          >
            <a href="javascript:void(0);" data-toggle="collapse" data-target="#s_Employee_dr"><div class="pull-left"><span class="right-nav-text">Employee</span></div><div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div><div class="clearfix"></div></a>
            <ul id="s_Employee_dr" class="collapse collapse-level-1">
              <li
                v-if="this.userPermissions.indexOf(104) != -1"
              >
                <router-link to="/settings/categories">Categories</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(109) != -1"
              >
                <router-link to="/settings/category-lists">Employee Positions</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(114) != -1"
              >
                <router-link to="/settings/leaving-reasons">Leaving Reasons</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(119) != -1"
              >
                <router-link to="/settings/asset-groups">Asset Groups</router-link>
              </li>
              <li
                v-if="this.userPermissions.indexOf(124) != -1"
              >
                <router-link to="/settings/asset-group-lists">Asset Group Settings</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li><hr class="light-grey-hr mb-10"/></li>
      <!-- Main End -->
    </ul>
  </div>
  <!-- /Left Sidebar Menu -->
</template>

<script type="javascript">
export default {
  data () {
    return {
    }
  },
  mounted() {
    // console.log(this.permissions)
  }
}
</script>


<style type="text/css" scoped>
  span {
    color:  white !important;
  }
</style>z