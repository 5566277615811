import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import VCalendar from "v-calendar";
Vue.use(VCalendar);

Vue.use(Autocomplete);

import saveAs from "file-saver";
Vue.use(saveAs);

Vue.config.productionTip = false;

require("@/store/subscriber");

require("./mixin.js");
require("./axios.js");
require("./directives.js");
require("./components.js");
require("./filters.js");

import AsyncComputed from "vue-async-computed";
Vue.use(AsyncComputed);

store.dispatch("employee/fetchEmployee");
store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
