<template>
  <section id="mydiv">
    <div class="container-fluid" style="margin-top: 20px;">
      <p style="font-size: 18px; font-weight: bold;">FOR THE MONTH: APRIL 2021</p>
      <table class="table table-responsive col-md-10">
        <thead>
          <tr>
            <th >Sr. No</th>
            <th>Employee Code</th>
            <th>Division Name</th>
            <th>Employee Name</th>
            <th>Father Name</th>
            <th>Designation Name</th>
            <th>Gender</th>
            <th>Date Of Birth</th>
            <th>Date Of Joining</th>
            <th>UAN Number</th>
            <th>PF Number</th>
            <th>Adhaar Number</th>
            <th>PROV. FUND</th>
            
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>VA00021</td>
            <td>COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td>SHANTARAM</td>
            <td>OFFICE BOY</td>
            <td>MALE</td>
            <td>04/02/1978</td>
            <td>01/04/2017</td>
            <td>100766112317</td>
            <td>10052</td>
            <td></td>
            <td>1188</td>
          </tr>
          <tr>
           <td>2.</td>
            <td>VA00021</td>
            <td>COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td>SHANTARAM</td>
            <td>OFFICE BOY</td>
            <td>MALE</td>
            <td>04/02/1978</td>
            <td>01/04/2017</td>
            <td>100766112317</td>
            <td>10052</td>
            <td></td>
            <td>1188</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>VA00021</td>
            <td>COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td>SHANTARAM</td>
            <td>OFFICE BOY</td>
            <td>MALE</td>
            <td>04/02/1978</td>
            <td>01/04/2017</td>
            <td>100766112317</td>
            <td>10052</td>
            <td></td>
            <td>1188</td>
          </tr>
          <tr>
            <td>4.</td>
            <td>VA00021</td>
            <td>COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td>SHANTARAM</td>
            <td>OFFICE BOY</td>
            <td>MALE</td>
            <td>04/02/1978</td>
            <td>01/04/2017</td>
            <td>100766112317</td>
            <td>10052</td>
            <td></td>
            <td>1188</td>
          </tr>
          <tr>
            <td>5.</td>
            <td>VA00021</td>
            <td>COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td>SHANTARAM</td>
            <td>OFFICE BOY</td>
            <td>MALE</td>
            <td>04/02/1978</td>
            <td>01/04/2017</td>
            <td>100766112317</td>
            <td>10052</td>
            <td></td>
            <td>1188</td>
          </tr>
          <tr>
            <td>6.</td>
            <td>VA00021</td>
            <td>COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td>SHANTARAM</td>
            <td>OFFICE BOY</td>
            <td>MALE</td>
            <td>04/02/1978</td>
            <td>01/04/2017</td>
            <td>100766112317</td>
            <td>10052</td>
            <td></td>
            <td>1188</td>
          </tr>
          <tr>
            <td>7.</td>
            <td>VA00021</td>
            <td>COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td>SHANTARAM</td>
            <td>OFFICE BOY</td>
            <td>MALE</td>
            <td>04/02/1978</td>
            <td>01/04/2017</td>
            <td>100766112317</td>
            <td>10052</td>
            <td></td>
            <td>1188</td>
          </tr>
          <tr>
            <td>8.</td>
            <td>VA00021</td>
            <td>COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td>SHANTARAM</td>
            <td>OFFICE BOY</td>
            <td>MALE</td>
            <td>04/02/1978</td>
            <td>01/04/2017</td>
            <td>100766112317</td>
            <td>10052</td>
            <td></td>
            <td>1188</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import html2pdf from 'html2pdf.js'
export default {
  name: 'PDFRegister',
  mounted: function () {
    this.createPDF()
  },
  methods:{
    createPDF() {
      
      var element = document.getElementById('mydiv');

      var opt = {
        margin: 0,
        filename: 'PFRegister.pdf',
        image: {type: 'jpeg',quality: 0.98},
        html2canvas: {scale: 2},
        jsPDF: {
          unit: 'mm',
          format: [400, 520],
          orientation: 'portrait'
        }
      };

      html2pdf().set(opt).from(element).save();
    },
  }
}
</script>
