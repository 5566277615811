<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">
          
          <div class="row">
            <span style="float: left;">Personal Information</span>
            <span style="float: right;">
              <i class="fa fa-pencil-square-o" aria-hidden="true" v-if="!editData" @click="toggleEditData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="editData" @click="toggleEditData"></i>
            </span>
          </div>

          <br>

          <div class="row">

            <div class="form-group row">
              <label class="col-sm-4 col-form-label">DOB</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.dob | ddmmyyyy }}</p>
                <my-datepicker :date.sync="form.dob" placeholder="DOB" v-if="editData"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Age</label>
              <div class="col-sm-8">
                <p>{{ this.duration }}</p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Blood Group</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.blood_group ? form.blood_group.code : '' }}</p>
                <my-select :selection.sync="form.blood_group_id" :suggestions="bloodGroups" placeholder="Select blood group" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Father's Name</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.father_name }}</p>
                <input v-uppercase type="text" class="form-control" placeholder="Father's Name" v-if="editData" v-model="form.father_name">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Gender</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.gender == 0 ? 'MALE' : 'FEMALE'}}</p>
                <my-select :selection.sync="form.gender" :suggestions="genders" placeholder="Select gender" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Marital Status</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.marital_status ? form.marital_status.code : '' }}</p>
                <my-select :selection.sync="form.marital_status_id" :suggestions="maritalStatuses" placeholder="Select marital status" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Marriage Date</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.marriage_date | ddmmyyyy }}</p>
                <my-datepicker :date.sync="form.marriage_date" placeholder="Marriage Date" v-if="editData"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Spouse Name</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.spouse_name }}</p>
                <input v-uppercase type="text" class="form-control" placeholder="Spouse Name" v-if="editData" v-model="form.spouse_name">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Nationality</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.nationality ? form.nationality.code : '' }}</p>
                <my-select :selection.sync="form.nationality_id" :suggestions="nationalities" placeholder="Select nationality" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Residential Status</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.residential_status ? form.residential_status.code : '' }}</p>
                <my-select :selection.sync="form.residential_status_id" :suggestions="residentialStatuses" placeholder="Select Residential Status" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Place Of Birth</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.place_of_birth }}</p>
                <input v-uppercase type="text" class="form-control" placeholder="Place Of Birth" v-if="editData" v-model="form.place_of_birth">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Country Of Origin</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.country_origin ? form.country_origin.code : '' }}</p>
                <my-select :selection.sync="form.country_origin_id" :suggestions="countries" placeholder="Select Country of Origin" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Religion</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.religion ? form.religion.code : '' }}</p>
                <my-select :selection.sync="form.religion_id" :suggestions="religions" placeholder="Select Religion" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Aadhar No</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.aadhar_no }}</p>
                <input v-uppercase type="text" class="form-control" placeholder="Aadhar No" v-if="editData" v-model="form.aadhar_no">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">International Employee</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.is_international_employee == 1 ? 'YES' : 'NO' }}</p>
                <my-checkbox :selection.sync="form.is_international_employee" v-if="editData"></my-checkbox>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Physically Challenged</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.is_physically_challanged == 1 ? 'YES' : 'NO' }}</p>
                <my-checkbox :selection.sync="form.is_physically_challanged" v-if="editData"></my-checkbox>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Is Director</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.is_director == 1 ? 'YES' : 'NO' }}</p>
                <my-checkbox :selection.sync="form.is_director" v-if="editData"></my-checkbox>
              </div>
            </div>
            
            <div class="form-group row">
              <div class="col-sm-10"
                v-if="editData"
              >
                <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import moment from 'moment'
export default {
  data () {
    return {
      editData: false,
    }
  },
  computed: {
    duration() {
      let DateOfBirth = moment(this.form.dob).isValid()?moment(this.form.dob):moment(this.form.dob, "DD-MM-YYYY").toDate()
      let current_date = moment();
      let duration = current_date.diff(DateOfBirth, "years");
      return duration
    }
  },
  props: ['form', 'bloodGroups', 'maritalStatuses', 'nationalities', 'residentialStatuses', 'countries', 'religions', 'isLoading', 'genders'],
  methods: {
    toggleEditData() {
      this.editData = !this.editData
    },
    cancelUpdate() {
      this.editData = false
    },
    async update() {
      this.$emit('update')
      this.editData = false
    }, 
  }, 
  mounted()  {
      
  }
}
</script>