<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;">PT Account</span>
            <span style="float: right;">
              <i class="fa fa-pencil-square-o" aria-hidden="true" v-if="!editData" @click="toggleEditData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="editData" @click="toggleEditData"></i>
            </span>
          </div>

          <br>

          <div class="row">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Is PT Applicable</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.is_pt_applicable == 1 ? 'YES' : 'NO' }}</p>
                <my-checkbox :selection.sync="form.is_pt_applicable" v-if="editData"></my-checkbox>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10"
                v-if="editData"
              >
                <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      editData: false,
    }
  },
  props: ['form', 'isLoading'],
  methods: {
    toggleEditData() {
      this.editData = !this.editData
    },
    cancelUpdate() {
      this.editData = false
    },
    async update() {
      console.log(123)
      this.$emit('update')
      this.editData = false
    }
  }
}
</script>