<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Pay Slip</h5>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-md-4">
            <my-datepicker
              :date.sync="from_date"
              placeholder="From Date"
            ></my-datepicker>
            <my-datepicker
              :date.sync="to_date"
              placeholder="To Date"
            ></my-datepicker>
            <br /><br />
            <button @click="searchData('generate')">Generate</button
            >&nbsp;&nbsp;
            <button @click="generatePDF">Save & Print</button>
            <br /><br />
          </div>
          <div class="col-md-offset-2 col-md-6">
            <my-select
              style="margin: 9px !important"
              @change="selectDivision"
              disableFormControl="true"
              :selection.sync="group"
              :suggestions="groups"
              :placeholder="`Select GROUP`"
            >
            </my-select>
            <my-select
              style="margin: 9px !important"
              disableFormControl="true"
              :selection.sync="groupDivision"
              :suggestions="groupDivisions"
              :placeholder="`Select DIVISION`"
            >
            </my-select>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <!-- <div class="col-md-12"> -->
                        <div v-for="(month, m) in months" :key="m">
                          <div v-if="month != ''">
                            <!-- {{month}} -->
                            <div v-for="(user, i) in month" :key="i">
                              <div v-if="user.employee">
                                <PaySlipPdf
                                  :form="user.employee.salary"
                                  :isLoading="isLoading"
                                  :configurations="configurations"
                                  :SalaryUser="user.employee"
                                  :MonthlySalary="user"
                                  @update="update"
                                ></PaySlipPdf>
                                <!-- {{ user.employee.full_name }} -->
                              </div>
                              <br />
                              <br />
                              <br />
                            </div>
                          </div>
                        </div>
                        <!-- <div
                            class="col-md-12"
                            v-for="(user, i) in month.m.users"
                            :key="i"
                          > -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Row -->
  <!-- </div>  -->
  <!-- </div> -->
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import store from "@/store";
import { updateFormData } from "@/static/helpers.js";
import PaySlipPdf from "./pay-slips-pdf.vue";
import html2pdf from "html2pdf.js";
import moment from "moment";

export default {
  name: "EmployeeSalary",
  data() {
    return {
      form: {},
      users: [],
      months: "",
      month_name: "",
      year: "",
      isLoading: false,
      addressTypes: [],
      configurations: {
        pf_configuration: {},
        esic_configuration: {},
        user: {},
      },
      from_date: "2021-06-01",
      to_date: "2021-07-15",
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
    };
  },
  components: {
    PaySlipPdf,
  },
  mounted() {
    this.getMasters();
    this.month_id = moment().format("M");
    this.year = moment().format("Y");
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;

      masters.address_types.forEach((addressType) => {
        this.addressTypes.push({
          id: addressType.id,
          name: addressType.code,
          code: addressType.code,
        });
      });

      this.configurations = masters.configurations;

      let masters1 = await axios.get("/monthly_salaries/masters");
      masters1 = masters1.data;

      this.categories = masters1.categories;
      this.groups = masters1.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    async search(input) {
      // if (input.length < 3) {
      //   return []
      // } else {
      let response = await axios.get(`/users?search=${input}`);
      return response.data.data;
      // }
    },
    searchData(source = "") {
      this.showPTRegister = false;
      this.showPTRegisterTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        this.months = [];
        let from_Date = moment(String(this.from_date)).format("YYYY-MM-DD");
        let to_Date = moment(String(this.to_date)).format("YYYY-MM-DD");
        let users = await axios.get(
          `/pt_reports/generate_payslip?from_date=${from_Date}&to_date=${to_Date}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = users.data.data.users;
        this.months = users.data.data.users[0];
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showPTRegisterTable = true;
        this.showPTRegister = false;
      }
      if (source == "generate") {
        this.showPTRegisterTable = false;
        this.showPTRegister = true;
      }
    },
    getResultValue(result) {
      return result.full_name;
    },
    async handleSubmit(result) {
      let response = await axios.get(`users/${result.id}`);
      store.dispatch("employee/setEmployee", response.data.data);
      this.updateForm(response.data.data);
    },
    updateForm(formData) {
      this.form = updateFormData(formData, this.addressTypes, this.site);
    },
    async update() {
      try {
        this.isLoading = true;
        let response = await axios.post(`/users`, this.form);
        store.dispatch("employee/setEmployee", response.data.data);
        this.updateForm(response.data.data);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        // margin: [bottom:10],
        filename: "PaySlip.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          // format: [210,450],
          format: [310, 450],
          size: 1,
          orientation: "portrait",
        },
      };
      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>
