<template>
  <section id="mydiv">
    <div class="container-fluid">
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <p>(For unexempted establishments Only)</p>
        </div>
        <div class="col-lg-9 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <center>
              <p style="font-weight: bold; font-size: 18px">
                Form 3A (Revised)
              </p>
            </center>
            <br />
            <p>
              The Employee's Provident Fund Scheme, 1952 (Para 35 & 42) and The
              Employee's Pension Scheme, 1955 (Para 19)
            </p>
            <p>
              Contribution Card for Currency Period From 1st April 2020 to 31st
              March 2021
            </p>
          </h5>
        </div>
      </div>
    </div>
    <br />
    <div class="container-fluid row">
      <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12">
        <p>1. P.F. ACCOUNT No.: ABD/2551232</p>
        <p>2. UAN No:</p>
        <p>3. Name/ Surname : HDIKD</p>
        <p>(In Block Letters)</p>
        <p>5. Father's / Husband Name: ASDF</p>
        <p>(In Block Letters)</p>
        <p>6. Statutory rate of P.F. Contribution 12.00 %</p>
        <p>7. Voluntary higher rate of employee's Contribution if any NIL %</p>
      </div>
      <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12">
        <p>4. Name $ Address of the Factory / Establishment</p>
        <p style="font-size: 20px; font-weight: bold">V ALLIANCE</p>
        <p>Office No-5-A, Raheja Arcade, Sector-11,</p>
        <p>CBD Belapur, Navi Mumbai-4000614.</p>
        <p>Plot no-61</p>
        <p>Navi Mumbai-4000614.</p>
      </div>
    </div>
    <hr />
    <div class="container-fluid">
      <p style="font-size: 18px; font-weight: bold">CONTRIBUTION</p>
      <table id="table-example-1" class="table table-responsive col-md-10">
        <thead>
          <tr>
            <th rowspan="2">Month</th>
            <th rowspan="2">Amount Of Wages</th>
            <th rowspan="2">Worker's Share E.P.F</th>
            <th colspan="2"><center>Employee's Share</center></th>
            <th rowspan="2">Refund Of Advances</th>
            <th rowspan="2">No. Of days/period of non-contributig service (if any)</th>
            <th rowspan="2">Remark</th>
          </tr>
          <tr>
            <th>E.P.F difference between 12% and 8.33% (if any)</th>
            <th>pension fund contribution 8.33% </th>
          </tr>
          <tr>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4(a)</th>
            <th>4(b)</th>
            <th>5</th>
            <th>6</th>
            <th>7</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>March Paid</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td rowspan="5">(a) Date Of Leaving Service (if any)</td>
          </tr>
          <tr>
            <td>In April</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
           <tr>
            <td>May</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
           <tr>
            <td>June</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
           <tr>
            <td>July</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
           <tr>
            <td>August</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
            <td rowspan="8">(b) Reasons for leaving service (if any)</td>
          </tr>
           <tr>
            <td>September</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
           <tr>
            <td>October</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
           <tr>
            <td>November</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
           <tr>
            <td>December</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
           <tr>
            <td>January</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
           <tr>
            <td>Febuary</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
           <tr>
            <td>Febuary Paid</td>
            <td>11246.00</td>
            <td>1350.00</td>
            <td>413.00</td>
            <td>937.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
           <tr>
            <td>In March</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
           <tr>
            <td>Total</td>
            <td>138384.00</td>
            <td>16609.00</td>
            <td>5082.00</td>
            <td>11527.00</td>
            <td></td>
            <td>0.00</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="container-fluid row">
      <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
        <p>Certified that the total of contribution (both shares) included in this Card i.e Rs 21691.00 has already been</p>
        <p>remitted in full in EPF A/c No. 1 and Pension Fund A/c No. 1011527.00 (vide note below)</p>
        <p>Certified that the difference between the total of the contribution shown under columns 3,4(a) & 4(b) of the above table and that arrived at on</p>
        <p>the total wwges shown in column 2 at the prescribed rate is solely due to rounding off of contributions to the nearest rupee under the rules.</p>
        <p>Date 01/06/2021</p>
      <hr>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
      </div>
      <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
        <p>Note: (1) In respect of the form 3A sent to the Regional Office during the course of the currency period for the purpose of final settelment of the </p>
        <p>&nbsp;accounts of the memner, who has left service, details of date & reasons for leaving service should be furnished under columns, 7(a) & (b)</p>
        <p>(2) In respect of those who are members of the Pension Fund, the employers share of contribution to the EPF will be 8.1/3% or 12% </p>
        <p>as the case may be & is to be shown under column 4(a)</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" style="border-style: solid; border-width: thin; border-color: #111111; margin-top: -120px;">
        <p style="font-size: 20px; font-weight: bold"> for V ALLIANCE</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <p>Authorised signature</p>
        Signature of the Employer with Office Seal
      </div>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "PDFForm3A",
   mounted: function () {
      this.createPDF()
  },
  methods: {
    createPDF() {
      var element = document.getElementById("mydiv");

      var opt = {
        margin: 0,
        filename: "PFForm3A.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [350, 400],
          orientation: "portrait",
        },
      };

      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
