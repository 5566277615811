<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Categories
            <add-button link="/settings/categories/create"></add-button>
            &nbsp;
            <upload-button link="/settings/categories/upload"></upload-button>
            &nbsp;
            <refresh-button @click="getData"></refresh-button>
            &nbsp;
          </h5>
          <br />
          <my-search
            :search="search"
            placeholder="Search category..."
            class="form-control"
            @searchData="searchData"
          ></my-search>
        </div>
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Categories</li>
          </ol>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Name</th>
                        <th>Is Enabled</th>
                        <th>Is Mandatory</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="5"
                      ></loading-table>
                      <router-link
                        v-for="(category, at) in categories"
                        :key="`category${at}`"
                        :to="`/settings/categories/${category.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ category.category_name }}</td>
                        <td>{{ category.is_enabled == 1 ? "YES" : "NO" }}</td>
                        <td>{{ category.is_mandatory == 1 ? "YES" : "NO" }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "Categories",
  data() {
    return {
      categories: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      search: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1, search = "") {
      this.isLoading = true;
      let categories = await axios.get(
        `/categories?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${search}`
      );
      this.categories = categories.data.data;
      this.count = categories.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    searchData(category) {
      this.getData(1, category);
    },
  },
};
</script>

<style>
th {
}
</style>
