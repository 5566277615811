<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;"><b>Passport</b></span>
            <span style="float: right;">
              <i class="fa fa-plus" aria-hidden="true" v-if="!addData" @click="toggleAddData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="addData" @click="toggleAddData"></i>
            </span>
          </div>

          <br>

          <div class="row"
            v-if="addData"
          >
            <p><u>Add New Passport Details</u></p>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">First Name</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="First Name" v-model="user_passport.first_name">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Middle Name</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Middle Name" v-model="user_passport.middle_name">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Surname</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Surname" v-model="user_passport.surname">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Address 1</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Address 1" v-model="user_passport.address_1">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Address 2</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Address 2" v-model="user_passport.address_2">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Address 3</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Address 3" v-model="user_passport.address_3">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Country</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_passport.country_id" :suggestions="countries" placeholder="Country"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Passport no</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Passport No" v-model="user_passport.passport_no">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Passport Type</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_passport.passport_type_id" :suggestions="passportTypes" placeholder="Passport Type"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Issue Place</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Issue Place" v-model="user_passport.issue_place">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Issue City</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Issue City" v-model="user_passport.issue_city">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Issue Date</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_passport.issue_date" placeholder="Issue Date"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Valid Till</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_passport.valid_till" placeholder="Valid Till"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10">
                <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
              </div>
            </div>
          </div>

          <!-- List of employee passports -->
          <div class="row"
            v-for="(userPassport, up) in form.user_passports"
            :key="`userPassport${up}`"
          >
            <div class="form-group row"
              v-if="!(editData == up)"
            >
              <label class="col-sm-4 col-form-label">{{ up + 1 }}. {{ userPassport.first_name + (userPassport.middle_name ? ' ' + userPassport.middle_name : '') + (userPassport.surname ? ' ' + userPassport.surname : '') }}</label>
              <div class="col-sm-6">
                {{ userPassport.passport_no }}
              </div>
              <div class="col-md-2">
                <i class="fa fa-pencil-square-o" style="float: right;" aria-hidden="true" @click="toggleEditData(userPassport, up)"></i>
              </div>
            </div>
            <div
              v-else
            >
              <p><u>{{ up + 1 }}. Update Employee Passport Details</u></p>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">First Name</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="First Name" v-model="user_passport.first_name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Middle Name</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Middle Name" v-model="user_passport.middle_name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Surname</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Surname" v-model="user_passport.surname">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Address 1</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Address 1" v-model="user_passport.address_1">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Address 2</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Address 2" v-model="user_passport.address_2">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Address 3</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Address 3" v-model="user_passport.address_3">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Country</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="user_passport.country_id" :suggestions="countries" placeholder="Country"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Passport no</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Passport No" v-model="user_passport.passport_no">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Passport Type</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="user_passport.passport_type_id" :suggestions="passportTypes" placeholder="Passport Type"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Issue Place</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Issue Place" v-model="user_passport.issue_place">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Issue City</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Issue City" v-model="user_passport.issue_city">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Issue Date</label>
                <div class="col-sm-8">
                  <my-datepicker :date.sync="user_passport.issue_date" placeholder="Issue Date"></my-datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Valid Till</label>
                <div class="col-sm-8">
                  <my-datepicker :date.sync="user_passport.valid_till" placeholder="Valid Till"></my-datepicker>
                </div>
              </div>
              <div class="col-sm-2">
                <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      addData: false,
      editData: null,
      user_passport: {
        first_name: '',
        middle_name: '',
        surname: '',
        address_1: '',
        address_2: '',
        address_3: '',
        country_id: '',
        passport_no: '',
        passport_type_id: '',
        issue_place: '',
        issue_city: '',
        issue_date: '',
        valid_till: '',
      },
    }
  },
  props: ['form', 'isLoading', 'passportTypes', 'countries'],
  methods: {
    toggleAddData() {
      this.user_passport = {
        first_name: '',
        middle_name: '',
        surname: '',
        address_1: '',
        address_2: '',
        address_3: '',
        country_id: '',
        passport_no: '',
        passport_type_id: '',
        issue_place: '',
        issue_city: '',
        issue_date: '',
        valid_till: '',
      }
      this.addData = !this.addData
    },
    toggleEditData(userPassport, up) {
      this.editData = up
      this.user_passport = userPassport
    },
    cancelUpdate() {
      this.editData = null
    },
    save() {
      if(this.user_passport.first_name == '' || this.user_passport.passport_no == '') 
        alert('Please enter first name and passport no')
      else {
        this.form.user_passports.push(this.user_passport)
        this.update()
        this.addData = false
      }
    },
    async update() {
      this.$emit('update')
      this.editData = null
    }
  }
}
</script>