<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;"><b>Visa</b></span>
            <span style="float: right;">
              <i class="fa fa-plus" aria-hidden="true" v-if="!addData" @click="toggleAddData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="addData" @click="toggleAddData"></i>
            </span>
          </div>

          <br>

          <div class="row"
            v-if="addData"
          >
            <p><u>Add New Visa Details</u></p>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Country</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_visa.country_id" :suggestions="countries" placeholder="Country"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Visa Type</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_visa.visa_type_id" :suggestions="visaTypes" placeholder="Visa Type"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Visa No</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Visa No" v-model="user_visa.visa_no">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Issue Date</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_visa.issue_date" placeholder="Issue Date"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Valid Till</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_visa.valid_till" placeholder="Valid Till"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10">
                <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
              </div>
            </div>
          </div>

          <!-- List of employee visas -->
          <div class="row"
            v-for="(userVisa, uv) in form.user_visas"
            :key="`userVisa${uv}`"
          >
            <div class="form-group row"
              v-if="!(editData == uv)"
            >
              <label class="col-sm-4 col-form-label">{{ uv + 1 }}. {{ userVisa.country.description }}</label>
              <div class="col-sm-6">
                {{ userVisa.visa_no }}
              </div>
              <div class="col-md-2">
                <i class="fa fa-pencil-square-o" style="float: right;" aria-hidden="true" @click="toggleEditData(userVisa, uv)"></i>
              </div>
            </div>
            <div
              v-else
            >
              <p><u>{{ uv + 1 }}. Update Employee Visa Details</u></p>
              <div class="form-group row">
              <label class="col-sm-4 col-form-label">Country</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_visa.country_id" :suggestions="countries" placeholder="Country"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Visa Type</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_visa.visa_type_id" :suggestions="visaTypes" placeholder="Visa Type"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Visa No</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Visa No" v-model="user_visa.visa_no">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Issue Date</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_visa.issue_date" placeholder="Issue Date"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Valid Till</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_visa.valid_till" placeholder="Valid Till"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10">
                <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
              </div>
            </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      addData: false,
      editData: null,
      user_visa: {
        country_id: '',
        visa_type_id: '',
        visa_no: '',
        issue_date: '',
        valid_till: '',
      },
    }
  },
  props: ['form', 'isLoading', 'countries', 'visaTypes'],
  methods: {
    toggleAddData() {
      this.user_visa = {
        country_id: '',
        visa_type_id: '',
        visa_no: '',
        issue_date: '',
        valid_till: '',
      }
      this.addData = !this.addData
    },
    toggleEditData(useVisa, uv) {
      this.editData = uv
      this.user_visa = useVisa
    },
    cancelUpdate() {
      this.editData = null
    },
    save() {
      if(this.user_visa.country_id == '' || this.user_visa.visa_no == '') 
        alert('Please enter country and visa no')
      else {
        this.form.user_visas.push(this.user_visa)
        this.update()
        this.addData = false
      }
    },
    async update() {
      this.$emit('update')
      this.editData = null
    }
  }
}
</script>