var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"mydiv"}},[_c('div',{staticClass:"container-fluid"},[_c('p',{staticStyle:{"font-size":"18px","font-weight":"bold"}},[_c('center',[_vm._v("V ALLIANCE")])],1),_c('p',[_c('center',[_vm._v(" Office No-5-A, Raheja Arcade, Sector-11, CBD Belapur, Navi Mumbai- 400 614 ")])],1),_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v("Arrear Payslip")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('table',{staticClass:"table table-responsive col-md-9",attrs:{"id":"table-example-1"}},[_vm._m(4),_c('tbody',[_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_c('tr',{staticStyle:{"font-weight":"bold"}},[_c('td',{attrs:{"colspan":"3"}},[_c('center',[_vm._v("Total")])],1),_c('td',[_vm._v("13685.00")]),_c('td',[_vm._v("15183.00")]),_c('td',[_vm._v("1498.00")])]),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_c('tr',{staticStyle:{"font-weight":"bold"}},[_c('td',{attrs:{"colspan":"3"}},[_c('center',[_vm._v("Total")])],1),_c('td',[_vm._v("13685.00")]),_c('td',[_vm._v("15183.00")]),_c('td',[_vm._v("1498.00")])]),_c('tr',{staticStyle:{"font-weight":"bold"}},[_c('td',{attrs:{"colspan":"3"}},[_c('center',[_vm._v("NET SALARY")])],1),_c('td',[_vm._v("13685.00")]),_c('td',[_vm._v("15183.00")]),_c('td',[_vm._v("1498.00")])])])]),_c('p',[_vm._v("Rupees One Thousand Three Hundred Seven Only")]),_c('p',[_vm._v("Bank : ICICI BANK        Account No. : 107101508117")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("Payslip :")])]),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("1")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("Paid Month :")])]),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("June 2019")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("Branch :")])]),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("MUMBAI")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("Emp Code :")])]),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("VA00173")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("Name :")])]),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("Mr. KALPESH B BAGWE")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("Department :")])]),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("OPERATION")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("Designation :")])]),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("OFFICE BOY")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("Division :")])]),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("KAYA LIMITED ANDHERI OFFICE")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("Joining Dt. :")])]),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("01/05/2017")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("ESIC No. :")])]),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("311151515")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("PF No. :")])]),_c('div',{staticClass:"col-md-6"},[_c('p')])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col-md-6",staticStyle:{"font-weight":"bold"}},[_c('p',[_vm._v("PAN No. :")])]),_c('div',{staticClass:"col-md-6"},[_c('p')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Month")]),_c('th',[_vm._v("Year")]),_c('th',[_vm._v("Particulars")]),_c('th',[_vm._v("Paid")]),_c('th',[_vm._v("Payable")]),_c('th',[_vm._v("Difference")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("January")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("D.A.")]),_c('td',[_vm._v("3650.00")]),_c('td',[_vm._v("5000.00")]),_c('td',[_vm._v("459.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("February")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("D.A.")]),_c('td',[_vm._v("3650.00")]),_c('td',[_vm._v("4104.00")]),_c('td',[_vm._v("459.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("March")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("D.A.")]),_c('td',[_vm._v("3650.00")]),_c('td',[_vm._v("4104.00")]),_c('td',[_vm._v("459.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("January")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("H.R.A.")]),_c('td',[_vm._v("453.00")]),_c('td',[_vm._v("476.00")]),_c('td',[_vm._v("23")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("February")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("H.R.A.")]),_c('td',[_vm._v("453.00")]),_c('td',[_vm._v("476.00")]),_c('td',[_vm._v("23")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("March")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("H.R.A.")]),_c('td',[_vm._v("453.00")]),_c('td',[_vm._v("476.00")]),_c('td',[_vm._v("23")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("January")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("OVER TIME")]),_c('td',[_vm._v("107.00")]),_c('td',[_vm._v("5000.00")]),_c('td',[_vm._v("4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("February")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("OVER TIME")]),_c('td',[_vm._v("107.00")]),_c('td',[_vm._v("5000.00")]),_c('td',[_vm._v("4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("March")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("OVER TIME")]),_c('td',[_vm._v("107.00")]),_c('td',[_vm._v("5000.00")]),_c('td',[_vm._v("4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"font-weight":"bold"}},[_c('th',{attrs:{"colspan":"3"}}),_c('th',[_vm._v("Recovered")]),_c('th',[_vm._v("Recoverable")]),_c('th',[_vm._v("Difference")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("January")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("PROV. FUND")]),_c('td',[_vm._v("1085.00")]),_c('td',[_vm._v("1140.00")]),_c('td',[_vm._v("55.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("February")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("PROV. FUND")]),_c('td',[_vm._v("1085.00")]),_c('td',[_vm._v("1140.00")]),_c('td',[_vm._v("55.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("March")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("PROV. FUND")]),_c('td',[_vm._v("1085.00")]),_c('td',[_vm._v("1140.00")]),_c('td',[_vm._v("55.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("January")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("E.S.I.C")]),_c('td',[_vm._v("258.00")]),_c('td',[_vm._v("274.00")]),_c('td',[_vm._v("8.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("February")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("E.S.I.C")]),_c('td',[_vm._v("258.00")]),_c('td',[_vm._v("274.00")]),_c('td',[_vm._v("8.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("March")]),_c('td',[_vm._v("19")]),_c('td',[_vm._v("E.S.I.C")]),_c('td',[_vm._v("258.00")]),_c('td',[_vm._v("274.00")]),_c('td',[_vm._v("8.00")])])
}]

export { render, staticRenderFns }