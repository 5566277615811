<template>
  <!-- Main Content -->
  <CTC></CTC>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";
import CTC from '@/views/main/employees/salary/index.vue'

export default {
  name: "PDFCTC",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022},
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showCTCTable: false,
      showCTC: false,
    };
  },
  components : {
    CTC
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showCTC = false;
      this.showCTCTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        let users = await axios.get(
          `/user_punches/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = [];
        users.data.data.forEach((user) => {
          if (
            user.employee_status == null ||
            (user.employee_status.code != "LEFT" &&
              user.employee_status.code != "TERMINATE")
          )
            this.users.push(user);
        });
        for (const user of this.users) {
          if (user.monthly_salary == null)
            this.initializeEmptyMonthlySalary(user);
          else this.getEarnedSalaryComponents(user);
        }
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showCTCTable = true;
        this.showCTC = false;
      }
      if (source == "generate") {
        this.showCTCTable = false;
        this.showCTC = true;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        // margin: [bottom:10],
        filename: "CTC.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          // format: [210,450],
          format: [310,450],
          size:1,
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
