<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button :link="`/main/tickets/${ticket.id}/follow-ups`"></back-button> 
            Create a new follow up of ticket: {{ ticket.description }}
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link :to="`/tickets/${ticket.id}/follow-ups`"> Ticket Follow Ups </router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <br>

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Description*</label>
                        <div class="col-sm-9">
                          <textarea v-uppercase class="form-control" placeholder="Description" v-model="form.description"></textarea>
                          <span class="error-block">{{ errors.description }}</span>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'followupCreate',
  data () {
    return {
      ticket: {},
      form: {
      },
      isLoading: false,
    }
  },
  mounted() {
    this.form.user_id = this.user.id
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      let ticket = await axios.get(`/tickets/${this.$route.params.ticketId}`)
      this.ticket = ticket.data.data
      this.isLoading = false
    },
    async save() {
      try {
        console.log(this.form)
        this.isLoading = true
        await axios.post(`/tickets/${this.$route.params.ticketId}/ticket_followups`, this.form)
        this.isLoading = false
        this.$router.push(`/main/tickets/${this.$route.params.ticketId}/follow-ups`)
      } catch(e) {
        this.isLoading = false
      }
    }
  }
}
</script>