<template>
  <section id="mydiv">
    <div class="container-fluid" style="margin-top: 20px;">
      <p style="font-size: 18px; font-weight: bold;">
        LEAVE REGISTER : FOR THE MONTH: APRIL 2020 TO MARCH 2021
      </p>
      <table class="col-md-12" border="2px">
        <thead>
          <tr>
            <th>Employee Code</th>
            <th>Division Name</th>
            <th>Employee Name</th>
            <th>Date Of Joining</th>
            <th>April 2020</th>
            <th>May 2020</th>
            <th>June 2020</th>
            <th>July 2020</th>
            <th>August 2020</th>
            <th>September 2020</th>
            <th>October 2020</th>
            <th>November 2020</th>
            <th>December 2020</th>
            <th>January 2021</th>
            <th>February 2021</th>
            <th>March 2021</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="5">VA00021</td>
            <td rowspan="5">COLLIERS INTERNATIONAL MUMBAI</td>
            <td>VIKITA VILAS DHURI</td>
            <td rowspan="5">04/02/1978</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>No of Days</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Days Paid</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Calculated Amt</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Leave Salary</td>
            <td>30.00</td>
            <td></td>
            <td></td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>

          <tr>
            <td rowspan="5">VA00128</td>
            <td rowspan="5">COLLIERS INTERNATIONAL MUMBAI</td>
            <td>GANESH BALIRAM GORE</td>
            <td rowspan="5">04/02/1978</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>No of Days</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Days Paid</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Calculated Amt</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Leave Salary</td>
            <td>30.00</td>
            <td></td>
            <td></td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td rowspan="5">VA00021</td>
            <td rowspan="5">COLLIERS INTERNATIONAL MUMBAI</td>
            <td>MANGESH UDAY GODSE</td>
            <td rowspan="5">04/02/1978</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>No of Days</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Days Paid</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Calculated Amt</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Leave Salary</td>
            <td>30.00</td>
            <td></td>
            <td></td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td rowspan="5">VA00021</td>
            <td rowspan="5">COLLIERS INTERNATIONAL MUMBAI</td>
            <td>SANDESH SAKHARAM GURAV</td>
            <td rowspan="5">04/02/1978</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>No of Days</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Days Paid</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Calculated Amt</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Leave Salary</td>
            <td>30.00</td>
            <td></td>
            <td></td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td rowspan="5">VA00021</td>
            <td rowspan="5">COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td rowspan="5">04/02/1978</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>No of Days</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Days Paid</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Calculated Amt</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Leave Salary</td>
            <td>30.00</td>
            <td></td>
            <td></td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td rowspan="5">VA00021</td>
            <td rowspan="5">COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td rowspan="5">04/02/1978</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>No of Days</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Days Paid</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Calculated Amt</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Leave Salary</td>
            <td>30.00</td>
            <td></td>
            <td></td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td rowspan="5">VA00021</td>
            <td rowspan="5">COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td rowspan="5">04/02/1978</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>No of Days</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Days Paid</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Calculated Amt</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Leave Salary</td>
            <td>30.00</td>
            <td></td>
            <td></td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td rowspan="5">VA00021</td>
            <td rowspan="5">COLLIERS INTERNATIONAL MUMBAI</td>
            <td>YOGESH S MEHTA</td>
            <td rowspan="5">04/02/1978</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>No of Days</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Days Paid</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Calculated Amt</td>
            <td>30.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
          <tr>
            <td>Leave Salary</td>
            <td>30.00</td>
            <td></td>
            <td></td>
            <td>31.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>30.00</td>
            <td>31.00</td>
            <td>31.00</td>
            <td>28.00</td>
            <td>31.00</td>
            <td>304.00</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "LEAVERegister",
  mounted: function() {
    this.createPDF()
  },
  methods: {
    createPDF() {
      var element = document.getElementById("mydiv");

      var opt = {
        margin: 0,
        filename: "LEAVERegister.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [400, 520],
          orientation: "portrait",
        },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>
<style scoped>
td,
th {
  border: 1px solid #ddd;
}
</style>
