<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            Invoice Annexure 2
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
          <my-search
            :search="searchText"
            placeholder="Search here..."
            @searchData="search"
          ></my-search>
          <br /><br />
          <div class="row">
            <div class="col-md-4">
              <my-select
                style="margin: 9px !important"
                @change="updateMonth"
                disableFormControl="true"
                :selection.sync="month_id"
                :suggestions="months"
                placeholder="Select month"
              ></my-select>
              <my-select
                style="margin: 9px !important"
                @change="updateMonth"
                disableFormControl="true"
                :selection.sync="year"
                :suggestions="years"
                placeholder="Select year"
              ></my-select>
              <br /><br />
              <!-- <button @click="searchData('search')">Search</button>&nbsp;&nbsp; -->
              <button @click="searchData('generate')">Generate</button
              >&nbsp;&nbsp;
              <button @click="generatePDF">Save & Print</button>
              <br /><br />
            </div>
            <div class="col-md-offset-2 col-md-6">
              <my-select
                style="margin: 9px !important"
                @change="selectDivision"
                disableFormControl="true"
                :selection.sync="group"
                :suggestions="groups"
                :placeholder="`Select GROUP`"
              >
              </my-select>
              <my-select
                style="margin: 9px !important"
                disableFormControl="true"
                :selection.sync="groupDivision"
                :suggestions="groupDivisions"
                :placeholder="`Select DIVISION`"
              >
              </my-select>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />

                <div class="table-wrap" v-if="showInvoiceAnnexure2Table"></div>
                <section id="mydiv" v-if="showInvoiceAnnexure2">
                  <div
                    class="container-fluid border"
                    style="overflow-x: scroll"
                  >
                    <table id="table-example-1" class="table table-responsive">
                      <thead>
                        <tr>
                          <th colspan="2"><center>Client Name:</center></th>
                          <th colspan="2">Kaya LIMITED</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>Days:</th>
                          <th>31</th>
                          <th>Month:</th>
                          <th>May-21</th>
                        </tr>
                        <tr>
                          <th colspan="2">Attendance Annexure</th>
                          <th></th>
                          <th colspan="4">HK Boy (12 Hrs)</th>
                          <th colspan="4">HK Boy (10 Hrs)</th>
                          <th colspan="4">Lab Assistant - 9 Hrs</th>
                          <th colspan="4">Valet (9 Hrs)</th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr>
                          <th>Sr no</th>
                          <th>Location</th>
                          <th>I.O No</th>
                          <th>Strength</th>
                          <th>Man Days</th>
                          <th>Rate</th>
                          <th>Total</th>
                          <th>Strength</th>
                          <th>Man Days</th>
                          <th>Rate</th>
                          <th>Total</th>
                          <th>Strength</th>
                          <th>Man Days</th>
                          <th>Rate</th>
                          <th>Total</th>
                          <th>Strength</th>
                          <th>Man Days</th>
                          <th>Rate</th>
                          <th>Total</th>
                          <th>gross Total</th>
                          <th>Duty Hours/ Days</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td style="font-weight: bold">A</td>
                          <td style="font-weight: bold">
                            HouseKeeping & Allied Services
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Bandra, Mumbai</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Peddar, Mumbai</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Sion, Mumbai</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Lokhandwala</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Kandiwali-W</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Powai</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Andheri E, Sher E Punjab</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>-</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr style="font-weight: bold">
                          <td colspan="6">
                            <p style="float: right; font-weight: bold">Total</p>
                          </td>
                          <td>-</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>344.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>-</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>-</td>
                          <td>4915.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="19">
                            <p style="float: right; font-weight: bold">
                              Service Charge@ 7.00%
                            </p>
                          </td>
                          <td>344.05</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="19">
                            <p style="float: right; font-weight: bold">Total</p>
                          </td>
                          <td>5236.05</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>

                    <p style="float: center; font-weight: bold">OVER TIME</p>
                    <table id="table-example-1" class="table table-responsive">
                      <thead>
                        <tr>
                          <th rowspan="2">Sr .No</th>
                          <th rowspan="2">Location</th>
                          <th rowspan="2">I.O. NO.</th>
                          <th colspan="4">HK Boy (12 Hrs)</th>
                          <th colspan="4">HK Boy (10 Hrs)</th>
                          <th colspan="4">Lab Assistant - 9 Hrs</th>
                          <th colspan="4">Valet (9 Hrs)</th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr>
                          <th>No.S</th>
                          <th>OT HRS</th>
                          <th>Rate</th>
                          <th>Amount (RS.)</th>
                          <th>No.S</th>
                          <th>OT HRS</th>
                          <th>Rate</th>
                          <th>Amount (RS.)</th>
                          <th>No.S</th>
                          <th>OT HRS</th>
                          <th>Rate</th>
                          <th>Amount (RS.)</th>
                          <th>No.S</th>
                          <th>OT HRS</th>
                          <th>Rate</th>
                          <th>Amount (RS.)</th>
                          <th>gross Total</th>
                          <th>Duty Hours/ Days</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td colspan="21">-</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Bandra, Mumbai</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Peddar, Mumbai</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Sion, Mumbai</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Lokhandwala</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Kandiwali-W</td>
                          <td>7409363</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>16,932.00</td>
                          <td>546.00</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>0</td>
                          <td>4104.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>-</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr style="font-weight: bold">
                          <td></td>
                          <td>
                            <p style="font-weight: bold">Total-Hk-Mumbai</p>
                          </td>
                          <td>-</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>-</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>-</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "InvoiceAnnexure2",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showInvoiceAnnexure2Table: false,
      showInvoiceAnnexure2: false,
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showInvoiceAnnexure2 = false;
      this.showInvoiceAnnexure2Table = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        let users = await axios.get(
          `/user_punches/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = [];
        users.data.data.forEach((user) => {
          if (
            user.employee_status == null ||
            (user.employee_status.code != "LEFT" &&
              user.employee_status.code != "TERMINATE")
          )
            this.users.push(user);
        });
        for (const user of this.users) {
          if (user.monthly_salary == null)
            this.initializeEmptyMonthlySalary(user);
          else this.getEarnedSalaryComponents(user);
        }
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showInvoiceAnnexure2Table = true;
        this.showInvoiceAnnexure2 = false;
      }
      if (source == "generate") {
        this.showInvoiceAnnexure2Table = false;
        this.showInvoiceAnnexure2 = true;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "InvoiceAnnexure2.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 800],
          orientation: "l",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
<style>
.border table,
.border th,
.border td {
  border: 2px solid black;
}
</style>
