<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/day-summaries"></back-button>
            Day Summary of {{ selectedUser.full_name }}
            <add-button
              :link="`/day-summaries/${selectedUser.id}/create`"
            ></add-button>
            <br />
            <save-button @click="save"></save-button>
            <small v-if="isSaving">Saving...</small>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Day Summary of {{ selectedUser.full_name }}</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      <!-- Filters -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-3" @click="(month_id = ''), (year = '')">
                    <my-datepicker
                      style="margin: 9px !important"
                      :date.sync="date"
                      placeholder="Date"
                    ></my-datepicker>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      :selection.sync="month_id"
                      :suggestions="months"
                      @change="date = ''"
                      placeholder="Select MONTH"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      :selection.sync="year"
                      :suggestions="years"
                      @change="date = ''"
                      placeholder="Select YEAR"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-primary btn-sm"
                      style="margin-top: 25px !important"
                      @click="getData"
                    >
                      SEARCH
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Filters -->
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Date</th>
                        <th>From Time</th>
                        <th>To Time</th>
                        <th>Daily Task</th>
                        <th>Description</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="7"
                      ></loading-table>
                      <tr
                        v-for="(dailySummary, at) in dailySummaries"
                        :key="`dailySummary${at}`"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ dailySummary.date | ddmmyyyy }}</td>
                        <td>{{ dailySummary.from_time }}</td>
                        <td>{{ dailySummary.to_time }}</td>
                        <td>
                          <my-select
                            :selection.sync="dailySummary.daily_task_id"
                            :suggestions="dailyTasks"
                            placeholder="Select Daily Task"
                          ></my-select>
                        </td>
                        <td>
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Description"
                            v-model="dailySummary.description"
                          />
                        </td>
                        <td>
                          <button
                            class="sm btn btn-danger"
                            @click="DeleteDaySummery(dailySummary)"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "DaySummaries",
  data() {
    return {
      dailySummaries: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      isSaving: false,
      selectedUser: {},
      dailyTasks: [],
      month_id: "",
      month_name: "",
      year: "",
      date: "",
      searchdate: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
      ],
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
    this.getMonths();
    // this.month_id = moment().format("M");
    // this.month_name = moment().format("MMMM");
    // this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    // updateMonth() {
    //   this.month_name = moment(this.month_id, "M").format("MMMM");
    //   this.totalDaysInMonth = moment(
    //     this.year + "-" + this.month_id,
    //     "YYYY-M"
    //   ).daysInMonth();
    //   this.getData();
    // },
    async getMasters() {
      let masters = await axios.get("/user_daily_summaries/masters");
      masters = masters.data;

      let dailyTasks = masters.daily_tasks;
      dailyTasks.forEach((dailyTask) => {
        this.dailyTasks.push({
          id: dailyTask.id,
          name: dailyTask.code,
        });
      });
    },
    async getData(page = 1) {
      this.isLoading = true;
      if (this.date) {
        let searchdate = moment(this.date).format("YYYY-M-DD");
        this.searchdate = searchdate;
        // console.log(searchdate);
      }
      let dailySummaries = await axios.get(
        `/users/${this.$route.query.userId}/user_daily_summaries?page=${page}&rowsPerPage=${this.rowsPerPage}&date=${this.searchdate}&month=${this.month_id}&year=${this.year}`
      );
      this.dailySummaries = dailySummaries.data.data;
      this.selectedUser = dailySummaries.data.user;
      this.count = dailySummaries.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async save() {
      if (this.dailySummaries.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.dailySummaries,
        };
        let response = await axios.post(
          `/users/${this.$route.query.userId}/user_daily_summaries_multiple`,
          payload
        );
        this.dailySummaries = response.data.data;
        this.isSaving = false;
      }
    },
    async DeleteDaySummery(daysummery) {
      var r = confirm("Are you sure you want to delete the data!");
      if (r == true) {
        await axios.delete(
          `/users/${this.$route.query.userId}/user_daily_summaries/${daysummery.id}`
        );
        this.getData();
      }
    },
  },
};
</script>
