<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;">Employee Education</span>
            <span style="float: right;">
              <i class="fa fa-plus" aria-hidden="true" v-if="!addData" @click="toggleAddData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="addData" @click="toggleAddData"></i>
            </span>
          </div>

          <br>

          <div class="row"
            v-if="addData"
          >
            <p><u>Add New Employee Education Details</u></p>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Qualification</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_qualification.qualification_id" :suggestions="qualifications" placeholder="Select qualification"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">From</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="From" v-model="user_qualification.from">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">To</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="To" v-model="user_qualification.to">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Institute</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Institute" v-model="user_qualification.institute">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Grade</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Grade" v-model="user_qualification.grade">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Qualification Area</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_qualification.qualification_area_id" :suggestions="qualification_areas" placeholder="Select qualification area"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Remarks</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Remarks" v-model="user_qualification.remarks">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10">
                <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
              </div>
            </div>
          </div>

          <!-- List of employee educations -->
          <div class="row"
            v-for="(userEducation, ue) in form.user_educations"
            :key="`userEducation${ue}`"
          >
            <div class="form-group row"
              v-if="!(editData == ue)"
            >
              <label class="col-sm-4 col-form-label">{{ userEducation.qualification.description }}</label>
              <div class="col-sm-6">
                {{ userEducation.institute }}
              </div>
              <div class="col-md-2">
                <i class="fa fa-pencil-square-o" style="float: right;" aria-hidden="true" @click="toggleEditData(userEducation, ue)"></i>
              </div>
            </div>
            <div
              v-else
            >
              <p><u>Update Employee Education Details</u></p>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Qualification</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="user_qualification.qualification_id" :suggestions="qualifications" placeholder="Select qualification"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">From</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="From" v-model="user_qualification.from">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">To</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="To" v-model="user_qualification.to">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Institute</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Institute" v-model="user_qualification.institute">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Grade</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Grade" v-model="user_qualification.grade">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Qualification Area</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="user_qualification.qualification_area_id" :suggestions="qualification_areas" placeholder="Select qualification area"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Remarks</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Remarks" v-model="user_qualification.remarks">
                </div>
              </div>
              <div class="col-sm-2">
                <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      addData: false,
      editData: null,
      user_qualification: {
        qualification_id: '',
        from: '',
        to: '',
        institute: '',
        grade: '',
        qualification_area_id: '',
        remarks: '',
      }
    }
  },
  props: ['form', 'isLoading', 'qualifications', 'qualification_areas'],
  methods: {
    toggleAddData() {
      this.user_qualification = {
        qualification_id: '',
        from: '',
        to: '',
        institute: '',
        grade: '',
        qualification_area_id: '',
        remarks: '',
      }
      this.addData = !this.addData
    },
    toggleEditData(userQualification, ue) {
      this.editData = ue
      this.user_qualification = userQualification
    },
    cancelUpdate() {
      this.editData = null
    },
    save() {
      if(this.user_qualification.qualification_id == '') 
        alert('Please enter qualification')
      else {
        this.form.user_educations.push(this.user_qualification)
        this.update()
        this.addData = false
      }
    },
    async update() {
      this.$emit('update')
      this.editData = null
    }
  }
}
</script>