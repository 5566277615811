<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Employee Family Details
            <br />
          </h5>
          <br />
        </div>
        <!-- /Title -->
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Employee</li>
            <li>Information</li>
            <li class="active">Employee Family Details</li>
          </ol>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <autocomplete
                      :search="search"
                      placeholder="Search employee"
                      aria-label="Search employee"
                      :get-result-value="getResultValue"
                      @submit="handleSubmit"
                    ></autocomplete>
                  </div>
                </div>
              </div>
              <u>{{ form.full_name }}</u>
              <br /><br />
            </div>
          </div>
        </div>
      </div>

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-12">
                          <Details
                            :form="form"
                            :isLoading="isLoading"
                            :nationalities="nationalities"
                            :genders="genders"
                            :blood_groups="blood_groups"
                            :relations="relations"
                            :states="states"
                            :countries="countries"
                            @update="update"
                          ></Details>
                        </div>
                      </div>
                    </div>

                    <router-link
                      to="/main/employees/position-history"
                      class="btn btn-primary"
                      >Next</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import store from "@/store";
import { updateFormData } from "@/static/helpers.js";
import Details from "./details.vue";

export default {
  name: "EmployeeFamilyDetails",
  data() {
    return {
      form: {},
      isLoading: false,
      nationalities: [],
      genders: [],
      blood_groups: [],
      relations: [],
      states: [],
      countries: [],
      addressTypes: [],
    };
  },
  components: {
    Details,
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;

      masters.nationalities.forEach((nationality) => {
        this.nationalities.push({
          id: nationality.id,
          name: nationality.code,
        });
      });
      masters.genders.forEach((gender) => {
        this.genders.push({
          id: gender.id,
          name: gender.code,
        });
      });
      masters.blood_groups.forEach((bloodGroup) => {
        this.blood_groups.push({
          id: bloodGroup.id,
          name: bloodGroup.code,
        });
      });
      masters.relations.forEach((relation) => {
        this.relations.push({
          id: relation.id,
          name: relation.code,
        });
      });
      masters.states.forEach((state) => {
        this.states.push({
          id: state.id,
          name: state.code,
        });
      });
      masters.countries.forEach((country) => {
        this.countries.push({
          id: country.id,
          name: country.code,
        });
      });
      masters.address_types.forEach((addressType) => {
        this.addressTypes.push({
          id: addressType.id,
          name: addressType.code,
          code: addressType.code,
        });
      });

      // Get Employee
      if (this.employee != null) {
        this.updateForm(this.employee);
      }
    },
    async search(input) {
      // if (input.length < 3) {
      //   return []
      // } else {
      let response = await axios.get(`/users?search=${input}`);
      return response.data.data;
      // }
    },
    getResultValue(result) {
      return result.full_name;
    },
    async handleSubmit(result) {
      let response = await axios.get(`users/${result.id}`);
      store.dispatch("employee/setEmployee", response.data.data);
      this.updateForm(response.data.data);
    },
    updateForm(formData) {
      this.form = updateFormData(formData, this.addressTypes, this.site);
    },
    async update() {
      try {
        this.isLoading = true;
        let response = await axios.post(`/users`, this.form);
        store.dispatch("employee/setEmployee", response.data.data);
        this.updateForm(response.data.data);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
