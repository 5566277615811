<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;"><b>Current Position</b></span>
          </div>

          <br>

          <div class="row">
            <div class="form-group row"
              v-for="(position, p) in current_positions"
              :key="`position${p}`"
            >
              <label class="col-sm-4 col-form-label">{{ position.category }}</label>
              <div class="col-sm-8">
                <p>{{ position.category_list }}</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  computed: {
    current_positions() {
      let current_positions = []
      this.categories.forEach(category => {
        let user_categories = this.form.user_categories.filter(user_category => user_category.category_id == category.id)
        if(user_categories.length > 0)
          current_positions.push({
            category: user_categories[0].category.category_name,
            category_list: user_categories[0].category_list.name
          })
        else 
          current_positions.push({
            category: category.category_name,
            category_list: '',
          })
      })
      return current_positions
    }
  },
  props: ['form', 'categories'],
}
</script>