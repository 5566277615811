var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"mydiv"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"col-md-12"},[_c('p',{staticStyle:{"font-weight":"bold"}},[_c('center',[_vm._v("Maharashtra Labour Welfare Board")])],1),_c('p',[_c('center',[_vm._v(" Hutatma Bau Genu, Mumbai Kamgar Kreeda Bhavan, Senapati Bapat Marg, Elphinstone, Mumbai - 400 013 ")])],1),_c('p',{staticStyle:{"font-weight":"bold"}},[_c('center',[_vm._v("FORM A-1-CUM RETURN")]),_c('center',[_vm._v("(VIDE RULE 3-A)")])],1),_c('br'),_vm._m(0),_c('br'),_vm._m(1),_vm._m(2),_vm._m(3),_c('br'),_c('div',{staticClass:"col-md-1"}),_vm._m(4),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('p',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Note: ")]),_vm._v("1) This form cum - return is required to be submitted by every Employer alongwith the of Employee's six monthly contribution made by him in respect of all employee's whose names stand on the register of his establishment as c31 December 2020 ")]),_c('p',[_vm._v(" as per the provisions of Section 6BB of the Bombay Labour Welfare Fund Act, 1953 ")]),_c('p',[_vm._v(" 2) Section 2 (2)(b) of Bombay Welfare Fund Act, 1953 \"Supervisor\" means who, being emplpoyed in a supervisory capacity, draws wages esceeding Three Thousand Five Hundred rupees per mensum or exercise, either by the nature of the duties attached to the office, or by reason of the powers vested in him, functions mainly of a managerial nature. ")]),_c('p',[_vm._v(" 3) EEC = Employee's contribution, ERC = Employer's contribution. ")])]),_c('div',{staticClass:"col-md-3"},[_c('p',[_vm._v("for office use only")]),_c('div',{staticStyle:{"border-style":"solid","border-width":"thin","border-color":"#111111"}},[_vm._v(" C "),_c('hr'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Establishment Code No.")])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticStyle:{"border-style":"solid","border-width":"thin","border-color":"#111111"}},[_vm._v(" THA76709 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',{attrs:{"type":"1"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('li',[_c('p',[_vm._v("Name & Address of the Establishment")])])]),_c('div',{staticClass:"col-md-9"},[_c('p',[_vm._v("V ALLIANCE")]),_c('p',[_vm._v(" Office No. 5-A, Raheja Arcade, Sector-11, CBD Belapur, Navi Mumbai-400614 ")]),_c('p',[_vm._v("Plot No- 61")]),_c('p',[_vm._v("NAVI MUMBAI -400 614")]),_c('p',[_vm._v("62448686")])])]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('li',[_c('p',[_vm._v("Name of the employer")])])]),_c('div',{staticClass:"col-md-9"},[_c('p',[_vm._v("Survarna Gaikwad")])])]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('li',[_c('p',[_vm._v(" Class of the Establishment (i.e whether factory, motor omnibus service, motor transport undertaking or commercial establishment, a a resident hotel, restaurant, eating house, theatre or other place of amusement of public entertainment.) ")])])]),_c('div',{staticClass:"col-md-9"})]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row col-md-3"},[_c('ol',{attrs:{"start":"4"}},[_c('li',[_c('p',[_vm._v(" Total number of employees whose names stood on the establishment register as on 31 December 2020 ")])]),_c('br'),_c('ol',{attrs:{"type":"a"}},[_c('li',[_c('p',[_vm._v("Manager")])]),_c('br'),_c('li',[_c('p',[_vm._v(" Employees working in supervisory capacity drawing wages exceeding Rs. 3500/- per month (Please refer NOTE 2 above) ")])]),_c('br'),_c('li',[_c('p',[_vm._v(" Employees drawing wages upto & inclusive of Rs. 3000/- per month ")]),_c('p',[_vm._v(" EEC @ Rs. 6/- per employee ")]),_c('p',[_vm._v("ERC @ Rs. 18/- per employee")])]),_c('br'),_c('li',[_c('p',[_vm._v("Employees drawing wages exceeding Rs. 3,000/- per month")]),_c('p',[_vm._v("EEC @ Rs. 12 per employee &")]),_c('p',[_vm._v("ERC @ Rs. 36 per employee")])]),_c('br')]),_c('li',[_c('p',[_vm._v("Total of (a) to (d) above")])]),_c('br'),_c('li',[_c('p',[_vm._v("Mode of Payment:-")]),_c('p',[_vm._v(" (Please specify No. & date of cheque/ draft/ money order/cash against this item.) ")])]),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8"},[_c('table',{staticClass:"table table-responsive col-md-10",attrs:{"id":"table-example-1"}},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("No. of Employee")]),_c('th',[_vm._v("E.E.C Rs")]),_c('th',[_vm._v("E.R.C Rs")]),_c('th',[_vm._v("Pental Interest Rs")]),_c('th',[_vm._v("Total Rs")])])]),_c('tbody',[_c('tr',[_c('td'),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("NIL")]),_c('td',[_vm._v("NIL")]),_c('td',[_vm._v("NIL")]),_c('td',[_vm._v("NIL")])]),_c('tr',[_c('td'),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("NIL")]),_c('td',[_vm._v("NIL")]),_c('td',[_vm._v("NIL")]),_c('td',[_vm._v("NIL")])]),_c('tr',[_c('td',[_vm._v("June 2019")]),_c('td',[_vm._v("0")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("0.00")])]),_c('tr',[_c('td',[_vm._v("Dec 2019")]),_c('td',[_vm._v("0")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("0.00")])]),_c('tr',[_c('td',[_vm._v("June")]),_c('td',[_vm._v("0")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("0.00")])]),_c('tr',[_c('td',[_vm._v("Dec")]),_c('td',[_vm._v("151")]),_c('td',[_vm._v("1,812.00")]),_c('td',[_vm._v("5,436.00")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("7248.00")])]),_c('tr',[_c('td'),_c('td',[_vm._v("151")]),_c('td',[_vm._v("1,812.00")]),_c('td',[_vm._v("5,436.00")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("7248.00")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"col-md-6"},[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" IMPORTANT ")]),_c('ol',{attrs:{"type":"1"}},[_c('li',[_vm._v(" Cheque / DD should be drawn to each Est. Code, Number seperately & in favor of Maharashtra Labour Welfare Fund. ")]),_c('li',[_vm._v(" Cash Payment will be accepted from 10:30 a.m to 3:00 p.m ")]),_c('li',[_vm._v(" Code no of the Establishment alloted should be quated at the appropriate place in this form ")]),_c('li',[_vm._v(" DD should be payable at BOMBAY only ")]),_c('li',[_vm._v(" Please write the Establishment code number on the back side of the cheque/DD ")])])]),_c('div',{staticClass:"col-md-6"},[_c('br'),_c('p',[_vm._v(" Certified that the information / particulars furnished above is / are true to te best of my knowledge and belief. ")]),_c('br'),_c('br'),_c('br'),_c('p',[_vm._v(" Signature with name & designation of the Authority filing this form-cum-return ")])])])])
}]

export { render, staticRenderFns }