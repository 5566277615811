<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button
              :link="`/training-videos/${course.id}/details`"
            ></back-button>
            Add a new lecture in course {{ course.course_name }}
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/training-videos">Training Videos</router-link>
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Title*</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Title"
                            v-model="form.title"
                          />
                          <span class="error-block">{{ errors.title }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Description*</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Course Description"
                            v-model="form.description"
                          />
                          <span class="error-block">{{
                            errors.description
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">No of hrs</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="number"
                            class="form-control"
                            placeholder="No of hrs"
                            v-model="form.no_of_hrs"
                          />
                          <span class="error-block">{{
                            errors.no_of_hrs
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">ImagePath</label>
                        <div class="col-sm-9">
                          <input
                            type="file"
                            id="file"
                            name="imagepath"
                            ref="file"
                            accept="image/*"
                            multiple
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">VideoLink</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="VideoLink"
                            v-model="form.videolink"
                          />
                          <span class="error-block">{{
                            errors.videolink
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="save"
                            text="Save"
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "trainingVideoDetailCreate",
  data() {
    return {
      course: {},
      form: {},
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getData();
  },
  methods: {
    async getData() {
      let course = await axios.get(
        `/courses/${this.$route.params.trainingVideoId}`
      );
      this.course = course.data.data;
    },
    async save() {
      try {
        this.isLoading = true;
        let form = await axios.post(
          `/courses/${this.$route.params.trainingVideoId}/course_details`,
          this.form
        );

        this.form = form.data.data;
        console.log(this.form.id);
        this.isLoading = false;
        this.$router.push(
          `/training-videos/${this.$route.params.trainingVideoId}/details`
        );
        this.handleFileUpload();
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      let attachment = this.$refs.file.files[0];
      const CourseDetailID = this.form.id;
      let formData = new FormData();
      formData.append("id", CourseDetailID);
      formData.append("imagepath", attachment);
      console.log(formData);
      await axios
        .post("upload_course_details_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>
