<template>
  <span>
    <router-link :to="link">
      <i class="fa fa-upload"></i>
    </router-link>
  </span>
</template>

<script type="text/javascript">
export default {
  name: 'AddButtonWidget',
  props: ['link']
}
</script>

<style scoped>
span {
  padding-left: 5px;
}
</style>