<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            PF Register
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
          &nbsp; &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      :selection.sync="groupDivision"
                      :suggestions="groupDivisions"
                      :placeholder="`Select DIVISION`"
                    >
                    </my-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select month"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select year"
                    ></my-select>
                  </div>
                  <div class="col-md-6">
                    <button
                      class="btn btn-primary"
                      @click="searchData('generate')"
                    >
                      Generate</button
                    >&nbsp; &nbsp;
                    <button class="btn btn-success" @click="generatePDF">
                      Save & Print</button
                    >&nbsp; &nbsp;
                    <download-excel
                      :title="title"
                      class="btn btn-default"
                      :data="pf_excel"
                      :fields="excel_fields"
                      worksheet="PF"
                      name="PFRegister.xls"
                    >
                      <a href="#" class="download">Export to Excel</a>
                    </download-excel>

                    <small v-if="isSaving">Saving...</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />

                <div class="table-wrap" v-if="showRegisterTable"></div>
                <section id="mydiv" v-if="showRegister">
                  <div v-if="monthly_salary_pfs.length">
                    <div class="container-fluid" style="margin-top: 20px">
                      <p style="font-size: 18px; font-weight: bold">
                        FOR THE MONTH: {{ month_name }} {{ year }}
                      </p>
                      <div class="table-wrap">
                        <div class="inner" style="height: 700px">
                          <table class="table table-responsive col-md-10">
                            <thead>
                              <tr>
                                <th>Sr. No</th>
                                <th>Employee Code</th>
                                <th>Division Name</th>
                                <th>Employee Name</th>
                                <th>Father Name</th>
                                <th>Designation Name</th>
                                <th>Gender</th>
                                <th>Date Of Birth</th>
                                <th>Date Of Joining</th>
                                <th>UAN Number</th>
                                <th>PF Number</th>
                                <th>Adhaar Number</th>
                                <th>PROV. FUND</th>
                              </tr>
                            </thead>
                            <tbody>
                              <loading-table
                                :isLoading="isLoading"
                                cols="11"
                              ></loading-table>
                              <tr
                                v-for="(
                                  monthly_salary_pf, at
                                ) in monthly_salary_pfs"
                                :key="`monthly_salary_pf${at}`"
                              >
                                <td>{{ serialNoStarting + at + 1 }}</td>
                                <!-- <td><img v-if="monthly_salary_pf.logo_path" :src="mediaUrl + monthly_salary_pf.logo_path" style="width: 50px; height: 50px;" /></td> -->
                                <td>{{ monthly_salary_pf.employee_id }}</td>
                                <td>
                                  {{
                                    monthly_salary_pf.group_division
                                      .division_name
                                  }}
                                </td>
                                <td>{{ monthly_salary_pf.full_name }}</td>
                                <td>{{ monthly_salary_pf.father_name }}</td>
                                <td>
                                  {{
                                    monthly_salary_pf.designation_category
                                      ? monthly_salary_pf.designation_category
                                          .category_list.code
                                      : ""
                                  }}
                                </td>
                                <td>
                                  {{
                                    monthly_salary_pf.gender == 0
                                      ? "Male"
                                      : "Female"
                                  }}
                                </td>
                                <td>{{ monthly_salary_pf.dob }}</td>
                                <td>{{ monthly_salary_pf.joined_on }}</td>
                                <td>{{ monthly_salary_pf.uan_no }}</td>
                                <td>{{ monthly_salary_pf.pf_no }}</td>
                                <td>{{ monthly_salary_pf.aadhar_no }}</td>
                                <td>
                                  {{
                                    monthly_salary_pf.monthly_salary
                                      .total_earnings
                                  }}
                                </td>
                                <td>{{ monthly_salary_pf.registration_no }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <h5>Register Not yet Generated</h5>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFRegister",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      monthly_salary_pfs: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showRegisterTable: false,
      showRegister: false,
      serialNoStarting: 0,
      excel_fields: {
        "Sr No": "sr_no",
        "Employee Code": "employee_code",
        "Division Name": "division_name",
        Title: "title",
        "Employee Name": "employee_name",
        "Father's Name": "father_name",
        Designation: "designation_name",
        Gender: "gender",
        "Date of Birth": "dob",
        "Date Joined": "joined_on",
        "UAN Number": "uan_no",
        "PF Number": "pf_no",
        "Adhar Number": "aadhar_no",
        "PROV FUND": "prov_fund",
      },
      pf_excel: [],
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showRegister = false;
      this.showRegisterTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.monthly_salary_pfs = [];
        let monthly_salary_pfs = await axios.get(
          `/pf_register/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.monthly_salary_pfs = monthly_salary_pfs.data.data.register;
        console.log(this.monthly_salary_pfs);
        this.users = [];
        // let users = await axios.get(
        //   `/reports/pf_register?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        // );
        // console.log(this.monthly_salary_pfs)
        this.users = [];

        // Designation
        let designation_category = {};

        this.monthly_salary_pfs.forEach((monthly_salary_pf) => {
          let designation_categories = monthly_salary_pf.user_categories.filter(
            (user_category) =>
              user_category.category.category_name == "DESIGNATION"
          );
          if (designation_categories.length > 0)
            designation_category = designation_categories[0];
          monthly_salary_pf.designation_category = designation_category;
        });

        // console.log(designation_category);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showRegisterTable = true;
        this.showRegister = false;
      }
      if (source == "generate") {
        this.showRegisterTable = false;
        this.showRegister = true;
      }
      this.exportToExcel();
    },
    exportToExcel() {
      this.title =
        "PF Register For the Month of " + this.month_name + " " + this.year;

      this.pf_excel = [];
      let sr_no = 1;
      // console.log(this.pf);
      this.monthly_salary_pfs.forEach((employee) => {
        // Designation
        let designation_category = {};
        let designation_categories = employee.user_categories.filter(
          (user_category) =>
            user_category.category.category_name == "DESIGNATION"
        );
        if (designation_categories.length > 0)
          designation_category = designation_categories[0];
        this.pf_excel.push({
          sr_no: sr_no,
          employee_code: employee.employee_id,
          division_name: employee.group_division
            ? employee.group_division.division_name
            : "",
          title: employee.title,
          employee_name: employee.full_name,
          father_name: employee.father_name ? employee.father_name : "",
          designation_name: designation_category.category_list
            ? designation_category.category_list.code
            : "",
          gender: employee.gender == "0" ? "M" : "F",

          dob: employee.dob ? employee.dob : "",
          joined_on: employee.joined_on ? employee.joined_on : "",
          uan_no: employee.uan_no ? employee.uan_no : "",
          pf_no: employee.pf_no,
          aadhar_no: employee.aadhar_no,
          prov_fund: employee.monthly_salary.total_earnings,
        });
        sr_no++;
      });
      // console.log(pf_excel)
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "PFRegister.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
