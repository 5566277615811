<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button
              :link="`/day-summaries/list?userId=${this.$route.params.employeeId}`"
            ></back-button>
            Add a new daily task
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link
                :to="`/day-summaries/list?userId=${this.$route.params.employeeId}`"
                >Daily Task</router-link
              >
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Date*</label>
                        <div class="col-sm-9">
                          <my-datepicker
                            :date.sync="rawdate"
                            placeholder="Date"
                          ></my-datepicker>
                          <span class="error-block">{{ errors.date }}</span>
                        </div>
                      </div>
                      <!-- <div class="form-group">
                        <label class="col-sm-3 control-label">From Time</label>
                        <div class="col-sm-9">
                          <my-timepicker
                            :time.sync="rawFromTime"
                            placeholder="From Time"
                          ></my-timepicker>
                          <span class="error-block">{{
                            errors.from_time
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">To Time</label>
                        <div class="col-sm-9">
                          <my-timepicker
                            :time.sync="rawToTime"
                            placeholder="To Time"
                          ></my-timepicker>
                          <span class="error-block">{{ errors.to_time }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Daily Task</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.daily_task_id"
                            :suggestions="dailyTasks"
                            placeholder="Select Daily Task"
                          ></my-select>
                          <span class="error-block">{{
                            errors.daily_task_id
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Description</label
                        >
                        <div class="col-sm-9">
                          <textarea
                            v-uppercase
                            class="form-control"
                            placeholder="Description"
                            v-model="form.description"
                          ></textarea>

                          <span class="error-block">{{
                            errors.description
                          }}</span>
                        </div>
                      </div> -->
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="save"
                            text="Save"
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "dailyTaskCreate",
  data() {
    return {
      form: {
        // date: "",
        daily_task_id: "",
        // from_time: "",
        // to_time: "",
      },
      rawdate: "",
      rawFromTime: "",
      rawToTime: "",

      dailyTasks: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.form.site_id = this.site.id;
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/user_daily_summaries/masters");
      masters = masters.data;

      let dailyTasks = masters.daily_tasks;
      dailyTasks.forEach((dailyTask) => {
        this.dailyTasks.push({
          id: dailyTask.id,
          name: dailyTask.code,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        if (this.rawdate) {
          this.form.date = moment(this.rawdate).format("Y-M-D");
        }
        if (this.rawFromTime) {
          this.form.from_time = moment(this.rawFromTime).format("hh:mm A");
        }
        if (this.rawToTime) {
          this.form.to_time = moment(this.rawToTime).format("hh:mm A");
        }
        await axios.post(
          `/users/${this.$route.params.employeeId}/user_daily_summaries/store_automatic`,
          this.form
        );
        this.isLoading = false;
        this.$router.push(
          `/day-summaries/list?userId=${this.$route.params.employeeId}`
        );
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
