<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            Invoice
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
          <my-search
            :search="searchText"
            placeholder="Search here..."
            @searchData="search"
          ></my-search>
          <br /><br />
          <div class="row">
            <div class="col-md-4">
              <my-select
                style="margin: 9px !important"
                @change="updateMonth"
                disableFormControl="true"
                :selection.sync="month_id"
                :suggestions="months"
                placeholder="Select month"
              ></my-select>
              <my-select
                style="margin: 9px !important"
                @change="updateMonth"
                disableFormControl="true"
                :selection.sync="year"
                :suggestions="years"
                placeholder="Select year"
              ></my-select>
              <br /><br />
              <!-- <button @click="searchData('search')">Search</button>&nbsp;&nbsp; -->
              <button @click="searchData('generate')">Generate</button
              >&nbsp;&nbsp;
              <button @click="generatePDF">Save & Print</button>
              <br /><br />
            </div>
            <div class="col-md-offset-2 col-md-6">
              <my-select
                style="margin: 9px !important"
                @change="selectDivision"
                disableFormControl="true"
                :selection.sync="group"
                :suggestions="groups"
                :placeholder="`Select GROUP`"
              >
              </my-select>
              <my-select
                style="margin: 9px !important"
                disableFormControl="true"
                :selection.sync="groupDivision"
                :suggestions="groupDivisions"
                :placeholder="`Select DIVISION`"
              >
              </my-select>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />

                <div class="table-wrap" v-if="showInvoiceTable"></div>
                <section id="mydiv" v-if="showInvoice">
                  <div class="container-fluid border">
                    <table
                      id="table-example-1"
                      class="table table-responsive col-md-9"
                    >
                      <thead>
                        <tr>
                          <th colspan="7"><center>TAX Invoice</center></th>
                        </tr>
                        <tr>
                          <th rowspan="7">Client</th>
                          <th>Billed To</th>
                          <th rowspan="2" colspan="2">Shipped To</th>
                          <th>Client Ref No</th>
                          <th colspan="2">PO NO</th>
                        </tr>
                        <tr>
                          <td rowspan="6">
                            KAYA LIMITED
                            <p>
                              Mark 23/ Mahal Industrial Area, Opp. Andhra Bank,
                            </p>
                            <p>
                              Mahakahli Caves Road, Near Paper Box, Andheri (E),
                            </p>
                            <p>Mumbai 400 093,</p>
                            <p>PAN NO :- AADSAFAFASFAS,</p>
                            <p>State Code 27/State: Maharashtra,</p>
                            <p>GST NO :- AADSAFAFASFAS,</p>
                          </td>
                          <th>Ref Date</th>
                          <th colspan="2">PO Date</th>
                        </tr>
                        <tr>
                          <td colspan="2" rowspan="5">
                            <center>Mumbai</center>
                          </td>
                          <th>Period</th>
                          <th colspan="2">01.05.2021</th>
                        </tr>
                        <tr>
                          <th>Invoice No</th>
                          <th colspan="2">VA/06/2122/040</th>
                        </tr>
                        <tr>
                          <th>Invoice Date</th>
                          <th colspan="2">10.06.2021</th>
                        </tr>
                        <tr>
                          <th>Month</th>
                          <th colspan="2">May 21</th>
                        </tr>
                        <tr>
                          <th>Place of Supply</th>
                          <th colspan="2">Mumbai</th>
                        </tr>
                        <tr>
                          <th>Sr no</th>
                          <th colspan="2">Particular</th>
                          <th>HSN/SAC</th>
                          <th>Unit</th>
                          <th>Rate</th>
                          <th>Amount()</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td rowspan="5">1)</td>
                          <td colspan="2">
                            ManPower supplied For HouseKeeping & Allied Services
                          </td>
                          <td rowspan="5">998519</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="2">Details as per Annexver</td>
                          <td></td>
                          <td></td>
                          <td>4104.00</td>
                        </tr>
                        <tr>
                          <td colspan="2">.</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="2">.</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="2">.</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td rowspan="8"></td>
                          <td colspan="3">
                            <p style="float: right">Sub-Total</p>
                          </td>
                          <td></td>
                          <td></td>
                          <td>4915.00</td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <p style="float: center">Service Charge @ 7.00%</p>
                          </td>
                          <td></td>
                          <td></td>
                          <td>344.00</td>
                        </tr>
                        <tr>
                          <td colspan="3"></td>
                          <td colspan="2" style="font-weight: bold">
                            <p style="float: right">Total</p>
                          </td>
                          <td>5259.00</td>
                        </tr>
                        <tr>
                          <td colspan="3">CGST @ 9%</td>
                          <td colspan="2" rowspan="3"></td>
                          <td>476.00</td>
                        </tr>
                        <tr>
                          <td colspan="3">SGST @ 9%</td>
                          <td>476.00</td>
                        </tr>
                        <tr>
                          <td colspan="3">IGST @</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td colspan="3"></td>
                          <td style="font-weight: bold" colspan="2">
                            Grand Total
                          </td>
                          <td>476.00</td>
                        </tr>
                        <tr>
                          <td colspan="2" style="margin-top: 2">
                            <p style="font-weight: bold">
                              <u>Bank Details For RTGS/NEFT:</u>
                            </p>
                            <div class="row">
                              <div class="col-md-6">
                                <p>Bank Name:</p>
                              </div>
                              <div class="col-md-6">
                                <p>ICICI BANK</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>Account No:</p>
                              </div>
                              <div class="col-md-6">
                                <p>08194645456</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>Branch Name:</p>
                              </div>
                              <div class="col-md-6">
                                <p>Belapur, Navi Mumbai</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>IFSC No:</p>
                              </div>
                              <div class="col-md-6">
                                <p>ICIC0000873</p>
                              </div>
                            </div>
                          </td>
                          <td colspan="4">
                            <p style="font-weight: bold">
                              <u>Statutory Registration Details</u>
                            </p>
                            <div class="row">
                              <div class="col-md-6">
                                <p>PAN No:</p>
                              </div>
                              <div class="col-md-6">
                                <p>ICIC0000873</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>PF No:</p>
                              </div>
                              <div class="col-md-6">
                                <p>ICIC0000873</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>ESIC No:</p>
                              </div>
                              <div class="col-md-6">
                                <p>ICIC0000873</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>Udyam Registration No:</p>
                              </div>
                              <div class="col-md-6">
                                <p>UDYAM-MH-33-0071932</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>GST No:</p>
                              </div>
                              <div class="col-md-6">
                                <p>27 ASWDSA0071932</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>State Code:</p>
                              </div>
                              <div class="col-md-6">
                                <p>27 - Maharashtra</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>Place Of Origin:</p>
                              </div>
                              <div class="col-md-6">
                                <p>Mumbai</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>HSN / SAC:</p>
                              </div>
                              <div class="col-md-6">
                                <p>998519</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7">
                            <p>Rupees One Thousand Three Hundred Seven Only</p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <p style="font-weight: bold">Terms & Conditions:</p>
                            <p>
                              1. Please issue cheque / DD / PO favouring
                              <span style="font-weight: bold"
                                >"V ALLIANCE"</span
                              >
                              Interest shall be charged @ 24% on all overdue
                              bills
                            </p>
                            <p>
                              2. Any issues relating to invoice raised is to
                              intimated or raised within 5 days of receipt of
                              the same. Else it will be considered as accepted
                              as it is.
                            </p>
                            <p>
                              3. Big Payment should be paid within 30 days of
                              the bill date.
                            </p>
                            <p>
                              4. All disputes are subject to mumbai
                              Jurisdiction.
                            </p>
                          </td>
                          <td colspan="3">
                            <p style="float: right">E. & O.E.</p>
                            <p style="font-weight: bold">For V ALLIANCE</p>
                            <br />
                            <br />
                            <br />
                            <br />
                            <p>Authorised Signatory</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "Invoice",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showInvoiceTable: false,
      showInvoice: false,
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showInvoice = false;
      this.showInvoiceTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        let users = await axios.get(
          `/user_punches/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = [];
        users.data.data.forEach((user) => {
          if (
            user.employee_status == null ||
            (user.employee_status.code != "LEFT" &&
              user.employee_status.code != "TERMINATE")
          )
            this.users.push(user);
        });
        for (const user of this.users) {
          if (user.monthly_salary == null)
            this.initializeEmptyMonthlySalary(user);
          else this.getEarnedSalaryComponents(user);
        }
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showInvoiceTable = true;
        this.showInvoice = false;
      }
      if (source == "generate") {
        this.showInvoiceTable = false;
        this.showInvoice = true;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "Invoice.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
<style>
.border table,
.border th,
.border td {
  border: 2px solid black;
}
</style>
