<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            Employees
            <add-button link="/main/employees/create"></add-button>
            <upload-button link="/main/employees/upload"></upload-button>
            <refresh-button @click="refreshData"></refresh-button>
          </h5>

          <small v-if="isSaving">Saving...</small>
        </div>
      </div>
      <!-- /Title -->

      <!-- Filters -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    ></my-select>
                  </div>
                  <div
                    class="col-md-3"
                    v-for="(category, c) in categories"
                    :key="`category${c}`"
                  >
                    <my-select
                      style="margin: 9px !important"
                      @change="searchData"
                      :selection.sync="category.selectedValue"
                      :suggestions="category.active_category_lists"
                      :placeholder="`Select ${category.category_name}`"
                      multiple="true"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="toggleOnlyActiveUsers"
                      :selection.sync="Status_Type"
                      :suggestions="Status_Types"
                      :placeholder="`Select Status Type`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="toggleOnlyActiveUsers"
                      :selection.sync="role"
                      :suggestions="roles"
                      :placeholder="`Search By Role`"
                    >
                    </my-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / Filters -->
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="row" style="margin-bottom: 5px">
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <download-excel
                  :title="title"
                  class="btn btn-default pull-right"
                  :data="employees_excel"
                  :fields="excel_fields"
                  worksheet="Employees"
                  name="Employee.xls"
                >
                  <a href="#" class="download">Export to Excel</a>
                </download-excel>
              </div>
            </div>
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="inner" style="height: 700px">
                    <table
                      class="table table-hover table-striped table-responsive"
                    >
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Employee Code</th>
                          <th>Division</th>
                          <th>Name</th>
                          <th>Role</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          <th>Is Active</th>
                          <th>Is Reliever</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <loading-table
                          :isLoading="isLoading"
                          cols="8"
                        ></loading-table>
                        <router-link
                          v-for="(user, at) in employees"
                          :key="`user${at}`"
                          :to="`/main/employees/${user.id}`"
                          tag="tr"
                        >
                          <td>{{ serialNoStarting + at + 1 }}</td>
                          <td>{{ user.employee_id }}</td>
                          <td>
                            {{
                              user.group_division
                                ? user.group_division.division_name
                                : ""
                            }}
                          </td>
                          <td>{{ user.full_name || "" }}</td>
                          <td>
                            {{
                              user.roles.length > 0 ? user.roles[0].name : ""
                            }}
                          </td>
                          <td>{{ user.email }}</td>
                          <td>{{ user.mobile }}</td>
                          <td>
                            {{
                              user.employee_status == null ||
                              (user.employee_status.code != "LEFT" &&
                                user.employee_status.code != "TERMINATE")
                                ? "YES"
                                : "NO"
                            }}
                          </td>
                          <td>
                            {{ user.is_reliever == true ? "YES" : "NO" }}
                          </td>
                          <td>
                            <router-link
                              :to="`/main/employees/profile?userId=${user.id}`"
                            >
                              <u>View</u>
                            </router-link>
                          </td>
                        </router-link>
                      </tbody>
                    </table>
                  </div>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>
<script type="text/javascript">
import axios from "axios";

export default {
  name: "employees",
  data() {
    return {
      employees: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      isShowAllUser: false,
      isOnlyActiveUser: "",
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      categories: [],
      selectedCategories: [],
      searchText: "",
      role: "",
      roles: [],
      title: "",
      excel_fields: {
        "Sr No": "sr_no",
        "Employee Code": "employee_code",
        "Employee Name": "employee_name",
        Title: "title",
        "Company Name": "company_name",
        Grade: "grade",
        Branch: "branch",
        Department: "department",
        "Division Name": "division_name",
        "Group Name": "group",
        "Category Name": "category",
        Designation: "designation_name",
        State: "state",
        "Date of Birth": "dob",
        Mobile: "mobile",
        "Date Joined": "joined_on",
        "Date of Confirmation": "confirmation_date",
        "Last Inc. Date": "last_increment_date",
        "Date Left": "date_left",
        "UAN Number": "uan_no",
        "Father's Name": "father_name",
        Gender: "gender",
        Age: "age",
        "Marital Status": "marital_status",
        "PT Number": "pt_no",
        "PF Number": "pf_no",
        "PAN Number": "pan_no",
        "ESIC Number": "esi_number",
        "PF Flag": "is_pf_applicable",
        "ESIC Flag": "is_esic_applicable",
        "Bonus Flag": "is_bonus_applicable",
        "Leave Flag": "is_leave_application",
        "PT Flag": "is_pt_applicable",
        "Bank A/c Number": "bank_acc_no",
        // "Bank Name": "bank_name",
        Mode: "mode",
        "Account Code": "bank_ifsc_code",
        "E-Mail": "email",
        Remarks: "remarks",
        "Resignation Date": "resignation_date",
        "Reason for Leaving": "reason_for_leaving",
        "Bank A/C No.1": "bank_acc_no_1",
        "Bank A/C No.2": "bank_acc_no_2",
        "Bank A/C No.3": "bank_acc_no_3",
        "Alt.Email": "alt_email",
        "Emp Status": "employee_status",
        "Spouse Name": "spouse_name",
        "PF Joining Date": "pf_joining_date",
        "PRAN Number": "pran_no",
        "Group Joining Date": "group_joining_date",
        "Aadhar Number": "aadhar_no",
        "Child in Hostel (For CED)": "child_in_hostel",
        "Tot Prev Exp (In Months)": "total_exp_in_month",
        "Total Exp in Years": "total_exp_in_year",
        "Fixed Basic": "fixed_basic",
        "Fixed D.A": "fixed_da",
        "Fixed H.R.A": "fixed_hra",
        "Fixed Conveyance": "fixed_conveyance",
        "Fixed CCA": "fixed_cca",
        "Fixed Medical Allowa": "fixed_medical_allowance",
        "Fixed Special Allowance": "fixed_special_allowance",
        "Fixed Mobile Allow.": "fixed_mobile_allowance",
        "Fixed Washing Allow": "fixed_washing_allowance",
        "Fixed Other Allow.": "fixed_other_allowance",
        "Leave Encashment": "leave_encashment",
        Bonus: "bonus",
        "Gross Salary": "gross_salary",
        "OT Rate": "ot_rate",
        "Is Reliever": "is_reliever",
        "Login Email": "login_email",
        "Login Password": "login_password",
      },
      Status_Type: 1,
      Status_Types: [
        {
          id: 1,
          name: "ACTIVE",
        },
        {
          id: 2,
          name: "LEFT",
        },
        {
          id: 3,
          name: "ALL USER",
        },
      ],
      employees_excel: [],
      isSaving: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.roles = masters.roles;
      this.refreshData();
    },
    selectDivision() {
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
      this.searchData();
    },
    refreshData() {
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.role = "";
      this.categories.forEach((category) => {
        category.selectedValue = "";
      });
      this.roles.forEach((role) => {
        role.selectedValue = "";
      });
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData() {
      let selectedCategoriesArray = [];
      this.categories.forEach((category) => {
        selectedCategoriesArray.push(
          category.id + "-" + category.selectedValue
        );
      });
      this.roles.forEach((role) => {
        selectedCategoriesArray.push(role.id + "-" + role.selectedValue);
      });
      this.getData(selectedCategoriesArray);
    },
    toggleOnlyActiveUsers() {
      this.getData();
    },
    async getData(selectedCategoriesArray = [], page = 1) {
      this.isLoading = true;
      this.employees = [];
      let employees = await axios.get(
        `/monthly_salaries/filter?monthId=${this.month_id}&year=${this.year}&selectedCategoriesArray=${selectedCategoriesArray}&searchText=${this.searchText}&groupId=${this.group}&groupDivisionId=${this.groupDivision}&StatusType=${this.Status_Type}&role=${this.role}`
      );
      this.employees = employees.data.data.selectedUsers;
      this.employees.forEach((user) => {
        let division_category = {};
        let division_categories = user.user_categories.filter(
          (user_category) => user_category.category.category_name == "DIVIS"
        );
        if (division_categories.length > 0)
          division_category = division_categories[0];
        user.division_category = division_category;
      });
      this.count = employees.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
      this.exportToExcel();
    },
    exportToExcel() {
      this.title = "Employee Master";

      this.employees_excel = [];
      let sr_no = 1;
      console.log(this.employees);
      this.employees.forEach((employee) => {
        // Branch

        let branch_category = {};
        let branch_categories = employee.user_categories.filter(
          (user_category) => user_category.category.category_name == "BRANCH"
        );
        if (branch_categories.length > 0)
          branch_category = branch_categories[0];
        // Grade
        let grade_category = {};
        let grade_categories = employee.user_categories.filter(
          (user_category) => user_category.category.category_name == "GRADE"
        );
        if (grade_categories.length > 0) grade_category = grade_categories[0];
        // Department
        let department_category = {};
        let department_categories = employee.user_categories.filter(
          (user_category) =>
            user_category.category.category_name == "DEPARTMENT"
        );
        if (department_categories.length > 0)
          department_category = department_categories[0];
        // Category
        let category_category = {};
        let category_categories = employee.user_categories.filter(
          (user_category) => user_category.category.category_name == "CATEGORY"
        );
        if (category_categories.length > 0)
          category_category = category_categories[0];
        // Designation
        let designation_category = {};
        let designation_categories = employee.user_categories.filter(
          (user_category) =>
            user_category.category.category_name == "DESIGNATION"
        );
        if (designation_categories.length > 0)
          designation_category = designation_categories[0];
        this.employees_excel.push({
          sr_no: sr_no,
          // employee_id: employee.id,
          employee_code: employee.employee_id ? employee.employee_id : "",
          employee_name: employee.full_name ? employee.full_name : "",
          title: employee.title ? employee.title : "",
          company_name: employee.sites[0].name ? employee.sites[0].name : "",
          mobile: employee.mobile ? employee.mobile : "",
          branch: branch_category.category_list
            ? branch_category.category_list.code
            : "",
          grade: grade_category.category_list
            ? grade_category.category_list.code
            : "",
          department: department_category.category_list
            ? department_category.category_list.code
            : "",
          division_name: employee.group_division
            ? employee.group_division.division_name
            : "",
          group: employee.group ? employee.group.name : "",
          category: category_category.category_list
            ? category_category.category_list.code
            : "",
          designation_name: designation_category.category_list
            ? designation_category.category_list.code
            : "",
          state: employee.group_division
            ? employee.group_division.state.code
            : "",
          dob: employee.dob ? employee.dob : "",
          joined_on: employee.joined_on ? employee.joined_on : "",
          confirmation_date: employee.confirmation_date
            ? employee.confirmation_date
            : "",
          last_increment_date: employee.last_increment_date
            ? employee.last_increment_date
            : "",
          date_left: employee.date_left ? employee.date_left : "",
          uan_no: employee.uan_no ? "'" + employee.uan_no : "",
          father_name: employee.father_name ? employee.father_name : "",
          gender: employee.gender == "0" ? "M" : "F",
          age: employee.age ? employee.age : "",
          marital_status: employee.marital_status
            ? employee.marital_status.code
            : "",
          pt_no: employee.pt_no ? employee.pt_no : "",
          pf_no: employee.pf_no ? employee.pf_no : "",
          pan_no: employee.pan_no ? employee.pan_no : "",
          esi_number: employee.esi_number ? employee.esi_number : "",
          is_pf_applicable: employee.is_pf_applicable
            ? employee.is_pf_applicable
            : "",
          is_esic_applicable: employee.is_esi_applicable
            ? employee.is_esi_applicable
            : "",
          is_bonus_applicable: employee.is_bonus_applicable
            ? employee.is_bonus_applicable
            : "",
          is_leave_application: employee.is_leave_application
            ? employee.is_leave_application
            : "",
          is_pt_applicable: employee.is_pt_applicable
            ? employee.is_pt_applicable
            : "",
          bank_acc_no: employee.bank_acc_no ? "'" + employee.bank_acc_no : "",
          // bank_name: employee.bank_name.name ? employee.bank_name.name : "",
          mode: employee.bank_payment_type_id
            ? employee.bank_payment_type_id
            : "",
          bank_ifsc_code: employee.bank_ifsc_code
            ? employee.bank_ifsc_code
            : "",
          email: employee.email ? employee.email : "",
          remarks: employee.remarks ? employee.remarks : "",
          resignation_date: employee.resignation_date
            ? employee.resignation_date
            : "",
          reason_for_leaving: employee.reason_for_leaving
            ? employee.reason_for_leaving
            : "",
          bank_acc_no_1: employee.bank_acc_no_1 ? employee.bank_acc_no_1 : "",
          bank_acc_no_2: employee.bank_acc_no_2 ? employee.bank_acc_no_2 : "",
          bank_acc_no_3: employee.bank_acc_no_3 ? employee.bank_acc_no_3 : "",
          alt_email: employee.alt_email ? employee.alt_email : "",
          employee_status: employee.employee_status
            ? employee.employee_status.code
            : "",
          spouse_name: employee.spouse_name ? employee.spouse_name : "",
          pf_joining_date: employee.pf_joining_date
            ? employee.pf_joining_date
            : "",
          pran_no: employee.pran_no ? employee.pran_no : "",
          group_joining_date: employee.group_joining_date
            ? employee.group_joining_date
            : "",
          aadhar_no: employee.aadhar_no ? "'" + employee.aadhar_no : "",
          child_in_hostel: employee.child_in_hostel
            ? employee.child_in_hostel
            : "",
          total_exp_in_month: employee.total_exp_in_month
            ? employee.total_exp_in_month
            : "",
          total_exp_in_year: employee.total_exp_in_year
            ? employee.total_exp_in_year
            : "",
          fixed_basic: employee.user_salaries[0]
            ? employee.user_salaries[0].fixed_basic
            : "",
          fixed_da: employee.user_salaries[0]
            ? employee.user_salaries[0].fixed_da
            : "",
          fixed_hra: employee.user_salaries[0]
            ? employee.user_salaries[0].fixed_hra
            : "",
          fixed_conveyance: employee.user_salaries[0]
            ? employee.user_salaries[0].fixed_conveyance
            : "",
          fixed_cca: employee.user_salaries[0]
            ? employee.user_salaries[0].fixed_cca
            : "",
          fixed_medical_allowance: employee.user_salaries[0]
            ? employee.user_salaries[0].fixed_medical_allowance
            : "",
          fixed_special_allowance: employee.user_salaries[0]
            ? employee.user_salaries[0].fixed_special_allowance
            : "",
          fixed_mobile_allowance: employee.user_salaries[0]
            ? employee.user_salaries[0].fixed_mobile_allowance
            : "",
          fixed_washing_allowance: employee.user_salaries[0]
            ? employee.user_salaries[0].fixed_washing_allowance
            : "",
          fixed_other_allowance: employee.user_salaries[0]
            ? employee.user_salaries[0].fixed_other_allowance
            : "",
          leave_encashment: employee.user_salaries[0]
            ? employee.user_salaries[0].leave_encashment
            : "",
          bonus: employee.user_salaries[0]
            ? employee.user_salaries[0].bonus
            : "",
          gross_salary: employee.user_salaries[0]
            ? employee.user_salaries[0].gross_salary
            : "",
          ot_rate: employee.user_salaries[0]
            ? employee.user_salaries[0].ot_rate
            : "",
          is_reliever: employee.is_reliever == 1 ? "YES" : "NO",
          login_email: employee.email ? employee.email : "",
          login_password: "123456",
        });
        sr_no++;
      });
    },
    // async getData(page = 1) {
    //   this.isLoading = true
    //   let employees = await axios.get(`/users?page=${page}&rowsPerPage=${this.rowsPerPage}&search=all`)
    //   this.employees = employees.data.data
    //   this.employees.forEach(employee => {
    //     let division_category = {}
    //     let division_categories = employee.user_categories.filter(state => user_category.category.category_name == 'DIVIS')
    //     if(division_categories.length > 0)
    //       division_category = division_categories[0]
    //     employee.division_category = division_category
    //   })
    //   this.count = employees.data.count
    //   this.serialNoStarting = (page - 1) * this.rowsPerPage
    //   this.isLoading = false
    // }
  },
};
// console.log(employees)
</script>
