<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;"><b>ASSET</b></span>
            <span style="float: right;">
              <i class="fa fa-plus" aria-hidden="true" v-if="!addData" @click="toggleAddData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="addData" @click="toggleAddData"></i>
            </span>
          </div>

          <br>

          <div class="row"
            v-if="addData"
          >
            <p><u>Add New Asset</u></p>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Asset Type</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_asset.asset_type_id" :suggestions="asset_groups" placeholder="Asset Type" @change="getAsset"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Asset</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_asset.asset_id" :suggestions="assets" placeholder="Asset"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Issued Date</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_asset.issue_date" placeholder="Issued Date"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Valid Till</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_asset.valid_till" placeholder="Valid Till"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Remarks</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Remarks" v-model="user_asset.remarks">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10">
                <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
              </div>
            </div>
          </div>

          <!-- List of employee passports -->
          <div class="row"
            v-for="(userAsset, ua) in form.user_assets"
            :key="`userAsset${ua}`"
          >
            <div class="form-group row"
              v-if="!(editData == ua)"
            >
              <label class="col-sm-4 col-form-label">{{ ua + 1 }}. {{ userAsset.asset_type.name }}</label>
              <div class="col-sm-6">
                {{ userAsset.asset.name }}
              </div>
              <div class="col-md-2">
                <i class="fa fa-pencil-square-o" style="float: right;" aria-hidden="true" @click="toggleEditData(userAsset, ua)"></i>
              </div>
            </div>
            <div
              v-else
            >
              <p><u>{{ ua + 1 }}. Update Employee Asset Details</u></p>
              <div class="form-group row">
              <label class="col-sm-4 col-form-label">Asset Type</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_asset.asset_type_id" :suggestions="asset_groups" placeholder="Asset Type" @change="getAsset"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Asset</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_asset.asset_id" :suggestions="assets" placeholder="Asset"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Issued Date</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_asset.issue_date" placeholder="Issued Date"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Valid Till</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_asset.valid_till" placeholder="Valid Till"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Remarks</label>
              <div class="col-sm-8">
                <input v-uppercase type="text" class="form-control" placeholder="Remarks" v-model="user_asset.remarks">
              </div>
            </div>
              <div class="col-sm-2">
                <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      addData: false,
      editData: null,
      user_asset: {
        asset_type_id: '',
        asset_id: '',
        issue_date: '',
        valid_till: '',
        remarks: '',
      },
      assets: [],
    }
  },
  props: ['form', 'isLoading', 'asset_groups',],
  methods: {
    toggleAddData() {
      this.user_asset = {
        asset_type_id: '',
        asset_id: '',
        issue_date: '',
        valid_till: '',
        remarks: '',
      }
      this.addData = !this.addData
    },
    toggleEditData(userAsset, ua) {
      this.editData = ua
      this.user_asset = userAsset
    },
    cancelUpdate() {
      this.editData = null
    },
    save() {
      if(this.user_asset.first_name == '' || this.user_asset.passport_no == '') 
        alert('Please enter first name and passport no')
      else {
        this.form.user_assets.push(this.user_asset)
        this.update()
        this.addData = false
      }
    },
    async update() {
      this.$emit('update')
      this.editData = null
    },
    getAsset() {
      let asset_type = this.asset_groups.find(asset_group => asset_group.id == this.user_asset.asset_type_id)
      this.assets = asset_type.active_asset_group_lists
    }
  }
}
</script>