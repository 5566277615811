<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">
          <div class="row">
            <span style="float: left">Employee Identity</span>
            <span style="float: right">
              <i
                class="fa fa-plus"
                aria-hidden="true"
                v-if="!addData"
                @click="toggleAddData"
              ></i>
              <i
                class="fa fa-close"
                aria-hidden="true"
                v-if="addData"
                @click="toggleAddData"
              ></i>
            </span>
          </div>

          <br />

          <div class="row" v-if="addData">
            <p><u>Add New Employee Identity</u></p>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Document Type</label>
              <div class="col-sm-8">
                <my-select
                  :selection.sync="user_identity.document_type_id"
                  :suggestions="documentTypes"
                  placeholder="Select Document Type"
                ></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Name in document</label>
              <div class="col-sm-8">
                <input
                  v-uppercase
                  type="text"
                  class="form-control"
                  placeholder="Name in document"
                  v-model="user_identity.name_in_document"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Document no</label>
              <div class="col-sm-8">
                <input
                  v-uppercase
                  type="text"
                  class="form-control"
                  placeholder="Document no"
                  v-model="user_identity.document_no"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Document Path sd</label>
              <div class="col-sm-8">
                <input
                  type="file"
                  id="file"
                  name="file"
                  ref="file"
                  accept="image/*"
                  multiple
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label"
                >Is Document Verified</label
              >
              <div class="col-sm-8">
                <my-checkbox
                  :selection.sync="user_identity.is_document_verified"
                ></my-checkbox>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10">
                <submit-button
                  :isLoading="isLoading"
                  @submit="save"
                  text="Save"
                ></submit-button>
              </div>
            </div>
          </div>

          <!-- List of employee identities -->
          <div
            class="row"
            v-for="(userIdentity, ui) in form.user_identities"
            :key="`userIdentity${ui}`"
          >
            <div class="form-group row" v-if="!(editData == ui)">
              <label class="col-sm-4 col-form-label">{{
                userIdentity.document_type.description
              }}</label>
              <div class="col-sm-6">
                {{ userIdentity.name_in_document }} |
                {{ userIdentity.document_no }} |
                {{
                  userIdentity.is_document_verified
                    ? "VERIFIED"
                    : "NOT VERIFIED"
                }}
              </div>
              <div class="col-md-2">
                <i
                  class="fa fa-pencil-square-o"
                  style="float: right"
                  aria-hidden="true"
                  @click="toggleEditData(userIdentity, ui)"
                ></i>
              </div>
            </div>
            <div v-else>
              <p><u>Update Employee Identity</u></p>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Document Type</label>
                <div class="col-sm-8">
                  <my-select
                    :selection.sync="user_identity.document_type_id"
                    :suggestions="documentTypes"
                    placeholder="Select Document Type"
                  ></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Name in document</label>
                <div class="col-sm-8">
                  <input
                    v-uppercase
                    type="text"
                    class="form-control"
                    placeholder="Name in document"
                    v-model="user_identity.name_in_document"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Document no</label>
                <div class="col-sm-8">
                  <input
                    v-uppercase
                    type="text"
                    class="form-control"
                    placeholder="Document no"
                    v-model="user_identity.document_no"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Document Path sd</label>
                <div class="col-sm-8">
                  <input
                    type="file"
                    id="file"
                    name="file"
                    ref="file"
                    accept="image/*"
                    multiple
                  />
                </div>
              </div>
              <div class="form-group row" v-if="user_identity.imagepath">
                <label class="col-sm-4 col-form-label"
                  >View Document Image</label
                >
                <div class="col-sm-8">
                  <a
                    :href="`${mediaUrl + user_identity.imagepath}`"
                    target="_blank"
                    >View Document Image</a
                  >
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label"
                  >Is Document Verified</label
                >
                <div class="col-sm-8">
                  <my-checkbox
                    :selection.sync="user_identity.is_document_verified"
                  ></my-checkbox>
                </div>
              </div>
              <div class="form-group row" v-if="editData == ui">
                <div class="col-sm-2">
                  <submit-button
                    :isLoading="isLoading"
                    @submit="update"
                    text="Update"
                  ></submit-button>
                </div>
                <!-- <div class="col-sm-2">
                  <submit-button :isLoading="isLoading" @submit="cancelUpdate" text="Cancel" myClass="btn btn-primary btn-anim"></submit-button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import axios from "axios";
export default {
  data() {
    return {
      addData: false,
      editData: null,
      user_identity: {
        document_type_id: "",
        document_no: "",
        name_in_document: "",
        is_document_verified: false,
      },
    };
  },
  props: ["form", "isLoading", "documentTypes"],
  methods: {
    toggleAddData() {
      this.user_identity = {
        document_type_id: "",
        document_no: "",
        name_in_document: "",
        is_document_verified: false,
      };
      this.addData = !this.addData;
    },
    toggleEditData(userIdentity, ui) {
      this.editData = ui;
      this.user_identity = userIdentity;
    },
    cancelUpdate() {
      this.editData = null;
    },
    save() {
      if (
        this.user_identity.document_type_id == "" ||
        this.user_identity.document_no == ""
      )
        alert("Please enter document type and document no");
      else {
        this.form.user_identities.push(this.user_identity);
        this.update();
        this.addData = false;
      }
    },
    async update() {
      this.$emit("update");
      if (this.user_identity.id) {
        this.handleFileUpload();
      } else {
        this.handleFileUploadCreate();
      }
      this.editData = null;
    },

    async handleFileUpload() {
      let attachment = this.$refs.file?.[0].files[0];
      if (attachment != undefined) {
        let formData = new FormData();
        if (this.user_identity.id) {
          const useridentityid = this.user_identity.id;
          formData.append("id", useridentityid);
        }
        formData.append("imagepath", attachment);
        formData.append("user_id", this.$route.query.userId);
        formData.append(
          "document_type_id",
          this.user_identity.document_type_id
        );
        await axios
          .post("upload_document_image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function () {
            console.log("FAILURE!!");
          });

        this.$router.go(this.$router.currentRoute);
      }
    },
    async handleFileUploadCreate() {
      let attachment = this.$refs.file?.files[0];
      if (attachment != undefined) {
        let formData = new FormData();

        formData.append("imagepath", attachment);
        formData.append("user_id", this.$route.query.userId);
        formData.append(
          "document_type_id",
          this.user_identity.document_type_id
        );
        await axios
          .post("upload_document_image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
        this.$router.go(this.$router.currentRoute);
      }
    },
  },
};
</script>
