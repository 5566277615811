<template>
  <section id="mydiv">
    <div class="container-fluid row">
      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
        <div
          style="border-style: solid; border-width: thin; height:250px border-color: #111111;"
        >
          logo
          <br />
          <br />
          <br />
        </div>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
        <h5 class="txt-dark">
          <center style="font-weight: bold; font-size: 18px">
            <p>Form No.9</p>
            <p>
              THE EMPLOYEE's PROVIDENT FUND SCHEME, 1952 (PARA 36(1)) AND THE
              EMPLOYEES PENSION SCHEME, 1995 [Para 20(1)]
            </p>
          </center>
        </h5>
      </div>
    </div>
    <div class="container-fluid row">
      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
        <p>Name of the Establishment</p>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
        <p style="font-weight: bold">ALL SOLUTION</p>
      </div>
    </div>
    <div class="container-fluid row">
      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
        <p>Address</p>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
        <p style="font-weight: bold">
          G - 210/206, DREAMS THE MALL, L.B.S ROAD, BHANDUP (WEST), MUMBAI 400
          078
        </p>
      </div>
    </div>
    <div class="container-fluid row">
      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
        <p>Code no. of the Establishment</p>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
        <p style="font-weight: bold">
          MH/126365 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; GROUP NO. 31
        </p>
      </div>
    </div>
    <div class="container-fluid">
      <table id="table-example-1" class="table table-responsive col-md-10">
        <thead>
          <tr>
            <th>Sr. No</th>
            <th>A/c No.</th>
            <th>Name of the Employee</th>
            <th>Father's name (or husband's name in Case of Married Woman)</th>
            <th>date of birth/ Age</th>
            <th>sex</th>
            <th>date of joining the fund</th>
            <th>total P.O.P.S (excludeding POB as on the Dt.)</th>
            <th>Date of joining Pension Scheme</th>
            <th>Initials of HC</th>
            <th>Date & Reason of leaving Service</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>358</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>M</td>
            <td>14/10/2020</td>
            <td></td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>1.</td>
            <td>358</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>M</td>
            <td>14/10/2020</td>
            <td></td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>1.</td>
            <td>358</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>M</td>
            <td>14/10/2020</td>
            <td></td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>1.</td>
            <td>358</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>M</td>
            <td>14/10/2020</td>
            <td></td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>1.</td>
            <td>358</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>M</td>
            <td>14/10/2020</td>
            <td></td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "PDFForm5A",
  mounted: function() {
      this.createPDF()
  },
  methods: {
    createPDF() {
      var element = document.getElementById("mydiv");

      var opt = {
        margin: 0,
        filename: "PFForm5A.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [380, 420],
          orientation: "portrait",
        },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>
