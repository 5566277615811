<template>
  <span>
    <select  
      :class="disableFormControl ? 'select_width_no_css' : 'form-control'" 
      v-model="value" 
      @change="$emit('update:selection', value)" 
      @blur="blurEvent"
      v-if="multiple == true"
      multiple
    >
      <option value="" disabled>{{ placeholder }}</option>
      <option 
        v-for="(match, g) in matches"
        :key="`match${g}`"
        :value="match.id">{{ match.name }}</option>
    </select>
    <select 
      :class="disableFormControl ? 'select_width_no_css' : 'form-control'" 
      v-model="value" 
      @change="$emit('update:selection', value)" 
      @blur="blurEvent"
      v-else
    >
      <option value="" disabled>{{ placeholder }}</option>
      <option 
        v-for="(match, g) in matches"
        :key="`match${g}`"
        :value="match.id">{{ match.name }}</option>
    </select>
    <script type="application/javascript" defer src="/custom.js"></script>
  </span>
</template>

<script type="text/javascript">

export default {
  name: 'MySelectWidget',
  data () {
    return {
      value: '',
      matches: []
    }
  },
  props: ['selection', 'suggestions', 'placeholder', 'disableFormControl', 'multiple'],
  watch: {
    'selection': 'getValue',
    'suggestions': 'getMatches',
    'value': 'changeEvent'
  },
  mounted() {
    this.getValue()
    this.getMatches()
  },
  methods: {
    getValue() {
      this.value = this.selection
    },
    getMatches() {
      this.matches = this.suggestions
    },
    changeEvent() {
      this.$emit('change')
    },
    blurEvent() {
      this.$emit('blur', this.value)
    }
  }
}
</script>

<style>
select {
  margin: 9px;
  color: grey !important;
  width: 180px;
}

select_width_no_css {
  width: 90px !important;
}
</style>