export function updateFormData(formData, addressTypes, site) {
  let form = {}
  form = formData
  form.title = form.title ?? ''
  form.marital_status_id = form.marital_status_id ?? ''
  form.blood_group_id = form.blood_group_id ?? ''
  form.nationality_id = form.nationality_id ?? ''
  form.residential_status_id = form.residential_status_id ?? ''
  form.country_id = form.country_id ?? ''
  form.religion_id = form.religion_id ?? ''
  form.verification_status_id = form.verification_status_id ?? ''
  form.employee_status_id = form.employee_status_id ?? ''
  form.bank_name_id = form.bank_name_id ?? ''
  form.bank_branch_id = form.bank_branch_id ?? ''
  form.bank_acc_type_id = form.bank_acc_type_id ?? ''
  form.bank_payment_type_id = form.bank_payment_type_id ?? ''
  form.group_id = form.group_id ?? ''
  form.group_division_id = form.group_division_id ?? ''
  form.user_identities = form.user_identities.length > 0 ? form.user_identities : []
  form.user_educations = form.user_educations.length > 0 ? form.user_educations : []
  form.user_categories = form.user_categories.length > 0 ? form.user_categories : []
  form.user_passports = form.user_passports.length > 0 ? form.user_passports : []
  form.user_visas = form.user_visas.length > 0 ? form.user_visas : []
  form.user_family_details = form.user_family_details.length > 0 ? form.user_family_details : []
  form.user_salaries = form.user_salaries.length > 0 ? form.user_salaries : []
  addressTypes.forEach(addressType => {
    let selectedAddressType = form.user_addresses.find(user_address => user_address.address_type_id == addressType.id)
    if(!selectedAddressType) {
      form.user_addresses.push({
        address_type_id: addressType.id,
        address_type: addressType,
        name: '',
        state_id: '',
        country_id: '',
      })
    }
  })
  form.roles.length > 0 ? form.role_id = form.roles[0].id : form.role_id = ''
  form.site_id = site.id
  return form
}