<template>
  <section>
    <header class="sp-header">
      <div class="sp-logo-wrap pull-left">
        <router-link to="/">
          <img class="brand-img mr-10" src="/dist/img/logo.png" alt="brand"/>
          <span class="brand-text">TRANS PAY INDIA HR SATHI</span>
        </router-link>
      </div>
      <div class="form-group mb-0 pull-right">
        <span class="inline-block pr-10">Don't have an account?</span>
        <router-link to="/register" class="inline-block btn btn-info btn-rounded btn-outline">
          Sign Up
        </router-link>
      </div>
      <div class="clearfix"></div>
    </header>
    
    <!-- Main Content -->
    <div class="page-wrapper pa-0 ma-0 auth-page">
      <div class="container-fluid">
        <!-- Row -->
        <div class="table-struct full-width" style="height: 900px;">
          <div class="table-cell vertical-align-middle auth-form-wrap">
            <div class="auth-form  ml-auto mr-auto no-float">
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <div class="mb-30" align="center">
                    <img style="width: 300px;" class="brand-img" src="/dist/img/logo-big.png" alt="brand"/>
                    <h3 class="text-center txt-dark mb-10">Sign in to TRANS PAY INDIA HR SATHI</h3>
                    <h6 class="text-center nonecase-font txt-grey">Enter your details below</h6>
                  </div>  
                  <div class="form-wrap">
                    <div class="form-group">
                      <label class="control-label mb-10" for="email">Email address</label>
                      <input type="email" class="form-control" required name="email" placeholder="Enter email" v-model="form.email">
                      <span class="error-block">{{ errors.email }}</span>
                    </div>
                    <div class="form-group">
                      <label class="pull-left control-label mb-10" for="password">Password</label>
                      <router-link class="capitalize-font txt-primary block mb-10 pull-right font-12" to="/forgot-password">forgot password ?</router-link>
                      <div class="clearfix"></div>
                      <input type="password" class="form-control" required placeholder="Enter pwd" name="password" v-model="form.password">
                      <span class="error-block">{{ errors.password }}</span>
                    </div>
                    <div class="form-group text-center">
                      <button class="btn btn-info btn-rounded" v-if="isLoading">Loading...</button>
                      <button @click="login" v-else class="btn btn-info btn-rounded btn-anim"><i class="icon-rocket"></i><span class="btn-text">submit</span></button>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
        <!-- /Row --> 
      </div>
      
    </div>
    <!-- /Main Content -->
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default{
  name: 'Login',
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      isLoading: false
    }
  },
  methods: {
    ...mapActions({
      logIn: 'auth/logIn'
    }),
    async login () {
      this.isLoading = true
      this.logIn(this.form)
        .then(() => {
          this.$router.push('/')
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
      this.$router.replace({
        name: 'Home'
      })
    }
  }
}
</script>