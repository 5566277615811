import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

// layout
import AuthLayout from "../views/auth-layout/index.vue";
import UnAuthLayout from "../views/unauth-layout/index.vue";
import PdfLayout from "../views/pdf-layout/index.vue";

import Home from "../views/home.vue";

import Settings from "../views/settings.vue";

// Auth
import Login from "@/views/auth/login.vue";
import Register from "@/views/auth/register.vue";
import ForgotPassword from "@/views/auth/forgot-password.vue";

// Profile
import Profile from "@/views/profile/index.vue";

// Class Societies
import ClassSocieties from "@/views/class-societies/index.vue";
import ClassSocietyCreate from "@/views/class-societies/create.vue";
import ClassSocietyId from "@/views/class-societies/id.vue";

// F3 Permissions
import Permissions from "@/views/permissions/index.vue";

// F9 Banks
import Banks from "@/views/settings/banks/index.vue";
import BankCreate from "@/views/settings/banks/create.vue";
import BankId from "@/views/settings/banks/id.vue";

// F11 BankBranches
import BankBranches from "@/views/settings/bank-branches/index.vue";
import BankBranchCreate from "@/views/settings/bank-branches/create.vue";
import BankBranchId from "@/views/settings/bank-branches/id.vue";

// F16 Sites
import Sites from "@/views/sites/index.vue";
import SiteCreate from "@/views/sites/create.vue";
import SiteId from "@/views/sites/id.vue";

// F19 Values
import Values from "@/views/settings/values/index.vue";
import ValuesUpload from "@/views/settings/values/upload.vue";
import ValueCreate from "@/views/settings/values/create.vue";
import ValueId from "@/views/settings/values/id.vue";

// F20 ValueLists
import ValueLists from "@/views/settings/value-lists/index.vue";
import ValueListUpload from "@/views/settings/value-lists/upload.vue";
import ValueListCreate from "@/views/settings/value-lists/create.vue";
import ValueListId from "@/views/settings/value-lists/id.vue";

// F23 Categories
import Categories from "@/views/settings/categories/index.vue";
import CategoryUpload from "@/views/settings/categories/upload.vue";
import CategoryCreate from "@/views/settings/categories/create.vue";
import CategoryId from "@/views/settings/categories/id.vue";

// F24 CategoryLists
import CategoryLists from "@/views/settings/category-lists/index.vue";
import CategoryListUpload from "@/views/settings/category-lists/upload.vue";
import CategoryListCreate from "@/views/settings/category-lists/create.vue";
import CategoryListId from "@/views/settings/category-lists/id.vue";

// F25 LeavingReasons
import LeavingReasons from "@/views/settings/leaving-reasons/index.vue";
import LeavingReasonCreate from "@/views/settings/leaving-reasons/create.vue";
import LeavingReasonId from "@/views/settings/leaving-reasons/id.vue";

// F26 AssetGroups
import AssetGroups from "@/views/settings/asset-groups/index.vue";
import AssetGroupCreate from "@/views/settings/asset-groups/create.vue";
import AssetGroupId from "@/views/settings/asset-groups/id.vue";

// F27 AssetGroupLists
import AssetGroupLists from "@/views/settings/asset-group-lists/index.vue";
import AssetGroupListCreate from "@/views/settings/asset-group-lists/create.vue";
import AssetGroupListId from "@/views/settings/asset-group-lists/id.vue";

// F52 Employees
import Employees from "@/views/main/employees/index.vue";
import EmployeeUpload from "@/views/main/employees/upload.vue";
import EmployeeCreate from "@/views/main/employees/create.vue";
import EmployeeId from "@/views/main/employees/id.vue";
// Attendance Upload
import AttendanceUpload from "@/views/main/workflows/monitor/attendance-upload.vue";
import PayrollUpload from "@/views/main/workflows/monitor/payroll-upload.vue";
// F52.a EmployeeProfile
import EmployeeProfile from "@/views/main/employees/profile/index.vue";

// F52.b EmployeeBankPfEsi
import EmployeeBankPfEsi from "@/views/main/employees/bank-pf-esi/index.vue";

// F52.c EmployeeFamilyDetails
import EmployeeFamilyDetails from "@/views/main/employees/family-details/index.vue";

// F52.d EmployeePassportVisa
import EmployeePassportVisa from "@/views/main/employees/passport-visa/index.vue";

// F52.e EmployeePositionHistory
import EmployeePositionHistory from "@/views/main/employees/position-history/index.vue";

// F52.f EmployeeAssets
import EmployeeAssets from "@/views/main/employees/assets/index.vue";

// F52.f EmployeeSalary
import EmployeeSalary from "@/views/main/employees/salary/index.vue";

// F65 Daily Summaries
import DaySummaries from "@/views/day-summaries/index.vue";
import DaySummaryCreate from "@/views/day-summaries/create.vue";
import DaySummaryList from "@/views/day-summaries/list.vue";
import DaySummaryId from "@/views/day-summaries/id.vue";

// F65 TrainingVideos
import TrainingVideos from "@/views/training-videos/index.vue";
import TrainingVideoCreate from "@/views/training-videos/create.vue";
import TrainingVideoUplaod from "@/views/training-videos/upload.vue";
import TrainingVideoId from "@/views/training-videos/id.vue";

// F66 TrainingVideoDetails
import TrainingVideoDetails from "@/views/training-videos/details/index.vue";
import TrainingVideoDetailCreate from "@/views/training-videos/details/create.vue";
import TrainingVideoDetailId from "@/views/training-videos/details/id.vue";

// F79 PfConfigurations
import PfConfigurations from "@/views/settings/pf-configurations/index.vue";
import PfConfigurationCreate from "@/views/settings/pf-configurations/create.vue";
import PfConfigurationId from "@/views/settings/pf-configurations/id.vue";
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
// WorkFlow

import WorkflowMonitorAttendance from "@/views/main/workflows/monitor/attendance.vue";
import WorkflowMonitorAttendanceCreate from "@/views/main/workflows/monitor/create.vue";
import WorkflowMonitorPayroll from "@/views/main/workflows/monitor/payroll.vue";
import WorkflowMonitorPunchDetails from "@/views/main/workflows/monitor/punch-details.vue";
import WorkflowMonitorDailyPunchLog from "@/views/main/workflows/monitor/daily-punch-log.vue";

// F80 PtConfigurations
import PtConfigurations from "@/views/settings/pt-configurations/index.vue";
import PtConfigurationCreate from "@/views/settings/pt-configurations/create.vue";
import PtConfigurationId from "@/views/settings/pt-configurations/id.vue";

// F81 LwfConfigurations
import LwfConfigurations from "@/views/settings/lwf-configurations/index.vue";
import LwfConfigurationCreate from "@/views/settings/lwf-configurations/create.vue";
import LwfConfigurationId from "@/views/settings/lwf-configurations/id.vue";

// BonusConfigurations
import BonusConfigurations from "@/views/settings/bonus-configurations/index.vue";
import BonusConfigurationCreate from "@/views/settings/bonus-configurations/create.vue";
import BonusConfigurationId from "@/views/settings/bonus-configurations/id.vue";

// LeaveConfigurations
import LeaveConfigurations from "@/views/settings/leave-configurations/index.vue";
import LeaveConfigurationCreate from "@/views/settings/leave-configurations/create.vue";
import LeaveConfigurationId from "@/views/settings/leave-configurations/id.vue";

// F82 EsicConfigurations
import EsicConfigurations from "@/views/settings/esic-configurations/index.vue";
import EsicConfigurationCreate from "@/views/settings/esic-configurations/create.vue";
import EsicConfigurationId from "@/views/settings/esic-configurations/id.vue";

// F70 Vendors
import Vendors from "@/views/main/vendors/index.vue";
import VendorCreate from "@/views/main/vendors/create.vue";
import VendorId from "@/views/main/vendors/id.vue";

// F71 Products
import Products from "@/views/main/products/index.vue";
import ProductCreate from "@/views/main/products/create.vue";
import ProductId from "@/views/main/products/id.vue";

// F85 Groups
import Groups from "@/views/main/groups/index.vue";
import GroupUpload from "@/views/main/groups/upload.vue";
import GroupCreate from "@/views/main/groups/create.vue";
import GroupId from "@/views/main/groups/id.vue";

// F85 Group Division
import GroupDivisions from "@/views/main/group-divisions/index.vue";
import GroupDivisionUpload from "@/views/main/group-divisions/upload.vue";
import GroupDivisionCreate from "@/views/main/group-divisions/create.vue";
import GroupDivisionId from "@/views/main/group-divisions/id.vue";

// F74 Tickets
import Tickets from "@/views/main/tickets/index.vue";
import TicketCreate from "@/views/main/tickets/create.vue";
import TicketId from "@/views/main/tickets/id.vue";

// F74 Ticket Follow ups
import FollowUps from "@/views/main/ticket-follow-ups/index.vue";
import FollowUpCreate from "@/views/main/ticket-follow-ups/create.vue";
import FollowUpId from "@/views/main/ticket-follow-ups/id.vue";

// F74 Ticket Follow ups
import FeedBacks from "@/views/main/feedbacks/index.vue";
import FeedBackCreate from "@/views/main/feedbacks/create.vue";
import FeedBackId from "@/views/main/feedbacks/id.vue";

// PF Reports
import PFChalan from "@/views/main/reports/pf-reports/challan.vue";
import PFChalanPDF from "@/views/main/reports/pf-reports/challan-pdf.vue";
import PFRegister from "@/views/main/reports/pf-reports/register.vue";
import PFRegisterPDF from "@/views/main/reports/pf-reports/register-pdf.vue";
import PFForm3A from "@/views/main/reports/pf-reports/form-3A.vue";
import PFForm3APDF from "@/views/main/reports/pf-reports/form-3A-pdf.vue";
import PFForm5A from "@/views/main/reports/pf-reports/form-5A.vue";
import PFForm5APDF from "@/views/main/reports/pf-reports/form-5A-pdf.vue";
import PFForm9A from "@/views/main/reports/pf-reports/form-9A.vue";
import PFForm9APDF from "@/views/main/reports/pf-reports/form-9A-pdf.vue";

//ESIC Reports
import ESICChallan from "@/views/main/reports/esic-reports/challan.vue";
import ESICChallanPDF from "@/views/main/reports/esic-reports/challan-pdf.vue";
import ESICRegister from "@/views/main/reports/esic-reports/register.vue";
import ESICRegisterPDF from "@/views/main/reports/esic-reports/register-pdf.vue";

// Pay Slips
import PaySlip from "@/views/main/reports/pay-slip.vue";
import PaySlip2 from "@/views/main/reports/pay-slip2.vue";
import PaySlip3 from "@/views/main/reports/pay-slip3.vue";
import PaySlip3Id from "@/views/main/reports/pay-slip3-id.vue";
import PaySlipView from "@/views/main/reports/pay-slips-pdf.vue";
import PaySlip2View from "@/views/main/reports/pay-slip2-pdf.vue";

// LWF Reports
import LWFChallan from "@/views/main/reports/lwf-challan.vue";
import LWFChallanPDF from "@/views/main/reports/lwf-challan-pdf.vue";

// PT Reports
import PTChallan from "@/views/main/reports/pt-reports/pt-challan.vue";
import PTChallanPDF from "@/views/main/reports/pt-reports/pt-challan-pdf.vue";
import PTRegister from "@/views/main/reports/pt-reports/pt-register.vue";
import PTRegisterPDF from "@/views/main/reports/pt-reports/pt-register-pdf.vue";

// BONUS /LEAVE Reports
import BONUSRegister from "@/views/main/reports/bonus-register.vue";
import BONUSRegisterPDF from "@/views/main/reports/bonus-register-pdf.vue";
import LEAVERegister from "@/views/main/reports/leave-register.vue";
import LEAVERegisterPDF from "@/views/main/reports/leave-register-pdf.vue";

// Salary/Wages Report
import SalaryRegister from "@/views/main/reports/salary-register.vue";
import SalaryRegisterPDF from "@/views/main/reports/salary-register-pdf.vue";

// CTC
import CTC from "@/views/main/reports/ctc.vue";
import CTCPDF from "@/views/main/reports/ctc-pdf.vue";

// Arrear Detailed Payslip
import ArrearDetailedPayslip from "@/views/main/reports/arrear-detailed-payslip.vue";
import ArrearDetailedPayslipPDF from "@/views/main/reports/arrear-detailed-payslip-pdf.vue";

// Invoice
import Invoice from "@/views/main/reports/invoice.vue";
import InvoiceAnnexure1 from "@/views/main/reports/invoice-annexure-1.vue";
import InvoiceAnnexure2 from "@/views/main/reports/invoice-annexure-2.vue";

// PF ECR
import PFECR from "@/views/main/reports/pf-ecr.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "AuthLayout",
    component: AuthLayout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
      },
      // Profile
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      // ClassSocieties
      {
        path: "/class-societies",
        name: "ClassSocieties",
        component: ClassSocieties,
      },
      {
        path: "/class-societies/create",
        name: "ClassSocietyCreate",
        component: ClassSocietyCreate,
      },
      {
        path: "/class-societies/:classSocietyId",
        name: "ClassSocietyId",
        component: ClassSocietyId,
      },
      // F6 Permissions
      {
        path: "/permissions",
        name: "Permissions",
        component: Permissions,
      },
      // F9 Banks
      {
        path: "/settings/banks",
        name: "Banks",
        component: Banks,
      },
      {
        path: "/settings/banks/create",
        name: "BankCreate",
        component: BankCreate,
      },
      {
        path: "/settings/banks/:bankId",
        name: "BankId",
        component: BankId,
      },
      // F11 BankBranches
      {
        path: "/settings/bank-branches",
        name: "BankBranches",
        component: BankBranches,
      },
      {
        path: "/settings/bank-branches/create",
        name: "BankBranchCreate",
        component: BankBranchCreate,
      },
      {
        path: "/settings/bank-branches/:bankBranchId",
        name: "BankBranchId",
        component: BankBranchId,
      },
      // F16 Sites
      {
        path: "/sites",
        name: "Sites",
        component: Sites,
      },
      {
        path: "/sites/create",
        name: "SiteCreate",
        component: SiteCreate,
      },
      {
        path: "/sites/:siteId",
        name: "SiteId",
        component: SiteId,
      },
      // F19 Values
      {
        path: "/settings/values",
        name: "Values",
        component: Values,
      },
      {
        path: "/settings/values/upload",
        name: "ValuesUpload",
        component: ValuesUpload,
      },
      {
        path: "/settings/values/create",
        name: "ValueCreate",
        component: ValueCreate,
      },
      {
        path: "/settings/values/:valueId",
        name: "ValueId",
        component: ValueId,
      },
      // F20 ValueLists
      {
        path: "/settings/value-lists",
        name: "ValueLists",
        component: ValueLists,
      },
      {
        path: "/settings/value-lists/upload",
        name: "ValueListUpload",
        component: ValueListUpload,
      },
      {
        path: "/settings/value-lists/create",
        name: "ValueListCreate",
        component: ValueListCreate,
      },
      {
        path: "/settings/value-lists/:valueListId",
        name: "ValueListId",
        component: ValueListId,
      },
      // F23 Categories
      {
        path: "/settings/categories",
        name: "Categories",
        component: Categories,
      },
      {
        path: "/settings/categories/upload",
        name: "CategoryUpload",
        component: CategoryUpload,
      },
      {
        path: "/settings/categories/create",
        name: "CategoryCreate",
        component: CategoryCreate,
      },
      {
        path: "/settings/categories/:categoryId",
        name: "CategoryId",
        component: CategoryId,
      },
      // F24 CategoryLists
      {
        path: "/settings/category-lists",
        name: "CategoryLists",
        component: CategoryLists,
      },
      {
        path: "/settings/category-lists/upload",
        name: "CategoryListUpload",
        component: CategoryListUpload,
      },
      {
        path: "/settings/category-lists/create",
        name: "CategoryListCreate",
        component: CategoryListCreate,
      },
      {
        path: "/settings/category-lists/:categoryListId",
        name: "CategoryListId",
        component: CategoryListId,
      },
      // F25 LeavingReasons
      {
        path: "/settings/leaving-reasons",
        name: "LeavingReasons",
        component: LeavingReasons,
      },
      {
        path: "/settings/leaving-reasons/create",
        name: "LeavingReasonCreate",
        component: LeavingReasonCreate,
      },
      {
        path: "/settings/leaving-reasons/:leavingReasonId",
        name: "LeavingReasonId",
        component: LeavingReasonId,
      },
      // F26 AssetGroups
      {
        path: "/settings/asset-groups",
        name: "AssetGroups",
        component: AssetGroups,
      },
      {
        path: "/settings/asset-groups/create",
        name: "AssetGroupCreate",
        component: AssetGroupCreate,
      },
      {
        path: "/settings/asset-groups/:assetGroupId",
        name: "AssetGroupId",
        component: AssetGroupId,
      },
      // F27 AssetGroupLists
      {
        path: "/settings/asset-group-lists",
        name: "AssetGroupLists",
        component: AssetGroupLists,
      },
      {
        path: "/settings/asset-group-lists/create",
        name: "AssetGroupListCreate",
        component: AssetGroupListCreate,
      },
      {
        path: "/settings/asset-group-lists/:assetGroupListId",
        name: "AssetGroupListId",
        component: AssetGroupListId,
      },
      // F52.a EmployeeProfile
      {
        path: "/main/employees/profile",
        name: "EmployeeProfile",
        component: EmployeeProfile,
      },
      // F52.b EmployeeBankPfEsi
      {
        path: "/main/employees/bank-pf-esi",
        name: "EmployeeBankPfEsi",
        component: EmployeeBankPfEsi,
      },
      // F52.c EmployeeFamilyDetails
      {
        path: "/main/employees/family-details",
        name: "EmployeeFamilyDetails",
        component: EmployeeFamilyDetails,
      },
      // F52.d EmployeePassportVisa
      {
        path: "/main/employees/passport-visa",
        name: "EmployeePassportVisa",
        component: EmployeePassportVisa,
      },
      // F52.e EmployeePositionHistory
      {
        path: "/main/employees/position-history",
        name: "EmployeePositionHistory",
        component: EmployeePositionHistory,
      },
      // F52.f EmployeeAssets
      {
        path: "/main/employees/assets",
        name: "EmployeeAssets",
        component: EmployeeAssets,
      },
      // F52.g EmployeeSalary
      {
        path: "/main/employees/salary",
        name: "EmployeeSalary",
        component: EmployeeSalary,
      },
      // F52 Employees
      {
        path: "/main/employees",
        name: "Employees",
        component: Employees,
      },
      {
        path: "/main/employees/create",
        name: "EmployeeCreate",
        component: EmployeeCreate,
      },
      {
        path: "/main/employees/upload",
        name: "EmployeeUpload",
        component: EmployeeUpload,
      },
      {
        path: "/main/employees/:employeeId",
        name: "EmployeeId",
        component: EmployeeId,
      },
      // F64 DaySummaries
      {
        path: "/day-summaries",
        name: "DaySummaries",
        component: DaySummaries,
      },
      {
        path: "/day-summaries/:employeeId/create",
        name: "DaySummaryCreate",
        component: DaySummaryCreate,
      },
      {
        path: "/day-summaries/list",
        name: "DaySummaryList",
        component: DaySummaryList,
      },
      {
        path: "/day-summaries/:employeeId/:daySummaryId",
        name: "DaySummaryId",
        component: DaySummaryId,
      },
      // F65 TrainingVideos
      {
        path: "/training-videos",
        name: "TrainingVideos",
        component: TrainingVideos,
      },
      {
        path: "/training-videos/create",
        name: "TrainingVideoCreate",
        component: TrainingVideoCreate,
      },
      {
        path: "/training-videos/upload",
        name: "TrainingVideoUplaod",
        component: TrainingVideoUplaod,
      },
      {
        path: "/training-videos/:trainingVideoId",
        name: "TrainingVideoId",
        component: TrainingVideoId,
      },
      // F66 TrainingVideoDetails
      {
        path: "/training-videos/:trainingVideoId/details",
        name: "TrainingVideoDetails",
        component: TrainingVideoDetails,
      },
      {
        path: "/training-videos/:trainingVideoId/details/create",
        name: "TrainingVideoDetailCreate",
        component: TrainingVideoDetailCreate,
      },
      {
        path: "/training-videos/:trainingVideoId/details/:detailId",
        name: "TrainingVideoDetailId",
        component: TrainingVideoDetailId,
      },
      // F79 PfConfigurations
      {
        path: "/settings/pf-configurations",
        name: "PfConfigurations",
        component: PfConfigurations,
      },
      {
        path: "/settings/pf-configurations/create",
        name: "PfConfigurationCreate",
        component: PfConfigurationCreate,
      },
      {
        path: "/settings/pf-configurations/:pfConfigurationId",
        name: "PfConfigurationId",
        component: PfConfigurationId,
      },
      //------------------------------------------------------------------------------
      //------------------------------------------------------------------------------
      // WorkFlow
      {
        path: "/main/workflows/monitor/attendance",
        name: "WorkflowMonitorAttendance",
        component: WorkflowMonitorAttendance,
      },
      {
        path: "/main/workflows/monitor/attendance/create",
        name: "WorkflowMonitorAttendanceCreate",
        component: WorkflowMonitorAttendanceCreate,
      },
      {
        path: "/main/workflows/monitor/attendance/upload",
        name: "AttendanceUpload",
        component: AttendanceUpload,
        component: AttendanceUpload,
      },
      {
        path: "/main/workflows/monitor/payroll/upload",
        name: "PayrollUpload",
        component: PayrollUpload,
        component: PayrollUpload,
      },
      {
        path: "/main/workflows/monitor/payroll",
        name: "WorkflowMonitorPayroll",
        component: WorkflowMonitorPayroll,
      },
      {
        path: "/main/workflows/monitor/punch-details",
        name: "WorkflowMonitorPunchDetails",
        component: WorkflowMonitorPunchDetails,
      },
      {
        path: "/main/workflows/monitor/daily-punch-log",
        name: "WorkflowMonitorDailyPunchLog",
        component: WorkflowMonitorDailyPunchLog,
      },
      // F80 PtConfigurations
      {
        path: "/settings/pt-configurations",
        name: "PtConfigurations",
        component: PtConfigurations,
      },
      {
        path: "/settings/pt-configurations/create",
        name: "PtConfigurationCreate",
        component: PtConfigurationCreate,
      },
      {
        path: "/settings/pt-configurations/:ptConfigurationId",
        name: "PtConfigurationId",
        component: PtConfigurationId,
      },
      // F81 LwfConfigurations
      {
        path: "/settings/lwf-configurations",
        name: "LwfConfigurations",
        component: LwfConfigurations,
      },
      {
        path: "/settings/lwf-configurations/create",
        name: "LwfConfigurationCreate",
        component: LwfConfigurationCreate,
      },
      {
        path: "/settings/lwf-configurations/:lwfConfigurationId",
        name: "LwfConfigurationId",
        component: LwfConfigurationId,
      },
      // BonusConfigurations
      {
        path: "/settings/bonus-configurations",
        name: "BonusConfigurations",
        component: BonusConfigurations,
      },
      {
        path: "/settings/bonus-configurations/create",
        name: "BonusConfigurationCreate",
        component: BonusConfigurationCreate,
      },
      {
        path: "/settings/bonus-configurations/:bonusConfigurationId",
        name: "BonusConfigurationId",
        component: BonusConfigurationId,
      },
      //LeaveConfigurations
      {
        path: "/settings/leave-configurations",
        name: "LeaveConfigurations",
        component: LeaveConfigurations,
      },
      {
        path: "/settings/leave-configurations/create",
        name: "LeaveConfigurationCreate",
        component: LeaveConfigurationCreate,
      },
      {
        path: "/settings/leave-configurations/:leaveConfigurationId",
        name: "LeaveConfigurationId",
        component: LeaveConfigurationId,
      },
      // F82 EsicConfigurations
      {
        path: "/settings/esic-configurations",
        name: "EsicConfigurations",
        component: EsicConfigurations,
      },
      {
        path: "/settings/esic-configurations/create",
        name: "EsicConfigurationCreate",
        component: EsicConfigurationCreate,
      },
      {
        path: "/settings/esic-configurations/:esicConfigurationId",
        name: "EsicConfigurationId",
        component: EsicConfigurationId,
      },
      // F70 Vendors
      {
        path: "/main/vendors",
        name: "Vendors",
        component: Vendors,
      },
      {
        path: "/main/vendors/create",
        name: "VendorCreate",
        component: VendorCreate,
      },
      {
        path: "/main/vendors/:vendorId",
        name: "VendorId",
        component: VendorId,
      },
      // F71 Products
      {
        path: "/main/products",
        name: "Products",
        component: Products,
      },
      {
        path: "/main/products/create",
        name: "ProductCreate",
        component: ProductCreate,
      },
      {
        path: "/main/products/:productId",
        name: "ProductId",
        component: ProductId,
      },
      // F70 Groups
      {
        path: "/main/groups",
        name: "Groups",
        component: Groups,
      },
      {
        path: "/main/groups/create",
        name: "GroupCreate",
        component: GroupCreate,
      },
      {
        path: "/main/groups/upload",
        name: "GroupUpload",
        component: GroupUpload,
      },
      {
        path: "/main/groups/:groupId",
        name: "GroupId",
        component: GroupId,
      },

      // F70 Group Divisions
      {
        path: "/main/group-divisions",
        name: "GroupDivisions",
        component: GroupDivisions,
      },
      {
        path: "/main/group-divisions/create",
        name: "GroupDivisionCreate",
        component: GroupDivisionCreate,
      },
      {
        path: "/main/group-divisions/upload",
        name: "GroupDivisionUpload",
        component: GroupDivisionUpload,
      },
      {
        path: "/main/group-divisions/:groupId",
        name: "GroupDivisionId",
        component: GroupDivisionId,
      },
      // F74 Tickets
      {
        path: "/main/tickets",
        name: "Tickets",
        component: Tickets,
      },
      {
        path: "/main/tickets/create",
        name: "TicketCreate",
        component: TicketCreate,
      },
      {
        path: "/main/tickets/:ticketId",
        name: "TicketId",
        component: TicketId,
      },
      //Follow Ups
      {
        path: "/main/tickets/:ticketId/follow-ups",
        name: "FollowUps",
        component: FollowUps,
      },
      {
        path: "/main/tickets/:ticketId/follow-ups/create",
        name: "FollowUpCreate",
        component: FollowUpCreate,
      },
      {
        path: "/main/tickets/:ticketId/follow-ups/:followupId",
        name: "FollowUpId",
        component: FollowUpId,
      },
      //Feedbacks
      {
        path: "/main/feedbacks",
        name: "FeedBacks",
        component: FeedBacks,
      },
      {
        path: "/main/feedbacks/create",
        name: "FeedBackCreate",
        component: FeedBackCreate,
      },
      {
        path: "/main/feedbacks/:feedbackId",
        name: "FeedBackId",
        component: FeedBackId,
      },
      // Data Table
      //   {
      //     path: '/main/data-table',
      //     name: 'DataTable',
      //     component: DataTable
      // },
      //PF Reports
      {
        path: "/main/reports/pf-reports/challan",
        name: "PFChalan",
        component: PFChalan,
      },
      {
        path: "/main/reports/pf-reports/register",
        name: "PFRegister",
        component: PFRegister,
      },
      {
        path: "/main/reports/pf-reports/form-3A",
        name: "PFForm3A",
        component: PFForm3A,
      },
      {
        path: "/main/reports/pf-reports/form-5A",
        name: "PFForm5A",
        component: PFForm5A,
      },
      {
        path: "/main/reports/pf-reports/form-9A",
        name: "PFForm9A",
        component: PFForm9A,
      },
      {
        path: "/main/reports/pay-slips",
        name: "PaySlip",
        component: PaySlip,
      },
      {
        path: "/main/reports/pay-slip-2",
        name: "PaySlip2",
        component: PaySlip2,
      },
      {
        path: "/main/reports/pay-slip-3",
        name: "PaySlip3",
        component: PaySlip3,
      },

      {
        path: "/main/reports/esic-reports/challan",
        name: "ESICChallan",
        component: ESICChallan,
      },
      {
        path: "/main/reports/esic-reports/register",
        name: "ESICRegister",
        component: ESICRegister,
      },
      {
        path: "/main/reports/lwf-challan",
        name: "LWFChallan",
        component: LWFChallan,
      },
      {
        path: "/main/reports/pt-reports/pt-challan",
        name: "PTChallan",
        component: PTChallan,
      },
      {
        path: "/main/reports/pt-reports/pt-register",
        name: "PTRegister",
        component: PTRegister,
      },
      {
        path: "/main/reports/bonus-register",
        name: "BONUSRegister",
        component: BONUSRegister,
      },
      {
        path: "/main/reports/leave-register",
        name: "LEAVERegister",
        component: LEAVERegister,
      },
      {
        path: "/main/reports/salary-register",
        name: "SalaryRegister",
        component: SalaryRegister,
      },
      {
        path: "/main/reports/ctc",
        name: "CTC",
        component: CTC,
      },
      {
        path: "/main/reports/arrear-detailed-payslip",
        name: "ArrearDetailedPayslip",
        component: ArrearDetailedPayslip,
      },
      {
        path: "/main/reports/invoice",
        name: "Invoice",
        component: Invoice,
      },
      {
        path: "/main/reports/invoice-annexure-1",
        name: "InvoiceAnnexure1",
        component: InvoiceAnnexure1,
      },
      {
        path: "/main/reports/invoice-annexure-2",
        name: "InvoiceAnnexure2",
        component: InvoiceAnnexure2,
      },
      {
        path: "/main/reports/pf-ecr",
        name: "PFECR",
        component: PFECR,
      },
    ],
  },
  {
    path: "",
    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
    ],
  },
  {
    path: "",
    name: "PdfLayout",
    component: PdfLayout,
    children: [
      {
        path: "/main/reports/pay-slips/pdf-view",
        name: "PaySlipView",
        component: PaySlipView,
      },
      {
        path: "/main/reports/pay-slips/pdf-view-2",
        name: "PaySlip2View",
        component: PaySlip2View,
      },
      {
        path: "/main/reports/pf-reports/challan-pdf",
        component: PFChalanPDF,
      },
      {
        path: "/main/reports/pf-reports/register-pdf",
        name: "PFRegisterPDF",
        component: PFRegisterPDF,
      },
      {
        path: "/main/reports/pf-reports/form-3A-pdf",
        name: "PFForm3APDF",
        component: PFForm3APDF,
      },
      {
        path: "/main/reports/pf-reports/form-5A-pdf",
        name: "PFForm5APDF",
        component: PFForm5APDF,
      },
      {
        path: "/main/reports/pf-reports/form-9A-pdf",
        name: "PFForm9APDF",
        component: PFForm9APDF,
      },
      {
        path: "/main/reports/esic-reports/register-pdf",
        name: "ESICRegisterPDF",
        component: ESICRegisterPDF,
      },
      {
        path: "/main/reports/esic-reports/challan-pdf",
        name: "ESICChallanPDF",
        component: ESICChallanPDF,
      },
      {
        path: "/main/reports/lwf-challan-pdf",
        name: "LWFChallanPDF",
        component: LWFChallanPDF,
      },
      {
        path: "/main/reports/pt-reports/pt-challan-pdf",
        name: "PTChallanPDF",
        component: PTChallanPDF,
      },
      {
        path: "/main/reports/pt-reports/pt-register-pdf",
        name: "PTRegisterPDF",
        component: PTRegisterPDF,
      },
      {
        path: "/main/reports/bonus-register-pdf",
        name: "BONUSRegisterPDF",
        component: BONUSRegisterPDF,
      },
      {
        path: "/main/reports/leave-register-pdf",
        name: "LEAVERegisterPDF",
        component: LEAVERegisterPDF,
      },
      {
        path: "/main/reports/salary-register-pdf",
        name: "SalaryRegisterPDF",
        component: SalaryRegisterPDF,
      },
      {
        path: "/main/reports/ctc-pdf",
        name: "CTCPDF",
        component: CTCPDF,
      },
      {
        path: "/main/reports/arrear-detailed-payslip-pdf",
        name: "ArrearDetailedPayslipPDF",
        component: ArrearDetailedPayslipPDF,
      },
      {
        path: "/main/reports/pay-slip-3/:payslip3Id",
        name: "PaySlip3Id",
        component: PaySlip3Id,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name === "Login" ||
    to.name === "Register" ||
    to.name === "ForgotPassword"
  ) {
    if (store.getters["auth/authenticated"]) {
      return next({
        name: "Home",
      });
    }
    next();
  } else if (!store.getters["auth/authenticated"]) {
    return next({
      name: "Login",
    });
  } else if (to.name != "Settings") {
    if (store.getters["auth/authenticated"]) {
      if (Object.keys(store.getters["auth/site"]).length == 0) {
        return next({
          name: "Settings",
        });
      }
    }
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
