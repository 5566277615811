<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            List of Asset Groups
            <save-button @click="save"></save-button>
          </h5> 
          <br>    
          <my-select  style="margin: 9px !important"
             @change="searchData" :selection.sync="search" :suggestions="assetGroups" placeholder="Select asset group"></my-select>
          <small v-if="isSaving">Saving...</small>
          <br />
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">List of Asset Groups</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="editable-table-wrapper">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Description</th>
                          <th>Is Active</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(assetGroupList, ar) in assetGroupLists"
                          :key="`assetGroupList${ar}`"
                        >
                          <td>
                            <span @click="deleteAssetGroupList(assetGroupList)"
                              v-if="!assetGroupList.id"
                            >
                              <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                            </span>
                            {{ ar + 1 }}.
                          </td>
                          <td>
                            <input v-uppercase type="text" placeholder="Name" v-model="assetGroupList.name">
                          </td>
                          <td>
                            <my-checkbox :selection.sync="assetGroupList.is_active" placeholder=""></my-checkbox>
                          </td>
                        </tr>
                        <tr
                          v-if="search"
                        >
                          <td colspan="9" align="center" @click="addEmptyAssetGroupList()">Add New Row</td>
                        </tr>
                        <tr><td></td></tr>
                      </tbody>
                    </table>
                  </div>

                  <errors :data="errors" />

                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'AssetGroups',
  data () {
    return {
      asset_groups: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      search: '',
      assetGroups: [],
      assetGroupLists: [],
      isSaving: false
    }
  },
  mounted() {
    this.getMasters()
  },
  methods: {
    async getMasters() {
      let masters = await axios.get('asset_group_lists/masters')
      masters = masters.data
      masters.asset_groups.forEach(assetGroup => {
        this.assetGroups.push({
          id: assetGroup.id,
          name: assetGroup.name
        })
      })
    },
    async getData(page = 1) {
      this.isLoading = true
      let assetGroupLists = await axios.get(`/asset_groups/${this.search}/asset_group_lists?page=${page}&rowsPerPage=${this.rowsPerPage}`)
      this.assetGroupLists = assetGroupLists.data.data
      this.count = assetGroupLists.data.count
      this.serialNoStarting = (page - 1) * this.rowsPerPage
      this.isLoading = false
    },
    searchData() {
      this.getData(1)
    },
    addEmptyAssetGroupList() {
      this.assetGroupLists.push({
        asset_group_id: this.search,
        site_id: this.site.id,
        name: '',
      })
    },
    deleteAssetGroupList(assetGroupList) {
      this.assetGroupLists = this.assetGroupLists.filter(ar => ar != assetGroupList)
    },
    async save() {
      if(this.assetGroupLists.length > 0) {
        this.isSaving = true
        let payload = {
          'datas': this.assetGroupLists
        }
        let response = await axios.post(`/asset_groups/${this.search}/asset_group_lists_multiple`, payload)
        this.assetGroupLists = response.data.data
        this.isSaving = false
      }
      
    }
  }
}
</script>

<style>


th {
  
}
</style>