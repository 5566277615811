<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            Invoice
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
          &nbsp; &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      :selection.sync="groupDivision"
                      :suggestions="groupDivisions"
                      :placeholder="`Select DIVISION`"
                    >
                    </my-select>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select month"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select year"
                    ></my-select>
                  </div>
                  <!-- <button @click="searchData('search')">Search</button>&nbsp;&nbsp; -->
                  <div class="col-md-6">
                    <button
                      class="btn btn-primary"
                      @click="searchData('generate')"
                    >
                      Generate</button
                    >&nbsp;&nbsp;
                    <button class="btn btn-primary" @click="generateInvoicePDF">
                      Print Invoice
                    </button>
                    &nbsp;&nbsp;
                    <button
                      class="btn btn-success"
                      @click="generateInvoiceAnnexurePDF"
                    >
                      Print Annexure
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->
      <!-- Invoice Form -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <!-- <div class="form-group">
                        <label class="col-sm-3 control-label">Billed To</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Billed To"
                            v-model="invoice.billed_to"
                          />
                          <span class="error-block">{{
                            errors.billed_to
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Shipped To</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Shipped To"
                            v-model="invoice.shipped_to"
                          />
                          <span class="error-block">{{
                            errors.shipped_to
                          }}</span>
                        </div>
                      </div> -->
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Client Ref No</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Client Ref No"
                                v-model="invoice.client_ref_no"
                              />
                              <span class="error-block">{{
                                errors.client_ref_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Ref Date</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="invoice.ref_date"
                                placeholder="Enter Ref Date"
                              ></my-datepicker>
                              <span class="error-block">{{
                                errors.ref_date
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Period From</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="invoice.from_period"
                                placeholder="Enter From"
                              ></my-datepicker>
                              <span class="error-block">{{
                                errors.from_period
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Period To</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="invoice.to_period"
                                placeholder="Enter To"
                              ></my-datepicker>
                              <span class="error-block">{{
                                errors.to_period
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Invoice No</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Invoice No"
                                v-model="invoice.invoice_no"
                              />
                              <span class="error-block">{{
                                errors.invoice_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Invoice Date</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="invoice.invoice_date"
                                placeholder="Enter Invoice Date"
                              ></my-datepicker>
                              <span class="error-block">{{
                                errors.invoice_date
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Place Of Supply</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Enter Place Of Supply"
                                v-model="invoice.place_of_supply"
                              />
                              <span class="error-block">{{
                                errors.place_of_supply
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Annexure Details Form -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap-new">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Service Particulars</th>
                        <th>Designation</th>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Strenght</th>
                        <th>Man Days</th>
                        <th>Rate</th>
                        <th>Total</th>
                        <th>OT HRS</th>
                        <th>OT Rate</th>
                        <th>Total OT</th>
                        <th>Gross Total</th>
                        <th>Duty Hours/ Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="8"
                      ></loading-table>
                      <tr
                        v-for="(
                          invoice_annexure_detail, at
                        ) in invoice.invoice_annexure_details"
                        :key="`invoice_annexure_detail${at}`"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>
                          <my-select
                            style="margin: 9px !important"
                            disableFormControl="true"
                            :id="`${at + 1}`"
                            :selection.sync="
                              invoice_annexure_detail.service_particular_id
                            "
                            :suggestions="service_particulars"
                            :placeholder="`Select Service Particulars`"
                            @change="
                              updateDays(
                                invoice_annexure_detail,
                                invoice_annexure_detail.user
                              )
                            "
                          >
                          </my-select>
                        </td>
                        <td>{{ invoice_annexure_detail.designation }}</td>
                        <td>{{ invoice_annexure_detail.name || "" }}</td>
                        <td></td>
                        <td>1</td>
                        <td>
                          <input
                            type="number"
                            v-model="invoice_annexure_detail.man_days"
                            @change="
                              updateDays(
                                invoice_annexure_detail,
                                invoice_annexure_detail.user
                              )
                            "
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            v-model="invoice_annexure_detail.rate"
                            @change="
                              updateDays(
                                invoice_annexure_detail,
                                invoice_annexure_detail.user
                              )
                            "
                          />
                        </td>
                        <td>{{ invoice_annexure_detail.total_rate }}</td>
                        <td>
                          <input
                            type="number"
                            v-model="invoice_annexure_detail.ot_hrs"
                            @change="
                              updateDays(
                                invoice_annexure_detail,
                                invoice_annexure_detail.user
                              )
                            "
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            v-model="invoice_annexure_detail.ot_rate"
                            @change="
                              updateDays(
                                invoice_annexure_detail,
                                invoice_annexure_detail.user
                              )
                            "
                          />
                        </td>
                        <td>{{ invoice_annexure_detail.total_ot }}</td>
                        <td>{{ invoice_annexure_detail.gross_total }}</td>
                        <td></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="8"><p style="float: right">Total</p></td>
                        <td>{{ invoice.total_rate }}</td>
                        <td colspan="3"></td>
                        <td>{{ invoice.total_ot_rate }}</td>
                        <td>{{ invoice.total_gross_total }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Particular Details Form -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap" style="height: auto !important">
                  <div class="editable-table-wrapper">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Particulars</th>
                          <th>HSN Code</th>
                          <th>Unit</th>
                          <th>Rate</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            invoice_particular_detail, ar
                          ) in invoice.invoice_particular_details"
                          :key="`invoice_particular_detail${ar}`"
                        >
                          <td>
                            <span
                              @click="
                                deleteParticularDetailList(
                                  invoice_particular_detail
                                )
                              "
                              v-if="!invoice_particular_detail.id && ar != 0"
                            >
                              <i
                                class="fa fa-times-circle-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                            {{ ar + 1 }}.
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Particular"
                              v-model="invoice_particular_detail.particular"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="HSN Code"
                              v-model="invoice_particular_detail.hsn_code"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Unit"
                              v-model="invoice_particular_detail.unit"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="number"
                              placeholder="rate"
                              v-model="invoice_particular_detail.rate"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="number"
                              placeholder="amount"
                              v-model="invoice_particular_detail.amount"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="9"
                            align="center"
                            @click="addEmptyParticularDetailList()"
                          >
                            Add New Row
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <errors :data="errors" />
                <div class="col-sm-9">
                  <submit-button
                    :isLoading="isLoading"
                    @submit="save"
                    text="Save"
                  ></submit-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row"></div>
      <!-- Row -->
      <!-- Invoice PDF -->
      <section id="invoice-pdf">
        <div class="row">
          <div class="col-sm-12">
            <div class="panel panel-default card-view">
              <div class="panel-wrapper collapse in">
                <div class="panel-body border" style="overflow-x: scroll">
                  <div class="container-fluid border">
                    <table
                      id="table-example-1"
                      class="table table-responsive col-md-9"
                    >
                      <thead>
                        <tr>
                          <th colspan="7"><center>TAX Invoice</center></th>
                        </tr>
                        <tr>
                          <th rowspan="7">Client</th>
                          <th>Billed To</th>
                          <th colspan="2">Shipped To</th>
                          <th>Client Ref No</th>
                          <th colspan="2">{{ invoice_pdf.client_ref_no }}</th>
                        </tr>
                        <tr>
                          <td rowspan="6">
                            <div style="white-space: pre">
                              {{ invoice_pdf.billed_to }}
                            </div>
                          </td>
                          <td colspan="2" rowspan="6">
                            <div style="white-space: pre">
                              {{ invoice_pdf.shipped_to }}
                            </div>
                          </td>
                          <th>Ref Date</th>
                          <th colspan="2">
                            {{ invoice_pdf.ref_date | ddmmyyyy }}
                          </th>
                        </tr>
                        <tr>
                          <th>Period</th>
                          <th>{{ invoice_pdf.from_period | ddmmyyyy }}</th>
                          <th>{{ invoice_pdf.to_period | ddmmyyyy }}</th>
                        </tr>
                        <tr>
                          <th>Invoice No</th>
                          <th colspan="2">{{ invoice_pdf.invoice_no }}</th>
                        </tr>
                        <tr>
                          <th>Invoice Date</th>
                          <th colspan="2">
                            {{ invoice_pdf.invoice_date | ddmmyyyy }}
                          </th>
                        </tr>
                        <tr>
                          <th>Month</th>
                          <th colspan="2">{{ month_name }} - {{ year }}</th>
                        </tr>
                        <tr>
                          <th>Place of Supply</th>
                          <th colspan="2">{{ invoice_pdf.place_of_supply }}</th>
                        </tr>
                        <tr>
                          <th>Sr no</th>
                          <th colspan="2">Particular</th>
                          <th>HSN/SAC</th>
                          <th>Unit</th>
                          <th>Rate</th>
                          <th>Amount()</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(
                            particular_detail, ar
                          ) in invoice_pdf.invoice_particular_details"
                          :key="`particular_detail${ar}`"
                        >
                          <td>{{ ar + 1 }}</td>
                          <td colspan="2">
                            {{ particular_detail.particular }}
                          </td>
                          <td>{{ particular_detail.hsn_code }}</td>
                          <td>{{ particular_detail.unit }}</td>
                          <td>{{ particular_detail.rate }}</td>
                          <td>
                            <p style="float: right">
                              {{ particular_detail.amount || "0" }}.00
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <td rowspan="8"></td>
                          <td colspan="3">
                            <p style="float: right">Sub-Total</p>
                          </td>
                          <td></td>
                          <td></td>
                          <td>
                            <p style="float: right">
                              {{ invoice_pdf.sub_total || "0" }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <p style="float: right">Service Charge @</p>
                          </td>
                          <td></td>
                          <td></td>
                          <td>
                            <p style="float: right">
                              {{ invoice_pdf.service_charge || "0" }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3"></td>
                          <td colspan="2" style="font-weight: bold">
                            <p style="float: right">Total</p>
                          </td>
                          <td>
                            <p style="float: right">
                              {{ invoice_pdf.total || "0" }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <p style="float: right">CGST @ 9%</p>
                          </td>
                          <td colspan="2" rowspan="3"></td>
                          <td>
                            <p style="float: right">
                              {{ invoice_pdf.cgst || "0" }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <p style="float: right">SGST @ 9%</p>
                          </td>
                          <td>
                            <p style="float: right">
                              {{ invoice_pdf.sgst || "0" }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <p style="float: right">IGST @</p>
                          </td>
                          <td>
                            <p style="float: right">
                              {{ invoice_pdf.igst || "0" }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3"></td>
                          <td style="font-weight: bold" colspan="2">
                            Grand Total
                          </td>
                          <td>
                            <p style="float: right">
                              {{ invoice_pdf.grand_total || "0" }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <p style="font-weight: bold">
                              <u>Bank Details For RTGS/NEFT:</u>
                            </p>
                            <div class="row">
                              <div class="col-md-6">
                                <p>Bank Name: {{ site.bank_name }}</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>Account No: {{ site.account_no }}</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>Branch Name: {{ site.branch_name }}</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>IFSC No: {{ site.ifsc }}</p>
                              </div>
                            </div>
                          </td>
                          <td colspan="4">
                            <p style="font-weight: bold">
                              <u>Statutory Registration Details</u>
                            </p>
                            <div class="row">
                              <div class="col-md-6">
                                <p>
                                  <span style="font-weight: bold"
                                    >PAN No:
                                  </span>
                                  {{ site.pan_no }}
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>
                                  <span style="font-weight: bold">PF No: </span>
                                  {{ site.pf_no }}
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>
                                  <span style="font-weight: bold"
                                    >ESIC No:
                                  </span>
                                  {{ site.esi_no }}
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>
                                  <span style="font-weight: bold"
                                    >Udyam Registration No:
                                  </span>
                                  {{ site.udyam_registration_no }}
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>
                                  <span style="font-weight: bold"
                                    >GST No:
                                  </span>
                                  {{ site.gst_no }}
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>
                                  <span style="font-weight: bold"
                                    >State Code:</span
                                  >
                                  {{ site.state_code }}-
                                  <span style="font-weight: bold">State:</span>
                                  {{ site_state_name }}
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>
                                  <span style="font-weight: bold">
                                    Place Of Origin: </span
                                  >{{ site.place_of_origin }}
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <p>
                                  <span style="font-weight: bold"
                                    >HSN / SAC:</span
                                  >
                                  {{ site.hsn_code }}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7">
                            <p>Rupees {{ invoice_pdf.grand_total_in_words }}</p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <p style="font-weight: bold">Terms & Conditions:</p>
                            <p>
                              1. Please issue cheque / DD / PO favouring
                              <span style="font-weight: bold"
                                >"V ALLIANCE"</span
                              >
                              Interest shall be charged @ 24% on all overdue
                              bills
                            </p>
                            <p>
                              2. Any issues relating to invoice raised is to
                              intimated or raised within 5 days of receipt of
                              the same. Else it will be considered as accepted
                              as it is.
                            </p>
                            <p>
                              3. Big Payment should be paid within 30 days of
                              the bill date.
                            </p>
                            <p>
                              4. All disputes are subject to mumbai
                              Jurisdiction.
                            </p>
                          </td>
                          <td colspan="3">
                            <p style="float: right">E. & O.E.</p>
                            <p style="font-weight: bold">For V ALLIANCE</p>
                            <br />
                            <br />
                            <br />
                            <br />
                            <p>Authorised Signatory</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Attendance Annexure Details PDF -->
      <section id="invoice-annexure-pdf">
        <div class="row">
          <div class="col-sm-12">
            <div class="panel panel-default card-view">
              <div class="panel-wrapper collapse in">
                <div class="panel-body">
                  <errors :data="errors" />
                  <div class="container-fluid border">
                    <table
                      id="table-example-1"
                      class="table table-responsive col-md-9"
                    >
                      <thead>
                        <tr>
                          <th colspan="2">CLIENT NAME</th>
                          <th colspan="6">
                            {{ group_data.name }}
                          </th>
                          <th>DAYS:</th>
                          <th>{{ totalDaysInMonth }}</th>
                          <th>MONTH:</th>
                          <th>{{ month_name }}-{{ year }}</th>
                        </tr>
                        <tr>
                          <th colspan="12">
                            <center>Attendance Annexure</center>
                          </th>
                        </tr>
                        <tr>
                          <th>Sr No</th>
                          <th>Particulars of Service</th>
                          <th>Name</th>
                          <th>Strength</th>
                          <th>Man Days</th>
                          <th>Rate</th>
                          <th>Total</th>
                          <th>OT HRS</th>
                          <th>OT Rate</th>
                          <th>Total</th>
                          <th>Gross Total</th>
                          <th>Duty Hourse/Days</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>A</td>
                          <td>HouseKeeping & Allied Service</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr
                          v-for="(annexuredata, at) in attendance_annexure_pdf"
                          :key="`annexuredata${at}`"
                        >
                          <td>{{ at + 1 }}</td>
                          <td>{{ annexuredata.service_name }}</td>
                          <td>{{ annexuredata.name }}</td>
                          <td>{{ annexuredata.strength }}</td>
                          <td>{{ annexuredata.man_days }}</td>
                          <td>{{ annexuredata.rate }}.00</td>
                          <td>{{ annexuredata.total_rate }}.00</td>
                          <td>{{ annexuredata.ot_hrs }}</td>
                          <td>{{ annexuredata.ot_rate }}.00</td>
                          <td>{{ annexuredata.total_ot_rate }}</td>
                          <td>{{ annexuredata.gross_total }}.00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colspan="6" style="font-weight: bold">
                            <span style="float: right">Total</span>
                          </td>
                          <td style="font-weight: bold">
                            {{ invoice.total_rate }}.00
                          </td>
                          <td></td>
                          <td></td>
                          <td>{{ invoice.total_ot_rate }}</td>
                          <td style="font-weight: bold">
                            {{ invoice.total_gross_total }}.00
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFInvoiceAnnexure1",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      employees: [],
      month_id: "",
      month_name: "",
      year: "",
      form: {},
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      states: [],
      state_id: "",
      group_data: {},
      service_particular: "",
      service_particulars: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      serialNoStarting: 1,
      showInvoiceAnnexure1Table: false,
      showInvoiceAnnexure1: false,
      invoice: {
        invoice_annexure_details: [],
        invoice_particular_details: [],
      },
      invoice_pdf: {},
      totalDaysInMonth: "",
      attendance_annexure_pdf: [],
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();
    // this.month_id = moment().format("M");
    // this.month_name = moment().format("MMMM");
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      let states = masters.states;
      states.forEach((state) => {
        this.states.push({
          id: state.id,
          name: state.code,
        });
      });
      let service_particulars = masters.service_particulars;
      service_particulars.forEach((service) => {
        this.service_particulars.push({
          id: service.id,
          name: service.code,
        });
      });
      this.site_state_name = this.states.find(
        (state) => state.id == this.site.state_id
      ).name;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showInvoiceAnnexure1 = false;
      this.showInvoiceAnnexure1Table = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        this.employees = [];
        let users = await axios.get(
          `/monthly_salaries/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.employees = [];
        this.group_div_flag = users.data.data.group_div_flag;
        this.employees = users.data.data.selectedUsers;
        this.initializeEmptyInvoice();
        this.employees.forEach((user) => {
          this.initializeEmptyAnnexureDetails(user);
        });
        this.addEmptyParticularDetailList();
        this.count = users.data.data.selectedUsers.count;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showInvoiceAnnexure1Table = true;
        this.showInvoiceAnnexure1 = false;
      }
      if (source == "generate") {
        this.showInvoiceAnnexure1Table = false;
        this.showInvoiceAnnexure1 = true;
      }
    },
    initializeEmptyInvoice() {
      this.invoice = {
        invoice_annexure_details: [],
        invoice_particular_details: [],
      };
    },
    initializeEmptyAnnexureDetails(user) {
      let invoice_annexure_detail = {
        service_particular_id: "",
        designation: "",
        name: user.full_name,
        location: "",
        strength: 1,
        man_days: user.monthly_salary.days_present,
        rate: user.user_salaries[0].total_cost,
        total_rate: 0,
        ot_hrs: user.monthly_salary.ot_hrs,
        ot_rate: user.user_salaries[0].billing_ot_rate,
        total_ot: 0,
        gross_total: 0,
        duty_hrs_days: 0,
        user: user,
      };
      this.invoice.invoice_annexure_details.push(invoice_annexure_detail);

      this.updateDays(invoice_annexure_detail, user);
    },
    updateDays(invoice_annexure_detail, user) {
      let totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
      // let service_particular_id = invoice_annexure_detail.service_particular_id;

      let designation_category = {};
      let designation_categories = user.user_categories.filter(
        (user_category) => user_category.category.category_name == "DESIGNATION"
      );
      if (designation_categories.length > 0)
        designation_category = designation_categories[0].category_list.code;

      let man_days = invoice_annexure_detail
        ? parseFloat(invoice_annexure_detail.man_days)
        : 0;

      let rate = invoice_annexure_detail.rate
        ? parseFloat(invoice_annexure_detail.rate)
        : 0;

      let total_rate = (man_days / totalDaysInMonth) * rate;

      let ot_hrs = invoice_annexure_detail
        ? parseFloat(invoice_annexure_detail.ot_hrs)
        : 0;

      let ot_rate = invoice_annexure_detail.ot_rate
        ? parseFloat(invoice_annexure_detail.ot_rate)
        : 0;

      let total_ot = ot_hrs * ot_rate;

      let gross_total = total_rate + total_ot;

      invoice_annexure_detail.designation = designation_category;
      invoice_annexure_detail.total_rate = Math.round(total_rate);
      invoice_annexure_detail.total_ot = Math.round(total_ot);
      invoice_annexure_detail.gross_total = Math.round(gross_total);
      invoice_annexure_detail.user = user;
      this.updateTotal();
    },
    updateTotal() {
      let totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
      this.invoice.total_rate = 0;
      this.invoice.total_ot_rate = 0;
      this.invoice.total_gross_total = 0;
      this.invoice.service_charge = 0;
      this.total_service_charge = 0;
      this.invoice.invoice_annexure_details.forEach((annexure_detail) => {
        this.invoice.total_rate =
          annexure_detail.total_rate + this.invoice.total_rate;
        this.invoice.total_ot_rate =
          annexure_detail.total_ot + this.invoice.total_ot_rate;
        this.invoice.total_gross_total =
          annexure_detail.gross_total + this.invoice.total_gross_total;

        let management_fees =
          annexure_detail.user.user_salaries[0].management_fees;
        if (management_fees) {
          let service_charge = Math.round(
            (management_fees / totalDaysInMonth) * annexure_detail.man_days
          );
          this.total_service_charge =
            service_charge + this.total_service_charge;
        }
      });
      this.invoice.sub_total = this.invoice.total_gross_total;
      this.invoice.service_charge = this.total_service_charge;
      this.invoice.total = this.invoice.sub_total + this.invoice.service_charge;
      if (this.group_div_flag) {
        // Check with group Division
        this.state_code = this.employees[0].group_division.state_code;
      } else {
        // Check With Group
        this.state_code = this.employees[0].group.state_code;
      }
      let site_state_code = this.site.state_code;
      this.invoice.igst = 0;
      this.invoice.cgst = 0;
      this.invoice.sgst = 0;
      if (this.state_code != site_state_code) {
        // IGST
        this.invoice.igst = Math.round((this.invoice.total * 18) / 100);
      } else {
        // CGST & SGST
        this.invoice.cgst = Math.round((this.invoice.total * 9) / 100);
        this.invoice.sgst = Math.round((this.invoice.total * 9) / 100);
      }
      this.invoice.grand_total =
        this.invoice.total +
        this.invoice.igst +
        this.invoice.cgst +
        this.invoice.sgst;
    },
    generateInvoicePDF() {
      var element = document.getElementById("invoice-pdf");
      var opt = {
        margin: 0,
        filename: "Invoice.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: {
          unit: "mm",
          // format: [430, 300], // [ height, width]
          format: [345, 300], // [ height, width] Top n Bottom Margin

          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
    generateInvoiceAnnexurePDF() {
      var element = document.getElementById("invoice-annexure-pdf");
      var opt = {
        margin: 0,
        filename: "InvoiceAnnexure1.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          // format: [300, 600],
          format: [313, 540],
          orientation: "l",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
    async save() {
      try {
        this.isLoading = true;
        this.invoice.month_id = this.month_id;
        this.invoice.year = this.year;

        this.group_data = this.employees[0].group;
        let name = this.group_data.name ? this.group_data.name : "";
        let pan_no = this.group_data.pan_no ? this.group_data.pan_no : "";
        let gstin_no = this.group_data.gstin_no ? this.group_data.gstin_no : "";
        let address_1 = this.group_data.address_1
          ? this.group_data.address_1
          : "";
        let address_2 = this.group_data.address_2
          ? this.group_data.address_2
          : "";

        let state_code = this.group_data.state_code
          ? this.group_data.state_code
          : "";
        let state_name = this.states.find(
          (state) => state.id == this.group_data.state_id
        ).name;
        this.invoice.billed_to =
          name +
          ",\n " +
          address_1 +
          " \n " +
          address_2 +
          "\n PAN NO: " +
          pan_no +
          ",\n GSTIN NO: " +
          gstin_no +
          ",\n STATE CODE: " +
          state_code +
          "/State: " +
          state_name;
        if (this.group_div_flag) {
          this.group_division_data = this.employees[0].group_division;
          let name = this.group_division_data.division_name
            ? this.group_division_data.division_name
            : "";
          let pan_no = this.group_division_data.pan_no
            ? this.group_division_data.pan_no
            : "";
          let gstin_no = this.group_division_data.gstin_no
            ? this.group_division_data.gstin_no
            : "";
          let address_1 = this.group_division_data.address_1
            ? this.group_division_data.address_1
            : "";
          let address_2 = this.group_division_data.address_2
            ? this.group_division_data.address_2
            : "";
          let state_code = this.group_division_data.state_code
            ? this.group_division_data.state_code
            : "";
          let state_name = this.states.find(
            (state) => state.id == this.group_division_data.state_id
          ).name;
          this.invoice.shipped_to =
            name +
            ",\n " +
            address_1 +
            " \n " +
            address_2 +
            "\n PAN NO: " +
            pan_no +
            ",\n GSTIN NO: " +
            gstin_no +
            ",\n STATE CODE: " +
            state_code +
            "/State: " +
            state_name;
        } else {
          this.invoice.shipped_to =
            name +
            ",\n " +
            address_1 +
            " \n " +
            address_2 +
            "\n PAN NO: " +
            pan_no +
            ",\n GSTIN NO: " +
            gstin_no +
            ",\n STATE CODE: " +
            state_code +
            "/State: " +
            state_name;
        }
        console.log(this.invoice);

        this.invoice.invoice_particular_details[0].amount =
          this.invoice.total_gross_total;

        let response = await axios.post("/invoices", this.invoice);
        this.invoice_pdf = response.data.data;
        this.attendance_annexure_pdf =
          response.data.data.invoice_annexure_details;
        this.attendance_annexure_pdf.forEach((annexuredata) => {
          let service_particular = this.service_particulars.find(
            (sp) => sp.id == annexuredata.service_particular_id
          );
          annexuredata.service_name = service_particular.name;
        });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyParticularDetailList() {
      let i = this.invoice.invoice_particular_details.length;
      let invoice_particular_details = {
        particular: "",
        hsn_code: "",
        unit: "",
        rate: "",
        amount: i == 0 ? this.invoice.total_gross_total : "",
      };
      this.invoice.invoice_particular_details.push(invoice_particular_details);
    },
    deleteParticularDetailList(invoice_particular_detail) {
      this.invoice.invoice_particular_details =
        this.invoice.invoice_particular_details.filter(
          (ar) => ar != invoice_particular_detail
        );
    },
  },
};
</script>
<style>
.border table,
.border th,
.border td {
  border: 2px solid black;
}

.table-wrap-new {
  height: auto !important;
}
</style>
