<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Group Divisions
            <save-button @click="save"></save-button>
            &nbsp;
            <upload-button link="/main/group-divisions/upload"></upload-button>
          </h5>
          <br> 
          <my-select
            style="margin: 9px !important"
            @change="searchData"
            :selection.sync="search"
            :suggestions="groups"
            placeholder="Select group"
          ></my-select>
          <small v-if="isSaving">Saving...</small>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Group Divisions</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="editable-table-wrapper">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Division Name</th>
                          <!-- <th>Logo Path</th> -->
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Address 1</th>
                          <th>Address 2</th>
                          <th>Country</th>
                          <th>State</th>
                          <th>State Code</th>
                          <th>City</th>
                          <th>Pincode</th>
                          <th>GstIn No</th>
                          <th>Pan No</th>
                          <th>PF Applicable ON</th>
                          <th>Management Fees</th>
                          <th>Management Fees Type</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(form, ar) in forms" :key="`form${ar}`">
                          <td>
                            <span
                              @click="deleteGroupDivision(form)"
                              v-if="!form.id"
                            >
                              <i
                                class="fa fa-times-circle-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                            {{ ar + 1 }}.
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Group Division Name"
                              v-model="form.division_name"
                            />
                          </td>
                          <!-- <td>
                            <input type="file" id="file" name="logo_path" ref="file" accept="image/*" multiple />
                          </td> -->
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Email"
                              v-model="form.email"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Phone"
                              v-model="form.phone"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Address 1"
                              v-model="form.address_1"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Address 2"
                              v-model="form.address_2"
                            />
                          </td>
                          <td>
                            <my-select
                              disableFormControl="true"
                              :selection.sync="form.country_id"
                              :suggestions="countries"
                              placeholder="Select Country"
                            ></my-select>
                          </td>
                          <td>
                            <my-select
                              disableFormControl="true"
                              :selection.sync="form.state_id"
                              :suggestions="states"
                              placeholder="Select State"
                            ></my-select>
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="State Code"
                              v-model="form.state_code"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="City"
                              v-model="form.city"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Pincode"
                              v-model="form.pincode"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="GSTN No"
                              v-model="form.gstin_no"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Pan No"
                              v-model="form.pan_no"
                            />
                          </td>
                          <td>
                            <my-select
                              :selection.sync="form.applicable_on"
                              :suggestions="applicables"
                              placeholder="Applicable On"
                              disableFormControl="true"
                            ></my-select>
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Management Fees"
                              v-model="form.management_fees_value"
                            />
                          </td>
                          <td>
                            <my-select
                              disableFormControl="true"
                              :selection.sync="form.management_fees_type"
                              :suggestions="configuration_types"
                              placeholder="Management Fees Type Type"
                            ></my-select>
                          </td>
                        </tr>
                        <tr v-if="search">
                          <td
                            colspan="13"
                            align="center"
                            @click="addEmptyGroupDivision()"
                          >
                            Add New Row
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <errors :data="errors" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "groupDivision",
  data() {
    return {
      states: [],
      countries: [],
      groups: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      search: "",
      forms: [],
      isSaving: false,
      applicables: [
        { id: 1, name: "On Gross" },
        { id: 2, name: "On Basic and DA" },
      ],
      configuration_types: [
        { id: 1, name: "Flat" },
        { id: 2, name: "%" },
      ],
    };
  },
  mounted() {
    this.getMasters();
    this.getGroups();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("group_divisions/masters");
      masters = masters.data;
      masters.states.forEach((state) => {
        this.states.push({
          id: state.id,
          name: state.description,
        });
      });
      masters.countries.forEach((country) => {
        this.countries.push({
          id: country.id,
          name: country.description,
        });
      });
    },
    async getGroups() {
      let masters = await axios.get("/groups");
      masters = masters.data;
      masters.data.forEach((value) => {
        this.groups.push({
          id: value.id,
          name: value.name,
        });
      });
    },
    async getData(page = 1) {
      this.isLoading = true;
      let forms = await axios.get(
        `/groups/${this.search}/group_divisions?page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.forms = forms.data.data;
      this.count = forms.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    searchData() {
      this.getData(1);
    },
    addEmptyGroupDivision() {
      this.forms.push({
        group_id: this.search,
        site_id: this.site.id,
        division_name: "",
        logo_path: "",
        email: "",
        phone: "",
        address_1: "",
        address_2: "",
        country_id: "",
        state_id: "",
        state_code: "",
        city: "",
        pincode: "",
        gstin_no: "",
        pan_no: "",
        applicable_on: 2,
        management_fees_value: "",
        management_fees_type: "",
      });
    },
    deleteGroupDivision(form) {
      this.forms = this.forms.filter((ar) => ar != form);
    },
    async save() {
      if (this.forms.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.forms,
        };
        await axios.post(
          `/groups/${this.search}/group_divisions_multiple`,
          payload
        );
        this.isSaving = false;
      }
    },
  },
};
</script>

<style>
th {
}
</style>