<template>
  <span @click="refresh">
    <i class="fa fa-refresh"></i>
  </span>
</template>

<script type="text/javascript">
export default {
  name: 'RefreshButtonWidget',
  props: ['link'],
  methods: {
    refresh() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
span {
  margin-left: 5px;
}
</style>