<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;">Address</span>
          </div>

          <br>

          <div class="row"
            v-for="(userAddress, at) in form.user_addresses"
            :key="`userAddress${at}`"
          >

            <div class="form-group row"
              v-if="!(editData == at)"
            >
              <label class="col-sm-4 col-form-label">{{ userAddress.address_type.code }}</label>
              <div class="col-sm-6">
                {{ userAddress.name }}, {{ userAddress.address_1 }}, {{ userAddress.address_2 }}, {{ userAddress.address_3 }}, {{ userAddress.city }}, {{ userAddress.state ? userAddress.state.code : '' }}, {{ userAddress.country ? userAddress.country.code : '' }}
              </div>
              <div class="col-md-2">
                <i class="fa fa-pencil-square-o" style="float: right;" aria-hidden="true" @click="toggleEditData(at)"></i>
              </div>
            </div>
            <div
              v-else
            >
              <u>{{ userAddress.address_type.code }}</u>
              <br>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Name</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Name" v-model="userAddress.name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Address 1</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Address 1" v-model="userAddress.address_1">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Address 2</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Address 2" v-model="userAddress.address_2">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Address 3</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Address 3" v-model="userAddress.address_3">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">City</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="City" v-model="userAddress.city">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">State</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="userAddress.state_id" :suggestions="states" placeholder="Select state"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Country</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="userAddress.country_id" :suggestions="countries" placeholder="Select country"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Pincode</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Pincode" v-model="userAddress.pincode">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Phone 1</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Phone 1" v-model="userAddress.phone_1">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Phone 2</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Phone 2" v-model="userAddress.phone_2">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Mobile</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Mobile" v-model="userAddress.mobile">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Email</label>
                <div class="col-sm-8">
                  <input v-uppercase type="text" class="form-control" placeholder="Email" v-model="userAddress.email">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-10"
                  v-if="editData == at"
                >
                  <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      editData: null,
    }
  },
  props: ['form', 'addressTypes', 'isLoading', 'states', 'countries'],
  methods: {
    toggleEditData(at) {
      this.editData = at
    },
    cancelUpdate() {
      this.editData = null
    },
    async update() {
      this.$emit('update')
      this.editData = null
    }
  }
}
</script>