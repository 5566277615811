<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            Day Summary
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
        </div>
      </div>
      <!-- /Title -->
      <!-- Filters -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      :search="searchText"
                      placeholder="Search..."
                      @searchData="search"
                    />
                  </div>
                </div>
                <div class="row">
                  <!-- <div class="col-md-3">
                    <my-datepicker
                      style="margin: 9px !important"
                      :date.sync="date"
                      @change="searchData"
                      placeholder="Date"
                    ></my-datepicker>
                  </div> -->
                  <!-- <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select MONTH"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="updateMonth"
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select YEAR"
                    ></my-select>
                  </div> -->
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="searchData"
                      :selection.sync="groupDivision"
                      :suggestions="groupDivisions"
                      :placeholder="`Select DIVISION`"
                    >
                    </my-select>
                  </div>
                  <div
                    class="col-md-3"
                    v-for="(category, c) in categories"
                    :key="`category${c}`"
                  >
                    <my-select
                      style="margin: 9px !important"
                      @change="searchData"
                      :selection.sync="category.selectedValue"
                      :suggestions="category.active_category_lists"
                      :placeholder="`Select ${category.category_name}`"
                      multiple="true"
                    >
                    </my-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Filters -->

      <!-- Content -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="inner" style="height: 700px">
                    <table
                      class="table table-hover table-striped table-responsive"
                    >
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Employee Code</th>
                          <th>Division</th>
                          <th>Name</th>
                          <!-- <th>Role</th> -->
                          <!-- <th>Email</th> -->
                          <th>Mobile</th>
                          <th>Is Active</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <loading-table
                          :isLoading="isLoading"
                          cols="8"
                        ></loading-table>
                        <router-link
                          v-for="(user, at) in employees"
                          :key="`user${at}`"
                          :to="`/main/employees/${user.id}`"
                          tag="tr"
                        >
                          <td>{{ serialNoStarting + at + 1 }}</td>
                          <td>{{ user.employee_id }}</td>
                          <td>
                            {{ user.group_division ? user.group_division.division_name : '', }}
                          </td>
                          <td>{{ user.full_name || "" }}</td>
                          <!-- <td>{{ user.roles.length > 0 ? user.roles[0].name : '' }}</td> -->
                          <!-- <td>{{ user.email }}</td> -->
                          <td>{{ user.mobile }}</td>
                          <td>
                            {{
                              user.employee_status == null ||
                              (user.employee_status.code != "LEFT" &&
                                user.employee_status.code != "TERMINATE")
                                ? "YES"
                                : "NO"
                            }}
                          </td>
                          <td>
                            <router-link
                              :to="`/day-summaries/list?userId=${user.id}`"
                            >
                              <u>View Day Summary</u>
                            </router-link>
                          </td>
                        </router-link>
                      </tbody>
                    </table>
                  </div>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / Content -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "employees",
  data() {
    return {
      employees: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      categories: [],
      selectedCategories: [],
      searchText: "",
      title: "",
    };
  },
  mounted() {
    this.getMasters();
    // this.getMonths();
    // this.month_name = moment().format("MMMM");
    // this.year = moment().format("Y");
  },
  methods: {
    // getMonths() {
    //   let months = moment.months();
    //   months.forEach((month, m) => {
    //     this.months.push({
    //       id: m + 1,
    //       name: month,
    //     });
    //   });
    // },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    // updateMonth() {
    //   this.month_name = moment(this.month_id, "M").format("MMMM");
    //   this.totalDaysInMonth = moment(
    //     this.year + "-" + this.month_id,
    //     "YYYY-M"
    //   ).daysInMonth();
    //   this.searchData();
    // },
    selectDivision() {
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
      this.searchData();
    },
    refreshData() {
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.categories.forEach((category) => {
        category.selectedValue = "";
      });
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData() {
      let selectedCategoriesArray = [];
      this.categories.forEach((category) => {
        selectedCategoriesArray.push(
          category.id + "-" + category.selectedValue
        );
      });
      this.getData(selectedCategoriesArray);
    },
    async getData(selectedCategoriesArray = [], page = 1) {
      let supervisorId = "";
      if (this.user.roles[0].name == "Supervisor") {
        supervisorId = this.user.id;
      }

      this.isLoading = true;
      this.employees = [];
      let employees = await axios.get(
        `/monthly_salaries/filter?monthId=${this.month_id}&year=${this.year}&selectedCategoriesArray=${selectedCategoriesArray}&searchText=${this.searchText}&groupId=${this.group}&groupDivisionId=${this.groupDivision}&supervisorId=${supervisorId}`
      );
      this.employees = employees.data.data.selectedUsers;
      this.employees.forEach((user) => {
        let division_category = {};
        let division_categories = user.user_categories.filter(
          (user_category) => user_category.category.category_name == "DIVIS"
        );
        if (division_categories.length > 0)
          division_category = division_categories[0];
        user.division_category = division_category;
      });
      this.count = employees.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
