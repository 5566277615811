<template>
  <span>
    <div class="checkbox checkbox-danger checkbox-circle">
      <input type="checkbox" v-model="value" @change="$emit('update:selection', value)">
      <label for="checkbox">{{ placeholder }}</label>
    </div>
  </span>
</template>

<script type="text/javascript">
export default {
  name: 'MyCheckboxWidget',
  data () {
    return {
      value: ''
    }
  },
  props: ['selection', 'placeholder'],
  watch: {
    'selection': 'getValue',
    'value': 'changeEvent',
  },
  mounted() {
    this.getValue()
  },
  methods: {
    getValue() {
      this.value = this.selection
    },
    changeEvent() {
      this.$emit('change')
    },
  }
}
</script>