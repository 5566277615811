<template>
  <tr v-if="isLoading">
    <td :colspan="cols">
      Loading Data...
    </td>
  </tr>
</template>

<script type="text/javascript">
export default {
  name: 'LoadingTableWidget',
  props: ['isLoading', 'cols']
}
</script>