<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            List of Values
            <save-button @click="save"></save-button>
          </h5>
          <br />
          <my-select
            style="margin: 9px !important"
            @change="searchData"
            :selection.sync="search"
            :suggestions="values"
            placeholder="Select value"
          ></my-select>
          <upload-button link="/settings/value-lists/upload"></upload-button>

          <small v-if="isSaving">Saving...</small>
          <br />
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">List of Values</li>
          </ol>
        </div>

        <!-- /Breadcrumb -->
      </div>
      <br />
      <br />
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="row" style="margin-bottom: 5px">
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <download-excel
                  :title="title"
                  style="float: right"
                  class="btn btn-default"
                  :data="value_list_excel"
                  :fields="excel_fields"
                  worksheet="Value_lists"
                  name="Value_list.xls"
                >
                  <a href="#" class="download">Export to Excel</a>
                </download-excel>
              </div>
            </div>
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="editable-table-wrapper" style="height: 700px">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Description</th>
                          <th>Code</th>
                          <th>Is Active</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(valueList, ar) in valueLists"
                          :key="`valueList${ar}`"
                        >
                          <td>
                            <span
                              @click="deleteValueList(valueList)"
                              v-if="!valueList.id"
                            >
                              <i
                                class="fa fa-times-circle-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                            {{ ar + 1 }}.
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Description"
                              v-model="valueList.description"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Code"
                              v-model="valueList.code"
                            />
                          </td>
                          <td>
                            <my-checkbox
                              :selection.sync="valueList.is_active"
                              placeholder=""
                            ></my-checkbox>
                          </td>
                        </tr>
                        <tr v-if="search">
                          <td
                            colspan="9"
                            align="center"
                            @click="addEmptyValueList()"
                          >
                            Add New Row
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <errors :data="errors" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "Values",
  data() {
    return {
      values: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      search: "",
      valueLists: [],
      excel_fields: {
        "Sr No": "sr_no",
        DESCRIPTION: "description",
        CODE: "code",
      },
      value_list_excel: [],
      isSaving: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("value_lists/masters");
      masters = masters.data;
      masters.values.forEach((value) => {
        this.values.push({
          id: value.id,
          name: value.name,
        });
      });
    },
    async getData(page = 1) {
      this.isLoading = true;
      let valueLists = await axios.get(`/values/${this.search}/value_lists`);
      this.valueLists = valueLists.data.data;
      this.count = valueLists.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
      this.exportToExcel();
    },
    exportToExcel() {
      let value_name = this.values.find((value) => value.id === this.search);
      this.title = "List of " + value_name.name;

      this.value_list_excel = [];
      let sr_no = 1;
      this.valueLists.forEach((valuelist) => {
        this.value_list_excel.push({
          sr_no: sr_no,
          description: valuelist.description,
          code: valuelist.code,
        });
        sr_no++;
      });
    },
    searchData() {
      this.getData(1);
    },
    addEmptyValueList() {
      this.valueLists.push({
        value_id: this.search,
        site_id: this.site.id,
        description: "",
        code: "",
        is_active: 1,
      });
    },
    deleteValueList(valueList) {
      this.valueLists = this.valueLists.filter((ar) => ar != valueList);
    },
    async save() {
      if (this.valueLists.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.valueLists,
        };
        let response = await axios.post(
          `/values/${this.search}/value_lists_multiple`,
          payload
        );
        this.valueLists = response.data.data;
        this.isSaving = false;
      }
    },
  },
};
</script>

<style>
/* th {
} */
</style>
