<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/settings/pt-configurations"></back-button> 
            Update PT Configurations
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/settings/pt-configurations">PT Configurations</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Effective Date</label>
                        <div class="col-sm-9">
                          <my-datepicker :date.sync="form.effective_date" placeholder="Effective Date"></my-datepicker>
                          <span class="error-block">{{ errors.effective_date }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Employer PT</label>
                        <div class="col-sm-5">
                          <input v-uppercase type="text" class="form-control" placeholder="Employer PT" v-model="form.employer_value">
                          <span class="error-block">{{ errors.employer_value }}</span>
                        </div>
                        <div class="col-sm-4">
                          <my-select :selection.sync="form.employer_type" :suggestions="configuration_types" placeholder="Employer PT Type"></my-select>
                          <span class="error-block">{{ errors.employer_type }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Employee PT</label>
                        <div class="col-sm-5">
                          <input v-uppercase type="text" class="form-control" placeholder="Employee PT" v-model="form.employee_value">
                          <span class="error-block">{{ errors.employee_value }}</span>
                        </div>
                        <div class="col-sm-4">
                          <my-select :selection.sync="form.employee_type" :suggestions="configuration_types" placeholder="Employee PT Type"></my-select>
                          <span class="error-block">{{ errors.employee_type }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">States</label>
                        <div class="col-sm-9">
                          <my-select :selection.sync="form.value_list_id" :suggestions="states" placeholder="States"></my-select>
                          <span class="error-block">{{ errors.value_list_id }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-sm-3 control-label"></div>
                        <div class="col-sm-5">
                          <my-checkbox :selection.sync="form.is_january" placeholder="Is January"></my-checkbox>
                        </div>
                        <div class="col-sm-4">
                          <my-checkbox :selection.sync="form.is_february" placeholder="Is February"></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-sm-3 control-label"></div>
                        <div class="col-sm-5">
                          <my-checkbox :selection.sync="form.is_march" placeholder="Is March"></my-checkbox>
                        </div>
                        <div class="col-sm-4">
                          <my-checkbox :selection.sync="form.is_april" placeholder="Is April"></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-sm-3 control-label"></div>
                        <div class="col-sm-5">
                          <my-checkbox :selection.sync="form.is_may" placeholder="Is May"></my-checkbox>
                        </div>
                        <div class="col-sm-4">
                          <my-checkbox :selection.sync="form.is_june" placeholder="Is June"></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-sm-3 control-label"></div>
                        <div class="col-sm-5">
                          <my-checkbox :selection.sync="form.is_july" placeholder="Is July"></my-checkbox>
                        </div>
                        <div class="col-sm-4">
                          <my-checkbox :selection.sync="form.is_august" placeholder="Is August"></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-sm-3 control-label"></div>
                        <div class="col-sm-5">
                          <my-checkbox :selection.sync="form.is_september" placeholder="Is September"></my-checkbox>
                        </div>
                        <div class="col-sm-4">
                          <my-checkbox :selection.sync="form.is_october" placeholder="Is October"></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-sm-3 control-label"></div>
                        <div class="col-sm-5">
                          <my-checkbox :selection.sync="form.is_november" placeholder="Is November"></my-checkbox>
                        </div>
                        <div class="col-sm-4">
                          <my-checkbox :selection.sync="form.is_december" placeholder="Is December"></my-checkbox>
                        </div>
                      </div>
                      <hr>
                      <div class="form-group">
                        <div class="col-sm-3 control-label"></div>
                        <div class="col-sm-5">
                          <my-checkbox :selection.sync="form.is_men" placeholder="Applicable To Man"></my-checkbox>
                        </div>
                        <div class="col-sm-4">
                          <my-checkbox :selection.sync="form.is_women" placeholder="Applicable To Women"></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Min Wage</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="number" class="form-control" placeholder="Min Wage" v-model="form.min_wage">
                          <span class="error-block">{{ errors.min_wage }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Max Wage</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="number" class="form-control" placeholder="Max Wage" v-model="form.max_wage">
                          <span class="error-block">{{ errors.max_wage }}</span>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button :isLoading="isLoading" @submit="save" text="Update"></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'ptConfigurationEdit',
  data () {
    return {
      form: {
        effective_date: new Date(),
        employer_value: 0,
        employer_type: 2,
        employee_value: 0,
        employee_type: 2,
        value_id: 1,
        value_list_id: "",
        min_wage: 0,
        max_wage: 0,
      },
      configuration_types: [
        {id: 1, name: 'Flat',},
        {id: 2, name: '%',},
      ],
      isLoading: false,
      states: [],
    }
  },
  mounted() {
    this.form.site_id = this.site.id
    this.getData()
    this.getStateData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      let form = await axios.get(`/pt_configurations/${this.$route.params.ptConfigurationId}`)
      this.form = form.data.data
      this.isLoading = false
    },
    async getStateData(){
      let masters = await axios.get('pt_configurations/masters')
      masters = masters.data
      masters.states.forEach(state => {
        this.states.push({
          id: state.id,
          name: state.code,
        })
      })
    },
    async save() {
      try {
        this.isLoading = true
        await axios.patch(`/pt_configurations/${this.$route.params.ptConfigurationId}`, this.form)
        this.isLoading = false
        this.$router.push('/settings/pt-configurations')
      } catch(e) {
        this.isLoading = false
      }
    }
  }
}
</script>