<template>
  <section id="mydiv">
    <div class="container-fluid">
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <center><p>COMBINED CHALLAN - A/C NO.1, 2, 3, 4 & 5 </p></center>
            <center><p>STATE BANK OF INDIA </p></center>
            <h5> EMPLOYEE'S PROVIDENT FUND ORGANIZATION</h5>
           <center> <p>(USE SEPERATE CHALLAN FOR EACH MONTH)</p></center>
          </h5>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12">
          <p>Orignal</p>
          <p>Triplicate</p>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12">
          <p>Duplicate</p>
          <p>Quadruplicate</p>
        </div>
      </div>
    </div>
    <br />
    <div class="container-fluid row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p>ESTABLISHMENT CODE NO.: abd/6652563</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p>ACCOUNT GROUP NO:- </p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p>PAID BY CHEQUE/CASH cheque</p>
      </div>
    </div>
    <hr />
    <div class="container-fluid row">
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>DUES FOR THE MONTH OF:</p>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>Employees Share : {{ month_name }} {{ year }}</p>
        <p>Employer Share : {{ month_name }} {{ year }}</p>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>DATE OF PAYMENT: {{ date_of_payment }}</p>
      </div>
    </div>
    <hr />
    <div class="container-fluid row">
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>Total No. Of Subscribers:</p>
        <br />
        <p>Total Wages Due:</p>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <input type="text" name="" id="" value="187">
        <p>A/C No. 1</p>
        <input type="text" name="" id="" value="1834565.00">
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <input type="text" name="" id="" value="185">
        <p>A/C No. 3</p>
        <input type="text" name="" id="" value="1813940.00">
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <input type="text" name="" id="" value="187">
        <p>A/C No. 4</p>
        <input type="text" name="" id="" value="1834565.00">
      </div>
    </div>
    <hr />
    <div class="container-fluid">
      <table class="table table-responsive col-md-10">
        <thead>
          <tr>
            <th>Sr.</th>
            <th>No. PARTICULARS</th>
            <th>A/C NO 1</th>
            <th>A/C NO 2</th>
            <th>A/C NO 3</th>
            <th>A/C NO 4</th>
            <th>A/C NO 5</th>
            <th>TOTAL</th>
            
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PART NO 1</td>
            <td></td>
            <td></td>
            <td colspan="4">---AMOUNT IN (RUPEES)---</td>
          </tr>
          <tr>
            <td>1.</td>
            <td>EMPLOYER'S SHARES OF CONT.</td>
            <td>69027.00</td>
            <td></td>
            <td>151125.00</td>
            <td>9187.00</td>
            <td></td>
            <td>229339.00</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>EMPLOYEE'S SHARES OF CONT.</td>
            <td>220152.00</td>
            <td></td>
            <td>0.00</td>
            <td></td>
            <td></td>
            <td>220152.00</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>ADM. CHARGES</td>
            <td></td>
            <td>9187.00</td>
            <td></td>
            <td></td>
            <td>0.00</td>
            <td>9187.00</td>
          </tr>
          <tr>
            <td>TOTAL</td>
            <td>289179.00</td>
            <td>91187.00</td>
            <td>151125.00</td>
            <td>9187.00</td>
            <td>0.00</td>
            <td>458678.00</td>
          </tr>
        </tbody>
      </table>
     <center> <p>(Amount in words Four Lac Fifty Eight Thousand Six Hundred Seventy Eight Only)</p></center>
    </div>
    <div class="container-fluid row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p>NAME OF ESTABLISHMENT:</p>
        <br />
        <p>Address</p>
        <br />
        <br />
        <br />
        <p>NAME OF THE DEPOSITOR</p>
        <p>SIGNATURE OF THE DEPOSITOR</p>
        <br />
        <br />
        <p>Name Of The Bank: ICICI Bank</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p style="font-size: 20px; font-weight: bold;">V ALLIANCE</p>
        <br />
        <p>Office No-5-A, Raheja Arcade, Sector-11,</p>
        <p>CBD Belapur, Navi Mumbai-4000614.</p>
        <p>Plot no-61</p>
        <p>Navi Mumbai-4000614.</p>
        <br>
        <br>
        <p style="font-size: 18PX; font-weight: bold;">(TO BE FILLED BY EMPLOYER)</p>
        <p>CHEQUE NO</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p>(For Bank Use Only)</p>
        <p>Amount Received Rs:</p>
        <p>For Cheque Only:</p>
        <p>Date Of Peresentation:</p>
        <br />
        <br />
        <p>Date Of Realization:</p>
        <p>Branch Name:</p>
        <p>DATE: 12/04/2021</p>
      </div>
    </div>
    <hr>
    <div class="container-fluid">
    <center><p style="font-size: 20px; font-weight: bold;"> FOR USE IN REPECT OF PAST ACCUMULATIONS ONLY</p></center>
      <table class="table table-responsive col-md-10">
        <thead>
          <tr>
            <th>S. No.  PARTICULARS</th>
            <th>A/C NO 1</th>
            <th>A/C NO 2</th>
            <th>A/C NO 3</th>
            <th>A/C NO 4</th>
            <th>A/C NO 5</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PART NO 2 </td>
            <td></td>
            <td></td>
            <td colspan="4">---AMOUNT IN (RUPEES)---</td>
          </tr>
          <tr>
            <td>1 EER'S SHARE OF PAST ACCUM.</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>2. EES' SHARE OF PAST ACCUM.</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>3. INT. ON SECURITES</td>
           <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
         
          <tr>
            <td>TOTAL</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import html2pdf from 'html2pdf.js'
import moment from 'moment'

export default {
  name: 'PDFChallan',
  data () {
    return {
      month_id: '',
      month_name: '',
      year: '',
      date_of_payment: '',
    }
  },
  mounted: function () {
    this.month_id = this.$route.query.monthId
    this.month_name = moment(this.month_id, "M").format("MMMM")
    this.year = this.$route.query.year
    this.date_of_payment = this.$route.query.date
    // this.createPDF()
  },
  methods:{
    createPDF() {
      
      var element = document.getElementById('mydiv');

      var opt = {
        margin: 0,
        filename: 'PFChallan.pdf',
        image: {type: 'jpeg',quality: 0.98},
        html2canvas: {scale: 2},
        jsPDF: {
          unit: 'mm',
          format: [280, 350],
          orientation: 'portrait'
        }
      };

      html2pdf().set(opt).from(element).save();
    },
  }
}
</script>
