<template>
  <section id="mydiv">
    <div class="container-fluid">
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">LOGO</h5>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12"></div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <h3>V ALLIANCE</h3>
            <p>{{ site.address }}</p>
          </h5>
        </div>
      </div>
    </div>
    <br />
    <div class="container-fluid row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <br />
        <p>EMP Code : {{ SalaryUser.employee_id }}</p>
        <p>ESIC No : {{ SalaryUser.esi_number }}</p>
        <p>Joining Date : {{ SalaryUser.joined_on }}</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p>Payslip for the month :{{ month_name }} {{ year }}</p>
        <p>Name : {{ SalaryUser.full_name }}</p>
        <p>Department : {{ department_category ? department_category : "" }}</p>
        <p>UAN No : {{ SalaryUser.uan_no }}</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p>Branch : {{ branch_category ? branch_category : "" }}</p>
        <p>
          Designation : {{ designation_category ? designation_category : "" }}
        </p>
        <p>
          Division :
          {{
            SalaryUser.group_division
              ? SalaryUser.group_division.division_name
              : ""
          }}
        </p>
        <p>PF No : {{ SalaryUser.pf_no }}</p>
      </div>
    </div>
    <hr />
    <div class="container-fluid row">
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>Days Paid : {{ MonthlySalary.payable_days }}</p>
        <p>OT hours : {{ MonthlySalary.ot_hrs }}</p>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>Days Peresent : {{ MonthlySalary.days_present }}</p>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>W.Off/Pd.Off : 4.00/0.00</p>
        <p>PL : 0.00</p>
        <p>Bal PL No : 0.00</p>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>LWP/Absent : 0.00/0.00</p>
      </div>
    </div>
    <hr />
    <div class="container-fluid">
      <table class="table table-responsive col-md-10">
        <thead>
          <tr>
            <th>Earning & Reimbursements</th>
            <th>Gross Amt</th>
            <th>Actual Amt</th>
            <th>Deduction & Recoveries</th>
            <th>Gross Amt</th>
            <th>Actual Amt</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Basic Salary</td>
            <td>{{ user_salary.fixed_basic }}</td>
            <td>{{ user_salary.fixed_basic }}</td>
            <td>PROV. FUND</td>
            <td>{{ user_salary.employee_pf }}</td>
            <td>{{ user_salary.employee_pf }}</td>
          </tr>
          <tr>
            <td>Conveyance Allowance</td>
            <td>{{ user_salary.fixed_conveyance }}</td>
            <td>{{ user_salary.fixed_conveyance }}</td>
            <td>E.S.I.C</td>
            <td>{{ user_salary.employee_esic }}</td>
            <td>{{ user_salary.employee_esic }}</td>
          </tr>
          <tr>
            <td>Other Allowance</td>
            <td>{{ user_salary.fixed_other_allowance }}</td>
            <td>{{ user_salary.fixed_other_allowance }}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Leave Encashment</td>
            <td>{{ user_salary.leave_encashment }}</td>
            <td>{{ user_salary.leave_encashment }}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>OVER TIME</td>
            <td>{{ user_salary.ot_rate }}</td>
            <td>{{ user_salary.ot_rate }}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th>Total Earnings</th>
            <th>
              {{
                user_salary.fixed_basic +
                  user_salary.fixed_conveyance +
                  user_salary.fixed_other_allowance +
                  user_salary.leave_encashment +
                  user_salary.ot_rate
              }}
            </th>
            <th>
              {{
                user_salary.fixed_basic +
                  user_salary.fixed_conveyance +
                  user_salary.fixed_other_allowance +
                  user_salary.leave_encashment +
                  user_salary.ot_rate
              }}
            </th>
            <th>
              <p>Total Deductions</p>
              <p>Net Pay</p>
            </th>
            <th>
              {{ user_salary.employee_esic + user_salary.employee_pf }}
            </th>
            <th>
              <p>
                {{
                  user_salary.employee_pf +
                    user_salary.employee_esic +
                    user_salary.employee_mlwf +
                    user_salary.employee_pt
                }}
              </p>
              <p>{{ user_salary.net_in_hand }}</p>
            </th>
          </tr>
        </tbody>
      </table>
      <p>Net Pay: {{ user_salary.NetinHandinWords }} Only</p>
      <p>
        *This is computer generated salary slip and requires no physical
        signature or stamp*
      </p>
      <p>
        Bank Name: {{ SalaryUser.bank_name.name }} - Bank A/c No. :
        {{ SalaryUser.bank_acc_no }}
      </p>
    </div>
  </section>
</template>

<script type="text/javascript">
import moment from "moment";

export default {
  data() {
    return {
      user_salary: {},
      month_id: "",
      month_name: "",
      year: "",
      department_category: "",
      branch_category: "",
      designation_category: "",
    };
  },
  props: [
    "form",
    "isLoading",
    "configurations",
    "SalaryUser",
    "MonthlySalary"
  ],
  watch: {
    form: "getCurrentSalary",
  },
  mounted() {
    this.getUser();
    this.month_id = this.MonthlySalary.month_id;
    this.month_name = moment(this.month_id, "M").format("MMMM");
    this.year = moment().format("Y");
  },
  methods: {
    updateMonth() {
      this.month_name = moment(this.from_Date, "M").format("MMMM");

      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    getUser() {
      this.user_salary = Object.assign({}, this.form);
      // Branch
      this.branch_category = {};
      let branch_categories = this.SalaryUser.user_categories.filter(
        (user_category) => user_category.category.category_name == "BRANCH"
      );
      if (branch_categories.length > 0)
        this.branch_category = branch_categories[0];
      this.branch_category = this.branch_category.category_list
        ? this.branch_category.category_list.code
        : "";
      // Grade
      this.grade_category = {};
      let grade_categories = this.SalaryUser.user_categories.filter(
        (user_category) => user_category.category.category_name == "GRADE"
      );
      if (grade_categories.length > 0)
        this.grade_category = grade_categories[0];
      this.grade_category = this.grade_category.category_list
        ? this.grade_category.category_list.code
        : "";
      // Department
      this.department_category = {};
      let department_categories = this.SalaryUser.user_categories.filter(
        (user_category) => user_category.category.category_name == "DEPARTMENT"
      );
      if (department_categories.length > 0)
        this.department_category = department_categories[0];
      this.department_category = this.department_category.category_list
        ? this.department_category.category_list.code
        : "";
      // Category
      this.category_category = {};
      let category_categories = this.SalaryUser.user_categories.filter(
        (user_category) => user_category.category.category_name == '"CATEGORY"'
      );
      if (category_categories.length > 0)
        this.category_category = category_categories[0];
      this.category_category = this.category_category.category_list
        ? this.category_category.category_list.code
        : "";
      // Designation
      this.designation_category = {};
      let designation_categories = this.SalaryUser.user_categories.filter(
        (user_category) => user_category.category.category_name == "DESIGNATION"
      );
      if (designation_categories.length > 0)
        this.designation_category = designation_categories[0];
      this.designation_category = this.designation_category.category_list
        ? this.designation_category.category_list.code
        : "";
      // this.payable_days = this.SalaryUser.monthly_salary;
    },
  },
};
</script>
