<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Payroll Process
            <save-button @click="save"></save-button>
            &nbsp;
            <upload-button
              link="/main/workflows/monitor/payroll/upload"
            ></upload-button>
            &nbsp;
            <refresh-button @click="refreshData"></refresh-button>
            &nbsp;
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Payroll</li>
            <li class="active">Payroll Process</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      <small v-if="isSaving">Saving...</small>
      <!-- Filter -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      :search="searchText"
                      placeholder="Search..."
                      @searchData="search"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select month"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select year"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      :selection.sync="groupDivision"
                      :suggestions="groupDivisions"
                      :placeholder="`Select DIVISION`"
                    >
                    </my-select>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-3"
                    v-for="(category, c) in categories"
                    :key="`category${c}`"
                  >
                    <my-select
                      style="margin: 9px !important"
                      @change="selectCategory"
                      :selection.sync="category.selectedValue"
                      :suggestions="category.active_category_lists"
                      :placeholder="`Select ${category.category_name}`"
                      multiple="true"
                    >
                    </my-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-2">
                    <my-checkbox
                      :selection.sync="isGenerateSalaryForAll"
                      placeholder="Generate Salary For All"
                      @change="toggleGenerateSalaryForAll"
                    ></my-checkbox>
                  </div>
                  <div class="col-md-1">
                    <button
                      class="btn btn-primary btn-sm pull-right"
                      @click="searchData"
                    >
                      SEARCH
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Filter -->
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="row" style="margin-bottom: 5px">
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <download-excel
                  :title="title"
                  class="btn btn-default btn-sm pull-right"
                  :data="attendances"
                  :fields="excel_fields"
                  worksheet="Attendances"
                  name="Payroll.xls"
                >
                  <a href="#" class="download">Export to Excel</a>
                </download-excel>
              </div>
            </div>
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="outer">
                    <div class="inner" style="height: 600px">
                      <table class="table table-striped" id="navigate">
                        <thead>
                          <tr>
                            <th class="first-col">Sr. No.</th>
                            <th class="second-col">Emp Code</th>
                            <th class="third-col">Name</th>
                            <th class="fourth-col">Gross Salary</th>
                            <th>OT Rate</th>
                            <th>Total Days</th>
                            <th>Absent Days</th>
                            <th>PL</th>
                            <!-- <th>Extra Days</th> -->
                            <th>Payable Days</th>
                            <th>OT Hrs</th>
                            <th>OT Amount</th>
                            <th>Reimbursement</th>
                            <th>PF Wages</th>
                            <th>ESIC Wages</th>
                            <th>Total Earning</th>
                            <th>PF</th>
                            <th>ESIC</th>
                            <th>PT</th>
                            <th>LWF</th>
                            <th>TDS</th>
                            <th>Loan</th>
                            <th>Salary Advance</th>
                            <th>Life Insurance</th>
                            <th>Uniform</th>
                            <th>Mobile</th>
                            <th>Accident Insurance</th>
                            <th>Mediclaim</th>
                            <th>Shoes</th>
                            <th>ID Card</th>
                            <th>Penalty</th>
                            <th>Others</th>
                            <th>Total Deductions</th>
                            <th>Total Payable</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <loading-table
                            :isLoading="isLoading"
                            cols="34"
                          ></loading-table>
                          <tr
                            v-for="(user, us) in users"
                            :key="`user${us}`"
                            :class="
                              user.monthly_salary.is_salary_generated
                                ? 'salary_generated disabled'
                                : ''
                            "
                          >
                            <td
                              :class="
                                user.monthly_salary.is_salary_generated
                                  ? 'first-r disabled'
                                  : ''
                              "
                            >
                              <my-checkbox
                                :selection.sync="
                                  user.monthly_salary.is_salary_generated
                                "
                                :placeholder="`${us + 1}.`"
                                @change="changeSalaryStatus(user)"
                              ></my-checkbox>
                            </td>
                            <td
                              :class="
                                user.monthly_salary.is_salary_generated
                                  ? 'second-r disabled'
                                  : ''
                              "
                            >
                              {{ user.employee_id }}
                            </td>
                            <td
                              :class="
                                user.monthly_salary.is_salary_generated
                                  ? 'third-r disabled'
                                  : ''
                              "
                            >
                              {{ user.full_name }}
                            </td>
                            <td
                              :class="
                                user.monthly_salary.is_salary_generated
                                  ? 'fourth-r disabled'
                                  : ''
                              "
                            >
                              {{
                                user.salary
                                  ? "₹" + user.salary.gross_salary
                                  : ""
                              }}
                            </td>
                            <td
                              :class="
                                user.monthly_salary.is_salary_generated
                                  ? 'fifth-r disabled'
                                  : ''
                              "
                            >
                              {{ user.salary ? "₹" + user.salary.ot_rate : "" }}
                            </td>
                            <td>
                              <input
                                type="number"
                                :id="`${us + 1}`"
                                v-model="user.monthly_salary.days_present"
                                @change="updateDays(user, 'PRESENT')"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.days_absent"
                                @change="updateDays(user, 'ABSENT')"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.paid_leave"
                                @change="updateDays(user, 'LEAVE')"
                              />
                            </td>
                            <!-- <td>
                              <input type="number" v-model="user.monthly_salary.extra_days" @change="updateDays(user)">
                            </td> -->
                            <td>₹{{ user.monthly_salary.payable_days }}</td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.ot_hrs"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>₹{{ user.monthly_salary.ot_amount }}</td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.reimbursement"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>₹{{ user.monthly_salary.earned_pf_wages }}</td>
                            <td>
                              ₹{{ user.monthly_salary.earned_esic_wages }}
                            </td>
                            <td>₹{{ user.monthly_salary.total_earnings }}</td>
                            <td>₹{{ user.monthly_salary.employee_pf }}</td>
                            <td>₹{{ user.monthly_salary.employee_esic }}</td>
                            <td>₹{{ user.monthly_salary.employee_pt }}</td>
                            <td>₹{{ user.monthly_salary.employee_lwf }}</td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.tds"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.loan"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.salary_advance"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.lic"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.uniform"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.mobile"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.accidental"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.mediclaim"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.shoes"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.id_card"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.penalty"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="user.monthly_salary.others"
                                @change="updateDays(user)"
                              />
                            </td>
                            <td>₹{{ user.monthly_salary.total_deductions }}</td>
                            <td>₹{{ user.monthly_salary.total_payable }}</td>
                            <td>
                              <input
                                v-uppercase
                                style="width: 100px"
                                type="text"
                                v-model="user.monthly_salary.remarks"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <errors :data="errors" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import moment from "moment";
import $ from "jquery";

export default {
  name: "MonthlySalary",
  data() {
    return {
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
      ],
      configurations: [],
      categories: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      title: "",
      isGenerateSalaryForAll: false,
      excel_fields: {
        "Sr No": "sr_no",
        "Emp Code": "employee_code",
        "Division Name": "division_name",
        "Employee Name": "employee_name",
        "Father Name": "father_name",
        "Designation Name": "designation_name",
        "Date Joined": "date_joined",
        "Date of Birth": "date_of_birth",
        Gender: "gender",
        "UAN Number": "uan_number",
        "PF Number": "pf_number",
        "Adhaar Number": "adhaar_number",
        "ESIC Number": "esic_number",
        // 'ESIC Flag': 'esic_flag',
        "Account No": "account_no",
        "IFSC Code": "ifsc_code",
        "Bank Name": "bank_name",
        Mode: "mode",
        "OT Rate": "ot_rate",
        "Gross Salary": "gross_salary",
        "Total Days": "total_days",
        "Days Present": "days_present",
        "Paid Leave": "paid_leave",
        "Absent Days": "absent_days",
        "Payable Days": "payable_days",
        "OT Hrs": "ot_hrs",
        "OT Amount": "ot_amount",
        Reimbursement: "reimbursement",
        // 'Incentive': 'incentive',
        // 'Attenance Bonus': 'attendance_bonus',
        Basic: "basic",
        "D.A.": "da",
        HRA: "hra",
        "Conveyance Allowance": "conveyance_allowance",
        CCA: "cca",
        Medical: "medical",
        Special: "special",
        "Mobile Allowance": "mobile_allowance",
        "Other Allowance": "other_allowance",
        "Washing Allowance": "washing_allowance",
        "Leave Encashment": "leave_encashment",
        Bonus: "bonus",
        "PF Wages": "pf_wages",
        "ESIC Wages": "esic_wages",
        "Total Earning": "total_earning",
        PF: "pf",
        ESIC: "employee_esic",
        PT: "employee_pt",
        LWF: "employee_lwf",
        TDS: "tds",
        Loan: "loan",
        "Salary Advance": "salary_advance",
        "Life Insurance": "lic",
        Uniform: "uniform",
        Mobile: "mobile",
        "Accident Insurance": "accidental",
        Mediclaim: "mediclaim",
        Shoes: "shoes",
        "ID Card": "id_card",
        Penalty: "penalty",
        Others: "others",
        "Total Deductions": "total_deductions",
        "Total Payable": "total_payable",
        Branch: "branch",
        State: "state",
        "Group Name": "group_name",
        Remarks: "remarks",
        "Employer PF": "employer_pf",
        "Employer ESIC": "employer_esic",
        "Employer LWF": "employer_lwf",
        EBonus: "e_bonus",
        "Leave Salary": "leave_salary",
      },
      attendances: [],
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();
    // this.keyBinding();

    this.month_id = moment().format("M");
    this.month_name = moment().format("MMMM");
    this.year = moment().format("Y");

    this.fixColumns();
  },
  methods: {
    fixColumns() {},
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      // this.searchData()
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
      // this.searchData()
    },
    selectCategory() {
      this.isGenerateSalaryForAll = false;
    },

    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.categories.forEach((category) => {
        category.selectedValue = "";
      });
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData() {
      let selectedCategoriesArray = [];
      this.categories.forEach((category) => {
        selectedCategoriesArray.push(
          category.id + "-" + category.selectedValue
        );
      });
      this.getData(selectedCategoriesArray);
    },
    async getData(selectedCategoriesArray = []) {
      try {
        this.isLoading = true;
        this.users = [];
        this.salaryGeneratedUsers = [];
        let users = await axios.get(
          `/monthly_salaries/filter?monthId=${this.month_id}&year=${this.year}&selectedCategoriesArray=${selectedCategoriesArray}&searchText=${this.searchText}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        // console.log(users);
        this.users = [];
        for (const user of users.data.data.selectedUsers) {
          if (
            user.employee_status == null ||
            (user.employee_status.code != "LEFT" &&
              user.employee_status.code != "TERMINATE")
          ) {
            this.users.push(user);
            if (user.monthly_salary == null) {
              this.initializeEmptyMonthlySalary(user);
            } else {
              this.getEarnedSalaryComponents(user);
            }
          }
        }
        // this.salaryGeneratedUsers = [];
        // for (const user of users.data.data.salaryGeneratedUsers) {
        //   if (
        //     user.employee_status == null ||
        //     (user.employee_status.code != "LEFT" &&
        //       user.employee_status.code != "TERMINATE")
        //   ) {
        //     this.users.push(user);
        //     if (user.monthly_salary == null) {
        //       this.initializeEmptyMonthlySalary(user);
        //     } else {
        //       this.getEarnedSalaryComponents(user);
        //     }
        //   }
        // }
        this.exportToExcel();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async save() {
      this.isSaving = true;
      let monthly_salaries = [];
      this.users.forEach((user) => {
        monthly_salaries.push(user.monthly_salary);
      });
      let payload = {
        monthly_salaries: monthly_salaries,
      };
      await axios.post(`/monthly_salaries/multiple`, payload);
      this.isSaving = false;
    },
    initializeEmptyMonthlySalary(user) {
      user.monthly_salary = {
        is_salary_generated: this.isGenerateSalaryForAll,
        employee_id: user.id,
        month_id: this.month_id,
        year: this.year,
        days_present: moment(
          this.year + "-" + this.month_id,
          "YYYY-M"
        ).daysInMonth(),
        paid_leave: 0,
        days_absent: 0,
        extra_days: 0,
        payable_days: 0,
        ot_hrs: 0,
        ot_amount: 0,
        reimbursement: 0,
        earned_basic: 0,
        earned_da: 0,
        earned_conveyance: 0,
        earned_cca: 0,
        earned_medical: 0,
        earned_special: 0,
        earned_mobile_allowance: 0,
        earned_other_allowance: 0,
        earned_washing_allowance: 0,
        earned_leave_encashment: 0,
        earned_bonus: 0,
        earned_pf_wages: 0,
        earned_esic_wages: 0,
        total_earnings: 0,
        employee_pf: 0,
        employee_esic: 0,
        employee_pt: 0,
        employee_lwf: 0,
        tds: 0,
        loan: 0,
        salary_advance: 0,
        lic: 0,
        uniform: 0,
        mobile: 0,
        accidental: 0,
        mediclaim: 0,
        shoes: 0,
        id_card: 0,
        penalty: 0,
        others: 0,
        total_deductions: 0,
        total_payable: 0,
        remarks: "",
      };
      this.updateDays(user, "PRESENT");
    },
    updateDays(user, inputColumn = "") {
      let totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();

      let fixed_basic = user.salary ? parseFloat(user.salary.fixed_basic) : 0;
      let fixed_da = user.salary ? parseFloat(user.salary.fixed_da) : 0;
      let fixed_hra = user.salary ? parseFloat(user.salary.fixed_hra) : 0;
      let gross_salary = user.salary ? parseFloat(user.salary.gross_salary) : 0;
      let ot_rate = user.salary ? parseFloat(user.salary.ot_rate) : 0;

      let fixed_conveyance = user.salary
        ? parseFloat(user.salary.fixed_conveyance)
        : 0;
      let fixed_cca = user.salary ? parseFloat(user.salary.fixed_cca) : 0;
      let fixed_medical = user.salary
        ? parseFloat(user.salary.fixed_medical_allowance)
        : 0;
      let fixed_special = user.salary
        ? parseFloat(user.salary.fixed_special_allowance)
        : 0;
      let fixed_mobile_allowance = user.salary
        ? parseFloat(user.salary.fixed_mobile_allowance)
        : 0;
      let fixed_other_allowance = user.salary
        ? parseFloat(user.salary.fixed_other_allowance)
        : 0;
      let fixed_washing_allowance = user.salary
        ? parseFloat(user.salary.fixed_washing_allowance)
        : 0;
      let fixed_leave_encashment = user.salary
        ? parseFloat(user.salary.leave_encashment)
        : 0;
      let fixed_bonus = user.salary ? parseFloat(user.salary.bonus) : 0;

      let days_present = parseFloat(user.monthly_salary.days_present);
      days_present =
        days_present >= totalDaysInMonth ? totalDaysInMonth : days_present;
      let paid_leave = parseFloat(user.monthly_salary.paid_leave);
      let days_absent = parseFloat(user.monthly_salary.days_absent);
      let extra_days = parseFloat(user.monthly_salary.extra_days);
      let payable_days = parseFloat(user.monthly_salary.payable_days);

      let ot_hrs = parseFloat(user.monthly_salary.ot_hrs);
      let ot_amount = parseFloat(user.monthly_salary.ot_amount);
      let reimbursement = parseFloat(user.monthly_salary.reimbursement);
      let total_earnings = parseFloat(user.monthly_salary.total_earnings);

      let earned_basic = 0;
      let earned_da = 0;
      let earned_hra = 0;
      let earned_conveyance = 0;
      let earned_cca = 0;
      let earned_medical = 0;
      let earned_special = 0;
      let earned_mobile_allowance = 0;
      let earned_other_allowance = 0;
      let earned_washing_allowance = 0;
      let earned_leave_encashment = 0;
      let earned_bonus = 0;

      let pf_wages = fixed_basic + fixed_da;
      if (user.group_division) {
        if (user.group_division.applicable_on == 1)
          pf_wages =
            gross_salary -
            fixed_hra -
            // reimbursement -
            fixed_leave_encashment -
            fixed_bonus;
        else if (user.group_division.applicable_on == 2)
          pf_wages = fixed_basic + fixed_da;
      } else if (user.group) {
        if (user.group.applicable_on == 1)
          pf_wages =
            gross_salary -
            fixed_hra -
            // reimbursement -
            fixed_leave_encashment -
            fixed_bonus;
        else if (user.group.applicable_on == 2)
          pf_wages = fixed_basic + fixed_da;
      }

      let earned_pf_wages = 0;
      let earned_esic_wages = 0;
      let earned_lwf_wages = 0;
      let earned_pt_wages = 0;

      let employee_pf = 0;
      let employee_pf_value = parseFloat(
        this.configurations.pf_configuration
          ? this.configurations.pf_configuration.employee_value
          : 0
      );
      let employee_pf_type = parseFloat(
        this.configurations.pf_configuration.employee_type
      );
      let employee_pf_max_wage = parseFloat(
        this.configurations.pf_configuration.max_wage
      );

      let employee_esic = 0;
      let employee_esic_value = parseFloat(
        this.configurations.esic_configuration
          ? this.configurations.esic_configuration.employee_value
          : 0
      );
      let employee_esic_type = parseFloat(
        this.configurations.esic_configuration.employee_type
      );
      let employee_esic_max_wage = parseFloat(
        this.configurations.esic_configuration.max_wage
      );

      let gender = user.gender;
      let employee_pt = 0;
      let pt_configurations = [];
      if (user.group_division)
        pt_configurations = this.configurations.pt_configurations.filter(
          (pt_configuration) =>
            pt_configuration.value_list_id == user.group_division.state_id
        );
      else if (user.group)
        pt_configurations = this.configurations.pt_configurations.filter(
          (pt_configuration) =>
            pt_configuration.value_list_id == user.group.state_id
        );
      else pt_configurations = [];

      let employee_lwf = 0;
      let lwf_configurations = [];
      if (user.group_division)
        lwf_configurations = this.configurations.lwf_configurations.filter(
          (lwf_configuration) =>
            lwf_configuration.value_list_id == user.group_division.state_id
        );
      else if (user.group)
        lwf_configurations = this.configurations.lwf_configurations.filter(
          (lwf_configuration) =>
            lwf_configuration.value_list_id == user.group.state_id
        );
      else lwf_configurations = [];
      let lwf_configuration =
        lwf_configurations.length > 0 ? lwf_configurations[0] : {};
      let employee_lwf_value = parseFloat(
        lwf_configuration ? lwf_configuration.employee_value : 0
      );
      let employee_lwf_type = parseFloat(lwf_configuration.employee_type);
      // let employee_lwf_max_wage = parseFloat(lwf_configuration.max_wage)
      let lwf_is_january = lwf_configuration.is_january;
      let lwf_is_february = lwf_configuration.is_february;
      let lwf_is_march = lwf_configuration.is_march;
      let lwf_is_april = lwf_configuration.is_april;
      let lwf_is_may = lwf_configuration.is_may;
      let lwf_is_june = lwf_configuration.is_june;
      let lwf_is_july = lwf_configuration.is_july;
      let lwf_is_august = lwf_configuration.is_august;
      let lwf_is_september = lwf_configuration.is_september;
      let lwf_is_october = lwf_configuration.is_october;
      let lwf_is_november = lwf_configuration.is_november;
      let lwf_is_december = lwf_configuration.is_december;

      let tds = parseFloat(user.monthly_salary.tds);
      let loan = parseFloat(user.monthly_salary.loan);
      let salary_advance = parseFloat(user.monthly_salary.salary_advance);
      let lic = parseFloat(user.monthly_salary.lic);
      let uniform = parseFloat(user.monthly_salary.uniform);
      let mobile = parseFloat(user.monthly_salary.mobile);
      let accidental = parseFloat(user.monthly_salary.accidental);
      let mediclaim = parseFloat(user.monthly_salary.mediclaim);
      let shoes = parseFloat(user.monthly_salary.shoes);
      let id_card = parseFloat(user.monthly_salary.id_card);
      let penalty = parseFloat(user.monthly_salary.penalty);
      let others = parseFloat(user.monthly_salary.others);
      let total_deductions = 0;
      let total_payable = 0;

      if (inputColumn == "PRESENT") {
        // user.monthly_salary.is_salary_generated = true
        days_absent = totalDaysInMonth - (days_present + paid_leave);
      }
      if (inputColumn == "ABSENT")
        days_present = totalDaysInMonth - (paid_leave + days_absent);
      if (inputColumn == "LEAVE") days_absent -= paid_leave;
      // console.log(inputColumn);
      payable_days = days_present + paid_leave + extra_days;

      ot_amount = ot_hrs * ot_rate;
      total_earnings =
        (payable_days * gross_salary) / totalDaysInMonth +
        ot_amount +
        reimbursement;

      earned_basic = (fixed_basic / totalDaysInMonth) * payable_days;
      earned_da = (fixed_da / totalDaysInMonth) * payable_days;
      earned_hra = (fixed_hra / totalDaysInMonth) * payable_days;
      earned_conveyance = (fixed_conveyance / totalDaysInMonth) * payable_days;
      earned_cca = (fixed_cca / totalDaysInMonth) * payable_days;
      earned_medical = (fixed_medical / totalDaysInMonth) * payable_days;
      earned_special = (fixed_special / totalDaysInMonth) * payable_days;
      earned_mobile_allowance =
        (fixed_mobile_allowance / totalDaysInMonth) * payable_days;
      earned_other_allowance =
        (fixed_other_allowance / totalDaysInMonth) * payable_days;
      earned_washing_allowance =
        (fixed_washing_allowance / totalDaysInMonth) * payable_days;
      earned_leave_encashment =
        (fixed_leave_encashment / totalDaysInMonth) * payable_days;
      earned_bonus = (fixed_bonus / totalDaysInMonth) * payable_days;

      // Employee PF
      if (user.is_pf_applicable == 1) {
        if (employee_pf_type == 1) employee_pf = employee_pf_value;
        if (employee_pf_type == 2) {
          earned_pf_wages = (payable_days * pf_wages) / totalDaysInMonth;
          earned_pf_wages =
            earned_pf_wages > employee_pf_max_wage
              ? employee_pf_max_wage
              : earned_pf_wages;
          employee_pf = (employee_pf_value * earned_pf_wages) / 100;
        }
        employee_pf = Math.round(employee_pf);
      }

      // Employee ESIC
      if (user.is_esi_applicable == 1) {
        // earned_esic_wages = total_earnings != 0 ? Math.ceil(total_earnings - reimbursement - fixed_washing_allowance) : 0
        earned_esic_wages =
          total_earnings != 0
            ? total_earnings - reimbursement - fixed_washing_allowance
            : 0;
        if (employee_esic_type == 1)
          employee_esic =
            gross_salary > employee_esic_max_wage ? 0 : employee_esic_value;
        if (employee_esic_type == 2) {
          earned_esic_wages =
            gross_salary > employee_esic_max_wage ? 0 : earned_esic_wages;
          employee_esic = (employee_esic_value * earned_esic_wages) / 100;
        }
        employee_esic = Math.ceil(employee_esic);
      }

      // Employee PT
      if (user.is_pt_applicable == 1) {
        earned_pt_wages = Math.round(total_earnings);
        pt_configurations.forEach((pt_configuration) => {
          if (
            (earned_pt_wages > pt_configuration.min_wage &&
              earned_pt_wages < pt_configuration.max_wage) ||
            (earned_pt_wages > pt_configuration.min_wage &&
              pt_configuration.max_wage == 0)
          ) {
            if (
              (pt_configuration.is_men && gender == 0) ||
              (pt_configuration.is_women && gender == 1)
            )
              if (
                (this.month_id == 1 && pt_configuration.is_january == 1) ||
                (this.month_id == 2 && pt_configuration.is_february == 1) ||
                (this.month_id == 3 && pt_configuration.is_march == 1) ||
                (this.month_id == 4 && pt_configuration.is_april == 1) ||
                (this.month_id == 5 && pt_configuration.is_may == 1) ||
                (this.month_id == 6 && pt_configuration.is_june == 1) ||
                (this.month_id == 7 && pt_configuration.is_july == 1) ||
                (this.month_id == 8 && pt_configuration.is_august == 1) ||
                (this.month_id == 9 && pt_configuration.is_september == 1) ||
                (this.month_id == 10 && pt_configuration.is_october == 1) ||
                (this.month_id == 11 && pt_configuration.is_november == 1) ||
                (this.month_id == 12 && pt_configuration.is_december == 1)
              )
                if (pt_configuration.employee_type == 1)
                  employee_pt = pt_configuration.employee_value;
                else
                  employee_pt = Math.round(
                    (pt_configuration.employee_value * this.gross) / 100
                  );
          }
        });
      }

      // Employee LWF
      if (user.is_lwf_applicable == 1) {
        earned_lwf_wages = total_earnings - reimbursement;
        if (
          (this.month_id == 1 && lwf_is_january == 1) ||
          (this.month_id == 2 && lwf_is_february == 1) ||
          (this.month_id == 3 && lwf_is_march == 1) ||
          (this.month_id == 4 && lwf_is_april == 1) ||
          (this.month_id == 5 && lwf_is_may == 1) ||
          (this.month_id == 6 && lwf_is_june == 1) ||
          (this.month_id == 7 && lwf_is_july == 1) ||
          (this.month_id == 8 && lwf_is_august == 1) ||
          (this.month_id == 9 && lwf_is_september == 1) ||
          (this.month_id == 10 && lwf_is_october == 1) ||
          (this.month_id == 11 && lwf_is_november == 1) ||
          (this.month_id == 12 && lwf_is_december == 1)
        )
          if (employee_lwf_type == 1)
            // employee_lwf = earned_lwf_wages > employee_lwf_max_wage ? 0 : employee_lwf_value
            employee_lwf = earned_lwf_wages == 0 ? 0 : employee_lwf_value;
          else {
            // earned_lwf_wages = earned_lwf_wages > employee_lwf_max_wage ? 0 : earned_lwf_wages
            employee_lwf = (employee_lwf_value * earned_lwf_wages) / 100;
          }
      }

      total_deductions =
        employee_pf +
        employee_esic +
        employee_pt +
        employee_lwf +
        tds +
        loan +
        salary_advance +
        lic +
        uniform +
        mobile +
        accidental +
        mediclaim +
        shoes +
        id_card +
        penalty +
        others;

      total_payable = total_earnings - total_deductions;

      user.monthly_salary.days_present = days_present;
      user.monthly_salary.paid_leave = paid_leave;
      user.monthly_salary.days_absent = days_absent;
      user.monthly_salary.payable_days = payable_days;
      user.monthly_salary.ot_amount = Math.round(ot_amount);
      user.monthly_salary.total_earnings = Math.round(total_earnings);
      user.monthly_salary.earned_basic = Math.round(earned_basic);
      user.monthly_salary.earned_da = Math.round(earned_da);
      user.monthly_salary.earned_hra = Math.round(earned_hra);
      user.monthly_salary.earned_conveyance = Math.round(earned_conveyance);
      user.monthly_salary.earned_cca = Math.round(earned_cca);
      user.monthly_salary.earned_medical = Math.round(earned_medical);
      user.monthly_salary.earned_special = Math.round(earned_special);
      user.monthly_salary.earned_mobile_allowance = Math.round(
        earned_mobile_allowance
      );
      user.monthly_salary.earned_other_allowance = Math.round(
        earned_other_allowance
      );
      user.monthly_salary.earned_washing_allowance = Math.round(
        earned_washing_allowance
      );
      user.monthly_salary.earned_leave_encashment = Math.round(
        earned_leave_encashment
      );
      user.monthly_salary.earned_bonus = Math.round(earned_bonus);
      // user.monthly_salary.earned_pf_wages = (earned_pf_wages).toFixed(2)
      user.monthly_salary.earned_pf_wages = Math.round(earned_pf_wages);
      user.monthly_salary.earned_esic_wages = Math.round(earned_esic_wages);
      user.monthly_salary.employee_pf = Math.round(employee_pf);
      user.monthly_salary.employee_esic = Math.ceil(employee_esic);
      user.monthly_salary.employee_pt = Math.round(employee_pt);
      user.monthly_salary.employee_lwf = Math.round(employee_lwf);
      user.monthly_salary.total_deductions = Math.round(total_deductions);
      user.monthly_salary.total_payable = Math.round(total_payable);
    },

    getEarnedSalaryComponents(user) {
      let totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();

      let fixed_basic = user.salary ? parseFloat(user.salary.fixed_basic) : 0;
      let fixed_da = user.salary ? parseFloat(user.salary.fixed_da) : 0;
      let fixed_hra = user.salary ? parseFloat(user.salary.fixed_hra) : 0;
      let fixed_conveyance = user.salary
        ? parseFloat(user.salary.fixed_conveyance)
        : 0;
      let fixed_cca = user.salary ? parseFloat(user.salary.fixed_cca) : 0;
      let fixed_medical = user.salary
        ? parseFloat(user.salary.fixed_medical_allowance)
        : 0;
      let fixed_special = user.salary
        ? parseFloat(user.salary.fixed_special_allowance)
        : 0;
      let fixed_mobile_allowance = user.salary
        ? parseFloat(user.salary.fixed_mobile_allowance)
        : 0;
      let fixed_other_allowance = user.salary
        ? parseFloat(user.salary.fixed_other_allowance)
        : 0;
      let fixed_washing_allowance = user.salary
        ? parseFloat(user.salary.fixed_washing_allowance)
        : 0;
      let fixed_leave_encashment = user.salary
        ? parseFloat(user.salary.leave_encashment)
        : 0;
      let fixed_bonus = user.salary ? parseFloat(user.salary.bonus) : 0;

      let earned_basic =
        (fixed_basic / totalDaysInMonth) * user.monthly_salary.payable_days;
      let earned_da =
        (fixed_da / totalDaysInMonth) * user.monthly_salary.payable_days;
      let earned_hra =
        (fixed_hra / totalDaysInMonth) * user.monthly_salary.payable_days;
      let earned_conveyance =
        (fixed_conveyance / totalDaysInMonth) *
        user.monthly_salary.payable_days;
      let earned_cca =
        (fixed_cca / totalDaysInMonth) * user.monthly_salary.payable_days;
      let earned_medical =
        (fixed_medical / totalDaysInMonth) * user.monthly_salary.payable_days;
      let earned_special =
        (fixed_special / totalDaysInMonth) * user.monthly_salary.payable_days;
      let earned_mobile_allowance =
        (fixed_mobile_allowance / totalDaysInMonth) *
        user.monthly_salary.payable_days;
      let earned_other_allowance =
        (fixed_other_allowance / totalDaysInMonth) *
        user.monthly_salary.payable_days;
      let earned_washing_allowance =
        (fixed_washing_allowance / totalDaysInMonth) *
        user.monthly_salary.payable_days;
      let earned_leave_encashment =
        (fixed_leave_encashment / totalDaysInMonth) *
        user.monthly_salary.payable_days;
      let earned_bonus =
        (fixed_bonus / totalDaysInMonth) * user.monthly_salary.payable_days;

      user.monthly_salary.earned_basic = Math.round(earned_basic);
      user.monthly_salary.earned_da = Math.round(earned_da);
      user.monthly_salary.earned_hra = Math.round(earned_hra);
      user.monthly_salary.earned_conveyance = Math.round(earned_conveyance);
      user.monthly_salary.earned_cca = Math.round(earned_cca);
      user.monthly_salary.earned_medical = Math.round(earned_medical);
      user.monthly_salary.earned_special = Math.round(earned_special);
      user.monthly_salary.earned_mobile_allowance = Math.round(
        earned_mobile_allowance
      );
      user.monthly_salary.earned_other_allowance = Math.round(
        earned_other_allowance
      );
      user.monthly_salary.earned_washing_allowance = Math.round(
        earned_washing_allowance
      );
      user.monthly_salary.earned_leave_encashment = Math.round(
        earned_leave_encashment
      );
      user.monthly_salary.earned_bonus = Math.round(earned_bonus);
    },

    exportToExcel() {
      this.title =
        "Salary / Wages Register For the Month of " +
        this.month_name +
        " " +
        this.year;

      this.attendances = [];
      let sr_no = 1;
      this.users.forEach((user) => {
        if (
          user.monthly_salary &&
          user.monthly_salary.is_salary_generated == true
        ) {
          // Branch
          let designation_category = {};
          let designation_categories = user.user_categories.filter(
            (user_category) =>
              user_category.category.category_name == "DESIGNATION"
          );
          if (designation_categories.length > 0)
            designation_category = designation_categories[0];
          // Branch
          let branch_category = {};
          let branch_categories = user.user_categories.filter(
            (user_category) => user_category.category.category_name == "BRANCH"
          );
          if (branch_categories.length > 0)
            branch_category = branch_categories[0];

          this.attendances.push({
            sr_no: sr_no,
            employee_id: user.id,
            employee_code: user.employee_id,
            division_name: user.group_division
              ? user.group_division.division_name
              : "",
            employee_name: user.full_name,
            father_name: user.father_name,
            designation_name: designation_category.category_list
              ? designation_category.category_list.code
              : "",
            date_joined: user.joined_on
              ? moment(user.joined_on).isValid()
                ? moment(user.joined_on).format("DD-MM-YYYY")
                : user.joined_on
              : "",
            date_of_birth: user.dob
              ? moment(user.dob).isValid()
                ? moment(user.dob).format("DD-MM-YYYY")
                : user.dob
              : "",
            gender: user.gender == 0 ? "MALE" : "FEMALE",
            uan_number: user.uan_no ? user.uan_no : "",
            pf_number: user.pf_no ? user.pf_no : "",
            adhaar_number: user.aadhar_no ? user.aadhar_no : "",
            esic_number: user.esi_number ? user.esi_number : "",
            // esic_flag: user.is_esi_applicable,
            account_no: user.bank_acc_no ? user.bank_acc_no : "",
            ifsc_code: user.bank_ifsc_code,
            bank_name: user.bank_name ? user.bank_name.name : "",
            mode: user.bank_payment_type ? user.bank_payment_type.code : "",
            ot_rate: user.salary ? user.salary.ot_rate : "",
            gross_salary: user.salary ? user.salary.gross_salary : "",
            total_days: moment(
              this.year + "-" + this.month_id,
              "YYYY-M"
            ).daysInMonth(),
            days_present: user.monthly_salary.days_present,
            paid_leave: user.monthly_salary.paid_leave,
            absent_days: user.monthly_salary.days_absent,
            payable_days: user.monthly_salary.payable_days,
            ot_hrs: user.monthly_salary.ot_hrs,
            ot_amount: user.monthly_salary.ot_amount,
            reimbursement: user.monthly_salary.reimbursement,
            // incentive: 'Incentive',
            // attendance_bonus: 'Attendance Bonus',
            basic: user.monthly_salary.earned_basic,
            da: user.monthly_salary.earned_da,
            hra: user.monthly_salary.earned_hra,
            conveyance_allowance: user.monthly_salary.earned_conveyance,
            cca: user.monthly_salary.earned_cca,
            medical: user.monthly_salary.earned_medical,
            special: user.monthly_salary.earned_special,
            mobile_allowance: user.monthly_salary.earned_mobile_allowance,
            other_allowance: user.monthly_salary.earned_other_allowance,
            washing_allowance: user.monthly_salary.earned_washing_allowance,
            leave_encashment: user.monthly_salary.earned_leave_encashment,
            bonus: user.monthly_salary.earned_bonus,
            pf_wages: user.monthly_salary.earned_pf_wages,
            esic_wages: user.monthly_salary.earned_esic_wages,
            total_earning: user.monthly_salary.total_earnings,
            pf: user.monthly_salary.employee_pf,
            employee_esic: user.monthly_salary.employee_esic,
            employee_pt: user.monthly_salary.employee_pt,
            employee_lwf: user.monthly_salary.employee_lwf,
            tds: user.monthly_salary.tds,
            loan: user.monthly_salary.loan,
            salary_advance: user.monthly_salary.salary_advance,
            lic: user.monthly_salary.lic,
            uniform: user.monthly_salary.uniform,
            mobile: user.monthly_salary.mobile,
            accidental: user.monthly_salary.accidental,
            mediclaim: user.monthly_salary.mediclaim,
            shoes: user.monthly_salary.shoes,
            id_card: user.monthly_salary.id_card,
            penalty: user.monthly_salary.penalty,
            others: user.monthly_salary.others,
            total_deductions: user.monthly_salary.total_deductions,
            total_payable: user.monthly_salary.total_payable,
            branch: branch_category.category_list
              ? branch_category.category_list.code
              : "",
            state: user.group_division
              ? user.group_division.state
                ? user.group_division.state.code
                : ""
              : "",
            group_name: user.group ? user.group.name : "",
            remarks: user.monthly_salary.remarks,
            employer_pf: user.salary ? user.salary.employer_pf : 0,
            employer_esic: user.salary ? user.salary.employer_esic : 0,
            employer_lwf: user.salary ? user.salary.employer_mlwf : 0,
            e_bonus: user.salary ? user.salary.employer_bonus : 0,
            leave_salary: user.salary ? user.salary.leave_encashment : 0,
          });
          // console.log(this.attendances);
          sr_no++;
        }
      });
    },

    changeSalaryStatus(user) {
      if (!user.monthly_salary.is_salary_generated) {
        user.monthly_salary.days_absent = moment(
          this.year + "-" + this.month_id,
          "YYYY-M"
        ).daysInMonth();
        user.monthly_salary.ot_hrs = 0;
        user.monthly_salary.reimbursement = 0;
        user.monthly_salary.tds = 0;
        user.monthly_salary.loan = 0;
        user.monthly_salary.salary_advance = 0;
        user.monthly_salary.lic = 0;
        user.monthly_salary.uniform = 0;
        user.monthly_salary.mobile = 0;
        user.monthly_salary.accidental = 0;
        user.monthly_salary.mediclaim = 0;
        user.monthly_salary.shoes = 0;
        user.monthly_salary.id_card = 0;
        user.monthly_salary.penalty = 0;
        user.monthly_salary.others = 0;
        user.monthly_salary.total_deductions = 0;
        user.monthly_salary.total_payable = 0;
        this.updateDays(user, "ABSENT");
      }
    },

    toggleGenerateSalaryForAll() {
      this.getData();
    },
    keyBinding() {
      var keyCodes = {
        up: 38,
        down: 40,
        left: 37,
        right: 39,
        enter: 13,
      };

      var active = 0;
      $("#navigate td").each(function (idx) {
        $(this).html(idx);
      });
      rePosition();

      $(document).keydown(function (e) {
        reCalculate(e);
        rePosition();
        return true;
      });

      $("td").click(function () {
        active = $(this).closest("table").find("td").index(this);
        // console.log(active);
        rePosition();
      });

      function reCalculate(e) {
        var rows = $("#navigate tr").length;
        var columns = $("#navigate tr:eq(1) td").length;

        if (e.keyCode == keyCodes.left) {
          //move left or wrap
          active = active > 0 ? active - 1 : active; // index 2 == active 1
          if ($("#".active)) {
            // # 1
          }
        }
        if (e.keyCode == keyCodes.up) {
          // move up
          active = active - columns >= 0 ? active - columns : active;
          // console.log('Up')
        }
        if (e.keyCode == keyCodes.right) {
          // move right or wrap
          active = active < columns * rows - 1 ? active + 1 : active;
        }
        if (e.keyCode == keyCodes.down) {
          // move down
          active =
            active + columns <= rows * columns - 1 ? active + columns : active;
        }
        if (e.keyCode == keyCodes.enter) {
          $(".active").find("input").focus();
        }
      }

      function rePosition() {
        $(".active").removeClass("active");
        $("#navigate tr td").eq(active).addClass("active");
        scrollInView();
      }

      function scrollInView() {
        var target = $("#navigate tr td:eq(" + active + ")");
        if (target.length) {
          var top = target.offset().top;

          $("html,body")
            .stop()
            .animate({ scrollTop: top - 100 }, 400);
          return false;
        }
      }
    },
  },
};
</script>

<style scoped>
.searchInput {
  width: 180px;
  margin-left: 10px;
}

/* input {
  width: 40px;
  border: none;
  text-align: right;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.fix {
  position: absolute;
  *position: relative; /*ie7*/
  margin-left: -100px;
  width: 100px;
}

.salary_generated,
.first-r,
.second-r,
.third-r,
.fourth-r,
.fifth-r {
  background-color: #e0f1e0 !important;
}

/* testing */
/* Makes Entire thead & Tfoot Sticky */
thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: hsl(120, 2%, 89%);
}
/* Makes First header Fixed */
thead th:first-child {
  left: 0;
  z-index: 2;
  background: hsl(120, 2%, 89%);
}
/* Makes second header Fixed */
thead th:nth-child(2) {
  left: 50px;
  z-index: 2;
  background: hsl(120, 2%, 89%);
}
/* Makes second header Fixed */
thead th:nth-child(3) {
  left: 114px;
  z-index: 2;
  background: hsl(120, 2%, 89%);
}
/* Makes second header Fixed */
thead th:nth-child(4) {
  left: 180px;
  z-index: 2;
  background: hsl(120, 2%, 89%);
}
thead th:nth-child(5) {
  left: 255px;
  z-index: 2;
  background: hsl(120, 2%, 89%);
}

tbody {
  overflow: scroll;
  height: 200px;
}

/* MAKE LEFT COLUMN FIXEZ */
tr > :first-child {
  position: -webkit-sticky;
  position: sticky;
  background: hsl(180, 25%, 98%);
  left: 0;
}
/* Makes Second Column Fixed */
tr > :nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  background: hsl(180, 25%, 98%);
  left: 50px;
}
tr > :nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  background: hsl(180, 25%, 98%);
  left: 114px;
}
tr > :nth-child(4) {
  position: -webkit-sticky;
  position: sticky;
  background: hsl(180, 25%, 98%);
  left: 180px;
}
tr > :nth-child(5) {
  position: -webkit-sticky;
  position: sticky;
  background: hsl(180, 25%, 98%);
  left: 255px;
}

td.active {
  border: 1px solid blue;
  font-weight: bold;
  color: red;
  /* background-color: red; */
}
td {
  padding: 5px;
  text-align: center;
}
</style>
