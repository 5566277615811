<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;">Bank Account</span>
            <span style="float: right;">
              <i class="fa fa-pencil-square-o" aria-hidden="true" v-if="!editData" @click="toggleEditData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="editData" @click="toggleEditData"></i>
            </span>
          </div>

          <br>

          <div class="row">

            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Bank Name</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.bank_name ? form.bank_name.name : '' }}</p>
                <my-select :selection.sync="form.bank_name_id" :suggestions="banks" placeholder="Select bank" v-if="editData" @change="getBankBranches"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Bank Branch</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.bank_branch ? form.bank_branch.branch_address : '' }}</p>
                <my-select :selection.sync="form.bank_branch_id" :suggestions="bankBranches" placeholder="Select bank branch" v-if="editData" ></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Bank Account No</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.bank_acc_no }}</p>
                <input v-uppercase type="text" class="form-control" placeholder="Bank Account No" v-if="editData" v-model="form.bank_acc_no">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Bank IFSC Code</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.bank_ifsc_code }}</p>
                <input v-uppercase type="text" class="form-control" placeholder="Bank IFSC Code" v-if="editData" v-model="form.bank_ifsc_code">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Bank Account Type</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.bank_acc_type ? form.bank_acc_type.code : '' }}</p>
                <my-select :selection.sync="form.bank_acc_type_id" :suggestions="accountTypes" placeholder="Select bank account type" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Bank Payment Type</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.bank_payment_type ? form.bank_payment_type.code : '' }}</p>
                <my-select :selection.sync="form.bank_payment_type_id" :suggestions="paymentTypes" placeholder="Select bank payment type" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Name on bank</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.name_on_bank }}</p>
                <input v-uppercase type="text" class="form-control" placeholder="Name on bank" v-if="editData" v-model="form.name_on_bank">
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-10"
                v-if="editData"
              >
                <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      editData: false,
      bankBranches: [],
    }
  },
  props: ['form', 'isLoading', 'banks', 'accountTypes', 'paymentTypes'],
  methods: {
    toggleEditData() {
      this.editData = !this.editData
    },
    cancelUpdate() {
      this.editData = false
    },
    async update() {
      this.$emit('update')
      this.editData = false
    },
    getBankBranches() {
      let bank = this.banks.find(bank => bank.id == this.form.bank_name_id)
      this.bankBranches = []
      bank.bank_branches.forEach(bankBranch => {
        this.bankBranches.push({
          id: bankBranch.id,
          name: bankBranch.branch_address,
          code: bankBranch.branch_address,
        })
      })
    }
  }
}
</script>