import Vue from "vue";
import moment from "moment";

Vue.filter("ddmmyyyy", function (value) {
  if (!value) return "";
  value = value.toString();
  let formatted_date = moment(value).format("DD-MM-YYYY");
  if (formatted_date != "Invalid date") {
    return formatted_date;
  } else {
    return moment(value, "DD-MM-YYYY").format("DD-MM-YYYY");
  }
});
Vue.filter("mm", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("MMMM");
});

Vue.filter("hhmm", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("hh:mm:ss A");
});
