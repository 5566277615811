<template>
  <v-date-picker v-model="value" :masks="masks" @input="$emit('update:date', value)">
    <template v-slot="{ inputValue, inputEvents }">
      <input
        :class="disableFormControl ? '' : 'bg-white border px-2 py-1 rounded form-control'"
        :value="inputValue"
        v-on="inputEvents"
        :placeholder="placeholder"
      />
    </template>
  </v-date-picker>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      // value: new Date(new Date().setHours(0,0,0,0)),
      value: '',
       masks: {
        input: 'YYYY-MM-DD',
      },
    }
  },
  props: ['date', 'placeholder', 'disableFormControl'],
  watch: {
    'date': 'getValue'
  },
  mounted() {
    this.getValue()
  },
  methods: {
    getValue() {
      this.value = this.date
    }
  }
}
</script>