<template>
  <section>
    <router-view/>
  </section>
</template>

<script>
 export default {
  name: 'PdfLayout',
}
</script>

<style type="text/css">

</style>