<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <h5 class="txt-dark">
            Leaving Reasons
            <add-button link="/settings/leaving-reasons/create"></add-button>
            &nbsp;
            <refresh-button @click="getData"></refresh-button>
            &nbsp;
          </h5>
          <br />
          <my-search
            :search="search"
            placeholder="Search value..."
            style="margin: 9px !important"
            class="form-control"
            @searchData="searchData"
          ></my-search>
          <br />
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Leaving Reasons</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Description</th>
                        <th>Code</th>
                        <th>PF Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="5"
                      ></loading-table>
                      <router-link
                        v-for="(leavingReason, at) in leavingReasons"
                        :key="`leavingReason${at}`"
                        :to="`/settings/leaving-reasons/${leavingReason.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ leavingReason.description }}</td>
                        <td>{{ leavingReason.code }}</td>
                        <td>
                          {{
                            leavingReason.pf_code
                              ? leavingReason.pf_code.code +
                                " - " +
                                leavingReason.description
                              : ""
                          }}
                        </td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "LeavingReasons",
  data() {
    return {
      leavingReasons: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      search: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1, search = "") {
      this.isLoading = true;
      let leavingReasons = await axios.get(
        `/leaving_reasons?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${search}`
      );
      this.leavingReasons = leavingReasons.data.data;
      this.count = leavingReasons.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    searchData(value) {
      this.getData(1, value);
    },
  },
};
</script>

<style>
th {
}
</style>
