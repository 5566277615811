<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            PF ECR
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      :selection.sync="groupDivision"
                      :suggestions="groupDivisions"
                      :placeholder="`Select DIVISION`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select month"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      style="margin: 9px !important"
                      @change="updateMonth"
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select year"
                    ></my-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <!-- <button
                  @click.prevent="Onclick"
                  class="btn btn-primary float-right"
                >
                  <i class="fa fa-download"></i> Generate Text
                </button> -->
                <button
                  @click="searchData('generate')"
                  class="btn btn-primary float-right"
                >
                  <i class="fa fa-download"></i> Generate Text
                </button>
                <div class="table-wrap"></div>
                <!-- <pagination :count="count" @submit="getData"></pagination> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>
<script>
import FileSaver from "file-saver";
import moment from "moment";
import axios from "axios";

export default {
  name: "PFECR",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showForm9aTable: false,
      showForm9a: false,
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format("M");
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showForm9a = false;
      this.showForm9aTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        let users = await axios.get(
          `/pf_register/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = users.data.data.register;
        this.pf_configurations = users.data.data.pf_configurations;
        this.Onclick(this.users);
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showForm9aTable = true;
        this.showForm9a = false;
      }
      if (source == "generate") {
        this.showForm9aTable = false;
        this.showForm9a = true;
      }
    },
    Onclick(users) {
      this.blob_users = [];
      users.forEach((user) => {
        let gross_salary = user.user_salaries[0].gross_salary;
        let epf = user.monthly_salary.earned_pf_wages;
        let edli = epf;
        let epf_contribution = Math.round(
          (epf * this.pf_configurations.employer_epf) / 100
        );

        let eps = 0;
        let eps_contribution = 0;
        let day_absent = user.monthly_salary.days_absent;
        if (user.over_58 == false) {
          // User Under 58
          eps = epf;
          eps_contribution = Math.round(
            (epf * this.pf_configurations.employer_eps) / 100
          );
        }
        let edli_contribution = epf_contribution - eps_contribution;
        let single_user =
          user.uan_no +
          "#~#" +
          user.full_name +
          "#~#" +
          gross_salary +
          "#~#" +
          epf +
          "#~#" +
          eps +
          "#~#" +
          edli +
          "#~#" +
          epf_contribution +
          "#~#" +
          eps_contribution +
          "#~#" +
          edli_contribution +
          "#~#" +
          day_absent +
          "#~#0 \n";

        this.blob_users.push(single_user);
      });
      var blob = new Blob(
        this.blob_users,
        // [
        //   "100615712068#-#+" users.name"+#-#15000#-#15000#-#15000#-#15000#-#1800#-#1250#-#550#-#0#-#0",
        // ],
        { type: "text/plain;charset=utf-8" }
      );
      FileSaver.saveAs(blob, "PFECR.txt");
    },
  },
};
</script>
