<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/main/groups"></back-button> 
            Update group: {{ form.id }}<loading :isLoading="isLoading"></loading>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/main/groups">Groups</router-link></li>
            <li class="active">Update</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Name*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Name" v-model="form.name">
                          <span class="error-block">{{ errors.name }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Logo Path</label>
                        <div class="col-sm-9">
                           <input
                            type="file"
                            id="file"
                            name="logo_path"
                            ref="file"
                            accept="image/*"
                            multiple
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Email*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Email" v-model="form.email">
                          <span class="error-block">{{ errors.email }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Phone*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Phone" v-model="form.phone">
                          <span class="error-block">{{ errors.phone }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Address 1</label>
                        <div class="col-sm-9">
                          <textarea v-uppercase class="form-control" placeholder="Address 1" v-model="form.address_1"></textarea>
                          <span class="error-block">{{ errors.address_1 }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Address 2</label>
                        <div class="col-sm-9">
                          <textarea v-uppercase class="form-control" placeholder="Address 2" v-model="form.address_2"></textarea>
                          <span class="error-block">{{ errors.address_2 }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Country</label>
                        <div class="col-sm-9">
                          <my-select :selection.sync="form.country_id" :suggestions="countries" placeholder="Select Country"></my-select>
                          <span class="error-block">{{ errors.country_id }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">State*</label>
                        <div class="col-sm-9">
                          <my-select :selection.sync="form.state_id" :suggestions="states" placeholder="Select State"></my-select>
                          <span class="error-block">{{ errors.state_id }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">State Code</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Enter State Code" v-model="form.state_code">
                          <span class="error-block">{{ errors.state_code }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">City</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Enter City" v-model="form.city">
                          <span class="error-block">{{ errors.city }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Pincode</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Pincode" v-model="form.pincode">
                          <span class="error-block">{{ errors.pincode }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Gst No</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Gst No" v-model="form.gstin_no">
                          <span class="error-block">{{ errors.gstin_no }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Pan No</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Pan No" v-model="form.pan_no">
                          <span class="error-block">{{ errors.pan_no }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">PF Applicable On</label>
                        <div class="col-sm-9">
                          <my-select :selection.sync="form.applicable_on" :suggestions="applicables" placeholder="Applicable On"></my-select>
                          <span class="error-block">{{ errors.applicable_on }}</span>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'GroupUpdate',
  data () {
    return {
      form: {
        country_id: '',
        state_id: '',
        applicable_on: '',
      },
      states: [],
      countries: [],
      isLoading: false,
      applicables: [
        {id: 1, name: 'On Gross'},
        {id: 2, name: 'On Basic and DA'}
      ],
    }
  },
  mounted() {
    this.getData()
    this.getMasters()
  },
  methods: {
    async getMasters() {
      let masters = await axios.get('groups/masters')
      masters = masters.data
      masters.states.forEach(state => {
        this.states.push({
          id: state.id,
          name: state.description
        })
      })
      masters.countries.forEach(country => {
        this.countries.push({
          id: country.id,
          name: country.description
        })
      })
    },
    async getData() {
      this.isLoading = true
      let form = await axios.get(`/groups/${this.$route.params.groupId}`)
      this.form = form.data.data
      this.form.country_id = this.form.country_id || ''
      this.form.state_id = this.form.state_id || ''
      this.form.applicable_on = this.form.applicable_on || ''
      this.isLoading = false
    },
    async update() {
      try {
        this.isLoading = true
        console.log(this.form);
        await axios.patch(`/groups/${this.$route.params.groupId}`, this.form)
        await this.handleFileUpload();
        this.isLoading = false
        this.$router.push('/main/groups')
      } catch(e) {
        this.isLoading = false
      }
    },
    async handleFileUpload() {
      let attachment = this.$refs.file.files[0];
      const groupid = this.form.id
      let formData = new FormData();
      formData.append("id", groupid);
      formData.append("logo_path", attachment);
      console.log(formData);
      await axios
        .post("upload_group_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  }
}
</script>