<template>
  <section id="mydiv">
    <div class="container-fluid">
      <p style="font-size: 18px; font-weight: bold">
        <center>V ALLIANCE</center>
      </p>
      <p>
        <center>
          Office No-5-A, Raheja Arcade, Sector-11, CBD Belapur, Navi Mumbai- 400
          614
        </center>
      </p>
      <p style="font-weight: bold">Arrear Payslip</p>
      <div class="row">
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold"><p>Payslip :</p></div>
          <div class="col-md-6"><p>1</p></div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold">
            <p>Paid Month :</p>
          </div>
          <div class="col-md-6"><p>June 2019</p></div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold"><p>Branch :</p></div>
          <div class="col-md-6"><p>MUMBAI</p></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold">
            <p>Emp Code :</p>
          </div>
          <div class="col-md-6"><p>VA00173</p></div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold">
            <p>Name :</p>
          </div>
          <div class="col-md-6"><p>Mr. KALPESH B BAGWE</p></div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold"> <p>Department :</p></div>
          <div class="col-md-6"><p>OPERATION</p></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold"><p>Designation :</p></div>
          <div class="col-md-6"><p>OFFICE BOY</p></div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold">
            <p>Division :</p>
          </div>
          <div class="col-md-6"> <p>KAYA LIMITED ANDHERI OFFICE</p></div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold"><p>Joining Dt. :</p></div>
          <div class="col-md-6"><p>01/05/2017</p></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold"><p>ESIC No. :</p></div>
          <div class="col-md-6"><p>311151515</p></div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold">
            <p>PF No. :</p>
          </div>
          <div class="col-md-6"><p></p></div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold"><p>PAN No. :</p></div>
          <div class="col-md-6"><p></p></div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold">
            <p>Payslip :</p>
            <p>Emp Code :</p>
            <p>Designation :</p>
            <p>ESIC No. :</p>
          </div>
          <div class="col-md-6">
            <p>1</p>
            <p>VA00173</p>
            <p>OFFICE BOY</p>
            <p>3111029675</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold">
            <p>Paid Month :</p>
            <p>Name :</p>
            <p>Division :</p>
            <p>PF No. :</p>
          </div>
          <div class="col-md-6">
            <p>June 2019</p>
            <p>Mr. KALPESH B BAGWE</p>
            <p>KAYA LIMITED ANDHERI OFFICE</p>
            <p></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-6" style="font-weight: bold">
            <p>Branch :</p>
            <p>Department :</p>
            <p>Joining Dt. :</p>
            <p>PAN No. :</p>
          </div>
          <div class="col-md-6">
            <p>MUMBAI</p>
            <p>OPERATION</p>
            <p>01/05/2017</p>
            <p></p>
          </div>
        </div>
      </div> -->
      <table id="table-example-1" class="table table-responsive col-md-9">
        <thead>
          <tr>
            <th>Month</th>
            <th>Year</th>
            <th>Particulars</th>
            <th>Paid</th>
            <th>Payable</th>
            <th>Difference</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>January</td>
            <td>19</td>
            <td>D.A.</td>
            <td>3650.00</td>
            <td>5000.00</td>
            <td>459.00</td>
          </tr>
          <tr>
            <td>February</td>
            <td>19</td>
            <td>D.A.</td>
            <td>3650.00</td>
            <td>4104.00</td>
            <td>459.00</td>
          </tr>
          <tr>
            <td>March</td>
            <td>19</td>
            <td>D.A.</td>
            <td>3650.00</td>
            <td>4104.00</td>
            <td>459.00</td>
          </tr>
          <tr>
            <td>January</td>
            <td>19</td>
            <td>H.R.A.</td>
            <td>453.00</td>
            <td>476.00</td>
            <td>23</td>
          </tr>
          <tr>
            <td>February</td>
            <td>19</td>
            <td>H.R.A.</td>
            <td>453.00</td>
            <td>476.00</td>
            <td>23</td>
          </tr>
          <tr>
            <td>March</td>
            <td>19</td>
            <td>H.R.A.</td>
            <td>453.00</td>
            <td>476.00</td>
            <td>23</td>
          </tr>
          <tr>
            <td>January</td>
            <td>19</td>
            <td>OVER TIME</td>
            <td>107.00</td>
            <td>5000.00</td>
            <td>4</td>
          </tr>
          <tr>
            <td>February</td>
            <td>19</td>
            <td>OVER TIME</td>
            <td>107.00</td>
            <td>5000.00</td>
            <td>4</td>
          </tr>
          <tr>
            <td>March</td>
            <td>19</td>
            <td>OVER TIME</td>
            <td>107.00</td>
            <td>5000.00</td>
            <td>4</td>
          </tr>
          <tr style="font-weight: bold">
            <td colspan="3"><center>Total</center></td>
            <td>13685.00</td>
            <td>15183.00</td>
            <td>1498.00</td>
          </tr>
          <tr style="font-weight: bold">
            <th colspan="3"></th>
            <th>Recovered</th>
            <th>Recoverable</th>
            <th>Difference</th>
          </tr>
           <tr>
            <td>January</td>
            <td>19</td>
            <td>PROV. FUND</td>
            <td>1085.00</td>
            <td>1140.00</td>
            <td>55.00</td>
          </tr>
          <tr>
            <td>February</td>
            <td>19</td>
            <td>PROV. FUND</td>
            <td>1085.00</td>
            <td>1140.00</td>
            <td>55.00</td>
          </tr>
          <tr>
            <td>March</td>
            <td>19</td>
            <td>PROV. FUND</td>
            <td>1085.00</td>
            <td>1140.00</td>
            <td>55.00</td>
          </tr>
           <tr>
            <td>January</td>
            <td>19</td>
            <td>E.S.I.C</td>
            <td>258.00</td>
            <td>274.00</td>
            <td>8.00</td>
          </tr>
          <tr>
            <td>February</td>
            <td>19</td>
            <td>E.S.I.C</td>
            <td>258.00</td>
            <td>274.00</td>
            <td>8.00</td>
          </tr>
          <tr>
            <td>March</td>
            <td>19</td>
            <td>E.S.I.C</td>
            <td>258.00</td>
            <td>274.00</td>
            <td>8.00</td>
          </tr>
          <tr style="font-weight: bold">
            <td colspan="3"><center>Total</center></td>
            <td>13685.00</td>
            <td>15183.00</td>
            <td>1498.00</td>
          </tr>
          <tr style="font-weight: bold">
            <td colspan="3"><center>NET SALARY</center></td>
            <td>13685.00</td>
            <td>15183.00</td>
            <td>1498.00</td>
          </tr>
        </tbody>
      </table>
      <p>Rupees One Thousand Three Hundred Seven Only</p>
      <p>Bank : ICICI BANK &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Account No. : 107101508117</p>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "ArrearDetailedPayslip",
  mounted: function() {
      this.createPDF()
  },
  methods: {
    createPDF() {
      var element = document.getElementById("mydiv");

      var opt = {
        margin: 0,
        filename: "ArrearDetailedPayslip.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [450, 500],
          orientation: "portrait",
        },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>
