<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Permissions
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Permissions</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                    <table class="table table-hover table-striped">
                        <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Id</th>
                            <th
                            v-for="(header, h) in headers"
                            :key="`header${h}`"
                            >
                            {{ header.text }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(item, i) in items"
                            :key="`item${i}`"
                        > 
                            <td>{{ i + 1 }}</td>
                            <td>PER{{ item.id }}</td>
                            <td>{{ item.name }}</td>
                            <td 
                            v-for="(header, i) in headers.slice(1)"
                            :key="i"
                            >
                            <div class="form-check">
                                <input type="checkbox"
                                hide-details 
                                v-model="selected"
                                :value="item.id + '' + header.value"
                                @click="updatePermission(item.id, header.value)"
                                >
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'ManagePermissions',
  data:() =>  ({
    headers: [
      { text: 'Permissions', value: 'permissions' }
    ],
    items: [],
    loading: true,
    selected: [], //This array will define the vmodel of checkbox,
  }),
  async mounted() {
    let permissions = await axios.get('/permissions');
    let roles = await axios.get('/roles?search=all');
    this.permissions = permissions.data.data.filter(p => (p.id != 1) & (p.id != 2) & (p.id != 3) & (p.id != 4) & (p.id != 5))
    this.roles = roles.data.data

    this.roles = this.restrictRolesList(this.roles)
    this.roles.forEach(role => {
      this.headers.push({
        text: role.name,
        value: role.id
      })
    });
    this.updateSelected(this.roles)
    // this.permissions = this.restrictPermissions(this.permissions)
    this.permissions.forEach(item => {
      this.items.push({
        id: item.id,
        name: item.name
      })
    });
    this.loading = false
  },
  methods: {
    async updatePermission(rowId, columnId) { // rowId is permission and columnId is role
      // Assign or unassign permission to role
      let permission_payload = {
        role_id: columnId,
        permission_id: rowId,
      }
      if(this.selected.indexOf(rowId + '' + columnId) == -1)
        await axios.post('/assign_permissions', permission_payload)
      else
        await axios.post('/unassign_permissions', permission_payload)
      this.updateSelected();
    },
    async updateSelected() {
      let roles = await axios.get('/roles?search=all');
      this.roles = roles.data.data
      this.roles = this.restrictRolesList(this.roles)
      this.selected = [];
      roles.data.data.forEach(role => {
        role.permissions.forEach(per => {
          this.selected.push(per.id + '' + role.id)
        })
      })
    },
    restrictRolesList(roles) {
      roles = roles.filter(role => role.name != 'Super Admin')
      // roles = roles.filter(role => role.name != 'Admin')
      return roles;
    },
    restrictPermissions(permissions) {
      permissions = permissions.filter(permission => permission.name != 'Manage Organizations')
      permissions = permissions.filter(permission => permission.name != 'Super Admin Settings')
      return permissions;
    }
  }
}
</script>