<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Employee Profile
            <br />
          </h5>
          <br />
        </div>
        <!-- /Title -->
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Employee</li>
            <li>Information</li>
            <li class="active">Employee Profile</li>
          </ol>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <autocomplete
                      :search="search"
                      placeholder="Search employee"
                      aria-label="Search employee"
                      :get-result-value="getResultValue"
                      @submit="handleSubmit"
                    ></autocomplete>
                  </div>
                </div>
              </div>
              <div v-if="form.full_name">
                <u>{{ form.full_name }}</u>
                <br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div id="searchBox">
        <div class="col-md-9">
          <autocomplete
            :search="search"
            placeholder="Search employee"
            aria-label="Search employee"
            :get-result-value="getResultValue"
            @submit="handleSubmit"
          ></autocomplete>
        </div>
      </div> -->

      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <Photo
                            :isLoading="isLoading"
                            @update="update"
                            :form="form"
                          ></Photo>

                          <PersonalInformation
                            :form="form"
                            :bloodGroups="bloodGroups"
                            :maritalStatuses="maritalStatuses"
                            :nationalities="nationalities"
                            :residentialStatuses="residentialStatuses"
                            :countries="countries"
                            :religions="religions"
                            :genders="genders"
                            :isLoading="isLoading"
                            @update="update"
                          ></PersonalInformation>

                          <EmployeeIdentity
                            :form="form"
                            :documentTypes="documentTypes"
                            :isLoading="isLoading"
                            @update="update"
                          ></EmployeeIdentity>

                          <Education
                            :form="form"
                            :qualifications="qualifications"
                            :qualification_areas="qualification_areas"
                            :isLoading="isLoading"
                            @update="update"
                          ></Education>
                        </div>
                        <div class="col-md-6">
                          <CurrentPosition
                            :form="form"
                            :categories="categories"
                          ></CurrentPosition>

                          <JoiningDetails
                            :form="form"
                            :employeeStatuses="employeeStatuses"
                            :groups="groups"
                            :supervisors="supervisors"
                            :isLoading="isLoading"
                            @update="update"
                          ></JoiningDetails>

                          <Address
                            :form="form"
                            :addressTypes="addressTypes"
                            :isLoading="isLoading"
                            :states="states"
                            :countries="countries"
                            @update="update"
                          ></Address>

                          <BackgroundCheck
                            :form="form"
                            :verificationStatuses="verificationStatuses"
                            :isLoading="isLoading"
                            @update="update"
                          ></BackgroundCheck>

                          <Remarks :form="form"></Remarks>
                        </div>
                      </div>
                    </div>

                    <router-link
                      to="/main/employees/family-details"
                      class="btn btn-primary"
                      >Next</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import store from "@/store";
import { updateFormData } from "@/static/helpers.js";
import Photo from "./photo.vue";
import PersonalInformation from "./personal-information.vue";
import EmployeeIdentity from "./employee-identity.vue";
import Education from "./education.vue";
import CurrentPosition from "./current-position.vue";
import JoiningDetails from "./joining-details.vue";
import Address from "./address.vue";
import BackgroundCheck from "./background-check.vue";
import Remarks from "./remarks.vue";
// import moment from "moment";

export default {
  name: "EmployeeProfile",
  data() {
    return {
      form: {
        marital_status: {},
        blood_group: {},
        nationality: {},
        residential_status: {},
        country: {},
        religion: {},
        verification_status: {},
        employee_status: {},
        user_addresses: [],
        user_identities: [],
        user_educations: [],
        user_categories: [],
        user_passports: [],
        user_visas: [],
        user_family_details: [],
      },
      supervisors: [],
      roles: [],
      bloodGroups: [],
      maritalStatuses: [],
      nationalities: [],
      residentialStatuses: [],
      genders: [
        { id: 0, name: "Male" },
        { id: 1, name: "Female" },
      ],
      countries: [],
      religions: [],
      verificationStatuses: [],
      employeeStatuses: [],
      addressTypes: [],
      states: [],
      documentTypes: [],
      qualifications: [],
      qualification_areas: [],
      categories: [],
      groups: [],
      isLoading: false,
    };
  },
  components: {
    Photo,
    CurrentPosition,
    PersonalInformation,
    EmployeeIdentity,
    Education,
    JoiningDetails,
    Address,
    BackgroundCheck,
    Remarks,
  },
  mounted() {
    this.getMasters();

    if (this.$route.query.userId) {
      let result = {
        id: this.$route.query.userId,
      };
      this.handleSubmit(result);
    }
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;
      this.supervisors = [];
      masters.supervisors.forEach((supervisor) => {
        this.supervisors.push({
          id: supervisor.id,
          name: supervisor.full_name,
        });
      });
      this.roles = [];
      masters.roles.forEach((role) => {
        this.roles.push({
          id: role.id,
          name: role.name,
        });
      });
      masters.blood_groups.forEach((bloodGroup) => {
        this.bloodGroups.push({
          id: bloodGroup.id,
          name: bloodGroup.code,
        });
      });
      masters.marital_statuses.forEach((maritalStatus) => {
        this.maritalStatuses.push({
          id: maritalStatus.id,
          name: maritalStatus.code,
        });
      });
      masters.nationalities.forEach((nationality) => {
        this.nationalities.push({
          id: nationality.id,
          name: nationality.code,
        });
      });
      masters.residential_statuses.forEach((residentialStatus) => {
        this.residentialStatuses.push({
          id: residentialStatus.id,
          name: residentialStatus.code,
        });
      });
      masters.countries.forEach((country) => {
        this.countries.push({
          id: country.id,
          name: country.code,
        });
      });
      masters.religions.forEach((religion) => {
        this.religions.push({
          id: religion.id,
          name: religion.code,
        });
      });
      masters.verification_statuses.forEach((verificationStatus) => {
        this.verificationStatuses.push({
          id: verificationStatus.id,
          name: verificationStatus.code,
        });
      });
      masters.employee_statuses.forEach((employeeStatus) => {
        this.employeeStatuses.push({
          id: employeeStatus.id,
          name: employeeStatus.code,
        });
      });
      masters.address_types.forEach((addressType) => {
        this.addressTypes.push({
          id: addressType.id,
          name: addressType.code,
          code: addressType.code,
        });
      });
      masters.states.forEach((state) => {
        this.states.push({
          id: state.id,
          name: state.code,
        });
      });
      masters.document_types.forEach((documentType) => {
        this.documentTypes.push({
          id: documentType.id,
          name: documentType.code,
        });
      });
      masters.qualifications.forEach((qualification) => {
        this.qualifications.push({
          id: qualification.id,
          name: qualification.code,
        });
      });
      masters.qualification_areas.forEach((qualificationArea) => {
        this.qualification_areas.push({
          id: qualificationArea.id,
          name: qualificationArea.code,
        });
      });
      this.groups = masters.groups;
      this.categories = masters.categories;
      if (this.employee != null) {
        this.updateForm(this.employee);
      }
    },
    async search(input) {
      // if (input.length < 3) {
      //   return []
      // } else {
      let response = await axios.get(`/users?search=${input}`);
      return response.data.data;
      // }
    },
    getResultValue(result) {
      return result.full_name;
    },
    async handleSubmit(result) {
      let response = await axios.get(`users/${result.id}`);
      store.dispatch("employee/setEmployee", response.data.data);

      this.updateForm(response.data.data);
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/class_societies", this.form);
        this.isLoading = false;
        this.$router.push("/settings/class-societies");
      } catch (e) {
        this.isLoading = false;
      }
    },
    updateForm(formData) {
      this.form = updateFormData(formData, this.addressTypes, this.site);
    },
    async update() {
      try {
        this.isLoading = true;
        this.form.image_path = null;
        let response = await axios.post(`/users`, this.form);
        store.dispatch("employee/setEmployee", response.data.data);
        this.updateForm(response.data.data);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    searchData() {},
  },
};
</script>
