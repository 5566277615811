<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            PF Form 9A
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <my-search
                      class="form-control"
                      :search="searchText"
                      placeholder="Search here..."
                      @searchData="search"
                    ></my-search>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="selectDivision"
                      :selection.sync="group"
                      :suggestions="groups"
                      :placeholder="`Select GROUP`"
                    >
                    </my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      :selection.sync="groupDivision"
                      :suggestions="groupDivisions"
                      :placeholder="`Select DIVISION`"
                    >
                    </my-select>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="month_id"
                      :suggestions="months"
                      placeholder="Select month"
                    ></my-select>
                  </div>
                  <div class="col-md-3">
                    <my-select
                      @change="updateMonth"
                      :selection.sync="year"
                      :suggestions="years"
                      placeholder="Select year"
                    ></my-select>
                  </div>
                  <div class="col-md-4">
                    <button
                      class="btn btn-primary"
                      @click="searchData('generate')"
                    >
                      Generate</button
                    >&nbsp;&nbsp;
                    <button class="btn btn-success" @click="generatePDF">
                      Save & Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />

                <div class="table-wrap" v-if="showForm9aTable"></div>
                <section id="mydiv" v-if="showForm9a">
                  <div class="container-fluid row">
                    <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                      <div
                        style="border-style: solid; border-width: thin; height:250px border-color: #111111;"
                      >
                        logo

                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
                      <h5 class="txt-dark">
                        <center style="font-weight: bold; font-size: 18px">
                          <p>Form No.9</p>
                          <p>
                            THE EMPLOYEE's PROVIDENT FUND SCHEME, 1952 (PARA
                            36(1)) AND THE EMPLOYEES PENSION SCHEME, 1995 [Para
                            20(1)]
                          </p>
                        </center>
                      </h5>
                    </div>
                  </div>
                  <div class="container-fluid row">
                    <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                      <p>Name of the Establishment</p>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
                      <p style="font-weight: bold">{{ site.name }}</p>
                    </div>
                  </div>
                  <div class="container-fluid row">
                    <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                      <p>Address</p>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
                      <p style="font-weight: bold">
                        {{ site.address }}
                      </p>
                    </div>
                  </div>
                  <div class="container-fluid row">
                    <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                      <p>Code no. of the Establishment</p>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
                      <p style="font-weight: bold">
                        {{ site.establishment_code }} &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; GROUP NO. 31
                      </p>
                    </div>
                  </div>
                  <div class="container-fluid">
                    <table
                      id="table-example-1"
                      class="table table-responsive col-md-10"
                    >
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>A/c No.</th>
                          <th>Name of the Employee</th>
                          <th>
                            Father's name (or husband's name in Case of Married
                            Woman)
                          </th>
                          <th>date of birth/ Age</th>
                          <th>sex</th>
                          <th>date of joining the fund</th>
                          <th>total P.O.P.S (excludeding POB as on the Dt.)</th>
                          <th>Date of joining Pension Scheme</th>
                          <th>Initials of HC</th>
                          <th>Date & Reason of leaving Service</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <loading-table
                          :isLoading="isLoading"
                          cols="11"
                        ></loading-table>
                        <tr
                          v-for="(user, at) in PF_Calculations"
                          :key="`user${at}`"
                        >
                          <td>{{ serialNoStarting + at + 1 }}</td>
                          <td>{{ user.bank_acc_no }}</td>
                          <td>{{ user.full_name }}</td>
                          <td>{{ user.father_name }}</td>
                          <td>{{ user.dob }}</td>
                          <td>{{ user.gender == 0 ? "Male" : "Female" }}</td>
                          <td>{{ user.joined_on }}</td>
                          <td></td>
                          <td>{{ user.pf_joining_date }}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "PDFForm9A",
  data() {
    return {
      date_of_payment: "",
      isLoading: false,
      isSaving: false,
      users: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
      ],
      configurations: [],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      serialNoStarting: 0,
      showForm9aTable: false,
      showForm9a: false,
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();

    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showForm9a = false;
      this.showForm9aTable = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.users = [];
        this.PF_Calculations = [];
        let PF_Calculations = await axios.get(
          `/pf_register/Form9A?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.PF_Calculations = PF_Calculations.data.data;
        this.site = PF_Calculations[0].sites[0];
        let users = await axios.get(
          `/user_punches/filter?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        this.users = [];
        users.data.data.forEach((user) => {
          if (
            user.employee_status == null ||
            (user.employee_status.code != "LEFT" &&
              user.employee_status.code != "TERMINATE")
          )
            this.users.push(user);
        });
        for (const user of this.users) {
          if (user.monthly_salary == null)
            this.initializeEmptyMonthlySalary(user);
          else this.getEarnedSalaryComponents(user);
        }
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showForm9aTable = true;
        this.showForm9a = false;
      }
      if (source == "generate") {
        this.showForm9aTable = false;
        this.showForm9a = true;
      }
    },
    generatePDF() {
      var element = document.getElementById("mydiv");
      var opt = {
        margin: 0,
        filename: "PFForm9A.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
