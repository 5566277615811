<template>
  <section id="mydiv">
    <div class="container-fluid">
      <div class="col-md-12">
        <p style="font-weight: bold">
          <center>Maharashtra Labour Welfare Board</center>
        </p>
        <p>
          <center>
            Hutatma Bau Genu, Mumbai Kamgar Kreeda Bhavan, Senapati Bapat Marg,
            Elphinstone, Mumbai - 400 013
          </center>
        </p>
        <p style="font-weight: bold">
          <center>FORM A-1-CUM RETURN</center>
          <center>(VIDE RULE 3-A)</center>
        </p>
        <br />

        <div class="row">
          <div class="col-md-9">
            <p>
              <span style="font-weight: bold">Note: </span>1) This form cum -
              return is required to be submitted by every Employer alongwith the
              of Employee's six monthly contribution made by him in respect of
              all employee's whose names stand on the register of his
              establishment as c31 December 2020
            </p>
            <p>
              as per the provisions of Section 6BB of the Bombay Labour Welfare
              Fund Act, 1953
            </p>
            <p>
              2) Section 2 (2)(b) of Bombay Welfare Fund Act, 1953 "Supervisor"
              means who, being emplpoyed in a supervisory capacity, draws wages
              esceeding Three Thousand Five Hundred rupees per mensum or
              exercise, either by the nature of the duties attached to the
              office, or by reason of the powers vested in him, functions mainly
              of a managerial nature.
            </p>
            <p>
              3) EEC = Employee's contribution, ERC = Employer's contribution.
            </p>
          </div>

          <div class="col-md-3">
            <p>for office use only</p>
            <div
              style="border-style: solid; border-width: thin; border-color: #111111;"
            >
              C
              <hr />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-md-3">
            <span style="font-weight: bold">Establishment Code No.</span>
          </div>
          <div class="col-md-6">
            <div
              style="border-style: solid; border-width: thin; border-color: #111111; "
            >
              THA76709
            </div>

            <!-- <p>2. Name of the employer</p>
            <p>
              
            </p>
            <p>
              
            </p>
            <p>a) Manager</p> -->
          </div>
        </div>

        <ol type="1">
          <div class="row">
            <div class="col-md-3">
              <li>
                <p>Name & Address of the Establishment</p>
              </li>
              <!-- </ol> -->
            </div>
            <div class="col-md-9">
              <p>V ALLIANCE</p>
              <p>
                Office No. 5-A, Raheja Arcade, Sector-11, CBD Belapur, Navi
                Mumbai-400614
              </p>
              <p>Plot No- 61</p>
              <p>NAVI MUMBAI -400 614</p>
              <p>62448686</p>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col-md-3">
              <li>
                <p>Name of the employer</p>
              </li>
            </div>
            <div class="col-md-9">
              <p>Survarna Gaikwad</p>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col-md-3">
              <li>
                <p>
                  Class of the Establishment (i.e whether factory, motor omnibus
                  service, motor transport undertaking or commercial
                  establishment, a a resident hotel, restaurant, eating house,
                  theatre or other place of amusement of public entertainment.)
                </p>
              </li>
            </div>
            <div class="col-md-9"></div>
          </div>
          <br />
        </ol>

        <div class="row col-md-3">
          <ol start="4">
            <li>
              <p>
                Total number of employees whose names stood on the establishment
                register as on 31 December 2020
              </p>
            </li>
            <br />

            <ol type="a">
              <li>
                <p>Manager</p>
              </li>
              <br />

              <li>
                <p>
                  Employees working in supervisory capacity drawing wages
                  exceeding Rs. 3500/- per month (Please refer NOTE 2 above)
                </p>
              </li>
              <br />

              <li>
                <p>
                  Employees drawing wages upto & inclusive of Rs. 3000/- per
                  month
                </p>
                <p>
                  EEC @ Rs. 6/- per employee
                </p>
                <p>ERC @ Rs. 18/- per employee</p>
              </li>
              <br />

              <li>
                <p>Employees drawing wages exceeding Rs. 3,000/- per month</p>
                <p>EEC @ Rs. 12 per employee &</p>
                <p>ERC @ Rs. 36 per employee</p>
              </li>
              <br />
            </ol>
            <li>
              <p>Total of (a) to (d) above</p>
            </li>
            <br />

            <li>
              <p>Mode of Payment:-</p>
              <p>
                (Please specify No. & date of cheque/ draft/ money order/cash
                against this item.)
              </p>
            </li>
            <br />
          </ol>
        </div>
        <br />

        <!-- <div class="row"> -->
        <div class="col-md-1">
          <!-- <br />
          <br />
          <br />
          <br />
          <br />
          <p>June 2019</p>
          <p>
            Dec 2019
          </p>
          <p>
            June
          </p>
          <br />
          <p>
            Dec
          </p> -->
        </div>
        <div class="col-md-8">
          <table id="table-example-1" class="table table-responsive col-md-10">
            <thead>
              <tr>
                <th></th>
                <th>No. of Employee</th>
                <th>E.E.C Rs</th>
                <th>E.R.C Rs</th>
                <th>Pental Interest Rs</th>
                <th>Total Rs</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>0.00</td>
                <td>NIL</td>
                <td>NIL</td>
                <td>NIL</td>
                <td>NIL</td>
              </tr>
              <tr>
                <td></td>
                <td>0.00</td>
                <td>NIL</td>
                <td>NIL</td>
                <td>NIL</td>
                <td>NIL</td>
              </tr>
              <tr>
                <td>June 2019</td>
                <td>0</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Dec 2019</td>
                <td>0</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>June</td>
                <td>0</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Dec</td>
                <td>151</td>
                <td>1,812.00</td>
                <td>5,436.00</td>
                <td>0.00</td>
                <td>7248.00</td>
              </tr>
              <tr>
                <td></td>
                <td>151</td>
                <td>1,812.00</td>
                <td>5,436.00</td>
                <td>0.00</td>
                <td>7248.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- </div> -->
        <div class="row">
          <div class="col-md-12">
            <div class="col-md-6">
              <p style="font-weight: bold">
                IMPORTANT
              </p>
              <ol type="1">
                <li>
                  Cheque / DD should be drawn to each Est. Code, Number
                  seperately & in favor of Maharashtra Labour Welfare Fund.
                </li>
                <li>
                  Cash Payment will be accepted from 10:30 a.m to 3:00 p.m
                </li>
                <li>
                  Code no of the Establishment alloted should be quated at the
                  appropriate place in this form
                </li>
                <li>
                  DD should be payable at BOMBAY only
                </li>
                <li>
                  Please write the Establishment code number on the back side of
                  the cheque/DD
                </li>
              </ol>
            </div>
            <div class="col-md-6">
              <br />
              <p>
                Certified that the information / particulars furnished above is
                / are true to te best of my knowledge and belief.
              </p>
              <br />
              <br />
              <br />
              <p>
                Signature with name & designation of the Authority filing this
                form-cum-return
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "LWFChallan",
  mounted: function() {
    this.createPDF();
  },
  methods: {
    createPDF() {
      var element = document.getElementById("mydiv");

      var opt = {
        margin: 0,
        filename: "LWFChallan.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>
