<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Upload Categories</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Upload Categories</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                &nbsp;&nbsp;
                <a
                  :href="`${mediaUrl}samples/categories-data.xlsx`"
                  target="_blank"
                >
                  [ Download Sample Excel ]
                </a>

                <input
                  type="file"
                  id="file"
                  name="file"
                  ref="file"
                  accept="application/ms-excel"
                  multiple
                />
                <br />
                <a href="javascript:void" @click="truncatePendingDocuments"
                  >Truncate List</a
                >
                <br /><br />
                <span style="color: red"> {{ message }} </span>
                <span v-if="isLoading"> Uploading...</span>

                <div class="form-footer" v-if="crudCategories.length == 0">
                  <button
                    class="btn btn-primary btn-block"
                    @click="handleFileUpload"
                  >
                    Upload file
                  </button>
                </div>

                <div class="form-footer" v-else>
                  <button
                    class="btn btn-success btn-block"
                    @click="processFile"
                  >
                    Process file
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>CATEGORY NAME</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="2"
                      ></loading-table>
                      <tr
                        v-for="(crudCategory, at) in crudCategories"
                        :key="`crudCategory${at}`"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ crudCategory.category_name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
export default {
  name: "uploadCategories",
  data() {
    return {
      crudCategories: [],
      message: "",
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crudCategories = await axios.get(`/crud_category`);
      this.crudCategories = crudCategories.data.data;
      // console.log(this.crudCategories);
      this.count = crudCategories.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isLoading = true;
      this.Categories = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("categoriesData", this.Categories);
      await axios
        .post("upload_category", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crudCategories = response.data.data;
          this.isLoading = false;
          alert("Uploaded Successfully");
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
    async processFile() {
      try {
        this.isLoading = true;
        await axios.get("process_category");
        alert("Processed Successfully");
        await axios.get("truncate_categories");
        this.crudCategories = [];
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("truncate_categories");
      alert("Truncated Successfully");
      this.crudCategories = [];
    },
    async deleteDocument($id) {
      var r = confirm("Are you sure you want to delete the document!");
      if (r == true) {
        await axios.delete(`crud_category/${$id}`);
        this.crudCategories = this.crudCategories.filter((d) => d.id != $id);
      }
    },
  },
};
</script>
