<template>
  <section id="mydiv">
    <div class="container-fluid">
      <p style="font-size: 18px; font-weight: bold">V ALLIANCE</p>
      <p style="font-size: 18px; font-weight: bold">
        Salary / Wages Register For The Month April- 2021. For Division Altius
        Digital Pvt Ltd
      </p>
      <p style="float: right;">Date 01/06/2021</p>

      <table id="table-example-1" class="table table-responsive col-md-9">
        <thead>
          <tr>
            <th rowspan="6">Sr</th>
            <th>Employee Code</th>
            <th>Bank A/C Number</th>
            <th>Total Days</th>
            <th>Fixed Basic</th>
            <th>Fixed CCA</th>
            <th>Basic</th>
            <th>MEDICAL</th>
            <th>Reimbursements</th>
            <th>OVER TIME</th>
            <th>PROV. FUND</th>
            <th>TDS</th>
            <th>Life Insurance</th>
            <th>Gross Salary</th>
            <th rowspan="6">Signature</th>
          </tr>
          <tr>
            <th>Employee Name</th>
            <th>Bank Name</th>
            <th>Days Paid</th>
            <th>Fixed D.A</th>
            <th>Fixed Medical</th>
            <th>D.A</th>
            <th>Other Allowances</th>
            <th>Incentive</th>
            <th>Other Deduction</th>
            <th>E.S.I.C</th>
            <th>Mediclaim</th>
            <th>Uniform Deduction</th>
            <th>Total Earning</th>
          </tr>
          <tr>
            <th>DesigName</th>
            <th>Mode</th>
            <th>Absent Days</th>
            <th>Fixed H.R.A</th>
            <th>Fixed Washing Allowances</th>
            <th>H.R.A</th>
            <th>Washing Allowances</th>
            <th>Arrears</th>
            <th>Pay Card Deduction</th>
            <th>P.Tax</th>
            <th>Loan</th>
            <th>Mobile Deduction</th>
            <th>Total Deduction</th>
          </tr>
          <tr>
            <th>Date Joined</th>
            <th></th>
            <th>OT Hours</th>
            <th>Fixed Conveyance</th>
            <th>Fixed Other Allowances</th>
            <th>Conveyance Allowances</th>
            <th>Mobile Allowances</th>
            <th>Attendance e Bonus</th>
            <th></th>
            <th>LWF EE</th>
            <th>Salary Advance</th>
            <th>Accidental Insurance</th>
            <th>Net Paid</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Leave Encashment Gro</th>
            <th>Bonus Gross</th>
            <th>CCA</th>
            <th>Leave Encashment</th>
            <th>Bonus</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="5">1</td>
            <td>VA00021</td>
            <td>20400422917</td>
            <td>30.00</td>
            <td>5000.00</td>
            <td>0.00</td>
            <td>5000.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>1092.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>10805.00</td>
          </tr>
          <tr>
            <td>SAPNA SANTOSH KANADE</td>
            <td>SBI</td>
            <td>30.00</td>
            <td>4104.00</td>
            <td>0.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>0.00</td>
          </tr>
          <tr>
            <td>CHAMBER MAID</td>
            <td>BANK</td>
            <td>30.00</td>
            <td>4104.00</td>
            <td>0.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>0.00</td>
          </tr>
          <tr>
            <td>01/05/2017</td>
            <td></td>
            <td>30.00</td>
            <td>4104.00</td>
            <td>0.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td></td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>0.00</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>4104.00</td>
            <td>0.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr style="font-weight: bold">
            <td colspan="3" rowspan="5"><center>Total</center></td>
            <td>30.00</td>
            <td>5000.00</td>
            <td>0.00</td>
            <td>5000.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>1092.00</td>
            <td>0.00</td>
            <td>0.00</td>
            <td>10805.00</td>
          </tr>
          <tr style="font-weight: bold">
            <td>30.00</td>
            <td>4104.00</td>
            <td>0.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>0.00</td>
          </tr>
          <tr style="font-weight: bold">
            <td>30.00</td>
            <td>4104.00</td>
            <td>0.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>0.00</td>
          </tr>
          <tr style="font-weight: bold">
            <td>30.00</td>
            <td>4104.00</td>
            <td>0.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td></td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>0.00</td>
          </tr>
          <tr style="font-weight: bold">
            <td></td>
            <td>4104.00</td>
            <td>0.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td>805.00</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "SalaryRegister",
  mounted: function() {
      this.createPDF()
  },
  methods: {
    createPDF() {
      var element = document.getElementById("mydiv");

      var opt = {
        margin: 0,
        filename: "SalaryRegister.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [450, 500],
          orientation: "portrait",
        },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>
