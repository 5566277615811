<template>
  <div>
    <Nav></Nav>

    <LeftSideBar></LeftSideBar>

    <RightSideBar></RightSideBar>

    <router-view />

    <!-- Footer -->
    <!-- <div class="panel-wrapper collapse in"> -->
    <footer class="footer">
      <!-- <div class="row">
        <div class="col-sm-12"> -->
      <div class="row">
        <div class="col-md-1">
          <img style="height: 40px" src="/dist/img/logo.png" alt="brand" />
        </div>
        <div class="col-md-6">
          <p>Powered By <b>Transpay India HR Sathi</b></p>
        </div>
      </div>
      <!-- </div> -->
      <!-- </div> -->
    </footer>
    <!-- </div> -->
    <!-- /Footer -->
  </div>
</template>

<script type="text/javascript">
import Nav from "@/views/auth-layout/nav.vue";
import LeftSideBar from "@/views/auth-layout/left-side-bar.vue";
import RightSideBar from "@/views/auth-layout/right-side-bar.vue";

export default {
  name: "AuthLayout",
  components: {
    Nav,
    LeftSideBar,
    RightSideBar,
  },
};
</script>

<style>
.footer {
  /* position: relative; */
  position: fixed;
  height: 50px;
  bottom: 0px;
  left: 250px;
  background-color: #f7f7f7;
  right: 0px;
  margin-bottom: 0px;
}
</style>
