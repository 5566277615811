<template>
  <section>
    <!-- Main Content -->
    <div class="page-wrapper">
      <div class="container-fluid pt-25">

        <!-- Row -->
        <div class="row">
          <div class="col-lg-3 col-xs-12">
            <div class="panel panel-default card-view  pa-0">
              <div class="panel-wrapper collapse in">
                <div class="panel-body  pa-0">
                  <div class="profile-box">
                    <div class="profile-cover-pic">
                      <div class="fileupload btn btn-default">
                        <span class="btn-text">edit</span>
                        <input class="upload" type="file">
                      </div>
                      <div class="profile-image-overlay"></div>
                    </div>
                    <div class="profile-info text-center">
                      <div class="profile-img-wrap">
                        <img class="inline-block mb-10" src="dist/img/mock1.jpg" alt="user"/>
                        <div class="fileupload btn btn-default">
                          <span class="btn-text">edit</span>
                          <input class="upload" type="file">
                        </div>
                      </div>  
                      <h5 class="block mt-10 mb-5 weight-500 capitalize-font txt-danger">{{ form.first_name + ' ' + form.middle_name + ' ' + form.last_name}}</h5>
                      <h6 class="block capitalize-font pb-20">{{ form.roles.length > 0 ? form.roles[0].name : '' }}</h6>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-xs-12">
            <div class="panel panel-default card-view pa-0">
              <div class="panel-wrapper collapse in">
                <div  class="panel-body pb-0">
                  <div  class="tab-struct custom-tab-1">
                    <ul role="tablist" class="nav nav-tabs nav-tabs-responsive" id="myTabs_8">
                      <li class="active" role="presentation"><a  data-toggle="tab" id="profile_tab_8" role="tab" href="#profile_8" aria-expanded="false"><span>profile</span></a></li>
                    </ul>
                    <div class="tab-content" id="myTabContent_8">
                      <div  id="profile_8" class="tab-pane fade active in" role="tabpanel">
                        <!-- Row -->
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="">
                              <div class="panel-wrapper collapse in">
                                <div class="panel-body pa-0">
                                  <div class="col-sm-12 col-xs-12">
                                    <div class="form-wrap">
                                      <form action="#">
                                        <div class="form-body overflow-hide">
                                          <div class="form-group">
                                            <label class="control-label mb-10">Name</label>
                                            <div class="row">
                                              <div class="col-md-4">
                                                <input type="text" class="form-control" v-model="form.first_name" placeholder="Enter first name">
                                              </div>
                                              <div class="col-md-4">
                                                <input type="text" class="form-control" v-model="form.middle_name" placeholder="Enter middle name">
                                              </div>
                                              <div class="col-md-4">
                                                <input type="text" class="form-control" v-model="form.last_name" placeholder="Enter last name">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <label class="control-label mb-10">Email address</label>
                                            <input type="email" class="form-control" placeholder="xyz@gmail.com">
                                          </div>
                                          <div class="form-group">
                                            <label class="control-label mb-10">Contact number</label>
                                            <div class="input-group">
                                              <div class="input-group-addon"><i class="icon-phone"></i></div>
                                              <input type="email" class="form-control" placeholder="+102 9388333">
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <label class="control-label mb-10" for="exampleInputpwd_01">Password</label>
                                            <div class="input-group">
                                              <div class="input-group-addon"><i class="icon-lock"></i></div>
                                              <input type="password" class="form-control" id="exampleInputpwd_01" placeholder="Enter pwd" value="password">
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-actions mt-10">      
                                          <button type="submit" class="btn btn-success mr-10 mb-30">Update profile</button>
                                        </div>        
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Row -->
      </div>
    </div>
    <!-- /Main Content -->
  </section>
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'Profile',
  data () {
    return {
      form: {
        roles: []
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      let user = await axios.get(`/users/${this.user.id}`)
      this.form = user.data.data
    }
  }
}
</script>