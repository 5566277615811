<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            List of Categories
            <save-button @click="save"></save-button>
            &nbsp;
            <upload-button
              link="/settings/category-lists/upload"
            ></upload-button>
            &nbsp;
          </h5>
          <br><br/>         
          <my-select
            @change="searchData"
            :selection.sync="search"
            :suggestions="categories"
            placeholder="Select category"
          ></my-select>
          <small v-if="isSaving">Saving...</small>
          <br/>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">List of Categories</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="editable-table-wrapper" style="height: 700px">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Description</th>
                          <th>Code</th>
                          <th>Is Active</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(categoryList, ar) in categoryLists"
                          :key="`categoryList${ar}`"
                        >
                          <td>
                            <span
                              @click="deleteCategoryList(categoryList)"
                              v-if="!categoryList.id"
                            >
                              <i
                                class="fa fa-times-circle-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                            {{ ar + 1 }}.
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Name"
                              v-model="categoryList.name"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Code"
                              v-model="categoryList.code"
                            />
                          </td>
                          <td>
                            <my-checkbox
                              :selection.sync="categoryList.is_active"
                              placeholder=""
                            ></my-checkbox>
                          </td>
                        </tr>
                        <tr v-if="search">
                          <td
                            colspan="9"
                            align="center"
                            @click="addEmptyCategoryList()"
                          >
                            Add New Row
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <errors :data="errors" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "Categories",
  data() {
    return {
      categories: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      search: "",
      categoryLists: [],
      isSaving: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("category_lists/masters");
      masters = masters.data;
      masters.categories.forEach((category) => {
        this.categories.push({
          id: category.id,
          name: category.category_name,
        });
      });
    },
    async getData(page = 1) {
      this.isLoading = true;
      let categoryLists = await axios.get(
        `/categories/${this.search}/category_lists`
      );
      this.categoryLists = categoryLists.data.data;
      this.count = categoryLists.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    searchData() {
      this.getData(1);
    },
    addEmptyCategoryList() {
      this.categoryLists.push({
        category_id: this.search,
        site_id: this.site.id,
        name: "",
        code: "",
        is_active: 1,
      });
    },
    deleteCategoryList(categoryList) {
      this.categoryLists = this.categoryLists.filter(
        (ar) => ar != categoryList
      );
    },
    async save() {
      if (this.categoryLists.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.categoryLists,
        };
        await axios.post(
          `/categories/${this.search}/category_lists_multiple`,
          payload
        );
        this.isSaving = false;
      }
    },
  },
};
</script>

<style>
th {
}
</style>
