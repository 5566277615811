<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Banks
            <add-button link="/settings/banks/create"></add-button>
            &nbsp;
            <refresh-button @click="getData"></refresh-button>
            &nbsp;
          </h5>
          <br />
          <my-search
            style="margin: 9px !important"
            class="form-control"
            :search="search"
            placeholder="Search bank..."
            @searchData="searchData"
          ></my-search>
        </div>
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Banks</li>
          </ol>
        </div>
        <!-- <my-search
          :search="search"
          placeholder="Search bank..."
          @searchData="searchData"
        ></my-search> -->
        <!-- Breadcrumb -->
        <!-- <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Banks</li>
          </ol>
        </div> -->
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="5"
                      ></loading-table>
                      <router-link
                        v-for="(bank, at) in banks"
                        :key="`bank${at}`"
                        :to="`/settings/banks/${bank.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ bank.name }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "Banks",
  data() {
    return {
      banks: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      search: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1, search = "") {
      this.isLoading = true;
      let banks = await axios.get(
        `/banks?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${search}`
      );
      this.banks = banks.data.data;
      this.count = banks.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    searchData(bank) {
      this.getData(1, bank);
    },
  },
};
</script>

<style>
th {
}
</style>
