<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Values
            <add-button link="/settings/values/create"></add-button>
            &nbsp;
            <upload-button link="/settings/values/upload"></upload-button>
            &nbsp;
            <refresh-button @click="getData"></refresh-button>
            &nbsp;
          </h5>
        </div>
          <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Values</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
        </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-8">
                      <my-search :search="search" class="form-control" 
                       placeholder="Search value..." @searchData="searchData"></my-search>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->
          <!-- <my-search :search="search" placeholder="Search value..." @searchData="searchData"></my-search> -->
        
        
      
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table :isLoading="isLoading" cols="5"></loading-table>
                      <router-link 
                        v-for="(value, at) in values"
                        :key="`value${at}`"
                        :to="`/settings/values/${value.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ value.name }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'Values',
  data () {
    return {
      values: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      search: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData(page = 1, search = '') {
      this.isLoading = true
      let values = await axios.get(`/values?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${search}`)
      this.values = values.data.data
      this.count = values.data.count
      this.serialNoStarting = (page - 1) * this.rowsPerPage
      this.isLoading = false
    },
    searchData(value) {
      this.getData(1, value)
    }
  }
}
</script>

<!-- <style>


th {
  
}
</style> -->