export default {
  namespaced: true,
  state: {
    employee: {}
  },
  getters: {
    getEmployee(state) {

      return state.employee
    }
  },
  mutations: {
    SET_EMPLOYEE(state, data) {
      state.employee = data
      localStorage.setItem('employee', JSON.stringify(state.employee))
    }
  },
  actions: {
    setEmployee({commit}, data) {
      commit('SET_EMPLOYEE', data)
    },
    fetchEmployee({ dispatch }) {
      let fetchedEmployee = localStorage.getItem('employee') ? JSON.parse(localStorage.getItem('employee')) : {}
      dispatch('setEmployee', fetchedEmployee)
    }
  }
}