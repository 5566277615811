<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Upload Payroll</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Upload Payroll</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <my-select
                      :selection.sync="form.month_id"
                      :suggestions="months"
                      placeholder="Select month"
                    ></my-select>
                  </div>
                  <div class="col-md-6">
                    <my-select
                      :selection.sync="form.year"
                      :suggestions="years"
                      placeholder="Select year"
                    ></my-select>
                  </div>
                </div>
              </div>
              <br /><br />
              <br /><br />
              <div class="panel-body">
                &nbsp;&nbsp;
                <a
                  :href="`${mediaUrl}samples/user-sample-data.xlsx`"
                  target="_blank"
                >
                  [ Download Sample Excel ]
                </a>

                <input
                  type="file"
                  id="file"
                  name="file"
                  ref="file"
                  accept="application/ms-excel"
                  multiple
                />
                <br />
                <a href="javascript:void" @click="truncatePendingDocuments"
                  >Truncate List</a
                >
                <br /><br />
                <span style="color: red"> {{ message }} </span>
                <span v-if="isLoading"> Uploading...</span>

                <div class="form-footer" v-if="crudPayroll.length == 0">
                  <button
                    class="btn btn-primary btn-block"
                    @click="handleFileUpload"
                  >
                    Upload file
                  </button>
                </div>

                <div class="form-footer" v-else>
                  <button
                    class="btn btn-success btn-block"
                    @click="processFile"
                  >
                    Process file
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Month</th>
                        <th>Year</th>
                        <th>Employee ID</th>
                        <th>Total Days</th>
                        <th>Absent Days</th>
                        <th>PL</th>
                        <th>OT Hrs</th>
                        <th>Reimbursement</th>
                        <th>TDS</th>
                        <th>Loan</th>
                        <th>Salary Advance</th>
                        <th>Life Insurance</th>
                        <th>Uniform</th>
                        <th>Mobile</th>
                        <th>Accident Insurance</th>
                        <th>Mediclaim</th>
                        <th>Shoes</th>
                        <th>ID Card</th>
                        <th>Penalty</th>
                        <th>Others</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="2"
                      ></loading-table>
                      <tr
                        v-for="(crudePayroll, at) in crudPayroll"
                        :key="`crudePayroll${at}`"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ crudePayroll.month | mm }}</td>
                        <td>{{ crudePayroll.year }}</td>
                        <td>{{ crudePayroll.employee_id }}</td>
                        <td>{{ crudePayroll.total_days }}</td>
                        <td>{{ crudePayroll.absent_days }}</td>
                        <td>{{ crudePayroll.pl }}</td>
                        <td>{{ crudePayroll.ot_hrs }}</td>
                        <td>{{ crudePayroll.reimbursement }}</td>
                        <td>{{ crudePayroll.tds }}</td>
                        <td>{{ crudePayroll.loan }}</td>
                        <td>{{ crudePayroll.salary_advanced }}</td>
                        <td>{{ crudePayroll.life_insurance }}</td>
                        <td>{{ crudePayroll.uniform }}</td>
                        <td>{{ crudePayroll.mobile }}</td>
                        <td>{{ crudePayroll.accident_insurance }}</td>
                        <td>{{ crudePayroll.mediclaim }}</td>
                        <td>{{ crudePayroll.shoes }}</td>
                        <td>{{ crudePayroll.id_card }}</td>
                        <td>{{ crudePayroll.panelty }}</td>
                        <td>{{ crudePayroll.others }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import moment from "moment";
import axios from "axios";
export default {
  name: "uploadPayroll",
  data() {
    return {
      form: {
        month_id: "",
        month_name: "",
        year: "",
      },
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
      ],
      crudPayroll: [],
      users: [],
      message: "",
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.getMonths();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;

      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
    },
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    upothersMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      // this.searchData()
    },
    async getData(page = 1) {
      this.isLoading = true;
      let crudPayroll = await axios.get(`/crude_payrolls`);
      this.crudPayroll = crudPayroll.data.data;
      // console.log(this.crudPayroll);
      this.count = crudPayroll.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isLoading = true;
      this.Payroll = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("payrollData", this.Payroll);
      await axios
        .post(
          `upload_payroll?month=${this.form.month_id}&year=${this.form.year}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data.data) this.crudPayroll = response.data.data;
          this.isLoading = false;
          // alert("Uploaded Successfully");
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
    async processFile() {
      try {
        this.isLoading = true;
        await axios.get("process_payroll");
        alert("Processed Successfully");
        await axios.get("truncate_payrolls");
        this.crudPayroll = [];
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("truncate_payrolls");
      alert("Truncated Successfully");
      this.crudPayroll = [];
    },
    async deleteDocument($id) {
      var r = confirm("Are you sure you want to delete the document!");
      if (r == true) {
        await axios.delete(`crude_payroll/${$id}`);
        this.crudPayroll = this.crudPayroll.filter((d) => d.id != $id);
      }
    },
  },
};
</script>
