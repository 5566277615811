<template>
  <section id="mydiv">
    <div class="container-fluid">
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">LOGO</h5>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12"></div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <h3>V ALLIANCE</h3>
            <p>Office No-5-A, Raheja Arcade, Sector-11,</p>
            <p>CBD Belapur, Navi Mumbai-4000614.</p>
          </h5>
        </div>
      </div>
    </div>
    <br />
    <div class="container-fluid row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <br />
        <p>EMP Code : abd</p>
        <p>ESIC No : abd</p>
        <p>Joining Date : 12/12/2020</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p>Payslip for the month : April 2021</p>
        <p>Name : XYZ</p>
        <p>Department : Operation</p>
        <p>UAN No : 8522558</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p>Branch : Kolkata</p>
        <p>Designation : Office Boy</p>
        <p>Division : XYZ</p>
        <p>PF No : XYZ/5558882</p>
      </div>
    </div>
    <hr />
    <div class="container-fluid row">
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <!-- <br /> -->
        <p>Days Paid : 30.00</p>
        <p>OT hours : 15.00</p>
        <!-- <p>SL : 0.00</p>
        <p>Bal SL : 0.00</p> -->
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>Days Peresent : 26.00</p>
        <!-- <p>Late Hours : 0.00</p> -->
        <!-- <p>CL : 0.00</p>
        <p>Bal CL : 0.00</p> -->
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>W.Off/Pd.Off : 4.00/0.00</p>
        <!-- <p>Holiday (P) : 0.00</p> -->
        <p>PL : 0.00</p>
        <p>Bal PL No : 0.00</p>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
        <p>LWP/Absent : 0.00/0.00</p>
        <!-- <p>Holiday (A) : 0.00</p> -->
        <!-- <p>CO + / CO - : 0.00/0.00</p>
        <p>Bal CO : 0.00</p> -->
      </div>
    </div>
    <hr />
    <div class="container-fluid">
      <table class="table table-responsive col-md-10">
        <thead>
          <tr>
            <th>Earning & Reimbursements</th>
            <th>Gross Amt</th>
            <th>Actual Amt</th>
            <th>Deduction & Recoveries</th>
            <th>Gross Amt</th>
            <th>Actual Amt</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Basic Salary</td>
            <td>10856.00</td>
            <td>10856.00</td>
            <td>PROV. FUND</td>
            <td>1350.00</td>
            <td>1350.00</td>
          </tr>
          <tr>
            <td>Basic Arrears</td>
            <td></td>
            <td>26250.00</td>
            <td>PROV. FUND Arrears</td>
            <td></td>
            <td>969.00</td>
          </tr>
          <tr>
            <td>D.A</td>
            <td>390.00</td>
            <td>390.00</td>
            <td>E.S.I.C</td>
            <td>129.00</td>
            <td>156.00</td>
          </tr>
          <tr>
            <td>D.A Arrears</td>
            <td></td>
            <td>-18203.00</td>
            <td>E.S.I.C Arrears</td>
            <td></td>
            <td>84.00</td>
          </tr>
          <tr>
            <td>H.R.A</td>
            <td>362.00</td>
            <td>362.00</td>
            <td>p. Tax</td>
            <td>0.00</td>
            <td>200.00</td>
          </tr>
          <tr>
            <td>H.R.A Arrears</td>
            <td></td>
            <td>402.00</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>C.C.A</td>
            <td>4275.00</td>
            <td>4275.00</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Leave Encashment</td>
            <td>1083.00</td>
            <td>1083.00</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Leave Encashment Arrears</td>
            <td></td>
            <td>573.00</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Over Time</td>
            <td>0.00</td>
            <td>3565.00</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Over Time Arrears</td>
            <td></td>
            <td>2108.00</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th>Total Earnings</th>
            <th>17166.00</th>
            <th>31861.00</th>
            <th><p>Total Deductions</p>
            <p>Net Pay</p></th>
            <th>1479.00</th>
            <th><p>2759.00</p>
            <p>29192.00</p></th>
          </tr>
        </tbody>
      </table>
      <p>Net Pay: Rupees Twenty Nine Thousand One Hundred Two Only </p>
      <p>*This is computer generated salary slip and requires no physical signature or stamp*</p>
      <p>Bank Name: ICICI Bank - Bank A/c No. : 55121545155421 </p>
    </div>
  </section>
</template>

<script>
import html2pdf from 'html2pdf.js'
export default {
  name: 'PDF',
//  mounted: function () {
//     this.createPDF()
// },
  methods:{
    createPDF() {
      
      var element = document.getElementById('mydiv');

      var opt = {
        margin: 0,
        filename: 'Payslip2.pdf',
        image: {type: 'jpeg',quality: 0.98},
        html2canvas: {scale: 2},
        jsPDF: {
          unit: 'mm',
          format: [280, 350],
          orientation: 'portrait'
        }
      };

      html2pdf().set(opt).from(element).save();
    },
  }
}
</script>
<style scoped>
td:nth-child(4), th:nth-child(4) {
 border-left: solid 2px black;
}

</style>