<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Bank Branches
            <save-button @click="save"></save-button>
          </h5>
            <br><br/>         
           <my-select
            @change="searchData"
            :selection.sync="search"
            :suggestions="banks"
            placeholder="Select value"
          ></my-select>
          <small v-if="isSaving">Saving...</small>
          <br/>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Bank Branches</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="editable-table-wrapper">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Branch Address</th>
                          <th>IFSC Code</th>
                          <th>Is Active</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(bankBranch, ar) in bankBranches"
                          :key="`bankBranch${ar}`"
                        >
                          <td>
                            <span
                              @click="deleteBankBranch(bankBranch)"
                              v-if="!bankBranch.id"
                            >
                              <i
                                class="fa fa-times-circle-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                            {{ ar + 1 }}.
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Branch Address"
                              v-model="bankBranch.branch_address"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="IFSC Code"
                              v-model="bankBranch.ifsc_code"
                            />
                          </td>
                          <td>
                            <my-checkbox
                              :selection.sync="bankBranch.is_active"
                              placeholder=""
                            ></my-checkbox>
                          </td>
                        </tr>
                        <tr v-if="search">
                          <td
                            colspan="9"
                            align="center"
                            @click="addEmptyBankBranch()"
                          >
                            Add New Row
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <errors :data="errors" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "Banks",
  data() {
    return {
      banks: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      search: "",
      bankBranches: [],
      isSaving: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("bank_branches/masters");
      masters = masters.data;
      masters.banks.forEach((value) => {
        this.banks.push({
          id: value.id,
          name: value.name,
        });
      });
    },
    async getData(page = 1) {
      this.isLoading = true;
      let bankBranches = await axios.get(
        `/banks/${this.search}/bank_branches?page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.bankBranches = bankBranches.data.data;
      this.count = bankBranches.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    searchData() {
      this.getData(1);
    },
    addEmptyBankBranch() {
      this.bankBranches.push({
        bank_id: this.search,
        site_id: this.site.id,
        branch_address: "",
        ifsc_code: "",
      });
    },
    deleteBankBranch(bankBranch) {
      this.bankBranches = this.bankBranches.filter((ar) => ar != bankBranch);
    },
    async save() {
      if (this.bankBranches.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.bankBranches,
        };
        await axios.post(
          `/banks/${this.search}/bank_branches_multiple`,
          payload
        );
        this.isSaving = false;
      }
    },
  },
};
</script>

<style>
th {
}
</style>