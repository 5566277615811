<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            ESIC Configuration
            <add-button link="/settings/esic-configurations/create"></add-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">ESIC Configuration</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Effective Date</th>
                        <th>Employer ESIC</th>
                        <th>Employee ESIC</th>
                        <!-- <th>Min Wage</th> -->
                        <th>Max Wage</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table :isLoading="isLoading" cols="8"></loading-table>
                      <router-link 
                        v-for="(esicConfiguration, at) in esic_configurations"
                        :key="`esicConfiguration${at}`"
                        :to="`/settings/esic-configurations/${esicConfiguration.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ esicConfiguration.effective_date | ddmmyyyy }}</td>
                        <td>{{ esicConfiguration.employer_value }} {{ esicConfiguration.employer_type == 1 ? 'Flat' : '%' }}</td>
                        <td>{{ esicConfiguration.employee_value }} {{ esicConfiguration.employee_type == 1 ? 'Flat' : '%' }}</td>
                        <!-- <td>{{ esicConfiguration.min_wage }} </td> -->
                        <td>{{ esicConfiguration.max_wage }} </td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'esicConfigurations',
  data () {
    return {
      esic_configurations: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true
      let esic_configurations = await axios.get(`/esic_configurations?page=${page}&rowsPerPage=${this.rowsPerPage}&search=all`)
      this.esic_configurations = esic_configurations.data.data
      this.count = esic_configurations.data.count
      this.serialNoStarting = (page - 1) * this.rowsPerPage
      this.isLoading = false
    }
  }
}
</script>