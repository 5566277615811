education.vue
<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;">Joining Details</span>
            <span style="float: right;">
              <i class="fa fa-pencil-square-o" aria-hidden="true" v-if="!editData" @click="toggleEditData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="editData" @click="toggleEditData"></i>
            </span>
          </div>

          <br>

          <div class="row">

            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Supervisor</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.supervisor ? form.supervisor.full_name : '' }}</p>
                <my-select :selection.sync="form.supervisor_id" :suggestions="supervisors" placeholder="Select supervisor" v-if="editData"></my-select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Group</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.group ? form.group.name : '' }}</p>
                <my-select @change="selectDivision" :selection.sync="form.group_id" :suggestions="groups" placeholder="Select Group" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Group Division</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.group_division ? form.group_division.division_name : '' }}</p>
                <my-select :selection.sync="form.group_division_id" :suggestions="groupDivisions" placeholder="Select Group Division" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Joined On</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.joined_on | ddmmyyyy }}</p>
                <my-datepicker :date.sync="form.joined_on" placeholder="Joined On" v-if="editData"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Confirmation Date</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.confirmation_date | ddmmyyyy }}</p>
                <my-datepicker :date.sync="form.confirmation_date" placeholder="Confirmation Date" v-if="editData"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Employee Status</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.employee_status ? form.employee_status.code : '' }}</p>
                <my-select :selection.sync="form.employee_status_id" :suggestions="employeeStatuses" placeholder="Select Employee Status" v-if="editData"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Date Left</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.date_left | ddmmyyyy}}</p>
                <my-datepicker :date.sync="form.date_left" placeholder="Date Left" v-if="editData"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Probation Period</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.probation_period }} Days</p>
                <input v-uppercase type="number" class="form-control" placeholder="Probation Period (in Days)" v-if="editData" v-model="form.probation_period">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Notice Period</label>
              <div class="col-sm-8">
                <p v-if="!editData">{{ form.notice_period }} Days</p>
                <input v-uppercase type="number" class="form-control" placeholder="Notice Period (in Days)" v-if="editData" v-model="form.notice_period">
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-10"
                v-if="editData"
              >
                <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      editData: false,
      groupDivisions: [],
    }
  },
  props: ['form', 'isLoading', 'employeeStatuses', 'groups', 'supervisors'],
  watch: {
    'form': 'selectDivision',
    'groups': 'selectDivision'
  },
  methods: {
    toggleEditData() {
      this.editData = !this.editData
    },
    cancelUpdate() {
      this.editData = false
    },
    async update() {
      this.$emit('update')
      this.editData = false
    },
    selectDivision(){
      let group = this.groups.find(group  =>  group.id == this.form.group_id);
      this.groupDivisions = [];
      group.group_divisions.forEach(groupDivision => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        }) 
      })
    },
  }
}
</script>