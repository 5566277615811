var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"mydiv"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row heading-bg"},[_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-4 col-xs-12"}),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-4 col-xs-12"},[_c('h5',{staticClass:"txt-dark"},[_c('center',[_c('p',[_vm._v("COMBINED CHALLAN - A/C NO.1, 2, 3, 4 & 5 ")])]),_c('center',[_c('p',[_vm._v("STATE BANK OF INDIA ")])]),_c('h5',[_vm._v(" EMPLOYEE'S PROVIDENT FUND ORGANIZATION")]),_c('center',[_c('p',[_vm._v("(USE SEPERATE CHALLAN FOR EACH MONTH)")])])],1)]),_vm._m(0),_vm._m(1)])]),_c('br'),_vm._m(2),_c('hr'),_c('div',{staticClass:"container-fluid row"},[_vm._m(3),_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-4 col-xs-12"},[_c('p',[_vm._v("Employees Share : "+_vm._s(_vm.month_name)+" "+_vm._s(_vm.year))]),_c('p',[_vm._v("Employer Share : "+_vm._s(_vm.month_name)+" "+_vm._s(_vm.year))])]),_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-4 col-xs-12"}),_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-4 col-xs-12"},[_c('p',[_vm._v("DATE OF PAYMENT: "+_vm._s(_vm.date_of_payment))])])]),_c('hr'),_vm._m(4),_c('hr'),_c('div',{staticClass:"container-fluid"},[_vm._m(5),_c('center',[_c('p',[_vm._v("(Amount in words Four Lac Fifty Eight Thousand Six Hundred Seventy Eight Only)")])])],1),_vm._m(6),_c('hr'),_c('div',{staticClass:"container-fluid"},[_c('center',[_c('p',{staticStyle:{"font-size":"20px","font-weight":"bold"}},[_vm._v(" FOR USE IN REPECT OF PAST ACCUMULATIONS ONLY")])]),_vm._m(7)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-2 col-md-4 col-sm-4 col-xs-12"},[_c('p',[_vm._v("Orignal")]),_c('p',[_vm._v("Triplicate")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-2 col-md-4 col-sm-4 col-xs-12"},[_c('p',[_vm._v("Duplicate")]),_c('p',[_vm._v("Quadruplicate")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid row"},[_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-4 col-xs-12"},[_c('p',[_vm._v("ESTABLISHMENT CODE NO.: abd/6652563")])]),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-4 col-xs-12"},[_c('p',[_vm._v("ACCOUNT GROUP NO:- ")])]),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-4 col-xs-12"},[_c('p',[_vm._v("PAID BY CHEQUE/CASH cheque")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-md-4 col-sm-4 col-xs-12"},[_c('p',[_vm._v("DUES FOR THE MONTH OF:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid row"},[_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-4 col-xs-12"},[_c('p',[_vm._v("Total No. Of Subscribers:")]),_c('br'),_c('p',[_vm._v("Total Wages Due:")])]),_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-4 col-xs-12"},[_c('input',{attrs:{"type":"text","name":"","id":"","value":"187"}}),_c('p',[_vm._v("A/C No. 1")]),_c('input',{attrs:{"type":"text","name":"","id":"","value":"1834565.00"}})]),_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-4 col-xs-12"},[_c('input',{attrs:{"type":"text","name":"","id":"","value":"185"}}),_c('p',[_vm._v("A/C No. 3")]),_c('input',{attrs:{"type":"text","name":"","id":"","value":"1813940.00"}})]),_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-4 col-xs-12"},[_c('input',{attrs:{"type":"text","name":"","id":"","value":"187"}}),_c('p',[_vm._v("A/C No. 4")]),_c('input',{attrs:{"type":"text","name":"","id":"","value":"1834565.00"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table table-responsive col-md-10"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Sr.")]),_c('th',[_vm._v("No. PARTICULARS")]),_c('th',[_vm._v("A/C NO 1")]),_c('th',[_vm._v("A/C NO 2")]),_c('th',[_vm._v("A/C NO 3")]),_c('th',[_vm._v("A/C NO 4")]),_c('th',[_vm._v("A/C NO 5")]),_c('th',[_vm._v("TOTAL")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("PART NO 1")]),_c('td'),_c('td'),_c('td',{attrs:{"colspan":"4"}},[_vm._v("---AMOUNT IN (RUPEES)---")])]),_c('tr',[_c('td',[_vm._v("1.")]),_c('td',[_vm._v("EMPLOYER'S SHARES OF CONT.")]),_c('td',[_vm._v("69027.00")]),_c('td'),_c('td',[_vm._v("151125.00")]),_c('td',[_vm._v("9187.00")]),_c('td'),_c('td',[_vm._v("229339.00")])]),_c('tr',[_c('td',[_vm._v("2.")]),_c('td',[_vm._v("EMPLOYEE'S SHARES OF CONT.")]),_c('td',[_vm._v("220152.00")]),_c('td'),_c('td',[_vm._v("0.00")]),_c('td'),_c('td'),_c('td',[_vm._v("220152.00")])]),_c('tr',[_c('td',[_vm._v("3.")]),_c('td',[_vm._v("ADM. CHARGES")]),_c('td'),_c('td',[_vm._v("9187.00")]),_c('td'),_c('td'),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("9187.00")])]),_c('tr',[_c('td',[_vm._v("TOTAL")]),_c('td',[_vm._v("289179.00")]),_c('td',[_vm._v("91187.00")]),_c('td',[_vm._v("151125.00")]),_c('td',[_vm._v("9187.00")]),_c('td',[_vm._v("0.00")]),_c('td',[_vm._v("458678.00")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid row"},[_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-4 col-xs-12"},[_c('p',[_vm._v("NAME OF ESTABLISHMENT:")]),_c('br'),_c('p',[_vm._v("Address")]),_c('br'),_c('br'),_c('br'),_c('p',[_vm._v("NAME OF THE DEPOSITOR")]),_c('p',[_vm._v("SIGNATURE OF THE DEPOSITOR")]),_c('br'),_c('br'),_c('p',[_vm._v("Name Of The Bank: ICICI Bank")])]),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-4 col-xs-12"},[_c('p',{staticStyle:{"font-size":"20px","font-weight":"bold"}},[_vm._v("V ALLIANCE")]),_c('br'),_c('p',[_vm._v("Office No-5-A, Raheja Arcade, Sector-11,")]),_c('p',[_vm._v("CBD Belapur, Navi Mumbai-4000614.")]),_c('p',[_vm._v("Plot no-61")]),_c('p',[_vm._v("Navi Mumbai-4000614.")]),_c('br'),_c('br'),_c('p',{staticStyle:{"font-size":"18PX","font-weight":"bold"}},[_vm._v("(TO BE FILLED BY EMPLOYER)")]),_c('p',[_vm._v("CHEQUE NO")])]),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-4 col-xs-12"},[_c('p',[_vm._v("(For Bank Use Only)")]),_c('p',[_vm._v("Amount Received Rs:")]),_c('p',[_vm._v("For Cheque Only:")]),_c('p',[_vm._v("Date Of Peresentation:")]),_c('br'),_c('br'),_c('p',[_vm._v("Date Of Realization:")]),_c('p',[_vm._v("Branch Name:")]),_c('p',[_vm._v("DATE: 12/04/2021")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table table-responsive col-md-10"},[_c('thead',[_c('tr',[_c('th',[_vm._v("S. No. PARTICULARS")]),_c('th',[_vm._v("A/C NO 1")]),_c('th',[_vm._v("A/C NO 2")]),_c('th',[_vm._v("A/C NO 3")]),_c('th',[_vm._v("A/C NO 4")]),_c('th',[_vm._v("A/C NO 5")]),_c('th',[_vm._v("TOTAL")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("PART NO 2 ")]),_c('td'),_c('td'),_c('td',{attrs:{"colspan":"4"}},[_vm._v("---AMOUNT IN (RUPEES)---")])]),_c('tr',[_c('td',[_vm._v("1 EER'S SHARE OF PAST ACCUM.")]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')]),_c('tr',[_c('td',[_vm._v("2. EES' SHARE OF PAST ACCUM.")]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')]),_c('tr',[_c('td',[_vm._v("3. INT. ON SECURITES")]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')]),_c('tr',[_c('td',[_vm._v("TOTAL")]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')])])])
}]

export { render, staticRenderFns }