<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/settings/leave-configurations"></back-button> 
            Add Leave Configurations
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/settings/leave-configurations">Leave Configurations</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Effective Date</label>
                        <div class="col-sm-9">
                          <my-datepicker :date.sync="form.effective_date" placeholder="Effective Date"></my-datepicker>
                          <span class="error-block">{{ errors.effective_date }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Employer Leave</label>
                        <div class="col-sm-5">
                          <input v-uppercase type="text" class="form-control" placeholder="Employer Value" v-model="form.employer_value">
                          <span class="error-block">{{ errors.employer_value }}</span>
                        </div>
                        <div class="col-sm-4">
                          <my-select :selection.sync="form.employer_type" :suggestions="configuration_types" placeholder="Employer Type"></my-select>
                          <span class="error-block">{{ errors.employer_type }}</span>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'leaveConfigurationCreate',
  data () {
    return {
      form: {
        effective_date: new Date(),
        employer_value: 0,
        employer_type: 2,
      },
      configuration_types: [
        {id: 1, name: 'Flat',},
        {id: 2, name: '%',},
      ],
      isLoading: false,
    }
  },
  mounted() {
    this.form.site_id = this.site.id
  },
  methods: {
    async save() {
      try {
        this.isLoading = true
        await axios.post('/leave_configurations', this.form)
        this.isLoading = false
        this.$router.push('/settings/leave-configurations')
      } catch(e) {
        this.isLoading = false
      }
    }
  }
}
</script>