<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">
          <div class="row">
            <span style="float: left;"></span>
            <span style="float: right;">
              <i class="fa fa-pencil-square-o" aria-hidden="true" v-if="!editData" @click="toggleEditData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="editData" @click="toggleEditData"></i>
            </span>
          </div>
          <br>

          <div class="row">
            <div class="col-md-4">
              <img v-if="form.image_path" :src="mediaUrl + form.image_path" style="width: 100px; height: 100px; border-radius: 50px;" />
              <button @click="removePhoto" v-if="form.image_path" class="btn btn-link" style="color: blue;">remove</button>
              <img v-else :src="`${mediaUrl}/user.png`" style="width: 100px; ">
            </div>
            <div class="col-md-8">

              <div class="form-group row">
                <label class="col-sm-4 col-form-label" v-if="editData">Photo</label>
                <div class="col-sm-8">
                  <!-- <p v-if="!editData">{{ form.image_path }}</p> -->
                  <input
                    type="file"
                    id="file"
                    name="imagepath"
                    ref="file"
                    accept="image/*"
                    multiple
                    v-if="editData" 
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">EMP ID</label>
                <div class="col-sm-8">
                  <p v-if="!editData">{{ form.employee_id }}</p>
                  <input v-uppercase type="text" class="form-control" placeholder="Employee ID" v-if="editData" v-model="form.employee_id">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Title</label>
                <div class="col-sm-8">
                  <p v-if="!editData">{{ form.title }}</p>
                  <my-select :selection.sync="form.title" :suggestions="titles" placeholder="Select title" v-if="editData"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Name</label>
                <div class="col-sm-8">
                  <p v-if="!editData">{{ form.full_name }}</p>
                  <input v-uppercase type="text" class="form-control" placeholder="Name" v-if="editData" v-model="form.full_name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Email</label>
                <div class="col-sm-8">
                  <p v-if="!editData">{{ form.email }}</p>
                  <input v-uppercase type="text" class="form-control" placeholder="Email" v-if="editData" v-model="form.email">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Mobile</label>
                <div class="col-sm-8">
                  <p v-if="!editData">{{ form.mobile }}</p>
                  <input v-uppercase type="text" class="form-control" placeholder="Mobile" v-if="editData" v-model="form.mobile">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-10"
                  v-if="editData"
                >
                  <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  data () {
    return {
      editData: false,
      titles: [
        {id: 'Mr.', name: 'Mr.'},
        {id: 'Mrs.', name: 'Mrs.'},
        {id: 'Miss', name: 'Miss'},
      ]
    }
  },
  props: ['form', 'isLoading'],
  methods: {
    toggleEditData() {
      this.editData = !this.editData
    },
    cancelUpdate() {
      this.editData = false
    },
    async update() {
      if(this.$refs.file.files.length > 0)
        await this.handleFileUpload()
      this.$emit('update')
      this.editData = false
    },
    async removePhoto() {
      this.form.image_path = ''
      await this.handleFileUpload()
      this.update()
    },
    async handleFileUpload() {
      let attachment = this.$refs.file?.files[0]
      const userid = this.form.id
      let formData = new FormData()
      formData.append("userid", userid)
      formData.append("imagepath", attachment)
      await axios
        .post("upload_user_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  }
}
</script>