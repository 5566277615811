<template>
  <section id="mydiv">
    <div class="container-fluid">
        <div class="col-lg-6 col-md-6 col-sm-4 col-xs-12">
          <p style="font-weight: bold; font-size: 16px">
            Name and address of the Factory / Establishment
          </p>
          <p>V ALLIANCE</p>
          <p>Office No-5-A, Raheja Arcade, Sector-11,</p>
          <p>CBD Belapur, Navi Mumbai-4000614.</p>
          <p>Plot no-61</p>
          <p>Navi Mumbai-4000614.</p>
          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <center style="font-weight: bold; font-size: 18px">
              <p>Form No.5A</p>
              <p>The Employee's Provident Fund Scheme, 1952 [Paragraph 36(2)(a)
                and 36(2)(b)] </p>
              <p>And Employee's Pension Scheme, 1955 [Para 20(4)]</p>
              <p>
                Contribution Card for Currency Period From 1st April 2020 to
                31st March 2021
              </p>
            </center>
          </h5>
        </div>
    </div>
    <div class="container-fluid">
        <div class="col-lg-6 col-md-6 col-sm-4 col-xs-12">
          <br />
          <br />
          <br />
          <br />
          <p>Code No. of Factory / Estt.:</p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-4 col-xs-12">
           <p>RETURN OF ADDICTIONS FOR THE MONTH OF April 2020</p>
          <p>PART 'A'. Details of employee qualifying for membership of </p>
          <p>Employee's provident Fund for the first time.</p>
          <p style="font-size: 11px;">(To be sent to Commissioner with form No.2)</p>
        </div>
    </div>
    <div class="container-fluid">
      <table id="table-example-1" class="table table-responsive col-md-10">
        <thead>
          <tr>
            <th>Sr. No</th>
            <th>Account No.</th>
            <th>UAN No.</th>
            <th>Name of the employees (in block letters)</th>
            <th>father's name or husband's name</th>
            <th>date of birth</th>
            <th>sex</th>
            <th>date of eligibility for new member / date of joining the estt. in case of old member</th>
            <th>total period of Perivious service (excluding period of breaks) as on the date of joining the Fund</th>
            <th>remarks: preivious A/c No. and particulars  of  previous employer, if any, (if so whether he is a member of family pension fund also)</th>
          </tr>
          <tr>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
            <th>6</th>
            <th>7</th>
            <th>8</th>
            <th>9</th>
            <th>10</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
         <tr>
            <td>2.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
         <tr>
            <td>3.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>4.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
         <tr>
            <td>5.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
         <tr>
            <td>6.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>7.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>8.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>9.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>10.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>11.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>12.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>13.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
         <tr>
            <td>14.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
         <tr>
            <td>15.</td>
            <td>THVSH/1585170/0010340</td>
            <td>100166295429</td>
            <td>NAGENDRA DADI</td>
            <td>VENKU NAIDU</td>
            <td>12/06/1984</td>
            <td>MALE</td>
            <td>14/10/2020</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      [Also fill in Part 'B' on reverse.]
    </div>
    <div class="container-fluid row">
      <div class="col-lg-9 col-md-9 col-sm-4 col-xs-12">
      </div>
      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
        <br />
        <br />
        <hr>
        <p>Signature of the employer or other authorised officer</p>
        <p>and stamp of the factory / Establishment</p>
      </div>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "PDFForm5A",
   mounted: function () {
      this.createPDF()
  },
  methods: {
    createPDF() {
      var element = document.getElementById("mydiv");

      var opt = {
        margin: 0,
        filename: "PFForm5A.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [380, 420],
          orientation: "portrait",
        },
      };

      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
