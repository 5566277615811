<template>
  <section id="mydiv">
    <div class="container-fluid">
      <div class="col-md-12">
        <p style="font-weight: bold">
          <center>V ALLIANCE</center>
        </p>
        <p style="font-weight: bold">
          <center>FORM III ( See Rule 12 )</center>
        </p>
        <br />

        <div class="row">
          <div class="col-md-6">
            <p>
              P.Tax Registration Certificate No. : 27801522796P
            </p>
            <p>
              Enrollment Certificate No. :
            </p>
          </div>

          <div class="col-md-6">
            <p>For Period : April 2021</p>
          </div>
        </div>
        <br />

        <div class="row">
          <table id="table-example-1" class="table table-responsive">
            <thead>
              <tr>
                <th>
                  Salary and wage earners. Such persons whose monthly salaries
                  or wage are -
                </th>
                <th>No. of EmployeeS</th>
                <th>Rate of Tax ( Rs. )</th>
                <th>Amount of Tax Deducted</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>From 0 to 7500</td>
                <td>22</td>
                <td>0</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Exceed 7500 but not Exceed 10000 - female</td>
                <td>1</td>
                <td>0</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>From 7501 to 10000</td>
                <td>20</td>
                <td>175</td>
                <td>3500.00</td>
              </tr>
              <tr>
                <td>From 10001 to 9999999</td>
                <td>115</td>
                <td>200</td>
                <td>23000.00</td>
              </tr>
              <tr>
                <td colspan="3" style="font-weight: bold">Total Amount</td>
                <td>26500.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />

        <div class="row">
          <p>
            The above statements are true or best of my knowledge and belief.
          </p>
          <br />
          <div class="col-md-6">
            <p>
              Date : 01/06/2021
            </p>
            <p>
              Place : MUMBAI
            </p>
          </div>

          <div class="col-md-6">
            <p>Signature</p>
            <p>Designation</p>
          </div>
        </div>
        <br />

        <div class="row">
          <hr />

          <p style="font-size: 20px; font-weight: bold">V ALLIANCE</p>
          <p>Office No-5-A, Raheja Arcade, Sector-11,</p>
          <p>CBD Belapur, Navi Mumbai-4000614.</p>
          <p>Plot no-61</p>
          <p>Navi Mumbai-4000614.</p>
          <p>Ph. No. 62448686</p>
          <hr />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "PTRegister",
  mounted: function() {
      this.createPDF()
  },
  methods: {
    createPDF() {
      var element = document.getElementById("mydiv");

      var opt = {
        margin: 0,
        filename: "PTRegister.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>
