import Vue from 'vue'

// Components
import SubmitButton from '@/views/widgets/submit-button.vue'
import AddButton from '@/views/widgets/add-button.vue'
import UploadButton from '@/views/widgets/upload-button.vue'
import BackButton from '@/views/widgets/back-button.vue'
import LoadingTable from '@/views/widgets/loading-table.vue'
import Pagination from '@/views/widgets/pagination.vue'
import RefreshButton from '@/views/widgets/refresh-button.vue'
import MySearch from '@/views/widgets/my-search.vue'
import MySelect from '@/views/widgets/my-select.vue'
import MyCheckbox from '@/views/widgets/my-checkbox.vue'
import SaveButton from '@/views/widgets/save-button.vue'
import Errors from '@/views/widgets/errors.vue'
import MyDatepicker from '@/views/widgets/my-datepicker.vue'
import MyTimepicker from '@/views/widgets/my-timepicker.vue'

Vue.component('submit-button', SubmitButton)
Vue.component('add-button', AddButton)
Vue.component('upload-button', UploadButton)
Vue.component('back-button', BackButton)
Vue.component('loading-table', LoadingTable)
Vue.component('pagination', Pagination)
Vue.component('refresh-button', RefreshButton)
Vue.component('my-search', MySearch)
Vue.component('my-select', MySelect)
Vue.component('my-checkbox', MyCheckbox)
Vue.component('save-button', SaveButton)
Vue.component('errors', Errors)
Vue.component('my-datepicker', MyDatepicker)
Vue.component('my-timepicker', MyTimepicker)
