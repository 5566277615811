<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">
          <div class="row">
            <span style="float: left"><b>Add Salary Details</b></span>
          </div>

          <br />

          <div class="row">
            <div class="col-md-4">
              <p><u>Gross Salary</u></p>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label"
                  >Last Effective Date</label
                >
                <div class="col-sm-8">
                  <my-datepicker
                    :date.sync="user_salary.effective_date"
                    placeholder="Effective Date"
                  ></my-datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Basic</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Basic"
                    v-model="user_salary.fixed_basic"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">DA</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="DA"
                    v-model="user_salary.fixed_da"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">HRA</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="HRA"
                    v-model="user_salary.fixed_hra"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Conveyance</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Conveyance"
                    v-model="user_salary.fixed_conveyance"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Medical Allowance</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Medical Allowance"
                    v-model="user_salary.fixed_medical_allowance"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label"
                  >Special Allowance
                </label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Special Allowance"
                    v-model="user_salary.fixed_special_allowance"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">CCA</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="CCA"
                    v-model="user_salary.fixed_cca"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Other Allowance</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Other Allowance"
                    v-model="user_salary.fixed_other_allowance"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Washing Allowance</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Washing Allowance"
                    v-model="user_salary.fixed_washing_allowance"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Leave</label>
                <div
                  class="col-sm-8"
                  v-if="this.form.is_leave_application == 1"
                >
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Leave"
                    v-model="user_salary.leave_encashment"
                  />
                </div>
                <div class="col-sm-8" v-else>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Leave"
                    v-model="user_salary.leave_encashment"
                    disabled
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Bonus</label>
                <div class="col-sm-8" v-if="this.form.is_bonus_applicable == 1">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Bonus"
                    v-model="user_salary.bonus"
                  />
                </div>
                <div class="col-sm-8" v-else>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Bonus"
                    v-model="user_salary.bonus"
                    disabled
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Mobile Allowance</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Mobile Allowance"
                    v-model="user_salary.fixed_mobile_allowance"
                  />
                </div>
              </div>
              <hr />
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Gross Salary</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Gross Salary"
                    :value="gross"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">OT Rate</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="OT Rate"
                    v-model="user_salary.ot_rate"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <p><u>Statutory Employee</u></p>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">PF</label>
                <div class="col-sm-8">
                  <input
                    disabled
                    type="number"
                    class="form-control"
                    placeholder="PF"
                    v-model="employee_pf"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">ESIC</label>
                <div class="col-sm-8">
                  <input
                    disabled
                    type="number"
                    class="form-control"
                    placeholder="ESIC"
                    v-model="employee_esic"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">PT</label>
                <div class="col-sm-8">
                  <input
                    disabled
                    type="number"
                    class="form-control"
                    placeholder="PT"
                    v-model="employee_pt"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">MLWF</label>
                <div class="col-sm-8">
                  <input
                    disabled
                    type="number"
                    class="form-control"
                    placeholder="MLWF"
                    v-model="user_salary.employee_mlwf"
                  />
                </div>
              </div>
              <hr />
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Net In Hand</label>
                <div class="col-sm-8">
                  <input
                    disabled
                    type="number"
                    class="form-control"
                    placeholder="Net In Hand"
                    v-model="net_in_hand"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <p><u>Statutory Employer</u></p>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">PF</label>
                <div class="col-sm-8">
                  <input
                    disabled
                    type="number"
                    class="form-control"
                    placeholder="PF"
                    v-model="employer_pf"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">ESIC</label>
                <div class="col-sm-8">
                  <input
                    disabled
                    type="number"
                    class="form-control"
                    placeholder="ESIC"
                    v-model="employer_esic"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">MLWF</label>
                <div class="col-sm-8">
                  <input
                    disabled
                    type="number"
                    class="form-control"
                    placeholder="MLWF"
                    v-model="employer_lwf"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Bonus</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Bonus"
                    v-model="employer_bonus"
                    disabled
                  />
                </div>
              </div>
              <!-- <div class="form-group row">
                <label class="col-sm-4 col-form-label">Bonus</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" placeholder="Bonus" v-model="user_salary.employer_bonus" disabled>
                </div>
              </div> -->
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Leave Encashment</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Leave Encashment"
                    v-model="employer_leave_salary"
                    disabled
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Gratuity</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Gratuity"
                    v-model="user_salary.employer_graduity"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Uniform Charges</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Uniform Charges"
                    v-model="user_salary.employer_uniform_charges"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label"
                  >Administrative Charges</label
                >
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Administrative Charges"
                    v-model="user_salary.employer_administrative_charges"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Training Charges</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Training Charges"
                    v-model="user_salary.employer_training_charges"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Relivery Charges</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Relivery Charges"
                    v-model="user_salary.employer_reliver_charges"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label"
                  >Min. Wage Provision</label
                >
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Min. Wage Provision"
                    v-model="user_salary.employer_min_wage"
                  />
                </div>
              </div>
              <hr />
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Total Cost</label>
                <div class="col-sm-8">
                  <input
                    disabled
                    type="number"
                    class="form-control"
                    placeholder="Total Cost"
                    :value="total_cost"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label"></label>
                <div class="col-sm-8">
                  <my-checkbox
                    :selection.sync="user_salary.is_updated_manually"
                    placeholder="Is Updated Manaually"
                  ></my-checkbox>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Management Fees</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Management Fees"
                    v-model="user_salary.management_fees"
                    v-if="user_salary.is_updated_manually"
                  />
                  <p v-else>{{ management_fees }}</p>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Total CTC</label>
                <div class="col-sm-8">
                  <input
                    disabled
                    type="number"
                    class="form-control"
                    placeholder="Total CTC"
                    v-model="total_ctc"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Billing OT Rate</label>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Billing OT Rate"
                    v-model="user_salary.billing_ot_rate"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group mb-0">
            <div class="col-sm-9">
              <submit-button
                :isLoading="isLoading"
                @submit="save"
                text="Save"
              ></submit-button>
            </div>
          </div>
          <hr />
          <h5 class="txt-dark">Salary Revisions</h5>
          <!-- Row -->
          <div class="row">
            <div class="col-sm-12">
              <div class="panel panel-default card-view">
                <div class="panel-wrapper collapse in">
                  <div class="panel-body">
                    <div class="table-wrap">
                      <table class="table table-hover table-striped">
                        <thead>
                          <tr>
                            <th>
                              Sr. N
                              <center>o.</center>
                            </th>
                            <th>Effective Date</th>
                            <th>Basic</th>
                            <th>DA</th>
                            <th>HRA</th>
                            <th>Conveyance</th>
                            <th>Medical Allowance</th>
                            <th>Special Allowance</th>
                            <th>CCA</th>
                            <th>Other Allowance</th>
                            <th>Washing Allowance</th>
                            <th>Leave Encashment</th>
                            <th>Bonus</th>
                            <th>Mobile Allowance</th>
                            <th>Gross Salary</th>
                            <th>OT Rate</th>
                            <th>Employee PF</th>
                            <th>Employee ESIC</th>
                            <th>Employee PT</th>
                            <th>Employee MLWF</th>
                            <th>Employee Net In Hand</th>
                            <th>Employer PF</th>
                            <th>Employer ESIC</th>
                            <th>Employer Bonus</th>
                            <th>Employer Leave Salary</th>
                            <th>Employer Graduity</th>
                            <th>Employer Uniform Charges</th>
                            <th>Employer Administrative Charges</th>
                            <th>Employer Training Charges</th>
                            <th>Employer Reliver Charges</th>
                            <th>Employer Min. Wage Provision</th>
                            <th>Total Cost</th>
                            <th>Management Fees</th>
                            <th>Total CTC</th>
                            <th>Billing OT Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <loading-table
                            :isLoading="isLoading"
                            cols="8"
                          ></loading-table>
                          <tr
                            v-for="(user_salary, us) in form.user_salaries"
                            :key="`user_salary${us}`"
                          >
                            <td>{{ us + 1 }}</td>
                            <td>{{ user_salary.effective_date | ddmmyyyy }}</td>
                            <td>{{ user_salary.fixed_basic }}</td>
                            <td>{{ user_salary.fixed_da }}</td>
                            <td>{{ user_salary.fixed_hra }}</td>
                            <td>{{ user_salary.fixed_conveyance }}</td>
                            <td>{{ user_salary.fixed_medical_allowance }}</td>
                            <td>{{ user_salary.fixed_special_allowance }}</td>
                            <td>{{ user_salary.fixed_cca }}</td>
                            <td>{{ user_salary.fixed_other_allowance }}</td>
                            <td>{{ user_salary.fixed_washing_allowance }}</td>
                            <td>{{ user_salary.leave_encashment }}</td>
                            <td>{{ user_salary.bonus }}</td>
                            <td>{{ user_salary.fixed_mobile_allowance }}</td>
                            <td>{{ user_salary.gross_salary }}</td>
                            <td>{{ user_salary.ot_rate }}</td>
                            <td>{{ user_salary.employee_pf }}</td>
                            <td>{{ user_salary.employee_esic }}</td>
                            <td>{{ user_salary.employee_pt }}</td>
                            <td>{{ user_salary.employee_mlwf }}</td>
                            <td>{{ user_salary.net_in_hand }}</td>
                            <td>{{ user_salary.employer_pf }}</td>
                            <td>{{ user_salary.employer_esic }}</td>
                            <td>{{ user_salary.employer_mlwf }}</td>
                            <td>{{ user_salary.employer_leave_salary }}</td>
                            <td>{{ user_salary.employer_graduity }}</td>
                            <td>{{ user_salary.employer_uniform_charges }}</td>
                            <td>
                              {{ user_salary.employer_administrative_charges }}
                            </td>
                            <td>{{ user_salary.employer_training_charges }}</td>
                            <td>{{ user_salary.employer_reliver_charges }}</td>
                            <td>{{ user_salary.employer_min_wage }}</td>
                            <td>{{ user_salary.total_cost }}</td>
                            <td>{{ user_salary.management_fees }}</td>
                            <td>{{ user_salary.total_ctc }}</td>
                            <td>{{ user_salary.billing_ot_rate }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Row -->
          <!-- <div class="col-sm-12"> -->
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />
                <section id="mydiv">
                  <div class="container-fluid border">
                    <p style="font-weight: bold">CTC Report</p>
                    <table
                      id="table-example-1"
                      class="table table-responsive col-md-10"
                      style="border: 2px solid black"
                    >
                      <thead>
                        <tr>
                          <th colspan="2"><center>Cost Break Up</center></th>
                          <th>{{ form.employee_id }} - {{ form.full_name }}</th>
                        </tr>
                        <tr>
                          <th colspan="2">
                            <center>{{ site.name }}</center>
                          </th>
                          <th>{{ designation_category }}</th>
                        </tr>
                        <tr>
                          <th>Sr. No</th>
                          <th>Particular</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <center>1.</center>
                          </td>
                          <td>(A)</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td><center>2.</center></td>
                          <td>BASIC</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.fixed_basic }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>3.</center></td>
                          <td>D.A (Special Allowance)</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.fixed_da }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th><center>4.</center></th>
                          <th>Basic + DA</th>
                          <th>
                            <p style="float: right">
                              {{
                                user_salary.fixed_basic + user_salary.fixed_da
                              }}.00
                            </p>
                          </th>
                        </tr>
                        <tr>
                          <td><center>5.</center></td>
                          <td>HRA</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.fixed_hra }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>6.</center></td>
                          <td>Conveyance Allowance</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.fixed_conveyance }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>7.</center></td>
                          <td>CCA</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.fixed_cca }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>8.</center></td>
                          <td>Medical Allowance</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.fixed_medical_allowance }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>9.</center></td>
                          <td>Special Allowance</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.fixed_special_allowance }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>10.</center></td>
                          <td>Other Allowance</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.fixed_other_allowance }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>11.</center></td>
                          <td>Washing Allowance</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.fixed_washing_allowance }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>12.</center></td>
                          <td>Leave Salary</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.leave_encashment }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>13.</center></td>
                          <td>Bonus</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.bonus }}.00
                            </p>
                          </td>
                        </tr>
                        <tr style="font-weight: bold">
                          <th><center>14.</center></th>
                          <th>Total Gross Salary</th>
                          <th>
                            <p style="float: right">
                              {{ user_salary.gross_salary }}.00
                            </p>
                          </th>
                        </tr>
                        <tr>
                          <td><center>15.</center></td>
                          <td>(B) Employee Deduction</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td><center>16.</center></td>
                          <td>
                            PF ({{
                              configurations.pf_configuration.employee_value
                            }}%)
                          </td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.employee_pf }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>17.</center></td>
                          <td>
                            ESIC ({{
                              configurations.esic_configuration.employee_value
                            }}%) on Gross Salary
                          </td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.employee_esic }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>18.</center></td>
                          <td>LWF</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.employee_mlwf }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>19.</center></td>
                          <td>Professional Tax</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.employee_pt }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th><center>20.</center></th>
                          <th>Total Deduction</th>
                          <th>
                            <p style="float: right">
                              {{
                                user_salary.employee_pf +
                                user_salary.employee_esic +
                                user_salary.employee_mlwf +
                                user_salary.employee_pt
                              }}.00
                            </p>
                          </th>
                        </tr>
                        <tr>
                          <td><center>21.</center></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr style="font-weight: bold">
                          <th><center>22.</center></th>
                          <th>Net in Hand Salary (A-B)</th>
                          <th>
                            <p style="float: right">
                              {{ user_salary.net_in_hand }}.00
                            </p>
                          </th>
                        </tr>
                        <tr>
                          <td><center>23.</center></td>
                          <td>(C) Statutory Charges</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td><center>24.</center></td>
                          <td>
                            PF ({{
                              configurations.pf_configuration.employer_value
                            }}%)
                          </td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.employer_pf }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>25.</center></td>
                          <td>
                            ESIC ({{
                              configurations.esic_configuration.employer_value
                            }}
                            %) On Gross Salary
                          </td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.employer_esic }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>26.</center></td>
                          <td>Bonus (8.33%) On Basic + DA</td>
                          <td>
                            <p style="float: right">{{ employer_bonus }}</p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>27.</center></td>
                          <td>Leave Salary</td>
                          <td>
                            <p style="float: right">
                              {{ employer_leave_salary }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>28.</center></td>
                          <td>LWF</td>
                          <td>
                            <p style="float: right">{{ employer_lwf }}.00</p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>29.</center></td>
                          <td>Gratuity</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.employer_graduity }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>30.</center></td>
                          <td>National Holiday</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td><center>31.</center></td>
                          <td>Uniform Charges</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.employer_uniform_charges }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>32.</center></td>
                          <td>Administrative Cost</td>
                          <td>
                            <p style="float: right">
                              {{
                                user_salary.employer_administrative_charges
                              }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>33.</center></td>
                          <td>Training Charges</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.employer_training_charges }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>34.</center></td>
                          <td>Reliver Charges</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.employer_reliver_charges }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th><center>35.</center></th>
                          <th>Total C</th>
                          <th>
                            <p style="float: right">
                              {{
                                user_salary.employer_pf +
                                user_salary.employer_esic +
                                employer_lwf +
                                employer_bonus +
                                user_salary.employer_graduity +
                                user_salary.employer_uniform_charges +
                                user_salary.employer_administrative_charges +
                                user_salary.employer_training_charges +
                                user_salary.employer_reliver_charges
                              }}
                            </p>
                          </th>
                        </tr>
                        <tr style="font-weight: bold">
                          <th><center>36.</center></th>
                          <th>CTC (A + C)</th>
                          <th>
                            <p style="float: right">{{ total_cost }}.00</p>
                          </th>
                        </tr>
                        <tr>
                          <td><center>37.</center></td>
                          <td>Management Fees/Contingency Charge On CTC</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.management_fees }}.00
                            </p>
                          </td>
                        </tr>
                        <tr style="font-weight: bold">
                          <th><center>38.</center></th>
                          <th>Total CTC</th>
                          <th>
                            <p style="float: right">{{ total_ctc }}.00</p>
                          </th>
                        </tr>
                        <tr>
                          <td><center>39.</center></td>
                          <td>OT</td>
                          <td>
                            <p style="float: right">
                              {{ user_salary.ot_rate }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>40.</center></td>
                          <td>
                            ESIC @
                            {{
                              configurations.esic_configuration.employer_value
                            }}%
                          </td>
                          <td>
                            <p style="float: right">
                              {{
                                Math.round(
                                  (user_salary.ot_rate *
                                    configurations.esic_configuration
                                      .employer_value) /
                                    100
                                )
                              }}.00
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td><center>41.</center></td>
                          <td>Chargable OT</td>
                          <td>
                            <p style="float: right">
                              {{
                                user_salary.ot_rate +
                                Math.round(
                                  (user_salary.ot_rate *
                                    configurations.esic_configuration
                                      .employer_value) /
                                    100
                                )
                              }}.00
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      user_salary: {},
    };
  },
  props: ["form", "isLoading", "configurations"],
  watch: {
    form: "getCurrentSalary",
  },
  computed: {
    gross() {
      // console.log(this.user_salary);
      let fixed_basic = parseFloat(this.user_salary.fixed_basic) || 0;
      let fixed_da = parseFloat(this.user_salary.fixed_da) || 0;
      let fixed_hra = parseFloat(this.user_salary.fixed_hra) || 0;
      let fixed_conveyance = parseFloat(this.user_salary.fixed_conveyance) || 0;
      let fixed_medical_allowance =
        parseFloat(this.user_salary.fixed_medical_allowance) || 0;
      let fixed_special_allowance =
        parseFloat(this.user_salary.fixed_special_allowance) || 0;
      let fixed_cca = parseFloat(this.user_salary.fixed_cca) || 0;
      let fixed_other_allowance =
        parseFloat(this.user_salary.fixed_other_allowance) || 0;
      let fixed_washing_allowance =
        parseFloat(this.user_salary.fixed_washing_allowance) || 0;
      let leave_encashment = parseFloat(this.user_salary.leave_encashment) || 0;
      let bonus = parseFloat(this.user_salary.bonus) || 0;
      let fixed_mobile_allowance =
        parseFloat(this.user_salary.fixed_mobile_allowance) || 0;

      // return Math.round(fixed_basic + fixed_da + fixed_hra + fixed_conveyance + fixed_medical_allowance + fixed_cca + fixed_other_allowance + fixed_washing_allowance + leave_encashment + bonus + fixed_mobile_allowance)
      return parseFloat(
        (
          fixed_basic +
          fixed_da +
          fixed_hra +
          fixed_conveyance +
          fixed_medical_allowance +
          fixed_special_allowance +
          fixed_cca +
          fixed_other_allowance +
          fixed_washing_allowance +
          leave_encashment +
          bonus +
          fixed_mobile_allowance
        ).toFixed(2)
      );
    },
    total_cost() {
      // let employer_mlwf = parseFloat(this.user_salary.employer_mlwf) || 0
      let employer_bonus = parseFloat(this.employer_bonus) || 0;
      let employer_leave_salary = parseFloat(this.employer_leave_salary) || 0;
      let employer_graduity =
        parseFloat(this.user_salary.employer_graduity) || 0;
      let employer_uniform_charges =
        parseFloat(this.user_salary.employer_uniform_charges) || 0;
      let employer_administrative_charges =
        parseFloat(this.user_salary.employer_administrative_charges) || 0;
      let employer_training_charges =
        parseFloat(this.user_salary.employer_training_charges) || 0;
      let employer_reliver_charges =
        parseFloat(this.user_salary.employer_reliver_charges) || 0;
      let employer_min_wage =
        parseFloat(this.user_salary.employer_min_wage) || 0;

      return Math.round(
        this.gross +
          this.employer_pf +
          this.employer_esic +
          this.employer_lwf +
          employer_bonus +
          employer_leave_salary +
          employer_graduity +
          employer_uniform_charges +
          employer_administrative_charges +
          employer_training_charges +
          employer_reliver_charges +
          employer_min_wage
      );
    },
    employee_pf() {
      if (!this.form.is_pf_applicable) return 0;
      let fixed_basic = parseFloat(this.user_salary.fixed_basic) || 0;
      let fixed_da = parseFloat(this.user_salary.fixed_da) || 0;
      let fixed_hra = parseFloat(this.user_salary.fixed_hra) || 0;
      let fixed_leave_encashment =
        parseFloat(this.user_salary.leave_encashment) || 0;
      let fixed_bonus = parseFloat(this.user_salary.bonus) || 0;

      let employee_pf_type =
        parseFloat(this.configurations.pf_configuration.employee_type) || 0;
      let employee_pf_value =
        parseFloat(this.configurations.pf_configuration.employee_value) || 0;
      let employee_pf_max_wage =
        parseFloat(this.configurations.pf_configuration.max_wage) || 0;

      let pf_wages = fixed_basic + fixed_da;
      if (this.form.group_division) {
        if (this.form.group_division.applicable_on == 1)
          pf_wages =
            this.gross -
            fixed_hra -
            // reimbursement -
            fixed_leave_encashment -
            fixed_bonus;
        else if (this.form.group_division.applicable_on == 2)
          pf_wages = fixed_basic + fixed_da;
      } else if (this.form.group) {
        if (this.form.group.applicable_on == 1)
          pf_wages =
            this.gross -
            fixed_hra -
            // reimbursement -
            fixed_leave_encashment -
            fixed_bonus;
        else if (this.form.group.applicable_on == 2)
          pf_wages = fixed_basic + fixed_da;
      }

      if (employee_pf_type == 1) return Math.round(employee_pf_value);
      if (employee_pf_type == 2) {
        if (pf_wages > employee_pf_max_wage)
          return Math.round((employee_pf_value * employee_pf_max_wage) / 100);
        return Math.round((employee_pf_value * pf_wages) / 100);
      }
      return 0;
    },
    employee_esic() {
      if (!this.form.is_esi_applicable) return 0;
      let fixed_washing_allowance =
        parseFloat(this.user_salary.fixed_washing_allowance) || 0;
      let esic_wages = (parseFloat(this.gross) || 0) - fixed_washing_allowance;
      let employee_esic_type =
        parseFloat(this.configurations.esic_configuration.employee_type) || 0;
      let employee_esic_value =
        parseFloat(this.configurations.esic_configuration.employee_value) || 0;
      let employee_esic_max_wage =
        parseFloat(this.configurations.esic_configuration.max_wage) || 0;
      if (employee_esic_type == 1) return Math.round(employee_esic_value);
      if (employee_esic_type == 2) {
        if (esic_wages > employee_esic_max_wage) return 0;
        return Math.ceil((employee_esic_value * esic_wages) / 100);
      }
      return 0;
    },
    employee_pt() {
      if (!this.form.is_pt_applicable) return 0;
      let gender = 0;
      let pt = 0;
      let pt_configurations = [];
      if (this.form.group_division)
        pt_configurations = this.configurations.pt_configurations.filter(
          (pt_configuration) =>
            pt_configuration.value_list_id == this.form.group_division.state_id
        );
      else if (this.form.group)
        pt_configurations = this.configurations.pt_configurations.filter(
          (pt_configuration) =>
            pt_configuration.value_list_id == this.form.group.state_id
        );
      else pt_configurations = [];
      let pt_wages = Math.round(this.gross);
      pt_configurations.forEach((pt_configuration) => {
        if (
          (pt_wages > pt_configuration.min_wage &&
            pt_wages < pt_configuration.max_wage) ||
          (pt_wages > pt_configuration.min_wage &&
            pt_configuration.max_wage == 0)
        ) {
          if (
            (pt_configuration.is_men && gender == 0) ||
            (pt_configuration.is_women && gender == 1)
          )
            if (pt_configuration.employee_type == 1)
              pt = pt_configuration.employee_value;
            else
              pt = Math.round(
                (pt_configuration.employee_value * pt_wages) / 100
              );
        }
      });
      return pt;
    },
    net_in_hand() {
      return Math.round(
        this.gross - (this.employee_pf + this.employee_esic + this.employee_pt)
      );
    },
    employer_pf() {
      if (!this.form.is_pf_applicable) return 0;

      let fixed_basic = parseFloat(this.user_salary.fixed_basic) || 0;
      let fixed_da = parseFloat(this.user_salary.fixed_da) || 0;
      let fixed_hra = parseFloat(this.user_salary.fixed_hra) || 0;
      let fixed_leave_encashment =
        parseFloat(this.user_salary.leave_encashment) || 0;
      let fixed_bonus = parseFloat(this.user_salary.bonus) || 0;

      let employer_pf_type =
        parseFloat(this.configurations.pf_configuration.employer_type) || 0;
      let employer_pf_value =
        parseFloat(this.configurations.pf_configuration.employer_value) || 0;
      let employer_pf_max_wage =
        parseFloat(this.configurations.pf_configuration.max_wage) || 0;

      let pf_wages = fixed_basic + fixed_da;
      if (this.form.group_division) {
        if (this.form.group_division.applicable_on == 1)
          pf_wages =
            this.gross -
            fixed_hra -
            // reimbursement -
            fixed_leave_encashment -
            fixed_bonus;
        else if (this.form.group_division.applicable_on == 2)
          pf_wages = fixed_basic + fixed_da;
      } else if (this.form.group) {
        if (this.form.group.applicable_on == 1)
          pf_wages =
            this.gross -
            fixed_hra -
            // reimbursement -
            fixed_leave_encashment -
            fixed_bonus;
        else if (this.form.group.applicable_on == 2)
          pf_wages = fixed_basic + fixed_da;
      }
      if (employer_pf_type == 1) return Math.round(employer_pf_value);
      if (employer_pf_type == 2) {
        if (pf_wages > employer_pf_max_wage)
          return Math.round((employer_pf_value * employer_pf_max_wage) / 100);
        return Math.round((employer_pf_value * pf_wages) / 100);
      }
      return 0;
    },
    employer_esic() {
      if (!this.form.is_esi_applicable) return 0;
      let fixed_washing_allowance =
        parseFloat(this.user_salary.fixed_washing_allowance) || 0;
      let esic_wages = (parseFloat(this.gross) || 0) - fixed_washing_allowance;
      let employer_esic_type =
        parseFloat(this.configurations.esic_configuration.employer_type) || 0;
      let employer_esic_value =
        parseFloat(this.configurations.esic_configuration.employer_value) || 0;
      let employer_esic_max_wage =
        parseFloat(this.configurations.esic_configuration.max_wage) || 0;
      if (employer_esic_type == 1) return Math.round(employer_esic_value);
      if (employer_esic_type == 2) {
        if (esic_wages > employer_esic_max_wage) return 0;
        return Math.ceil((employer_esic_value * esic_wages) / 100);
      }
      return 0;
    },
    employer_lwf() {
      if (!this.form.is_lwf_applicable) return 0;
      let employer_lwf = 0;
      let division_value = 0;
      let lwf_wages = parseFloat(this.gross) || 0;
      let lwf_configurations = [];
      if (this.form.group_division)
        lwf_configurations = this.configurations.lwf_configurations.filter(
          (lwf_configuration) =>
            lwf_configuration.value_list_id == this.form.group_division.state_id
        );
      else if (this.form.group)
        lwf_configurations = this.configurations.lwf_configurations.filter(
          (lwf_configuration) =>
            lwf_configuration.value_list_id == this.form.group.state_id
        );
      else lwf_configurations = [];
      let lwf_configuration =
        lwf_configurations.length > 0 ? lwf_configurations[0] : {};
      let employer_lwf_value = parseFloat(
        lwf_configuration ? lwf_configuration.employer_value : 0
      );
      let employer_lwf_type = parseFloat(lwf_configuration.employer_type);
      if (employer_lwf_type == 1)
        employer_lwf = lwf_wages == 0 ? 0 : employer_lwf_value;
      else employer_lwf = (employer_lwf_value * lwf_wages) / 100;
      if (lwf_configuration.is_january == 1) division_value++;
      if (lwf_configuration.is_february == 1) division_value++;
      if (lwf_configuration.is_march == 1) division_value++;
      if (lwf_configuration.is_april == 1) division_value++;
      if (lwf_configuration.is_may == 1) division_value++;
      if (lwf_configuration.is_june == 1) division_value++;
      if (lwf_configuration.is_july == 1) division_value++;
      if (lwf_configuration.is_august == 1) division_value++;
      if (lwf_configuration.is_september == 1) division_value++;
      if (lwf_configuration.is_october == 1) division_value++;
      if (lwf_configuration.is_november == 1) division_value++;
      if (lwf_configuration.is_december == 1) division_value++;
      division_value = division_value == 0 ? 1 : 12 / division_value;
      return employer_lwf / division_value;
    },
    management_fees() {
      let management_fees = 0;
      if (this.user_salary.is_updated_manually) {
        management_fees = parseFloat(this.user_salary.management_fees) || 0;
      } else {
        if (this.form.group_division) {
          // Flat
          if (this.form.group_division.management_fees_type == 1) {
            management_fees = parseFloat(
              this.form.group_division.management_fees_value
            );
          }
          // Percent
          else if (this.form.group_division.management_fees_type == 2) {
            management_fees =
              (this.total_cost *
                parseFloat(this.form.group_division.management_fees_value)) /
              100;
          }
        }
      }
      return Math.round(management_fees);
    },
    total_ctc() {
      // let management_fees = parseFloat(this.user_salary.management_fees) || 0
      return Math.round(this.total_cost + this.management_fees);
    },
    employer_leave() {
      return 0;
    },
    employer_bonus() {
      let bonus = 0;
      if (this.form.is_bonus_applicable != 1) return 0;
      if (this.user_salary.bonus != 0) return 0;
      else if (this.configurations.bonus_configuration.employer_type == 1)
        bonus = this.configurations.bonus_configuration.employer_value;
      else
        bonus =
          (this.configurations.bonus_configuration.employer_value *
            (this.user_salary.fixed_basic + this.user_salary.fixed_da)) /
          100;
      return Math.round(bonus);
    },
    employer_leave_salary() {
      let leave = 0;
      if (this.form.is_leave_application != 1) return 0;
      if (this.user_salary.leave_encashment != 0) return 0;
      else if (this.configurations.leave_configuration.employer_type == 1)
        leave = this.configurations.leave_configuration.employer_value;
      else
        leave =
          (this.configurations.leave_configuration.employer_value *
            (this.user_salary.fixed_basic + this.user_salary.fixed_da)) /
          100;
      return leave;
    },
  },
  mounted() {
    this.initalizeEmptySalary();
  },
  methods: {
    getCurrentSalary() {
      this.form.user_salaries.length > 0
        ? (this.user_salary = Object.assign({}, this.form.user_salaries[0]))
        : this.initalizeEmptySalary();
      // console.log(this.form);
      this.designation_category = {};
      let designation_categories = this.form.user_categories.filter(
        (user_category) => user_category.category.category_name == "DESIGNATION"
      );
      if (designation_categories.length > 0)
        this.designation_category =
          designation_categories[0].category_list.code;
    },
    save() {
      this.user_salary.id = null;
      this.user_salary.gross_salary = this.gross;
      this.user_salary.total_cost = this.total_cost;
      this.user_salary.employee_pf = this.employee_pf;
      this.user_salary.employee_esic = this.employee_esic;
      this.user_salary.employee_pt = this.employee_pt;
      this.user_salary.net_in_hand = this.net_in_hand;
      this.user_salary.employer_pf = this.employer_pf;
      this.user_salary.employer_esic = this.employer_esic;
      this.user_salary.employer_mlwf = this.employer_mlwf;
      this.user_salary.management_fees = this.management_fees;
      this.user_salary.total_ctc = this.total_ctc;
      this.user_salary.employer_bonus = this.employer_bonus;
      console.log(this.user_salary);
      this.form.user_salaries.push(this.user_salary);
      this.update();
    },
    async update() {
      this.$emit("update");
    },
    initalizeEmptySalary() {
      this.user_salary = {
        effective_date: new Date(),
        fixed_basic: 0,
        fixed_da: 0,
        fixed_hra: 0,
        fixed_conveyance: 0,
        fixed_medical_allowance: 0,
        fixed_special_allowance: 0,
        fixed_cca: 0,
        fixed_other_allowance: 0,
        fixed_washing_allowance: 0,
        leave_encashment: 0,
        bonus: 0,
        fixed_mobile_allowance: 0,
        gross_salary: 0,
        ot_rate: 0,
        employee_pf: 0,
        employee_esic: 0,
        employee_pt: 0,
        employee_mlwf: 0,
        net_in_hand: 0,
        employer_pf: 0,
        employer_esic: 0,
        employer_mlwf: 0,
        employer_bonus: 0,
        employer_leave_salary: 0,
        employer_graduity: 0,
        employer_uniform_charges: 0,
        employer_administrative_charges: 0,
        employer_training_charges: 0,
        employer_reliver_charges: 0,
        employer_min_wage: 0,
        total_cost: 0,
        management_fees: 0,
        total_ctc: 0,
        billing_ot_rate: 0,
      };
    },
  },
};
</script>
