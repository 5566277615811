<template>
  <div class="panel panel-default card-view">
    <div class="panel-wrapper collapse in">
      <div class="panel-body">
        <div class="col-md-12">

          <div class="row">
            <span style="float: left;"><b>{{ category.category_name }}</b></span>
            <span style="float: right;">
              <i class="fa fa-plus" aria-hidden="true" v-if="!addData" @click="toggleAddData"></i>
              <i class="fa fa-close" aria-hidden="true" v-if="addData" @click="toggleAddData"></i>
            </span>
          </div>

          <br>

          <div class="row"
            v-if="addData"
          >
            <p><u>Add New {{ category.category_name }}</u></p>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Select {{ category.category_name }}</label>
              <div class="col-sm-8">
                <my-select :selection.sync="user_category.category_list_id" :suggestions="categoryLists" placeholder="Select value"></my-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Effective From</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_category.effective_from" placeholder="Effective From"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Effective To</label>
              <div class="col-sm-8">
                <my-datepicker :date.sync="user_category.effective_to" placeholder="Effective To"></my-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10">
                <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
              </div>
            </div>
          </div>

          <!-- List of employee category -->
          <div class="row"
            v-for="(userCategory, uc) in user_categories"
            :key="`userCategory${uc}`"
          >
            <div class="form-group row"
              v-if="!(editData == uc)"
            >
              <label class="col-sm-4 col-form-label">{{ uc + 1 }}. {{ userCategory.category_list.name }}</label>
              <div class="col-sm-6">
                {{ userCategory.effective_from | ddmmyyyy }}
              </div>
              <div class="col-md-2">
                <i class="fa fa-pencil-square-o" style="float: right;" aria-hidden="true" @click="toggleEditData(userCategory, uc)"></i>
              </div>
            </div>
            <div
              v-else
            >
              <p><u>{{ uc + 1 }}. Update Employee {{ category.category_name }}</u></p>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Select {{ category.category_name }}</label>
                <div class="col-sm-8">
                  <my-select :selection.sync="user_category.category_list_id" :suggestions="categoryLists" placeholder="Select value"></my-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Effective From</label>
                <div class="col-sm-8">
                  <my-datepicker :date.sync="user_category.effective_from" placeholder="Effective From"></my-datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Effective To</label>
                <div class="col-sm-8">
                  <my-datepicker :date.sync="user_category.effective_to" placeholder="Effective To"></my-datepicker>
                </div>
              </div>
              <div class="form-group row"
                v-if="editData == uc"
              >
                <div class="col-sm-2">
                  <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data () {
    return {
      addData: false,
      editData: null,
      user_category: {
        category_id: '',
        category_list_id: '',
        effective_from: '',
        effective_to: '',
      },
      categoryLists: [],
    }
  },
  computed: {
    user_categories() {
      let user_categories = []
      this.form.user_categories.forEach(user_category => {
        if(user_category.category_id == this.category.id)
          user_categories.push(user_category)
      })
      return user_categories
    }
  },
  props: ['form', 'isLoading', 'category'],
  mounted() {
    this.user_category.category_id = this.category.id
    this.getCategoryLists()
  },
  methods: {
    getCategoryLists() {
      this.category_lists = []
      this.category.category_lists.forEach(categoryList => {
        this.categoryLists.push({
          id: categoryList.id,
          name: categoryList.name,
          code: categoryList.code,
        })
      })
    },
    toggleAddData() {
      this.user_category = {
        category_id: this.category.id,
        category_list_id: '',
        effective_from: '',
        effective_to: '',
      }
      this.addData = !this.addData
    },
    toggleEditData(userCategory, uc) {
      this.editData = uc
      this.user_category = userCategory
    },
    cancelUpdate() {
      this.editData = null
    },
    save() {
      if(this.user_category.category_list_id == '' || this.user_category.effective_from == '') 
        alert('Please enter category and effective from')
      else {
        this.form.user_categories.push(this.user_category)
        this.update()
        this.addData = false
      }
    },
    async update() {
      this.$emit('update')
      this.editData = null
    }
  }
}
</script>