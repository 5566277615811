<template>
  <section id="mydiv">
    <div class="container-fluid border">
      <table
        id="table-example-1"
        class="table table-responsive col-md-10"
        style="border:2px solid black"
      >
        <thead>
          <tr>
            <th colspan="2"><center>Cost Break Up</center></th>
            <th>New Proposal for Facility Services</th>
          </tr>
          <tr>
            <th colspan="2"><center>V ALLIANCE</center></th>
            <th>Rate For 12 Hrs Duty 30 Days work</th>
          </tr>
          <tr>
            <th>Sr. No</th>
            <th>Particular</th>
            <th>Care Taker</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>(A)</td>
            <td></td>
          </tr>
          <tr>
            <td>2.</td>
            <td>BASIC</td>
            <td>5800.00</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>D.A (Special Allowance)</td>
            <td>4101.00</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Basic + DA</td>
            <td>4101.00</td>
          </tr>
          <tr>
            <td>5</td>
            <td>HRA ( 5% Of Basic + DA )</td>
            <td>4101.00</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Conveyance</td>
            <td>4101.00</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Medical Allowance</td>
            <td>-</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Others</td>
            <td>4101.00</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Washing Allowance</td>
            <td>4101.00</td>
          </tr>
          <tr>
            <td>22</td>
            <td>Leave Salary ( 5.83% ) Of Total Gross Salary</td>
            <td>4101.00</td>
          </tr>
          <tr style="font-weight: bold; ">
            <th>9</th>
            <th>Total Gross Salary</th>
            <th>4101.00</th>
          </tr>
          <tr>
            <td>10</td>
            <td>(B)</td>
            <td></td>
          </tr>
          <tr>
            <td>11</td>
            <td>PF (12%) on (Basic + DA)</td>
            <td>4101.00</td>
          </tr>
          <tr>
            <td>12</td>
            <td>ESIC (0.75%) on Gross Salary</td>
            <td>4101.00</td>
          </tr>
          <tr>
            <td>13</td>
            <td>LWF</td>
            <td>4101.00</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Professional Tax</td>
            <td>4101.00</td>
          </tr>
          <tr>
            <td>15</td>
            <td>Employee Deduction</td>
            <td>4101.00</td>
          </tr>
          <tr>
            <td>16</td>
            <td></td>
            <td></td>
          </tr>
          <tr style="font-weight: bold;">
            <th>17</th>
            <th>Net in Hand Salary (A-B)</th>
            <th>15,000.00</th>
          </tr>
          <tr>
            <td>18</td>
            <td>(C)</td>
            <td></td>
          </tr>
          <tr>
            <td>19</td>
            <td>PF (13 %) On (Basic + DA)</td>
            <td>1288.00</td>
          </tr>
          <tr>
            <td>20</td>
            <td>ESIC (4.75 %) On Gross Salary</td>
            <td>1288.00</td>
          </tr>
          <tr>
            <td>21</td>
            <td>Bonus (8.33%) On Basic + DA</td>
            <td>1288.00</td>
          </tr>
          <tr>
            <td>22</td>
            <td>Leave Salary (5.83%) Of Total Gross Salary</td>
            <td>-</td>
          </tr>
          <tr>
            <td>23</td>
            <td>LWF</td>
            <td>1288.00</td>
          </tr>
          <tr>
            <td>24</td>
            <td>Gratuity ( 4.81% On Basic + DA )</td>
            <td>-</td>
          </tr>
          <tr>
            <td>25</td>
            <td>National Holiday ( 1.28% On A )</td>
            <td>-</td>
          </tr>
          <tr>
            <td>26</td>
            <td>Uniform fix Charge</td>
            <td>-</td>
          </tr>
          <tr>
            <td>27</td>
            <td>Administrative Cost (Fixed)</td>
            <td>-</td>
          </tr>
          <tr>
            <td>28</td>
            <td>Training Charges (5% On A)</td>
            <td>-</td>
          </tr>
          <tr>
            <td>29</td>
            <td>Statutory Charges To Company</td>
            <td>2400.00</td>
          </tr>
          <tr>
            <td>30</td>
            <td>Reliver Charges</td>
            <td>2400.00</td>
          </tr>
          <tr style="font-weight: bold;">
            <th>31</th>
            <th>CTC (A + C)</th>
            <th>2400.00</th>
          </tr>
          <tr>
            <td>32</td>
            <td>Management Fees/Contingency Charge 10% On CTC</td>
            <td>2400.00</td>
          </tr>
          <tr>
            <td>33</td>
            <td>Site Allowance</td>
            <td></td>
          </tr>
          <tr style="font-weight: bold;">
            <th>34</th>
            <th>Total CTC</th>
            <th>2400.00</th>
          </tr>
          <br />
          <br />
          <tr>
            <td>35</td>
            <td>OT</td>
            <td>-</td>
          </tr>
          <tr>
            <td>36</td>
            <td>ESIC @ 4.75%/</td>
            <td>-</td>
          </tr>
          <tr>
            <td>37</td>
            <td>Chargable OT</td>
            <td>-</td>
          </tr>
          <br />
          <br />
          <tr>
            <td colspan="3">Note-</td>
          </tr>
          <tr>
            <td colspan="2">+ 18 % Gst on Total Amount</td>
            <td>3780.00</td>
          </tr>
          <tr>
            <td colspan="2">Grand Total Amount</td>
            <td>24,780.00</td>
          </tr>
          <tr>
            <td colspan="3">
              Material & Consumable shall be billed on actual basis
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "CTC",
  mounted: function() {
    this.createPDF();
  },
  methods: {
    createPDF() {
      var element = document.getElementById("mydiv");

      var opt = {
        margin: 0,
        filename: "CTC.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [400, 800],
          orientation: "portrait",
        },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>

<style>
.border table,
.border th,
.border td{
  border: 2px solid black;
}
</style>
