<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/settings/asset-groups"></back-button> 
            Update asset group: {{ form.id }}
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/settings/asset-groups">Class Societies</router-link></li>
            <li class="active">Update</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Name*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Name" v-model="form.name">
                          <span class="error-block">{{ errors.name }}</span>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'AssetGroupUpdate',
  data () {
    return {
      form: {},
      isLoading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      let form = await axios.get(`/asset_groups/${this.$route.params.assetGroupId}`)
      this.form = form.data.data
    },
    async update() {
      try {
        this.isLoading = true
        await axios.patch(`/asset_groups/${this.$route.params.assetGroupId}`, this.form)
        this.isLoading = false
        this.$router.push('/settings/asset-groups')
      } catch(e) {
        this.isLoading = false
      }
    }
  }
}
</script>