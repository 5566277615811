<template>
  <span>
    <ul class="pagination mt-0 mb-0 mr-15 pagination">
      <li v-if="page != 1"> 
        <a href="javascript:void(0)" @click="onClick(page - 1)"><i class="fa fa-angle-left"></i></a> 
      </li>
      <li
        v-for="p in noOfPages"
        :key="p"
        :class="page == p ? 'active' : ''"
      > 
        <a href="javascript:void(0)" @click="onClick(p)">{{ p }}</a> 
      </li>
      <li v-if="page != noOfPages"> 
        <a href="javascript:void(0)" @click="onClick(page + 1)"><i class="fa fa-angle-right"></i></a> 
      </li>
    </ul>
    <script type="application/javascript" defer src="/custom.js"></script>
  </span>
</template>

<script type="text/javascript">
export default {
  name: 'PaginationWidget',
  data () {
    return {
      page: 1,
      noOfPages: 0,
    }
  },
  props: ['count'],
  watch: {
    'count': 'calculateNoOfPages'
  },
  mounted() {
    this.calculateNoOfPages()
  },
  methods: {
    calculateNoOfPages() {
      if(this.count != 0) {
        this.noOfPages = parseInt(this.count / this.rowsPerPage) + 1
      }
    },
    onClick(page) {
      if(this.page != page) {
        this.page = page
        this.$emit('submit', page)
      }
    }
  }
}
</script>

<style>
.pagination {
  padding: 10px;
  float: right;
}
</style>