<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/main/feedbacks"></back-button>
            Update feedback: {{ form.id
            }}<loading :isLoading="isLoading"></loading>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/main/feedbacks">Tickets</router-link></li>
            <li class="active">Update</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-8">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Client Name</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Client Name"
                            v-model="form.client_name"
                          />
                          <span class="error-block">{{
                            errors.client_name
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Locaion</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Locaion"
                            v-model="form.location"
                          />
                          <span class="error-block">{{ errors.location }}</span>
                        </div>
                      </div>
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <table
                          class="table table-hover table-striped table-responsive"
                        >
                          <th>Sr No</th>
                          <th>Housekeeping Services</th>
                          <th colspan="4">
                            Score (choose one for each service)
                          </th>
                          <th>(Please specify)</th>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>
                              <i
                                class="far fa-frown"
                                style="font-size: 36px"
                              ></i>
                            </td>
                            <td>
                              <i
                                class="far fa-flushed"
                                style="font-size: 36px"
                              ></i>
                            </td>
                            <td>
                              <i
                                class="far fa-smile"
                                style="font-size: 36px"
                              ></i>
                            </td>
                            <td>
                              <i
                                class="far fa-laugh-beam"
                                style="font-size: 36px"
                              ></i>
                            </td>
                            <td></td>
                          </tr>
                          <tr
                            v-for="(
                              feedback_service, at
                            ) in form.feedback_services"
                            :key="`feedback_service${at}`"
                          >
                            <td>{{ 1 + at }}</td>
                            <td>
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Feedback Services"
                                v-model="feedback_service.service_name"
                                readonly
                              />
                            </td>
                            <td>
                              <my-checkbox
                                :selection.sync="feedback_service.score_1"
                              ></my-checkbox>
                            </td>
                            <td>
                              <my-checkbox
                                :selection.sync="feedback_service.score_2"
                              ></my-checkbox>
                            </td>
                            <td>
                              <my-checkbox
                                :selection.sync="feedback_service.score_3"
                              ></my-checkbox>
                            </td>
                            <td>
                              <my-checkbox
                                :selection.sync="feedback_service.score_4"
                              ></my-checkbox>
                            </td>
                            <td>
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                v-model="feedback_service.specify"
                              />
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Description</label
                        >
                        <div class="col-sm-9">
                          <textarea
                            v-uppercase
                            class="form-control"
                            placeholder="Description"
                            v-model="form.description"
                          ></textarea>
                          <span class="error-block">{{
                            errors.description
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Name</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Name"
                            v-model="form.name"
                          />
                          <span class="error-block">{{ errors.name }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Mobile Number</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Mobile Number"
                            v-model="form.mobile_no"
                          />
                          <span class="error-block">{{
                            errors.mobile_no
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="col-md-12 col-sm-12 col-xs-12"
                        style="border: 2px solid black"
                      >
                        <vueSignature
                          ref="signature"
                          :sigOption="option"
                          :h="'400px'"
                          :disabled="disabled"
                          :defaultUrl="dataUrl"
                        ></vueSignature>

                        <div style="padding: 20px; border-top: 2px solid black">
                          <button
                            class="btn btn-primary"
                            @click="save_signature"
                          >
                            Save Signature
                          </button>
                          &nbsp;
                          <button class="btn btn-primary" @click="clear">
                            Clear Signature
                          </button>
                          &nbsp;
                          <button class="btn btn-primary" @click="undo">
                            Undo Signature
                          </button>
                        </div>
                      </div>
                      <br />
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9 mt-10">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="save"
                            text="Update"
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import vueSignature from "vue-signature";

export default {
  name: "feedbackUpdate",
  components: {
    vueSignature,
  },
  data() {
    return {
      form: {
        feedback_services: [],
      },
      feedbacks: [],
      isLoading: false,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      disabled: false,
      dataUrl: "",
    };
  },
  mounted() {
    this.getData();
    // this.getMasters();
  },
  methods: {
    // async getMasters() {
    //   let masters = await axios.get("feedbacks/masters");
    //   masters = masters.data;
    //   masters.feedback_services.forEach((feedback_service) => {
    //     this.form.feedback_services.push({
    //       id: feedback_service.id,
    //       service_name: feedback_service.description,
    //       score_1: false,
    //       score_2: false,
    //       score_3: false,
    //       score_4: false,
    //       specify: "",
    //     });
    //   });
    //   console.log(this.form.feedback_services);
    // },

    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/feedbacks/${this.$route.params.feedbackId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/feedbacks", this.form);
        this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/main/feedbacks");
      } catch (e) {
        this.isLoading = false;
      }
    },
    save_signature() {
      var _this = this;
      // var png = _this.$refs.signature.save();
      var jpeg = _this.$refs.signature.save();
      this.signature_path = jpeg;
      console.log(this.signature_path);
      // var svg = _this.$refs.signature.save("image/svg+xml");
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.signature.undo();
    },

    fromDataURL(url) {
      var _this = this;
      _this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    },
    async handleFileUpload() {
      this.isLoading = true;
      if (this.form.client_name == undefined) {
        this.form.client = "";
      }
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("signature_path", this.signature_path);
      formData.append("client_name", this.form.client_name);
      await axios
        .post("upload_feedback_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.feedback = response.data.data;
          this.isLoading = false;
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
table,
th,
td {
  padding: 10px;
}
</style>
