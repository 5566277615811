<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h5 class="txt-dark">
            Pay Slip 3
            <refresh-button @click="refreshData"></refresh-button>
          </h5>
          <my-search
            :search="searchText"
            placeholder="Search here..."
            @searchData="search"
          ></my-search>
          <br /><br />
          <div class="row">
            <div class="col-md-4">
              <my-select
                style="margin: 9px !important"
                @change="updateMonth"
                disableFormControl="true"
                :selection.sync="month_id"
                :suggestions="months"
                placeholder="Select month"
              ></my-select>
              <my-select
                style="margin: 9px !important"
                @change="updateMonth"
                disableFormControl="true"
                :selection.sync="year"
                :suggestions="years"
                placeholder="Select year"
              ></my-select>
              <br /><br />
              <button @click="searchData('generate')">Generate</button>
              &nbsp;&nbsp;
              <button @click="sendEmail()">Send Email</button>
              &nbsp;&nbsp;
              <br /><br />
            </div>
            <div class="col-md-offset-2 col-md-6">
              <my-select
                style="margin: 9px !important"
                @change="selectDivision"
                disableFormControl="true"
                :selection.sync="group"
                :suggestions="groups"
                :placeholder="`Select GROUP`"
              >
              </my-select>
              <my-select
                style="margin: 9px !important"
                disableFormControl="true"
                :selection.sync="groupDivision"
                :suggestions="groupDivisions"
                :placeholder="`Select DIVISION`"
              >
              </my-select>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <errors :data="errors" />
                <div v-if="monthlySalaryofUsers.length">
                  <section id="mydiv">
                    <div class="table-wrap">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Send Email</th>
                            <th>Employee Code</th>
                            <th>Division</th>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Mobile</th>
                            <th>Is Active</th>
                            <th>Salary Slip</th>
                          </tr>
                        </thead>
                        <tbody>
                          <loading-table
                            :isLoading="isLoading"
                            cols="5"
                          ></loading-table>
                          <tr
                            v-for="(
                              monthlySalaryofUser, at
                            ) in monthlySalaryofUsers"
                            :key="`user${at}`"
                          >
                            <td>{{ serialNoStarting + at + 1 }}</td>
                            <td>
                              <input
                                type="text"
                                v-model="monthlySalaryofUser.sendEmailTo"
                              />
                              <button
                                class="btn-link"
                                @click="sendEmail(monthlySalaryofUser)"
                              >
                                Send Email
                              </button>
                              <span v-if="monthlySalaryofUser.isSendingEmail"
                                >Sending email...</span
                              >
                            </td>
                            <td>
                              {{ monthlySalaryofUser.employee.employee_id }}
                            </td>
                            <td>
                              {{
                                monthlySalaryofUser.employee.group_division
                                  ? monthlySalaryofUser.employee.group_division
                                      .division_name
                                  : ""
                              }}
                            </td>
                            <td>
                              {{ monthlySalaryofUser.employee.full_name || "" }}
                            </td>
                            <td>
                              {{
                                monthlySalaryofUser.employee.roles.length > 0
                                  ? monthlySalaryofUser.employee.roles[0].name
                                  : ""
                              }}
                            </td>
                            <td>{{ monthlySalaryofUser.employee.mobile }}</td>
                            <td>
                              {{
                                monthlySalaryofUser.employee.employee_status ==
                                  null ||
                                (monthlySalaryofUser.employee.employee_status
                                  .code != "LEFT" &&
                                  monthlySalaryofUser.employee.employee_status
                                    .code != "TERMINATE")
                                  ? "YES"
                                  : "NO"
                              }}
                            </td>
                            <td>
                              <router-link
                                :to="`/main/reports/pay-slip-3/${monthlySalaryofUser.employee.id}?monthId=${month_id}&year=${year}`"
                                target="_blank"
                              >
                                <u>View</u>
                              </router-link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
                <div v-else>
                  <h5>Pay Slip Not Generated Yet !!!</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script>
// import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";
export default {
  name: "PDFPaySlip3",
  data() {
    return {
      isLoading: false,
      isSaving: false,
      monthlySalaryofUsers: [],
      month_id: "",
      month_name: "",
      year: "",
      months: [],
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
        { id: 2022, name: 2022 },
        { id: 2023, name: 2023 },
      ],
      group: "",
      groupDivision: "",
      groups: [],
      groupDivisions: [],
      selectedCategories: [],
      searchText: "",
      showPaySlip3: false,
      count: 0,
      serialNoStarting: 0,
    };
  },
  mounted() {
    this.getMonths();
    this.getMasters();
    // this.month_id = moment().format('M')
    // this.month_name = moment().format('MMMM')
    this.year = moment().format("Y");
  },
  methods: {
    getMonths() {
      let months = moment.months();
      months.forEach((month, m) => {
        this.months.push({
          id: m + 1,
          name: month,
        });
      });
    },
    updateMonth() {
      this.month_name = moment(this.month_id, "M").format("MMMM");
      this.totalDaysInMonth = moment(
        this.year + "-" + this.month_id,
        "YYYY-M"
      ).daysInMonth();
    },
    async getMasters() {
      let masters = await axios.get("/monthly_salaries/masters");
      masters = masters.data;
      this.configurations = masters.configurations;
      this.categories = masters.categories;
      this.groups = masters.groups;
      this.refreshData();
    },
    selectDivision() {
      this.groupDivision = "";
      this.isGenerateSalaryForAll = false;
      let group = this.groups.find((group) => group.id == this.group);
      this.groupDivisions = [];
      group.group_divisions.forEach((groupDivision) => {
        this.groupDivisions.push({
          id: groupDivision.id,
          name: groupDivision.division_name,
        });
      });
    },
    refreshData() {
      // this.month_id = ''
      this.searchText = "";
      this.group = "";
      this.groupDivision = "";
      this.searchData();
    },
    // This function is called when we search from search text
    search(searchText) {
      this.searchText = searchText;
      this.searchData();
    },
    searchData(source = "") {
      this.showPaySlip3 = false;
      this.getData(source);
    },
    async getData(source) {
      try {
        this.isLoading = true;
        this.monthlySalaryofUsers = [];
        let monthlySalaryofUsers = await axios.get(
          `/pt_reports/generate_payslip3?monthId=${this.month_id}&year=${this.year}&groupId=${this.group}&groupDivisionId=${this.groupDivision}`
        );
        monthlySalaryofUsers =
          monthlySalaryofUsers.data.data.monthlySalaryofUsers;
        monthlySalaryofUsers.forEach((monthlySalaryofUser) => {
          monthlySalaryofUser.sendEmailTo = monthlySalaryofUser.employee.email;
          monthlySalaryofUser.isSendingEmail = false;
        });
        this.monthlySalaryofUsers = monthlySalaryofUsers;

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
      if (source == "search") {
        this.showPaySlip3 = false;
      }
      if (source == "generate") {
        this.showPaySlip3 = true;
      }
    },
    async sendEmail(monthlySalaryofUser) {
      monthlySalaryofUser.isSendingEmail = true;
      let emailsend = await axios.get(
        `/monthly_salaries/${monthlySalaryofUser.employee.id}/sendEmail?to=${monthlySalaryofUser.sendEmailTo}&monthId=${this.month_id}&year=${this.year}`
      );
      console.log(emailsend);
      setInterval(() => {
        monthlySalaryofUser.isSendingEmail = false;
      }, 3000);
    },
  },
};
</script>
