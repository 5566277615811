<template>
  <span>
    <router-link :to="link">
      <!-- <i class="zmdi zmdi-arrow-left"></i> -->
      <i class="far fa-arrow-alt-circle-left"></i>
    </router-link>
  </span>
</template>

<script type="text/javascript">
export default {
  name: 'BackButtonWidget',
  props: ['link']
}
</script>