<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/sites"></back-button>
            Create a new Company
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/sites">Sites</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Company Name*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Company Name"
                                v-model="form.name"
                              />
                              <span class="error-block">{{ errors.name }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Email*</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Email"
                                v-model="form.email"
                              />
                              <span class="error-block">{{
                                errors.email
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Phone*</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Phone"
                                v-model="form.phone"
                              />
                              <span class="error-block">{{
                                errors.phone
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Address*</label
                            >
                            <div class="col-sm-9">
                              <textarea
                                v-uppercase
                                class="form-control"
                                placeholder="Address"
                                v-model="form.address"
                              ></textarea>
                              <span class="error-block">{{
                                errors.address
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">PF No</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="PF No"
                                v-model="form.pf_no"
                              />
                              <span class="error-block">{{
                                errors.pf_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">TAN No</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="TAN No"
                                v-model="form.tan_no"
                              />
                              <span class="error-block">{{
                                errors.tan_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">PAN No</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="PAN No"
                                v-model="form.pan_no"
                              />
                              <span class="error-block">{{
                                errors.pan_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">ESI No</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="ESI No"
                                v-model="form.esi_no"
                              />
                              <span class="error-block">{{
                                errors.esi_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">TIN No</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="TIN No"
                                v-model="form.tin_no"
                              />
                              <span class="error-block">{{
                                errors.tin_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">GST No</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="GST No"
                                v-model="form.gst_no"
                              />
                              <span class="error-block">{{
                                errors.gst_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Registration No</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Registration No"
                                v-model="form.registration_no"
                              />
                              <span class="error-block">{{
                                errors.registration_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Udyam Registration No</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Udyam Registration No"
                                v-model="form.udyam_registration_no"
                              />
                              <span class="error-block">{{
                                errors.udyam_registration_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >HSN Code</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="HSN Code"
                                v-model="form.hsn_code"
                              />
                              <span class="error-block">{{
                                errors.hsn_code
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Establishment Code</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Establishment Code"
                                v-model="form.establishment_code"
                              />
                              <span class="error-block">{{
                                errors.establishment_code
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Place Of Origin</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Enter Place Of Origin"
                                v-model="form.place_of_origin"
                              />
                              <span class="error-block">{{
                                errors.place_of_origin
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Logo Path</label
                            >
                            <div class="col-sm-9">
                              <input
                                type="file"
                                id="file"
                                name="logo_path"
                                ref="file"
                                accept="image/*"
                                multiple
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >State Code</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Enter State Code"
                                v-model="form.state_code"
                              />
                              <span class="error-block">{{
                                errors.state_code
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">State*</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.state_id"
                                :suggestions="states"
                                placeholder="Select State"
                              ></my-select>
                              <span class="error-block">{{
                                errors.state_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Code Of Conduct*</label
                            >
                            <div class="col-sm-9">
                              <textarea
                                class="form-control"
                                placeholder="Code of Conduct"
                                v-model="form.code_of_conduct"
                              ></textarea>
                              <span class="error-block">{{
                                errors.code_of_conduct
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="col-md-12">
                        <h5>Bank Details</h5>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Bank Name</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Enter Bank Name"
                                v-model="form.bank_name"
                              />
                              <span class="error-block">{{
                                errors.bank_name
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Branch Name</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Enter Branch Name"
                                v-model="form.branch_name"
                              />
                              <span class="error-block">{{
                                errors.branch_name
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Account No</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Enter Account No"
                                v-model="form.account_no"
                              />
                              <span class="error-block">{{
                                errors.account_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">IFSC</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Enter IFSC"
                                v-model="form.ifsc"
                              />
                              <span class="error-block">{{ errors.ifsc }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-9">
                        <submit-button
                          :isLoading="isLoading"
                          @submit="save"
                          text="Save"
                        ></submit-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "siteCreate",
  data() {
    return {
      form: {},
      states: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("groups/masters");
      masters = masters.data;
      masters.states.forEach((state) => {
        this.states.push({
          id: state.id,
          name: state.description,
        });
      });
      masters.countries.forEach((country) => {
        this.countries.push({
          id: country.id,
          name: country.description,
        });
      });
    },
    async save() {
      try {
        let site = await axios.post(`/sites`, this.form);
        this.form = site.data.data;
        this.isLoading = false;
        this.$router.push("/sites");
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      let attachment = this.$refs.file.files[0];
      const siteid = this.form.id;
      let formData = new FormData();
      formData.append("id", siteid);
      formData.append("logo_path", attachment);
      console.log(formData);
      await axios
        .post("upload_site_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>
